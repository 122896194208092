import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LogoutAuthOptions, OidcSecurityService } from 'angular-auth-oidc-client';
import { map, switchMap, tap } from 'rxjs/operators';

import { ENVIRONMENTSETTINGS } from '../../../core/app-tokens/environment-setting';
import { Environment } from '../../../core/models/environment';
import { AuthLoginActionTypes, AuthLoginFailureAction, AuthLoginForbiddenAction, AuthLogoutAction } from '../actions';

@Injectable()
export class AuthEffects {
  unauth$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType<AuthLoginFailureAction>(AuthLoginActionTypes.Login_Failure),
        map((action) => action.payload),
        tap(() => {
          const route = this.environmentSettings.unauthorizedRoute || '/unauthorized';
          this.router.navigate([route], { skipLocationChange: true });
        })
      ) },
    { dispatch: false }
  );

  forbidden$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType<AuthLoginForbiddenAction>(AuthLoginActionTypes.Login_Forbidden),
        map((action) => action.payload),
        tap(() => {
          const route = this.environmentSettings.forbiddenRoute || '/forbidden';
          this.router.navigate([route], { skipLocationChange: true });
        })
      ) },
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType<AuthLogoutAction>(AuthLoginActionTypes.Logout),
        switchMap(() => {
          // https://angular-auth-oidc-client.com/docs/documentation/login-logout#logout
          // logoffMethod` - Which can be `GET` or `POST
          const logoutAuthOptions: LogoutAuthOptions = {
            logoffMethod: 'GET',
          };

          return this.securityService.logoff('loadshop-identity-server', logoutAuthOptions);
        })
      ) },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private securityService: OidcSecurityService,
    @Inject(ENVIRONMENTSETTINGS) private environmentSettings: Environment
  ) {}
}
