import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ForbiddenMessageService } from '../../../core/services';

@Component({
  selector: 'kbxl-forbidden',
  template: `
    <p *ngIf="(forbiddenMessage$ | async) && !(forbiddenMarkup$ | async)">
      {{ forbiddenMessage$ | async }}
    </p>
    <p *ngIf="forbiddenMarkup$ | async" [innerHtml]="forbiddenMarkup$ | async"></p>
  `,
})
export class ForbiddenComponent implements OnInit {
  forbiddenMessage$: Observable<string>;
  forbiddenMarkup$: Observable<string>;

  constructor(private messages: ForbiddenMessageService) {}

  ngOnInit() {
    this.forbiddenMessage$ = this.messages.getForbiddenMessage();
    this.forbiddenMarkup$ = this.messages.getForbiddenMarkup();
  }
}
