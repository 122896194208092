import { LoadDocumentMetadata, LoadDocumentUpload } from '.';
import { CarrierAccessorialRequestStatusEnum } from './carrier-accessorial-request-status-enum';

export interface CarrierAccessorialRequestActionData {
  carrierAccessorialRequestCommentId?: number;
  carrierAccessorialRequestId?: number;
  comment: string;
  userId?: string;
  userName: string;
  createDtTm: Date;
  status?: CarrierAccessorialRequestStatusEnum;
  statusDescription?: string;
  document?: LoadDocumentMetadata;

  // UI fields
  documentToUpload?: LoadDocumentUpload;
}
