import { CarrierDisplayData } from './carrier-display-data';
import { LoadCounterOfferActionData } from './load-counter-offer-action-data';
import { CounterOfferRequestStatusEnum } from './load-counter-offer-request-status-enum';
import { UserContactData } from './user-contact-data';

export interface LoadCounterOfferData {
  loadCounterOfferId: number;
  counterOfferLineHaulRate: number;
  counterOfferLoadshopFee?: number;
  counterOfferTotalRate: number;
  percentIncrease: number;
  expirationDate: Date;
  status: CounterOfferRequestStatusEnum;
  actions: LoadCounterOfferActionData[];
  requesterUserData: UserContactData;
  created: Date;
  isCarrierOffer: boolean;
  parentLoadCounterOfferId: number;
  targetUserData: UserContactData;

  // ui fields
  actionTaken?: CounterOfferRequestStatusEnum;
  hasError?: boolean;
}
