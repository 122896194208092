import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { DialogService } from 'primeng/dynamicdialog';
import { MultiSelect } from 'primeng/multiselect';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { RateSeekerBrandMarkup, SmartSpotBrandMarkup } from 'src/app/core/utilities/constants';
import {
  Carrier,
  CarrierProfile,
  CarrierScacRestrictionTypes,
  CarrierServiceTypeData,
  Commodity,
  CustomerData,
  CustomerLaneGroupShipperData,
  DynamicPricingRuleData,
  DynamicPricingScheduleChangedData,
  FuelRateEstimateByProgramData,
  ILoadAuditLogData,
  IShippingLoadDetail,
  LaneHistoryData,
  LoadCarrierScacData,
  LoadCarrierScacRestrictionData,
  LoadPricingRuleData,
  ServiceType,
  ShipperGeneratedCounterOffer,
  ShippingLoadDetail,
  ShippingLoadDetailError,
  SmartSpotPrice,
  SmartSpotPriceRequest,
  SpotPriceQuoteRequest,
  TransactionType,
  UserModel,
  ValidationProblemDetails,
  defaultDynamicPricing,
  defaultFuelRateEstimateByProgramData,
  defaultLoadPricingRule,
  defaultSmartSpotPriceRequest,
} from 'src/app/shared/models';
import { FuelProgramTypeEnum } from 'src/app/shared/models/FuelProgramType';
import { NoteData } from 'src/app/shared/models/note-data';
import { TransactionTypeService } from 'src/app/shared/services';
import { firstBy } from 'thenby';
import {
  CoreState,
  FuelRateEstimateAction,
  SmartSpotPriceLoadSpotQuoteAction,
  getFuelRateEstimate,
  getFuelRateEstimateError,
  getFuelRateEstimateLoading,
  getLoadshopSettings,
  getSmartSpotLoading,
  getSmartSpotPrice,
} from '../../../../core/store';
import {
  BaseComponent,
  CounterOfferRequestsModalComponent,
  ShippingLoadAuditLogGridModalComponent,
  SpotPriceModalComponent,
} from '../../../../shared/components';
import { automatedShipmentStatusServiceType, powerPlusServiceType } from '../../../../shared/constants';
import { defaultFuelRateEstimateData } from '../../../../shared/models/fuel-rate-estimate-data';
import { UserState, getUserProfileEntity } from '../../../../user/store';
import {
  ShippingLoadAuditLogsLoadAction,
  ShippingLoadDetailLoadSupplementalAction,
  ShippingLoadDetailToggleRateSeekerErrorAction,
  ShippingLoadDetailUpdateLoadAction,
  ShippingState,
  getShippingLoadAuditLogForLoadId,
  getShippingLoadLoadingAuditLogForLoadId,
  getShippingLoadsLoadingContractRatesForLoadId,
  getShippingLoadsSupplementalDataForLoadId,
  getShippingLoadsSupplementalLoadingForLoadId,
} from '../../../store';
import { ShipperCounterOfferCarrierSelectionComponent } from '../../shipper-counter-offer-carrier-selection';
import { ShippingLaneHistoryDisplayComponent } from '../shipping-lane-history-display';
import { LoadDynamicPricingContainerComponent } from '../shipping-load-dynamic-pricing-container';

@Component({
  selector: 'kbxl-shipping-load-detail',
  templateUrl: './shipping-load-detail.component.html',
  styleUrls: ['./shipping-load-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class ShippingLoadDetailComponent extends BaseComponent implements OnChanges, OnInit, OnDestroy {
  @ViewChild(LoadDynamicPricingContainerComponent) rateSeekerModal: LoadDynamicPricingContainerComponent;
  @ViewChild('selectedGroupCarriersMultiSelect') selectedGroupCarriersMultiSelect: MultiSelect;
  @ViewChild('selectedCarriersMultiSelect') selectedCarriersMultiSelect: MultiSelect;
  @Input() details: ShippingLoadDetail;
  private _problemDetails: ValidationProblemDetails;

  private smartSpotFetchQueue$: BehaviorSubject<SmartSpotPriceRequest[]> = new BehaviorSubject<SmartSpotPriceRequest[]>([]);

  @Input() set errors(value: ValidationProblemDetails) {
    this._problemDetails = value;

    if (!value || !value.errors) {
      return;
    }
    if (!this.details && !this.details.loadId) {
      return;
    }

    // Map from ValidationProblemDetails format to shipping load detail form format
    const decodedErrors: ShippingLoadDetailError = new ShippingLoadDetailError();
    decodedErrors.root = value.errors['urn:root'];
    decodedErrors.load = value.errors[`urn:load:${this.details.loadId}`];
    decodedErrors.commodity = value.errors[`urn:load:${this.details.loadId}:Commodity`];
    decodedErrors.totalRate = value.errors[`urn:load:${this.details.loadId}:TotalRate`];
    decodedErrors.fuelRateByProgram = value.errors[`urn:load:${this.details.loadId}:FuelRateByProgram`];
    decodedErrors.rateSeeker = value.errors[`urn:load:${this.details.loadId}:RateSeeker`];
    this.detailErrors = decodedErrors;
  }
  get errors() {
    return this._problemDetails;
  }
  @Input() carriers: CarrierProfile[];
  @Input() commodities: Commodity[];
  @Input() serviceTypes: ServiceType[];
  @Input() manualLoadServiceTypes: ServiceType[];
  @Input() user: UserModel;
  @Output() close = new EventEmitter<IShippingLoadDetail>();

  @ViewChild('form', { static: true }) form: NgForm;

  loadCarrierGroups: CustomerLaneGroupShipperData[];
  loadCarrierScacs: LoadCarrierScacData[];
  loadCarrierScacRestrictions: LoadCarrierScacRestrictionData[];
  laneHistory: LaneHistoryData[];
  detailErrors: ShippingLoadDetailError = null;

  loadingScacs$: Observable<boolean>;
  loadingScacRestrictions$: Observable<boolean>;
  loadingLoadCarriers$: Observable<boolean>;
  loadAuditLogs$: Observable<ILoadAuditLogData[]>;
  loadingAuditLogs$: Observable<boolean>;
  loadingSmartSpot$: Observable<boolean>;
  loadingFuelRate$: Observable<boolean>;
  loadingLaneHistory$: Observable<boolean>;
  displayDynamicPricingDialog: boolean;
  fuelError$: Observable<boolean>;
  fuelError = false;
  defaultPricingRule$: Observable<DynamicPricingRuleData>;
  carrierServiceTypeFilters: ServiceType[] = [];
  loadingNotes: boolean;
  notes: NoteData[];
  set selectedCommodity(commodity: Commodity) {
    if (this.details) {
      this.details.commodity = commodity ? commodity.commodityName : null;
    }
  }
  get selectedCommodity() {
    return this.commodities && this.details
      ? this.commodities.find((_) => _.commodityName.toLowerCase() === this.details.commodity.toLowerCase())
      : null;
  }

  readonlyEditor = true; // hack to prevent underlying quill control from automatically taking focus and scrolling visibile when initialized

  displayGroups: CustomerLaneGroupShipperData[];
  groupCarriers: Carrier[];
  availableCarriers: Carrier[];

  visibleScacs: LoadCarrierScacData[];
  hiddenScacs: LoadCarrierScacData[];
  carrierGroupSet = false;
  smartSpotLabel = SmartSpotBrandMarkup;
  rateSeekerLabel = RateSeekerBrandMarkup;
  selectedCarriers: Carrier[];
  selectedGroupCarriers: Carrier[];
  latestSmartSpotPriceQuote: SmartSpotPrice;

  private allGroupCarriersObj: { [s: string]: Carrier };
  private allGroupCarriers: Carrier[];
  private _selectedGroups: CustomerLaneGroupShipperData[];

  set selectedGroups(value: CustomerLaneGroupShipperData[]) {
    this._selectedGroups = value || [];
    this.updateCarrierLists();
  }
  get selectedGroups() {
    return this._selectedGroups;
  }

  private fuelLoading = false;
  private fuelActionTriggered = false;

  private closing = false;

  rateSeekerFloor: number;
  private latestLoadPricingRule: LoadPricingRuleData;
  // fuel program fields
  selectedFuelProgram: { label: string; value: FuelProgramTypeEnum };
  fuelPrograms: any[];
  fuelProgramSelectorEnabled = true;
  fuelRateByProgram?: FuelRateEstimateByProgramData;
  resetTotalRateOnClose = false;
  loadWasPosted = false;

  showSpotQuoteModal = false;
  selectedGroupCarriersSelectAll: boolean = false;
  selectedCarriersSelectAll: boolean = false;

  constructor(
    private transactionTypeService: TransactionTypeService,
    private shippingStore: Store<ShippingState>,
    private coreStore: Store<CoreState>,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef,
    private userState: Store<UserState>
  ) {
    super();

    // set up fuel programs and defaults
    this.fuelPrograms = [
      { label: 'BTF', value: FuelProgramTypeEnum.BTF },
      { label: 'DOE', value: FuelProgramTypeEnum.DOE },
    ];

    //
    // TODO: remove this once the new spot quote is available for everyone
    //
    combineLatest([coreStore.pipe(select(getLoadshopSettings)), userState.pipe(select(getUserProfileEntity))])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([loadshopSettings, userProfile]) => {
        if (loadshopSettings) {
          const featureFlag = loadshopSettings.find((setting) => setting.key.toLocaleLowerCase() === 'feature_spotpricev2');

          if (featureFlag) {
            const featureOn = featureFlag.value === 'true';
            if (featureOn && userProfile && userProfile.securityAccessRoles) {
              const securityRoles = ['System Admin', 'LS Admin'];
              this.showSpotQuoteModal = userProfile.securityAccessRoles.filter((x) => securityRoles.includes(x.accessRoleName)).length > 0;
            }
          }
        }
      });
  }

  /*
   ** Called from parent component when load is posted
   */
  public postLoad(): ShippingLoadDetail {
    this.details.carrierGroupIds = this.selectedGroups.map((carrierGroup) => carrierGroup.customerLaneGroupId);
    this.details.selectedGroupCarriers = this.selectedGroupCarriers;
    this.details.selectedCarriers = this.selectedCarriers;
    this.details.spotPriceQuote = this.latestSmartSpotPriceQuote.spotPriceQuote;
    this.setAllCarriersPosted();

    if (this.details.isDynamicPricingEnabled) {
      this.details.latestLoadPricingRule = this.latestLoadPricingRule;
      this.latestLoadPricingRule = null;
    }
    this.details.fuelRateByProgram = { ...this.fuelRateByProgram };

    // ensure we reset when the load is posted because we don't need to recalc ssp
    this.resetTotalRateOnClose = false;
    this.loadWasPosted = true;

    return this.details.selected ? this.details : null;
  }

  /*
   ** Called from parent component when load is removed from marketplace
   */
  public removedFromMarketplace(): void {
    // change linehaul to trigger an update and rebuild the pricing schedule
    if (this.details.isDynamicPricingEnabled) {
      this.latestLoadPricingRule = null;
      this.details.latestLoadPricingRule = null;
      this.rateSeekerModal.rebuildRateSeekerSchedule();
    }

    this.details.fuelRateByProgram = { ...this.fuelRateByProgram };
    this.details = this.deepClone(this.details);

    this.updateSummaryCard();

    this.cdr.detectChanges();
  }
  ngOnInit() {
    // Dispatch data calls
    this.shippingStore.dispatch(new ShippingLoadDetailLoadSupplementalAction({ loadId: this.details.loadId }));
    this.shippingStore.dispatch(new ShippingLoadAuditLogsLoadAction({ loadId: this.details.loadId }));

    // Page data subscriptions
    this.shippingStore
      .pipe(
        select(getShippingLoadsSupplementalDataForLoadId, { loadId: this.details.loadId }),
        takeUntil(this.destroyed$),
        map((x) => this.deepClone(x))
      )
      .subscribe((x) => {
        if (x && !this.closing) {
          this.loadCarrierGroups = x.carrierGroups;
          this.loadCarrierScacs = x.carrierScacs;
          this.loadCarrierScacRestrictions = x.carrierScacRestrictions;
          this.laneHistory = x.laneHistory;
          this.setGroupSelections();
          this.onScacChange();
        }
      });

    this.loadAuditLogs$ = this.shippingStore.pipe(
      select(getShippingLoadAuditLogForLoadId, { loadId: this.details.loadId }),
      map((x) => this.deepClone(x))
    );

    // Loading indicators
    this.loadingLoadCarriers$ = this.shippingStore.pipe(
      select(getShippingLoadsSupplementalLoadingForLoadId, { loadId: this.details.loadId })
    );
    this.loadingScacs$ = this.shippingStore.pipe(select(getShippingLoadsLoadingContractRatesForLoadId, { loadId: this.details.loadId }));
    this.loadingScacRestrictions$ = this.shippingStore.pipe(
      select(getShippingLoadsSupplementalLoadingForLoadId, { loadId: this.details.loadId })
    );
    this.loadingAuditLogs$ = this.shippingStore.pipe(select(getShippingLoadLoadingAuditLogForLoadId, { loadId: this.details.loadId }));
    this.loadingLaneHistory$ = this.shippingStore.pipe(
      select(getShippingLoadsSupplementalLoadingForLoadId, { loadId: this.details.loadId })
    );
    this.loadingSmartSpot$ = this.coreStore.pipe(select(getSmartSpotLoading, { loadId: this.details.loadId }));
    this.loadingFuelRate$ = this.coreStore.pipe(select(getFuelRateEstimateLoading, { loadId: this.details.loadId }));

    // Listen for SmartSpot store updates
    // After changes the ngOnChanges get hit for details
    this.coreStore
      .pipe(select(getSmartSpotPrice, { loadId: this.details.loadId }), takeUntil(this.destroyed$))
      .subscribe((smartSpotPrice) => {
        if (!smartSpotPrice && !smartSpotPrice?.spotPriceQuote) {
          return;
        }
        this.latestSmartSpotPriceQuote = smartSpotPrice;

        this.updateLineHaulWithLatestSmartSpotQuote();

        if (!this.details.lineHaulRate || this.details.lineHaulRate === 0) {
          this.resetTotalRateOnClose = true;

          // only override if we haven't posted or is on the marketplace
          if (!this.details.postedTotalRate && !this.details.totalRate) {
            this.details.totalRate = this.details.smartSpotRate;
            this.backfillPricingComponents();
          }

          // update contract rates since this call might have happened after they were checked and can change if linehaul was updated
          this.updateScacVisibility();
          // update the store with new linehaul
          this.updateSummaryCard();
        }
      });

    this.fuelError$ = this.coreStore.pipe(select(getFuelRateEstimateError, { loadId: this.details.loadId }), takeUntil(this.destroyed$));

    // Listen for fuel Rate Estimate updates
    // After changes the ngOnChanges get hit for details
    this.coreStore
      .pipe(
        select(getFuelRateEstimate, { loadId: this.details.loadId }),
        takeUntil(this.destroyed$),
        map((_) => this.deepClone(_))
      )
      .subscribe((fuelRateEstimateByProgramData) => {
        // if the fuel is empty or we didn't kick off the request, then we will be viewing old fuel data
        if (!fuelRateEstimateByProgramData || !this.fuelActionTriggered) {
          return;
        }

        let rateValue = 0;
        // set the default fuel program
        if (
          this.details.customer.fuelProgramType === FuelProgramTypeEnum.Both &&
          fuelRateEstimateByProgramData.btf !== null &&
          fuelRateEstimateByProgramData.doe !== null
        ) {
          this.fuelRateByProgram.btf = { ...fuelRateEstimateByProgramData.btf };
          this.fuelRateByProgram.doe = { ...fuelRateEstimateByProgramData.doe };

          rateValue = fuelRateEstimateByProgramData.doe.rateValue;
        } else {
          this.fuelRateByProgram.btf = { ...fuelRateEstimateByProgramData.btf };
          rateValue = fuelRateEstimateByProgramData.btf?.rateValue;
        }
        // Number(fuelRateEstimateByProgramData.btf?.rateValue);
        if (rateValue && rateValue > 0) {
          this.details.shippersFSC = rateValue;

          // if we have SSP or the linehaul is populated and we retrieve fuel, subtract it from the linehaul
          // to keep the total rate the same
          if (this.details.lineHaulRate > 0 && !this.details.customer.allowEditingFuel && !this.details.postedTotalRate) {
            this.details.lineHaulRate -= rateValue;
          }

          this.updateSummaryCard();
          this.updateScacVisibility();
        }
        this.fuelError = fuelRateEstimateByProgramData.fuelError;
      });

    // We kick off smartspot request for changes in load (carriers), queue those requests up and take the latest one
    this.smartSpotFetchQueue$
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(350),
        filter((requests) => requests && requests.length > 0)
      )
      .subscribe((smartSpotRequest) => {
        // Keep the existing action
        // this.coreStore.dispatch(new SmartSpotPriceLoadAction(smartSpotRequest));

        const origin = this.details.loadStops.find((x) => x.stopNbr === 1);
        const destination = this.details.loadStops.find((x) => x.stopNbr === this.details.loadStops.length);

        const selectedCarrierScacs = (this.selectedCarriers || []).concat(this.selectedGroupCarriers || []);

        const distinctCarriers = new Set(selectedCarrierScacs.map((x) => x.carrierId));
        const distinctScacs = selectedCarrierScacs.map((x) => x.carrierScacs).reduce((input, output) => input.concat(output), []);

        let serviceTypes = [];
        if (this.details.serviceTypes && this.details.serviceTypes.length > 0) {
          serviceTypes = this.details.serviceTypes.map((x) => x.name);
        }

        const payload: SpotPriceQuoteRequest = {
          loadId: this.details.loadId,
          commodity: this.details.commodity,
          shipmentServices: serviceTypes,
          equipmentCategory: this.details.equipmentCategoryId,
          equipmentId: this.details.equipmentId,
          origin: {
            city: origin.city,
            state: origin.state,
            countryCode: origin.country,
            postalCode: origin.postalCode,
            coordinates: {
              latitude: origin.latitude,
              longitude: origin.longitude,
            },
          },
          destination: {
            city: destination.city,
            state: destination.state,
            countryCode: destination.country,
            postalCode: destination.postalCode,
            coordinates: {
              latitude: destination.latitude,
              longitude: destination.longitude,
            },
          },
          pickupAppointmentType: origin.apptType,
          dropoffAppointmentType: destination.apptType,
          stops: this.details.loadStops.length,
          miles: this.details.mileage,
          weight: this.details.weight,
          intermediateStopsCoordinates: [],
          // NOTE that once TRS_003 runs and actually posts the load, we wont have the exact count
          totalPostedScacs: distinctScacs.length, // Assume every carrier is eligible that we have the on screen
          totalPostedCarriers: distinctCarriers.size, // Assume every carrier is eligible that we have
          originLateArrivalDateTime: origin.lateDtTm,
          destinationLateArrivalDateTime: destination.lateDtTm,
        };

        if (this.details.loadStops.length > 2) {
          payload.intermediateStopsCoordinates = this.details.loadStops
            .filter((x) => x.stopNbr > 1 && x.stopNbr < this.details.loadStops.length)
            .map((x) => {
              return {
                latitude: x.latitude,
                longitude: x.longitude,
              };
            });
        }

        this.coreStore.dispatch(new SmartSpotPriceLoadSpotQuoteAction(payload));
      });

    if (!this.details.totalRate) {
      this.details.totalRate = this.details.lineHaulRate + this.details.shippersFSC;
    }
  }

  ngOnDestroy(): void {
    if (this.resetTotalRateOnClose) {
      this.details.lineHaulRate = 0;
      this.details.totalRate = 0;
      this.details.shippersFSC = 0;
      this.details.selected = false;

      this.updateSummaryCard();
    }

    if (this.loadWasPosted) {
      // when posting set this field so if the user reloads this before its posted on the marketplace, we have the correct total rate
      this.details.postedTotalRate = this.details.totalRate;
      this.details.selected = false;
      this.updateSummaryCard();
    }
    super.ngOnDestroy();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.closing) {
      return;
    }
    if (changes.carriers) {
      this.setGroupSelections();
      this.onScacChange();
    }

    if (changes.details) {
      if (!this.details.onLoadshop && this.details.postedTotalRate > 0) {
        this.details.totalRate = this.details.postedTotalRate;
      }

      if (!this.selectedFuelProgram && changes.details.currentValue) {
        if (changes.details.currentValue.customer.fuelProgramType === FuelProgramTypeEnum.Both) {
          this.selectedFuelProgram = this.fuelPrograms[1];
        } else if (changes.details.currentValue.customer.fuelProgramType === FuelProgramTypeEnum.BTF) {
          this.selectedFuelProgram = this.fuelPrograms[0];
        }
      }

      this.setSelectedServiceTypes();

      setTimeout(() => {
        this.readonlyEditor = false;
      }, 100); // Hack to get around quill editor autofocus feature/bug

      this.populateFuelProgramsWithPostLoadFuelPrograms();

      // get  fuel first, then trigger SSP
      this.triggerGetFuelRateEstimate();

      // after fuel loads, the fuel will be updated and we need to go fetch SSP
      if (changes.details.currentValue && changes.details.currentValue.shippersFSC > 0 && this.fuelLoading) {
        this.fuelLoading = false;
        this.triggerSmartSpotPriceUpdate();
      }

      // HACK to get memory leak from happening
      // the marketplace check below has a flaw; however due to testing we will need to revisit it at a later date
      // the loads are populated with a LH =0 but total rate > 1,
      if (changes.details && JSON.stringify(changes.details.currentValue) === JSON.stringify(changes.details.previousValue)) {
        console.log('ngChanges occurred but was ignored because the obj models are the same.');
        return;
      }

      if (
        // on marketplace
        ((!this.details.lineHaulRate || this.details.lineHaulRate < 1) && this.details.totalRate > 0) ||
        // processing to marketplace
        (!this.isInValidStateForPosting() && this.details.totalRate > 0 && this.details.lineHaulRate <= 0)
      ) {
        this.backfillPricingComponents();
      }
    }

    // when the user selects another load, since we have overridden the SSP and DAT, this NGChange will get kicked off
    // ensure we keep the latest quote pricing if it was loaded
    this.updateLineHaulWithLatestSmartSpotQuote();
  }
  closeClicked() {
    this.closing = true;
    if (this.details) {
      this.close.emit({ ...this.details, hasChanges: this.form.dirty });
    }
  }

  viewCarrierClicksClicked() {
    this.dialogService.open(ShippingLoadAuditLogGridModalComponent, {
      data: {
        loadAuditLogs: this.loadAuditLogs$,
        loadingAuditLogs: this.loadingAuditLogs$,
      },
      width: '100%',
      style: { 'max-width': 'calc(100vw - 200px)', overflow: 'auto', 'max-height': '70%' },
      header: 'Carrier Views',
      closable: true,
      closeOnEscape: true,
    });
  }

  viewLaneHistoryClicked() {
    this.dialogService.open(ShippingLaneHistoryDisplayComponent, {
      data: {
        laneHistory: this.laneHistory,
      },
      width: '100%',
      style: { 'max-width': 'calc(100vw - 200px)', overflow: 'auto', 'max-height': '70%' },
      header: 'Lane History',
      closable: true,
      closeOnEscape: true,
    });
  }

  showErrorSummary() {
    return (
      this.detailErrors &&
      (this.detailErrors.root ||
        this.detailErrors.load ||
        this.detailErrors.totalRate ||
        this.detailErrors.commodity ||
        this.detailErrors.fuelRateByProgram ||
        this.detailErrors.rateSeeker)
    );
  }

  private populateFuelProgramsWithPostLoadFuelPrograms(): void {
    if (!this.fuelRateByProgram && this.details) {
      this.fuelRateByProgram = { ...defaultFuelRateEstimateByProgramData };
    } else {
      return;
    }

    // populate all the fuel estimate data with that fuel programs that were posted with the load
    if (
      this.details.customer.fuelProgramType === FuelProgramTypeEnum.Both ||
      this.details.customer.fuelProgramType === FuelProgramTypeEnum.BTF
    ) {
      if (!this.fuelRateByProgram.btf) {
        this.fuelRateByProgram.btf = {
          ...defaultFuelRateEstimateData,
        };
      }
      const btfLoadFuel = this.details.loadFuelPrograms.find((x) => x.fuelProgramType === FuelProgramTypeEnum.BTF);
      if (btfLoadFuel) {
        this.fuelRateByProgram.btf.rateValue = btfLoadFuel.fuelRate;
      }
    }
    if (
      this.details.customer.fuelProgramType === FuelProgramTypeEnum.Both ||
      this.details.customer.fuelProgramType === FuelProgramTypeEnum.DOE
    ) {
      if (!this.fuelRateByProgram.doe) {
        this.fuelRateByProgram.doe = {
          ...defaultFuelRateEstimateData,
        };
      }
      const doeLoadFuel = this.details.loadFuelPrograms.find((x) => x.fuelProgramType === FuelProgramTypeEnum.DOE);
      if (doeLoadFuel) {
        this.fuelRateByProgram.doe.rateValue = doeLoadFuel.fuelRate;
      }
    }
    // override the incoming if the user hasn't edited the fuel already
    if (this.details.customer.fuelProgramType === FuelProgramTypeEnum.None && this.details.shippersFSC < 1) {
      this.fuelRateByProgram.overrideFuelAmount = this.details.loadFuelPrograms.find(
        (x) => x.fuelProgramType === FuelProgramTypeEnum.None
      )?.fuelRate;
    }
  }

  triggerGetFuelRateEstimate() {
    // set the default shipperFSC based on posted load fuel programs, create load overrides
    if (this.details && this.details.shippersFSC <= 0) {
      if (this.details.customer.fuelProgramType === FuelProgramTypeEnum.Both) {
        if (this.selectedFuelProgram.value === FuelProgramTypeEnum.BTF) {
          this.details.shippersFSC = this.fuelRateByProgram.btf.rateValue;
        } else {
          this.details.shippersFSC = this.fuelRateByProgram.doe.rateValue;
        }
      } else if (this.details.customer.fuelProgramType === FuelProgramTypeEnum.None) {
        this.details.shippersFSC = this.fuelRateByProgram.overrideFuelAmount;
      } else if (this.details.customer.fuelProgramType === FuelProgramTypeEnum.BTF) {
        this.details.shippersFSC = this.fuelRateByProgram.btf.rateValue;
      }
    }
    //
    // Note: Fuel should have been fetched when loads were created; however their still might
    // be loads with no fuel when this change is deployed, so go fetch fuel
    //
    const missingFuel = this.details && this.details.shippersFSC <= 0;
    if (this.fuelRateAllowed() && missingFuel && !this.fuelError && !this.fuelLoading) {
      this.fuelLoading = true;
      this.fuelActionTriggered = true;
      this.coreStore.dispatch(new FuelRateEstimateAction({ loadId: this.details.loadId, customerId: this.user.primaryCustomerId }));
    }
  }

  fuelRateAllowed(): boolean {
    const shipper = this.user.authorizedShippers.find((x) => x.customerId === this.user.primaryCustomerId) as CustomerData;
    return this.details && !this.details.usesAllInRates && shipper.fuelProgramType !== FuelProgramTypeEnum.None;
  }

  isSmartSpotUpdateAllowed(): boolean {
    const hasFuelRate = this.details && this.details.shippersFSC > 0;
    return !this.fuelRateAllowed() || (this.fuelRateAllowed() && hasFuelRate);
  }

  triggerSmartSpotPriceUpdate() {
    if (
      this.details &&
      this.loadCarrierGroups &&
      this.carriers &&
      this.loadCarrierScacs &&
      this.selectedGroups &&
      (this.selectedCarriers || this.selectedGroupCarriers) &&
      this.isSmartSpotUpdateAllowed()
    ) {
      const carrierIds = (this.selectedCarriers || []).concat(this.selectedGroupCarriers || []).map((x) => x.carrierId);
      this.updateSmartSpotPrice({ details: this.details, carrierIds: carrierIds });
    }
  }

  onScacChange() {
    this.updateScacVisibility();
    this.triggerSmartSpotPriceUpdate();

    this.setAllCarriersPosted();

    // check fuel program
    if (
      this.details.customer &&
      this.details.customer.fuelProgramType === FuelProgramTypeEnum.Both &&
      this.loadCarrierScacs &&
      this.loadCarrierScacs.length > 0
    ) {
      this.fuelProgramSelectorEnabled = false;
      // check if all selected carriers are on that same program
      // if so update selectedFuelProgram and disable it
      let btfScacsSelected = false;
      let doeScacsSelected = false;

      for (let index = 0; index < this.selectedCarriers.length; index++) {
        const carrier = this.selectedCarriers[index];
        if (btfScacsSelected && doeScacsSelected) {
          break;
        }
        const scacs = this.loadCarrierScacs.filter((x) => carrier.carrierScacs.includes(x.scac));

        if (!btfScacsSelected) {
          btfScacsSelected = scacs.some((x) => x.fuelProgramType === FuelProgramTypeEnum.BTF);
        }

        if (!doeScacsSelected) {
          doeScacsSelected = scacs.some((x) => x.fuelProgramType === FuelProgramTypeEnum.DOE);
        }
      }

      this.fuelProgramSelectorEnabled = (btfScacsSelected && doeScacsSelected) || (!btfScacsSelected && !doeScacsSelected);

      // force selection if all carriers are specific fuel program
      if (btfScacsSelected && !doeScacsSelected) {
        this.selectedFuelProgram = this.fuelPrograms[0];
        this.fuelProgramChanged(null);
      }
      if (!btfScacsSelected && doeScacsSelected) {
        this.selectedFuelProgram = this.fuelPrograms[1];
        this.fuelProgramChanged(null);
      }
    }
    this.updateSelectAllMultiSelects();
  }

  setAllCarriersPosted() {
    if (this.selectedGroups) {
      if (!this.carriers) {
        this.details.allCarriersPosted = false;
      } else {
        this.details.allCarriersPosted = this.selectedGroups.length === 0 && this.carriers.length === this.selectedCarriers?.length;
      }
    }
  }

  showLoadDynamicPricing(e: Event) {
    if (!this.details.latestLoadPricingRule || this.latestLoadPricingRule) {
      this.defaultPricingRule$ = of({ ...defaultDynamicPricing });
    } else {
      this.defaultPricingRule$ = null;
    }
    this.displayDynamicPricingDialog = true;
  }

  applyRateSeekerChanges(payload: DynamicPricingScheduleChangedData) {
    const rule = payload.pricingRule;

    if (this.detailErrors && this.detailErrors.rateSeeker) {
      this.detailErrors.rateSeeker = null;
    }
    if (!payload.valid && payload.error && payload.error.length > 0) {
      this.detailErrors = {
        ...this.detailErrors,
        rateSeeker: [payload.error],
      };
      this.latestLoadPricingRule = null;
      this.rateSeekerFloor = null;

      if (!this.details.hasRateSeekerError) {
        this.details.hasRateSeekerError = true;
        this.shippingStore.dispatch(new ShippingLoadDetailToggleRateSeekerErrorAction([this.details.loadId], true));
      }

      return;
    }

    let loadPricingRule: LoadPricingRuleData = defaultLoadPricingRule;

    if (this.latestLoadPricingRule && this.latestLoadPricingRule.dynamicPricingSchedule) {
      loadPricingRule = this.latestLoadPricingRule;
    }

    this.latestLoadPricingRule = {
      ...loadPricingRule,
      loadId: this.details.loadId,
      pricingRuleId: rule.customerLaneGroupPricingRuleId,
      floorAmt: rule.floorAmt,
      floorPct: rule.floorPct,
      nbrAdjustments: rule.nbrAdjustments,
      adjustmentFrequency: rule.adjustmentFrequency,
      dynamicPricingSchedule: rule.pricingSchedule,
      reservedMargin: rule.reservedMargin,
      startBeforePickUpDateHours: rule.startBeforePickUpDateHours,
      stopBeforePickUpDateHours: rule.stopBeforePickUpDateHours,
    };
    this.setRateSeekerFloor();
    if (this.details.hasRateSeekerError) {
      this.details.hasRateSeekerError = false;
      this.shippingStore.dispatch(new ShippingLoadDetailToggleRateSeekerErrorAction([this.details.loadId], false));
    }

    this.details.latestLoadPricingRule = this.latestLoadPricingRule;
    this.updateSummaryCard();
  }

  updateSummaryCard() {
    this.details.totalRate = this.details.lineHaulRate + this.details.shippersFSC;
    this.shippingStore.dispatch(new ShippingLoadDetailUpdateLoadAction({ ...this.details }));
  }

  backfillPricingComponents() {
    this.details.lineHaulRate = this.details.totalRate - this.details.shippersFSC;
    this.updateScacVisibility();
    this.updateSummaryCard();
  }

  onLineHaulAmountChange() {
    this.updateSummaryCard();
    this.updateScacVisibility();
  }

  isInValidStateForPosting(): boolean {
    const validPostingTransaction = this.transactionTypeService.getTransactionTypesForPost();

    return validPostingTransaction.includes(TransactionType[this.details.latestTransactionTypeId]);
  }

  onServicesChanged() {
    const powerPlusService = this.details.serviceTypes.find((x) => x.name === powerPlusServiceType);
    if (powerPlusService) {
      this.details.isPowerPlus = true;
    } else {
      this.details.isPowerPlus = false;
      this.details.altXCapRate = null;
    }

    const automatedShipmentStatus = this.details.serviceTypes.find((x) => x.name === automatedShipmentStatusServiceType);

    if (automatedShipmentStatus) {
      // filter carrier by this service type
      const tmp = [...this.carrierServiceTypeFilters];
      if (!tmp.find((x) => x.serviceTypeId === automatedShipmentStatus.serviceTypeId)) {
        tmp.push(automatedShipmentStatus);
      }
      this.carrierServiceTypeFilters = tmp;
      this.selectedCarriers = null;
    } else {
      // remove if its on the filter
      this.carrierServiceTypeFilters = this.carrierServiceTypeFilters.filter((x) => x.name !== automatedShipmentStatusServiceType);
      this.selectedCarriers = null;
    }

    this.updateCarrierLists();
  }

  onLoadContactEmailChanged() {
    if (this.details && this.details.loadContact) {
      this.details.loadContact.isEmailOverridden = true;
      // update the store with the new value
      // NOTE this will persist the change so if the user wants to undo it
      // when they close the load, it will not revert, similar to linehaul / fuel updates
      this.shippingStore.dispatch(new ShippingLoadDetailUpdateLoadAction(this.details));
    }
  }

  getCarrierSelectionLabel(): string {
    if (this.loadCarrierGroups && this.loadCarrierGroups.length > 0) {
      return 'Check to Include Additional Carriers';
    }
    return 'All Carriers';
  }

  removeServiceType(serviceType: CarrierServiceTypeData): void {
    // remove if its on the filter
    this.carrierServiceTypeFilters = this.carrierServiceTypeFilters.filter((x) => x.serviceTypeId !== serviceType.serviceTypeId);
    this.details.serviceTypes = this.details.serviceTypes.filter((x) => x.serviceTypeId !== serviceType.serviceTypeId);
    this.selectedCarriers = null;
    this.updateCarrierLists();
  }

  setRateSeekerFloor(): void {
    if (this.details && this.latestLoadPricingRule && this.latestLoadPricingRule.dynamicPricingSchedule) {
      const activePriceBlock = this.latestLoadPricingRule.dynamicPricingSchedule.schedule.find((x) => x.activePrice);
      if (activePriceBlock) {
        this.rateSeekerFloor = activePriceBlock.adjustmentPrice;
      } else {
        const floor = this.latestLoadPricingRule.dynamicPricingSchedule.schedule[0].adjustmentPrice;

        this.rateSeekerFloor = floor > 0 ? floor : null;
      }
    } else {
      this.rateSeekerFloor = null;
    }
  }
  allowShipperCounter(): boolean {
    const shipper = this.user.authorizedShippers.find((x) => x.customerId === this.user.primaryCustomerId) as CustomerData;

    return (
      this.details.isCounterOfferEnabled &&
      this.details.allowCounterOffer &&
      this.details.onLoadshop &&
      shipper &&
      shipper.contractCarriersBookAtPostedRate
    );
  }
  showShipperCounterOfferModal(): void {
    const dialog$ = this.dialogService.open(ShipperCounterOfferCarrierSelectionComponent, {
      data: {
        visibleScacs: this.visibleScacs,
        hiddenScacs: this.hiddenScacs,
      },
      header: 'Counter Offer to Carrier',
      closable: true,
      closeOnEscape: true,
    });

    dialog$.onClose.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      const shipper = this.user.authorizedShippers.find((y) => y.customerId === this.user.primaryCustomerId) as CustomerData;
      // check if carrier selection was made
      if (x && x.scac && x.carrierUser) {
        let fuelRate = this.details.shippersFSC;
        if (x.scacFuelProgramType === FuelProgramTypeEnum.BTF) {
          fuelRate = this.details.loadFuelPrograms.find((y) => y.fuelProgramType === FuelProgramTypeEnum.BTF).fuelRate;
        } else if (x.scacFuelProgramType === FuelProgramTypeEnum.DOE) {
          fuelRate = this.details.loadFuelPrograms.find((y) => y.fuelProgramType === FuelProgramTypeEnum.DOE).fuelRate;
        }
        const postTabLineHaul = this.details.totalRate - fuelRate;
        const shipperOfferOptions: ShipperGeneratedCounterOffer = {
          carrierUser: x.carrierUser,
          scac: x.scac,
          contractedCarrierLineHaul: x.contractRate,
          postTabLineHaul: postTabLineHaul,
          postTabFuel: fuelRate,
        };

        this.dialogService.open(CounterOfferRequestsModalComponent, {
          data: {
            fetchLoad: true,
            loadId: this.details.loadId,
            user: this.user,
            shipper: shipper,
            shipperOfferOptions: shipperOfferOptions,
          },
          header: 'Counter Offer to Carrier',
          closable: true,
          closeOnEscape: true,
        });
      }
    });
  }
  /*
   ** Fuel program was changed, either to view or was forced based on carrier selection
   */
  fuelProgramChanged(payload: { originalEvent: Event; value: any }): void {
    let rateValue = 0;

    if (this.selectedFuelProgram) {
      if (this.selectedFuelProgram.value === FuelProgramTypeEnum.BTF) {
        rateValue = this.fuelRateByProgram.btf.rateValue;
      } else if (this.selectedFuelProgram.value === FuelProgramTypeEnum.DOE) {
        rateValue = this.fuelRateByProgram.doe.rateValue;
      }
    }
    if (rateValue && rateValue > 0) {
      this.details.shippersFSC = rateValue;
      this.details.lineHaulRate = this.details.totalRate - this.details.shippersFSC;
      this.updateSummaryCard();
    }
  }

  /*
   ** Update the fuel program obj based on inputs from the user
   */
  updateFuelProgram(): void {
    if (!this.fuelRateByProgram) {
      this.fuelRateByProgram = { ...defaultFuelRateEstimateByProgramData };
    }
    // the user can only select between BTF and DOE
    if (this.selectedFuelProgram) {
      if (this.selectedFuelProgram.value === FuelProgramTypeEnum.BTF) {
        this.fuelRateByProgram.btf.rateValue = this.details.shippersFSC;
      } else if (this.selectedFuelProgram.value === FuelProgramTypeEnum.DOE) {
        this.fuelRateByProgram.doe.rateValue = this.details.shippersFSC;
      }
    }
    if (this.details.customer.fuelProgramType === FuelProgramTypeEnum.None && this.details.customer.allowEditingFuel) {
      // clone the obj to prevent read only error
      this.fuelRateByProgram = JSON.parse(JSON.stringify(this.fuelRateByProgram));
      this.fuelRateByProgram.overrideFuelAmount = this.details.shippersFSC;
    }
    this.details.lineHaulRate = this.details.totalRate - this.details.shippersFSC;
    this.updateSummaryCard();
    this.updateScacVisibility();
  }

  /*
   ** Opens modal to view SpotPrice details
   */
  openSpotPriceModal(): void {
    const ref$ = this.dialogService.open(SpotPriceModalComponent, {
      header: 'Spot Price Quote Details',
      baseZIndex: 5000,
      data: {
        spotPriceQuote: this.latestSmartSpotPriceQuote.spotPriceQuote,
        fuel: this.details.shippersFSC,
      },
    });

    ref$.onClose.pipe(takeUntil(this.destroyed$)).subscribe((arg) => {
      // if the user selects a new total rate from the spot quote, use that
      if (arg && arg.selectedPrice) {
        this.details.totalRate = arg.selectedPrice;
        this.backfillPricingComponents();
      }
    });
  }

  onSelectedGroupCarriersSelectAllChange(event) {
    this.selectedGroupCarriers = event.checked ? [...this.selectedGroupCarriersMultiSelect.visibleOptions()] : [];
    this.selectedGroupCarriersSelectAll = event.checked;
  }
  onSelectedCarriersSelectAllChange(event) {
    this.selectedCarriers = event.checked ? [...this.selectedCarriersMultiSelect.visibleOptions()] : [];
    this.selectedCarriersSelectAll = event.checked;
  }

  private updateLineHaulWithLatestSmartSpotQuote(): void {
    if (this.latestSmartSpotPriceQuote && this.details) {
      this.details.smartSpotRate = this.latestSmartSpotPriceQuote?.spotPriceQuote?.loadshopDatGuardRate;

      // this.details.datGuardRate = this.latestSmartSpotPriceQuote.datGuardRate;

      // this.details.smartSpotRate = this.latestSmartSpotPriceQuote.price;
      // this.details.datGuardRate = this.latestSmartSpotPriceQuote.datGuardRate;
      // this.details.machineLearningRate = this.latestSmartSpotPriceQuote.machineLearningRate;
    }
  }

  private setGroupSelections() {
    if (this.details && this.loadCarrierGroups && this.carriers && this.loadCarrierScacRestrictions) {
      this.orderGroupsAndDefaultSelection();
      this.setSelectedServiceTypes();
      // update all carriers
      this.updateCarrierLists();
    }
  }

  private orderGroupsAndDefaultSelection() {
    // Rank Groups
    // Ranking happens in the Api. Still sort just in case
    const rankings = this.loadCarrierGroups.map((_) => ({
      group: _,
      rank: _.rank,
    }));

    const rankedGroups = rankings.sort(firstBy((x) => x.rank)); // asc
    const maxRank = rankedGroups.length > 0 ? rankedGroups[0].rank : null;

    // Set Display Groups
    this.displayGroups = rankedGroups.map((_) => _.group);

    // if load doesnt have a group selected then select the best group
    // we do not want to default a carrier group is the load has been posted
    if (
      this.details.carrierGroupIds.length === 0 &&
      !this.details.onLoadshop &&
      this.isInValidStateForPosting() &&
      (!this.selectedGroups || this.displayGroups.length === 0) &&
      (!this.carrierGroupSet || !this.loadCarrierScacs || this.loadCarrierScacs.length === 0)
    ) {
      this.selectedGroups = rankedGroups.filter((_) => _.rank === maxRank).map((_) => _.group);

      if (this.selectedGroups.length === 0) {
        this.selectedCarriers = this.availableCarriers;
      }

      this.carrierGroupSet = true;
    } else if (this.loadCarrierScacs && !this.carrierGroupSet) {
      // Load already has a group selected

      // Set Groups and Carries by Scacs already assigned to load
      const selectedScacs = this.loadCarrierScacs.map((loadCarrierScac) => loadCarrierScac.scac);

      // Set Selected Carriers
      const selectedCarriers: Carrier[] = [];

      this.carriers.forEach((carrier) => {
        const intersectingScacs = carrier.carrierScacs.filter((scac) => selectedScacs.includes(scac));
        // We could have Dedicated SCACs that were removed from the load
        //  so if there is at least one SCAC from the carrier select it
        if (intersectingScacs.length > 0) {
          selectedCarriers.push(carrier);
        }
      });

      this.selectedGroupCarriers = this.filterCarriersByScacRestrictions(selectedCarriers);
      this.updateSelectAllMultiSelects();
      this.selectedCarriers = selectedCarriers;
      this._selectedGroups = this.loadCarrierGroups.filter((lcg) => this.details.carrierGroupIds.includes(lcg.customerLaneGroupId));
    }

    this.onScacChange();
  }

  private setSelectedServiceTypes(): void {
    const serviceTypeIds = new Set();
    if (this.details.serviceTypes && this.details.serviceTypes.length > 0) {
      this.details.serviceTypes.forEach((x) => serviceTypeIds.add(x.serviceTypeId));
    }

    // check if any carrier groups and service types
    if (this.selectedGroups && this.selectedGroups.length > 0) {
      this.selectedGroups.forEach((x) => {
        x.serviceTypeIds.forEach((y) => serviceTypeIds.add(y));
      });
    }
    // auto select service types based on lane management for loads
    // Only if load is non brokered and not manually created loads
    if (!this.details.isBrokeredLoad && !this.details.manuallyCreated && this.serviceTypes) {
      this.details.serviceTypes = this.serviceTypes.filter((x) => serviceTypeIds.has(x.serviceTypeId));
    }
    const automatedShipmentStatus = this.details.serviceTypes.find((x) => x.name === automatedShipmentStatusServiceType);

    if (automatedShipmentStatus) {
      // filter carrier by this service type
      const tmp = [...this.carrierServiceTypeFilters];
      if (!tmp.find((x) => x.serviceTypeId === automatedShipmentStatus.serviceTypeId)) {
        tmp.push(automatedShipmentStatus);
      }
      this.carrierServiceTypeFilters = tmp;
    }
  }

  private setAvailableGroupCarriers() {
    if (this.loadCarrierGroups && this.loadCarrierGroups.length > 0) {
      this.allGroupCarriersObj = (this.selectedGroups || []).reduce((value, carrierGroup) => {
        carrierGroup.carriers.forEach((carrier) => (value[carrier.carrierId] = carrier));
        return value;
      }, {} as { [s: string]: Carrier });

      this.allGroupCarriers = Object.keys(this.allGroupCarriersObj).map((_) => this.allGroupCarriersObj[_]);

      // Use to filter carrier group carriers for faster performance
      const allCarriersObj = (this.carriers || []).reduce((value, carrier) => {
        value[carrier.carrierId] = carrier;
        return value;
      }, {});

      const c = this.allGroupCarriers.filter((carrier) =>
        Object.prototype.hasOwnProperty.call(allCarriersObj, carrier.carrierId)
      ) as CarrierProfile[];
      const carriersFilterdByServiceType = this.filterCarriersByServiceTypes(c);

      // filter group carriers to only carriers that are active
      const activeGroupCarriers = this.filterCarriersByScacRestrictions(carriersFilterdByServiceType);

      if (!this.allGroupCarriers || this.allGroupCarriers.length === 0) {
        this.groupCarriers = [];
      } else {
        this.groupCarriers = activeGroupCarriers;
      }
    }

    // results are loaded but no groups found
    if (this.loadCarrierGroups && !this.allGroupCarriersObj) {
      this.allGroupCarriersObj = {};
      this.allGroupCarriers = [];
    }
  }

  private setAvailableCarriers() {
    if (this.allGroupCarriersObj) {
      let availableCarriers: CarrierProfile[];
      if (this.loadCarrierGroups && this.loadCarrierGroups.length > 0) {
        if (!this.allGroupCarriers || this.allGroupCarriers.length === 0) {
          availableCarriers = this.carriers;
        } else {
          availableCarriers = (this.carriers || []).filter(
            (_) => !Object.prototype.hasOwnProperty.call(this.allGroupCarriersObj, _.carrierId)
          );
        }
      } else {
        availableCarriers = this.carriers;
      }

      availableCarriers = this.filterCarriersByServiceTypes(availableCarriers);
      this.availableCarriers = this.filterCarriersByScacRestrictions(availableCarriers);
    }
  }

  private setSelectedCarriers() {
    if (this.allGroupCarriersObj) {
      let selectedCarriers: Carrier[] = this.selectedCarriers;

      if (this.loadCarrierGroups && this.loadCarrierGroups.length > 0) {
        // remove any selected carriers that were placed into the group carrier list and update the available carriers
        selectedCarriers = this.filterCarriersByScacRestrictions(
          (selectedCarriers || []).filter((_) => !Object.prototype.hasOwnProperty.call(this.allGroupCarriersObj, _.carrierId))
        );
      } else {
        if (!this.selectedCarriers) {
          selectedCarriers = this.availableCarriers;
        }
      }

      selectedCarriers = this.filterCarriersByServiceTypes(selectedCarriers as CarrierProfile[]);
      this.selectedCarriers = this.filterCarriersByScacRestrictions(selectedCarriers);

      this.updateSelectAllMultiSelects();
    }
  }

  private setIncludedCarrierScacs() {
    if (this.allGroupCarriers) {
      let selectedCarrierGroupCarriers: Carrier[];

      if (this.details.onLoadshop) {
        const selectedCarriersObj = (this.selectedGroupCarriers || []).reduce((value, carrier) => {
          value[carrier.carrierId] = carrier;
          return value;
        }, {} as { [s: string]: Carrier });
        selectedCarrierGroupCarriers = this.allGroupCarriers.filter((_) =>
          Object.prototype.hasOwnProperty.call(selectedCarriersObj, _.carrierId)
        );
      } else {
        const applicableCarriers = this.filterCarriersByServiceTypes(this.allGroupCarriers as CarrierProfile[]);

        selectedCarrierGroupCarriers = this.filterCarriersByScacRestrictions(applicableCarriers);
      }
      this.selectedGroupCarriers = selectedCarrierGroupCarriers;

      this.updateSelectAllMultiSelects();
    }
  }

  private updateCarrierLists() {
    this.setAvailableGroupCarriers();
    if (this.allGroupCarriersObj) {
      this.setAvailableCarriers();
      this.setSelectedCarriers();
      this.setIncludedCarrierScacs();
    }
    this.updateScacVisibility();
    this.updateSelectAllMultiSelects();
  }

  private filterCarriersByScacRestrictions(carriersToFilter: Carrier[]): Carrier[] {
    if (this.loadCarrierScacRestrictions && carriersToFilter) {
      const useOnlyScacs = this.loadCarrierScacRestrictions
        .filter((x) => x.loadCarrierScacRestrictionTypeId === CarrierScacRestrictionTypes.UseOnly)
        .map((x) => x.scac);
      const doNotUseScacs = this.loadCarrierScacRestrictions
        .filter((x) => x.loadCarrierScacRestrictionTypeId === CarrierScacRestrictionTypes.DoNotUse)
        .map((x) => x.scac);

      const validCarriers: Carrier[] = [];
      carriersToFilter.forEach((carrier) => {
        const carrierScacs = carrier.carrierScacs.filter(
          (scac) =>
            (useOnlyScacs.length === 0 || useOnlyScacs.includes(scac)) && (doNotUseScacs.length === 0 || !doNotUseScacs.includes(scac))
        );

        if (carrierScacs.length > 0) {
          const newCarrier = { ...carrier, carrierScacs: carrierScacs };
          validCarriers.push(newCarrier);
        }
      });

      return validCarriers;
    }

    return carriersToFilter;
  }

  private filterCarriersByServiceTypes(carriersToFilter: CarrierProfile[]): CarrierProfile[] {
    if (!this.carrierServiceTypeFilters || this.carrierServiceTypeFilters.length === 0) {
      return carriersToFilter;
    }

    return carriersToFilter.filter((x) =>
      x.carrierServiceTypes.some((y) => this.carrierServiceTypeFilters.some((z) => z.serviceTypeId === y.serviceTypeId))
    );
  }

  private updateScacVisibility() {
    const rate = this.details ? this.details.totalRate : null;

    const actualScacs = this.details ? this.loadCarrierScacs || [] : [];

    const selectedCarrierScacs = (this.selectedCarriers || [])
      .concat(this.selectedGroupCarriers || [])
      .map((x) => x.carrierScacs)
      .reduce((input, output) => input.concat(output), []);

    const filterdScacs = actualScacs.filter((scac) => selectedCarrierScacs.indexOf(scac.scac) > -1);
    this.setRatesonScac(filterdScacs, this.fuelRateByProgram);
    const sortedScacs = filterdScacs.sort(this.sortCarrierScacs);
    this.visibleScacs = sortedScacs.filter((_) => (_.totalRate || 0) <= (rate || 0));
    this.hiddenScacs = sortedScacs.filter((_) => (_.totalRate || 0) > (rate || 0));
  }

  private setRatesonScac(carrierScacs: LoadCarrierScacData[], fuelRateByProgram: FuelRateEstimateByProgramData) {
    carrierScacs.forEach((value) => {
      if (value.fuelProgramType === FuelProgramTypeEnum.BTF) {
        value.fuelRate = fuelRateByProgram?.btf?.rateValue;
      } else if (value.fuelProgramType === FuelProgramTypeEnum.DOE) {
        value.fuelRate = fuelRateByProgram?.doe?.rateValue;
      } else {
        value.fuelRate = this.details.shippersFSC;
      }
      value.totalRate = value.contractRate ? value.contractRate + value.fuelRate : null;
    });
  }

  private sortCarrierScacs(a: LoadCarrierScacData, b: LoadCarrierScacData) {
    if (a.totalRate === null && b.totalRate !== null) {
      return 1;
    } else if (b.totalRate === null && a.totalRate !== null) {
      return -1;
    }
    if (a.totalRate > b.totalRate) {
      return 1;
    } else if (a.totalRate < b.totalRate) {
      return -1;
    } else if (a.scac > b.scac) {
      return 1;
    } else if (a.scac < b.scac) {
      return -1;
    }
    return 0;
  }

  private updateSmartSpotPrice(obj: any) {
    const request = {
      ...defaultSmartSpotPriceRequest,
      loadId: obj.details.loadId,
      weight: obj.details.weight,
      commodity: obj.details.commodity,
      equipmentId: obj.details.equipmentId,
      carrierIds: obj.carrierIds,
    };
    this.smartSpotFetchQueue$.next([request]);
  }
  /*
   ** Update the selected carriers and groups multiselect as prime ng cannot do this effieciently
   ** when the user selects all.  If this was removed, toggle times would be 3-5 seconds to open the control
   */
  private updateSelectAllMultiSelects(): void {
    if (this.selectedGroupCarriers && this.groupCarriers) {
      this.selectedGroupCarriersSelectAll = this.selectedGroupCarriers.length === this.groupCarriers.length;
    }

    if (this.selectedCarriers && this.availableCarriers) {
      this.selectedCarriersSelectAll = this.selectedCarriers.length === this.availableCarriers.length;
    }
  }
}
