import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CustomerLaneGroupDetailData } from '../../../shared/models';
import { LaneManagementService } from '../../services';
import {
  LaneManagementActionTypes,
  LaneManagementAddAction,
  LaneManagementAddFailureAction,
  LaneManagementAddSuccessAction,
  //   LaneManagementCarrierLoadAction,
  //   LaneManagementCopyCarriersFailureAction,
  //   LaneManagementCopyCarriersSuccessAction,
  LaneManagementDeleteAction,
  LaneManagementDeleteFailureAction,
  LaneManagementDeleteSuccessAction,
  LaneManagementLoadAction,
  LaneManagementLoadCarrierGroupTypesAction,
  LaneManagementLoadCarrierGroupTypesFailure,
  LaneManagementLoadCarrierGroupTypesSuccessAction,
  LaneManagementLoadFailureAction,
  LaneManagementLoadGroupAction,
  LaneManagementLoadGroupFailureAction,
  LaneManagementLoadGroupSuccessAction,
  LaneManagementLoadSuccessAction,
  LaneManagementUpdateAction,
  LaneManagementUpdateFailureAction,
  LaneManagementUpdateSuccessAction,
} from '../actions';
import { mapToPayload } from 'src/app/shared/utilities/map-to-payload';

@Injectable()
export class LaneManagementEffects {
  $load = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LaneManagementLoadAction>(LaneManagementActionTypes.Load),
      mapToPayload<{ customerId: string }>(),
      switchMap((payload: { customerId: string }) =>
        this.laneManagementService.getGroups(payload.customerId).pipe(
          map((data) => new LaneManagementLoadSuccessAction(data)),
          catchError((err) => of(new LaneManagementLoadFailureAction(err)))
        )
      )
    ) }
  );

  $loadGroup = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LaneManagementLoadGroupAction>(LaneManagementActionTypes.Load_Lane),
      mapToPayload<{ LaneManagementId: number }>(),
      switchMap((payload: { LaneManagementId: number }) =>
        this.laneManagementService.getGroup(payload.LaneManagementId).pipe(
          map((data) => new LaneManagementLoadGroupSuccessAction(data)),
          catchError((err) => of(new LaneManagementLoadGroupFailureAction(err)))
        )
      )
    ) }
  );

  $LaneManagementTypes = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LaneManagementLoadCarrierGroupTypesAction>(LaneManagementActionTypes.Load_Carrier_Group_Types),
      switchMap(() =>
        this.laneManagementService.getLoadCarrierGroupTypes().pipe(
          map((data) => new LaneManagementLoadCarrierGroupTypesSuccessAction(data)),
          catchError((err) => of(new LaneManagementLoadCarrierGroupTypesFailure(err)))
        )
      )
    ) }
  );

  $groupAdd = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType<LaneManagementAddSuccessAction>(LaneManagementActionTypes.Add_Success),
        tap((_) => this.messageService.add({ id: 0, detail: 'Lane Group Added', severity: 'success' }))
      ) },
    { dispatch: false }
  );

  $groupUpdate = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType<LaneManagementUpdateSuccessAction>(LaneManagementActionTypes.Update_Success),
        tap((_) => this.messageService.add({ id: 0, detail: 'Lane Group Updated', severity: 'success' }))
      ) },
    { dispatch: false }
  );

  $groupDelete = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType<LaneManagementDeleteSuccessAction>(LaneManagementActionTypes.Delete_Success),
        tap((_) => this.messageService.add({ id: 0, detail: 'Lane Group Deleted', severity: 'success' }))
      ) },
    { dispatch: false }
  );

  $update = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LaneManagementUpdateAction>(LaneManagementActionTypes.Update),
      mapToPayload<CustomerLaneGroupDetailData>(),
      switchMap((group: CustomerLaneGroupDetailData) =>
        this.laneManagementService.updateGroup(group).pipe(
          map((data) => new LaneManagementUpdateSuccessAction(data)),
          catchError((err) => {
            if (err && err.error && err.error.title && err.error.detail) {
              this.messageService.add({ summary: err.error.title, detail: err.error.detail, severity: 'error' });
            }
            return of(new LaneManagementUpdateFailureAction(err));
          })
        )
      )
    ) }
  );

  $add = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LaneManagementAddAction>(LaneManagementActionTypes.Add),
      mapToPayload<CustomerLaneGroupDetailData>(),
      switchMap((group: CustomerLaneGroupDetailData) =>
        this.laneManagementService.addGroup(group).pipe(
          map((data) => new LaneManagementAddSuccessAction(data)),
          catchError((err) => {
            if (err && err.error && err.error.title && err.error.detail) {
              this.messageService.add({ summary: err.error.title, detail: err.error.detail, severity: 'error' });
            }
            return of(new LaneManagementAddFailureAction(err));
          })
        )
      )
    ) }
  );

  $delete = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LaneManagementDeleteAction>(LaneManagementActionTypes.Delete),
      mapToPayload<CustomerLaneGroupDetailData>(),
      switchMap((group: CustomerLaneGroupDetailData) =>
        this.laneManagementService.deleteGroup(group).pipe(
          map((data) => new LaneManagementDeleteSuccessAction(group)),
          catchError((err) => of(new LaneManagementDeleteFailureAction(err)))
        )
      )
    ) }
  );

  //   @Effect()
  //   $copyCarriers: Observable<Action> = this.actions$.pipe(
  //     ofType<LaneManagementCarrierLoadAction>(LaneManagementActionTypes.Copy_Carriers_Load),
  //     mapToPayload<{ LaneManagementId: number }>(),
  //     switchMap((payload: { LaneManagementId: number }) => {
  //       return this.laneManagementService.getGroup(payload.LaneManagementId).pipe(
  //         map((group: LaneManagement) => {
  //           const carriers = group.carriers.map((x) => ({
  //             LaneManagementCarrierId: 0,
  //             LaneManagementId: 0,
  //             carrierId: x.carrierId,
  //           }));

  //           return new LaneManagementCopyCarriersSuccessAction(carriers);
  //         }),
  //         catchError((err) => of(new LaneManagementCopyCarriersFailureAction(err)))
  //       );
  //     })
  //   );

  constructor(private actions$: Actions, private laneManagementService: LaneManagementService, private messageService: MessageService) {}
}
