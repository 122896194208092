import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ShippingService } from '../../services';
import {
  OrderEntryActionTypes,
  OrderEntryCopyLoadAction,
  OrderEntryCopyLoadFailureAction,
  OrderEntryCopyLoadSuccessAction,
  OrderEntryCreateLoadAction,
  OrderEntryCreateLoadFailureAction,
  OrderEntryCreateLoadSuccessAction,
  OrderEntryGetLoadAction,
  OrderEntryGetLoadFailureAction,
  OrderEntryGetLoadSuccessAction,
  OrderEntryUpdateLoadAction,
  OrderEntryUpdateLoadFailureAction,
  OrderEntryUpdateLoadSuccessAction,
} from '../actions';

@Injectable()
export class OrderEntryEffects {
  $getLoad = createEffect(() =>
    { return this.actions$.pipe(
      ofType<OrderEntryGetLoadAction>(OrderEntryActionTypes.Get_Load),
      map((a) => a.payload),
      switchMap((payload) =>
        this.shippingService.getOrderEntryLoadById(payload.loadId, payload.smartSpotQuoteId).pipe(
          map((data) => new OrderEntryGetLoadSuccessAction(data)),
          catchError((err) => of(new OrderEntryGetLoadFailureAction(err)))
        )
      )
    ) }
  );

  $copyLoad = createEffect(() =>
    { return this.actions$.pipe(
      ofType<OrderEntryCopyLoadAction>(OrderEntryActionTypes.Copy_Load),
      map((a) => a.payload),
      switchMap((payload) =>
        this.shippingService.copyOrderEntryLoadById(payload.loadId, payload.smartSpotQuoteId).pipe(
          map((data) => new OrderEntryCopyLoadSuccessAction(data)),
          catchError((err) => of(new OrderEntryCopyLoadFailureAction(err)))
        )
      )
    ) }
  );

  $createLoad = createEffect(() =>
    { return this.actions$.pipe(
      ofType<OrderEntryCreateLoadAction>(OrderEntryActionTypes.Create_Load),
      map((a) => a.payload),
      switchMap((load: any) =>
        this.shippingService.createLoad(load).pipe(
          map((data) => new OrderEntryCreateLoadSuccessAction(data)),
          catchError((err) => {
            if (err.error && err.error.errors) {
              this.messageService.add({ summary: err.error.title, detail: err.error.detail, severity: 'error' });
            }
            return of(new OrderEntryCreateLoadFailureAction(err));
          })
        )
      ),
      tap((action: OrderEntryCreateLoadSuccessAction) => {
        if (action && action.payload && action.payload.referenceLoadDisplay) {
          this.messageService.add({
            id: 0,
            detail: `Order ${action.payload.referenceLoadDisplay} was added to Post screen`,
            severity: 'success',
          });
        }
      })
    ) }
  );

  $updateLoad = createEffect(() =>
    { return this.actions$.pipe(
      ofType<OrderEntryUpdateLoadAction>(OrderEntryActionTypes.Update_Load),
      switchMap((action: OrderEntryUpdateLoadAction) =>
        this.shippingService.updateLoad(action.payload).pipe(
          map((data) => new OrderEntryUpdateLoadSuccessAction(data, action.resetState)),
          catchError((err) => {
            if (err.error && err.error.errors) {
              this.messageService.add({ summary: err.error.title, detail: err.error.detail, severity: 'error' });
            }
            return of(new OrderEntryUpdateLoadFailureAction(err));
          })
        )
      ),
      tap((action: OrderEntryUpdateLoadSuccessAction) => {
        if (action && action.payload && action.payload.referenceLoadDisplay) {
          if (action.payload.onLoadshop) {
            this.messageService.add({
              id: 0,
              detail: `Order ${action.payload.referenceLoadDisplay} was removed from Marketplace and changes have been saved`,
              severity: 'success',
            });
          } else {
            this.messageService.add({
              id: 0,
              detail: `Order ${action.payload.referenceLoadDisplay} changes have been saved`,
              severity: 'success',
            });
          }
        }
      })
    ) }
  );

  constructor(private actions$: Actions, private shippingService: ShippingService, private messageService: MessageService) {}
}
