import { Equipment } from './equipment';
import { Place } from './place';

export interface SmartSpotQuoteCreateRequest {
  smartSpotQuoteId: string;
  loadId: string;
  origin: Place;
  destination: Place;
  equipment: Equipment;
  weight: number;
  pickupDate: Date;
  deliveryDate: Date;
  pickupTime: string;
  deliveryTime: string;
}
