import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../base-component';

@Component({
  selector: 'kbxl-chat-user',
  templateUrl: './chat-user.component.html',
  styleUrls: ['./chat-user.component.scss'],
})
export class ChatUserComponent extends BaseComponent {
  @Input() userName: string;
  @Input() userId: string;
  @Output() chatNow: EventEmitter<any> = new EventEmitter<any>();
  userActive = false;
  constructor() {
    super();
  }

  chatNowClick(): void {
    this.chatNow.emit(true);
  }
}
