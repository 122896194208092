import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { notesModalZIndex } from 'src/app/shared/constants';
import { UserModel } from 'src/app/shared/models';
import { NoteData } from 'src/app/shared/models/note-data';
import { SecurityAppActionType } from 'src/app/shared/models/security-app-action-type';
import { NoteService } from 'src/app/shared/services';
import { BaseComponent } from '../../base-component';
import { NotesDisplayContainerComponent } from '../notes-display-container/notes-display-container.component';

@Component({
  selector: 'kbxl-notes-count',
  templateUrl: './notes-count.component.html',
  styleUrls: ['./notes-count.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NotesCountComponent extends BaseComponent implements OnInit {
  notes: NoteData[];
  @Input() loadId: string;
  @Input() iconSize: SizeProp;
  @Input() user: UserModel;
  loadingSubject$ = new BehaviorSubject<boolean>(true);
  constructor(private dialogService: DialogService, private cdr: ChangeDetectorRef, private noteService: NoteService) {
    super();
  }

  ngOnInit(): void {
    if (this.user.hasSecurityAction(SecurityAppActionType.NotesView)) {
      this.getNotes(this.loadId);
    }
  }

  viewNotesClicked() {
    const ref = this.dialogService.open(NotesDisplayContainerComponent, {
      data: {
        notes: this.notes,
        loadId: this.loadId,
        user: this.user,
      },
      width: '100%',
      style: { 'max-width': '50%', 'max-height': '70%', margin: '50px 0 auto 0' },
      header: 'Notes',
      closable: true,
      closeOnEscape: true,
      baseZIndex: notesModalZIndex,
    });

    ref.onClose.pipe(takeUntil(this.destroyed$)).subscribe((data: NoteData) => {
      if (data) {
        this.getNotes(this.loadId);
      }
    });
  }

  getNotes(loadId: string) {
    this.loadingSubject$.next(true);
    this.noteService
      .getNotes(loadId)
      .pipe(
        takeUntil(this.destroyed$),
        map((x: NoteData[]) => x.sort(this.sortByCreateDatedesc))
      )
      .subscribe(
        (notes) => {
          this.notes = notes;
          this.loadingSubject$.next(false);
        },
        (err) => {
          this.loadingSubject$.next(false);
        }
      );
  }

  sortByCreateDatedesc(a: NoteData, b: NoteData): number {
    if (a.createDtTm < b.createDtTm) {
      return -1;
    }
    if (a.createDtTm > b.createDtTm) {
      return 1;
    }
    return 0;
  }
}

export type noteSizeProp = 'sm' | 'md';
