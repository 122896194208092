<div class="page-container">
  <div class="loading-indicator" *ngIf="processing">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <ng-container *ngIf="group">
    <p-panel *ngIf="group" styleClass="">
      <p-header> Lane Group Settings </p-header>
      <kbxl-error-list [errors]="errors" urnPath="urn:root:CustomerLaneGroup"></kbxl-error-list>     
      <div class="row">
        <div class="col-12 col-lg-6 kbxl-inputgroup-container">
          <div class="kbxl-field-label">
            <label for="name" class="control-label">Name</label>
            <kbxl-error-list [errors]="errors" urnPath="urn:root:CustomerLaneGroup:Name" errorType="field"></kbxl-error-list>
          </div>
          <input id="name" type="text" [(ngModel)]="group.name" pInputText maxlength="255" />
        </div>
        <div class="col-12 col-lg-6 kbxl-inputgroup-container">
          <div for="description" class="kbxl-field-label">
            <label for="description" class="control-label">Description</label>
            <kbxl-error-list [errors]="errors" urnPath="urn:root:CustomerLaneGroup:Description" errorType="field"></kbxl-error-list>
          </div>
          <input id="carrier" type="text" [(ngModel)]="group.description" pInputText maxlength="500" />
        </div>
      </div>

      <div class="row" *ngFor="let lane of group.customerLanes; let i = index">
        <div class="col">
          <kbxl-lane-detail
            [lane]="lane"
            [index]="i"
            [states]="states"
            [quoteOnly]="group.eligibleForQuote"
            [groupedEquipment]="groupedEquipment"
            [errors]="errors"
            (updated)="laneUpdated($event)"
          >
          </kbxl-lane-detail>
        </div>
        <div class="col-1">
          <div class="kbxl-field-label" style="height: 25px">&nbsp;</div>
          <div class="col-12 kbxl-inputgroup-container">&nbsp;</div>
          <button
            *ngIf="group.customerLanes && group.customerLanes.length > 1"
            class="p-button-danger"
            pButton
            icon="pi pi-trash"
            (click)="deleteLane(i)"
            title="Delete Lane"
          ></button>
        </div>
      </div>

      <div class="d-flex button-group add">
        <button pButton type="button" (click)="addLane()" label="Add Lane"></button>
      </div>
    </p-panel>
    <div class="py-2 mb-1" *ngIf="!group.eligibleForQuote">
      <p-panel [toggler]="'header'" [toggleable]="true" [(collapsed)]="specialInstructionsCollapsed" styleClass="">
        <p-header class="mr-2">
          <div class="d-flex align-items-center">
            <fa-icon [icon]="['fas', 'tasks']" size="lg" class="mr-2"></fa-icon>
            <span> Special Instructions</span>
          </div>
        </p-header>
        <div class="row">
          <div class="col-12 kbxl-inputgroup-container">
            <p-editor name="specialInstructions" [(ngModel)]="group.specialInstructions" [style]="{ height: '100px' }">
              <p-header>
                <span class="ql-formats">
                  <button class="ql-bold" aria-label="Bold"></button>
                  <button class="ql-italic" aria-label="Italic"></button>
                  <button class="ql-underline" aria-label="Underline"></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-list" value="ordered" aria-label="Ordered List"></button>
                  <button class="ql-list" value="bullet" aria-label="Bulleted List"></button>
                </span>
              </p-header>
            </p-editor>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button pButton (click)="clear('specialInstructions')" label="Clear" class="p-button-outlined p-button-secondary"></button>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="border-top py-2 mb-1" *ngIf="!group.eligibleForQuote">
      <p-panel [toggler]="'header'" [toggleable]="true" [(collapsed)]="requiredServiceTypesCollapsed" styleClass="">
        <p-header class="mr-2">
          <div class="d-flex align-items-center">
            <fa-icon [icon]="['fas', 'toolbox']" size="lg" class="mr-2"></fa-icon>
            <span> Required Service Types </span>
          </div>
        </p-header>
        <div class="row">
          <div class="col col-lg-6 kbxl-inputgroup-container">
            <div>
              <p-multiSelect
                [options]="customerServiceTypes"
                placeholder="Select Service Types"
                [(ngModel)]="group.serviceTypeIds"
                resetFilterOnHide="true"
                [virtualScroll]="true"
                virtualScrollItemSize="30"
                [styleClass]="'p-dropdown-primary'"
                dropdownIcon="pi pi-caret-down"
                [filter]="true"
                filterBy="label"
                placeholder="Select Service Types"
                filterPlaceHolder="Filter Service Types"
              >
                <ng-template let-item pTemplate="item">
                  <span>{{ item?.label | titlecase }}</span>
                </ng-template>
              </p-multiSelect>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button pButton (click)="clear('requiredServiceTypes')" label="Clear" class="p-button-outlined p-button-secondary"></button>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="border-top py-2 mb-1" *ngIf="!group.eligibleForQuote">
      <p-panel [toggler]="'header'" [toggleable]="true" [(collapsed)]="carrierGroupCollapsed" styleClass="">
        <p-header class="mr-2">
          <div class="d-flex align-items-center">
            <fa-icon [icon]="['fas', 'user-plus']" size="lg" class="mr-2"></fa-icon>
            <span>Carrier Group</span>
          </div>
        </p-header>
        <div>
          <div class="kbxl-inputgroup-container">
            <div class="kbxl-field-label">
              <label>Carrier Group Type</label>
            </div>
            <kbxl-error-list [errors]="errors" urnPath="urn:root:LoadCarrierGroup" errorType="field"></kbxl-error-list>
            <span *ngFor="let loadCarrierGroupType of loadCarrierGroupTypes" class="pr-4">
              <p-radioButton
                name="loadCarrierGroupType"
                [value]="loadCarrierGroupType.loadCarrierGroupTypeId"
                [label]="loadCarrierGroupType.name"
                [(ngModel)]="group.loadCarrierGroupTypeId"
                (onClick)="warnGroupTypeChange()"
              ></p-radioButton>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col col-lg-6 kbxl-inputgroup-container">
            <div class="kbxl-field-label">
              <label class="control-label" *ngIf="group.loadCarrierGroupTypeId === 1">Included Carriers</label>
              <label class="control-label" *ngIf="group.loadCarrierGroupTypeId === 2">Excluded Carriers</label>
            </div>
            <div>
              <p-multiSelect
                [options]="carriers"
                placeholder="Select Carrier"
                [(ngModel)]="group.carrierIds"
                resetFilterOnHide="true"
                [virtualScroll]="true"
                virtualScrollItemSize="30"
                [styleClass]="'p-dropdown-primary'"
                dropdownIcon="pi pi-caret-down"
                [filter]="true"
                filterBy="label"
                placeholder="Select Carriers to Add"
                filterPlaceHolder="Filter Available Carriers"
                (onChange)="carrierChange($event)"
              >
                <ng-template let-item pTemplate="item">
                  <span>{{ item?.label | titlecase }}</span>
                </ng-template>
              </p-multiSelect>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button pButton (click)="clear('carrierGroups')" label="Clear" class="p-button-outlined p-button-secondary"></button>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="border-top py-2 mb-1" *ngIf="shipper && !shipper.brokeredLoads">
      <p-panel [toggler]="'header'" [toggleable]="false" [(collapsed)]="rateSeekerCollapsed" styleClass="">
        <p-header class="mr-2">
          <div class="d-flex align-items-center">
            <fa-icon [icon]="['fas', 'binoculars']" size="lg" class="mr-2"></fa-icon>
            <span [innerHtml]="rateSeekerLabel"></span>
            <span class="ml-1" style="color: orangered;font-weight: bold;" >Unavailable</span>
          </div>
        </p-header>
        <!-- Updates to Prime NG does not allow this panel to be hidden while the property 'toggleable' is set to false, so commenting out for now
        <div class="row">
          <div class="col">
            <kbxl-dynamic-pricing-rule-form
              [pricingRule]="group.customerLaneGroupPricingRule"
              [percentOptions]="percentOptions"
              [frequencyOptions]="frequencyOptions"
              [intervalOptions]="intervalOptions"
              [showSchedule]="true"
              [errors]="errors"
              [showAutoApproveWarning]="group.allowCounterOffer"
              urnPath="urn:root:CustomerLaneGroup:CustomerLaneGroupPricingRule"
              (scheduleChanged)="scheduleChanged($event)"
            >
            </kbxl-dynamic-pricing-rule-form>
          </div>
        </div> -->
      </p-panel>
    </div>
    <div class="border-top py-2 mb-1" *ngIf="!group.eligibleForQuote">
      <p-panel [toggler]="'header'" [toggleable]="true" [(collapsed)]="contactsCollapsed" styleClass="">
        <p-header class="mr-2">
          <div class="d-flex align-items-center">
            <fa-icon [icon]="['fas', 'address-card']" size="lg" class="mr-2"></fa-icon>
            <span> Shipper Contact </span>
          </div>
        </p-header>
        <div class="row">
          <div class="col-12 col-lg-6 kbxl-inputgroup-container">
            <div class="kbxl-field-label">
              <label for="firstName" class="control-label">Display Name</label>
              <kbxl-error-list
                [errors]="errors"
                urnPath="urn:root:CustomerLaneGroup:CustomerLaneGroupContacts:Display:0"
                errorType="field"
              ></kbxl-error-list>
            </div>
            <input name="displayName" [(ngModel)]="contact.display" type="text" pInputText maxlength="250" />
          </div>
          <div class="col-12 col-lg-2 kbxl-inputgroup-container">
            <div class="kbxl-field-label">
              <label for="phoneNumber" class="control-label">Phone</label>
              <kbxl-error-list
                [errors]="errors"
                urnPath="urn:root:CustomerLaneGroup:CustomerLaneGroupContacts:Phone:0"
                errorType="field"
              ></kbxl-error-list>
            </div>
            <p-inputMask mask="(999) 999-9999" name="phoneNumber" [(ngModel)]="contact.phone" placeholder="(999) 999-9999"> </p-inputMask>
          </div>
          <div class="col-12 col-lg-4 kbxl-inputgroup-container">
            <div class="kbxl-field-label">
              <label for="email" class="control-label">Email</label>
              <kbxl-error-list
                [errors]="errors"
                urnPath="urn:root:CustomerLaneGroup:CustomerLaneGroupContacts:Email:0"
                errorType="field"
              ></kbxl-error-list>
            </div>
            <input name="email" type="text" [(ngModel)]="contact.email" pInputText maxlength="250" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button pButton (click)="clear('shipperContacts')" label="Clear" class="p-button-outlined p-button-secondary"></button>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="d-flex button-group save">
      <button pButton (click)="save()" label="Save" class="p-button-teal ml-2"></button>
      <button pButton (click)="cancel()" label="Cancel" class="p-button-outlined p-button-secondary ml-2"></button>
      <button pButton (click)="delete()" label="Delete" class="p-button-danger ml-2"></button>
    </div>
  </ng-container>
</div>

<p-dialog header="Warning!" [(visible)]="showCarrierGroupTypeWarning" [style]="{ width: '500px' }">
  You have changed the Carrier Group Type, make sure you intended to do this. Carrier's may now be included or excluded that you did not
  intend.
  <p-footer>
    <button type="button" class="p-button-warning" pButton (click)="showCarrierGroupTypeWarning = false" label="Acknowledge"></button>
  </p-footer>
</p-dialog>
