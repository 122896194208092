import { Action } from '@ngrx/store';
import { PostLoadsClientResponse } from 'src/app/shared/models/post-loads-client-response';
import {
  IShippingLoadDetail,
  ResponseError,
  ShippingLoadContractRateData,
  ShippingLoadDetail,
  ShippingLoadSupplementalData,
} from '../../../shared/models';

export enum ShippingLoadDetailActionTypes {
  Load_All = '[ShippingLoadDetail] LOAD_ALL',
  Load_All_Success = '[ShippingLoadDetail] LOAD_ALL_SUCCESS',
  Load_All_Failure = '[ShippingLoadDetail] LOAD_ALL_FAILURE',
  Load_Supplemental = '[ShippingLoadDetail] LOAD_SUPPLEMENTAL',
  Load_Supplemental_Success = '[ShippingLoadDetail] LOAD_SUPPLEMENTAL_SUCCESS',
  Load_Supplemental_Failure = '[ShippingLoadDetail] LOAD_SUPPLEMENTAL_FAILURE',
  Post_Loads = '[ShippingLoadDetail] POST_LOADS',
  Post_Loads_Success = '[ShippingLoadDetail] POST_LOADS_SUCCESS',
  Post_Loads_Failure = '[ShippingLoadDetail] POST_LOADS_FAILURE',
  Remove_Load = '[ShippingLoadDetail] REMOVE_LOAD',
  Remove_Load_Success = '[ShippingLoadDetail] REMOVE_LOAD_SUCCESS',
  Remove_Load_Failure = '[ShippingLoadDetail] REMOVE_LOAD_FAILURE',
  Delete_Load = '[ShippingLoadDetail] DELETE_LOAD',
  Delete_Load_Success = '[ShippingLoadDetail] DELETE_LOAD_SUCCESS',
  Delete_Load_Failure = '[ShippingLoadDetail] DELETE_LOAD_FAILURE',
  Discard_Changes = '[ShippingLoadDetail] DISCARD_CHANGES',
  Update_Load = '[ShippingLoadDetail] UPDATE LOAD',
  Select_Loads = '[ShippingLoadDetail] SELECT_LOADS',
  Unselect_Loads = '[ShippingLoadDetail] UNSELECT_LOADS',
  Toggle_Rate_Seeker_Error = '[ShippingLoadDetail] TOGGLE_RATE_SEEKER_ERROR',
  Start_Contract_Rates_Polling = '[ShippingLoadDetail] START_CONTRACT_RATES_POLLING',
  Cancel_Contract_Rates_All_Polling = '[ShippingLoadDetail] END_CONTRACT_RATES_ALL_POLLING',
  Load_Contract_Rates = '[ShippingLoadDetail] LOAD_CONTRACT_RATES',
  Load_Contract_Rates_Success = '[ShippingLoadDetail] LOAD_CONTRACT_RATES_SUCCESS',
  Load_Contract_Rates_Failure = '[ShippingLoadDetail] LOAD_CONTRACT_RATES_FAILURE',
}

export class ShippingLoadDetailLoadAllAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Load_All;
}

export class ShippingLoadDetailLoadAllSuccessAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Load_All_Success;

  constructor(public payload: IShippingLoadDetail[]) {}
}

export class ShippingLoadDetailLoadAllFailureAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Load_All_Failure;

  constructor(public payload: Error) {}
}

export class ShippingLoadDetailLoadSupplementalAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Load_Supplemental;

  constructor(public payload: { loadId: string }) {}
}

export class ShippingLoadDetailLoadSupplementalSuccessAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Load_Supplemental_Success;

  constructor(public payload: { loadId: string; data: ShippingLoadSupplementalData }) {}
}

export class ShippingLoadDetailLoadSupplementalFailureAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Load_Supplemental_Failure;

  constructor(public payload: ResponseError) {}
}

export class ShippingLoadDetailPostLoadsAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Post_Loads;

  constructor(public payload: ShippingLoadDetail[]) {}
}

export class ShippingLoadDetailPostLoadsSuccessAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Post_Loads_Success;

  constructor(public payload: PostLoadsClientResponse) {}
}

export class ShippingLoadDetailPostLoadsFailureAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Post_Loads_Failure;

  constructor(public payload: Error) {}
}

export class ShippingLoadDetailRemoveLoadAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Remove_Load;

  constructor(public payload: string) {}
}

export class ShippingLoadDetailRemoveLoadSuccessAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Remove_Load_Success;

  constructor(public payload: IShippingLoadDetail) {}
}

export class ShippingLoadDetailRemoveLoadFailureAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Remove_Load_Failure;

  constructor(public payload: Error) {}
}

export class ShippingLoadDetailDeleteLoadAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Delete_Load;

  constructor(public payload: string) {}
}

export class ShippingLoadDetailDeleteLoadSuccessAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Delete_Load_Success;

  constructor(public payload: IShippingLoadDetail) {}
}

export class ShippingLoadDetailDeleteLoadFailureAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Delete_Load_Failure;

  constructor(public payload: Error) {}
}

export class ShippingLoadDetailDiscardChanges implements Action {
  readonly type = ShippingLoadDetailActionTypes.Discard_Changes;

  constructor(public payload: IShippingLoadDetail) {}
}

export class ShippingLoadDetailUpdateLoadAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Update_Load;

  constructor(public payload: IShippingLoadDetail) {}
}

export class ShippingLoadDetailSelectLoadAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Select_Loads;

  constructor(public loadIds: string[]) {}
}

export class ShippingLoadDetailUnselectLoadAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Unselect_Loads;

  constructor(public loadIds: string[]) {}
}

export class ShippingLoadDetailToggleRateSeekerErrorAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Toggle_Rate_Seeker_Error;

  constructor(public loadIds: string[], public hasError: boolean) {}
}
export class ShippingLoadDetailContractRatesPollingAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Start_Contract_Rates_Polling;

  constructor(public payload: string) {}
}

export class ShippingLoadDetailContractRatesCancelAllPollingAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Cancel_Contract_Rates_All_Polling;
}

export class ShippingLoadDetailLoadContractRatesAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Load_Contract_Rates;

  constructor(public payload: string) {}
}

export class ShippingLoadDetailLoadContractRatesSuccessAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Load_Contract_Rates_Success;

  constructor(public payload: { loadId: string; data: ShippingLoadContractRateData }) {}
}

export class ShippingLoadDetailLoadContractRatesFailureAction implements Action {
  readonly type = ShippingLoadDetailActionTypes.Load_Contract_Rates_Failure;

  constructor(public payload: { loadId: string; error: Error }) {}
}

export type ShippingLoadDetailActions =
  | ShippingLoadDetailLoadAllAction
  | ShippingLoadDetailLoadAllSuccessAction
  | ShippingLoadDetailLoadAllFailureAction
  | ShippingLoadDetailLoadSupplementalAction
  | ShippingLoadDetailLoadSupplementalSuccessAction
  | ShippingLoadDetailLoadSupplementalFailureAction
  | ShippingLoadDetailPostLoadsAction
  | ShippingLoadDetailPostLoadsSuccessAction
  | ShippingLoadDetailPostLoadsFailureAction
  | ShippingLoadDetailRemoveLoadAction
  | ShippingLoadDetailRemoveLoadSuccessAction
  | ShippingLoadDetailRemoveLoadFailureAction
  | ShippingLoadDetailDeleteLoadAction
  | ShippingLoadDetailDeleteLoadSuccessAction
  | ShippingLoadDetailDeleteLoadFailureAction
  | ShippingLoadDetailDiscardChanges
  | ShippingLoadDetailUpdateLoadAction
  | ShippingLoadDetailSelectLoadAction
  | ShippingLoadDetailUnselectLoadAction
  | ShippingLoadDetailToggleRateSeekerErrorAction
  | ShippingLoadDetailContractRatesPollingAction
  | ShippingLoadDetailContractRatesCancelAllPollingAction
  | ShippingLoadDetailLoadContractRatesAction
  | ShippingLoadDetailLoadContractRatesSuccessAction
  | ShippingLoadDetailLoadContractRatesFailureAction;
