import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { mapToPayload } from 'src/app/shared/utilities/map-to-payload';
import { ShipperAccessorialData } from '../../../shared/models';
import { ShipperAdminAccessorialService } from '../../services';
import {
  ShipperAccessorialActionTypes,
  ShipperAccessorialAddAction,
  ShipperAccessorialAddFailureAction,
  ShipperAccessorialAddSuccessAction,
  ShipperAccessorialBulkOperationAction,
  ShipperAccessorialBulkOperationFailureAction,
  ShipperAccessorialBulkOperationSuccessAction,
  ShipperAccessorialLoadAccessorialsAction,
  ShipperAccessorialLoadAccessorialsFailureAction,
  ShipperAccessorialLoadAccessorialsSuccessAction,
  ShipperAccessorialLoadAccessorialUoMAction,
  ShipperAccessorialLoadAccessorialUoMFailureAction,
  ShipperAccessorialLoadAccessorialUoMSuccessAction,
  ShipperAccessorialLoadAction,
  ShipperAccessorialLoadFailureAction,
  ShipperAccessorialLoadSuccessAction,
} from '../actions';
import { NavigationGoAction } from '../../../shared/store';

@Injectable()
export class ShipperAccessorialEffects {
  $load = createEffect(() =>
    { return this.actions$.pipe(
      ofType<ShipperAccessorialLoadAction>(ShipperAccessorialActionTypes.Load),
      mergeMap((payload) =>
        this.shipperAccessorialService.getAll(payload.customerId).pipe(
          map((data) => new ShipperAccessorialLoadSuccessAction(data, payload.saveToGlobal)),
          catchError((err) => of(new ShipperAccessorialLoadFailureAction(err)))
        )
      )
    ) }
  );

  $loadAccessorials = createEffect(() =>
    { return this.actions$.pipe(
      ofType<ShipperAccessorialLoadAccessorialsAction>(ShipperAccessorialActionTypes.Load_Accessorials),
      switchMap((payload) =>
        this.shipperAccessorialService.getAllAccessorials().pipe(
          map((data) => new ShipperAccessorialLoadAccessorialsSuccessAction(data)),
          catchError((err) => of(new ShipperAccessorialLoadAccessorialsFailureAction(err)))
        )
      )
    ) }
  );

  $loadAccessorialUnitOfMeasures = createEffect(() =>
    { return this.actions$.pipe(
      ofType<ShipperAccessorialLoadAccessorialUoMAction>(ShipperAccessorialActionTypes.Load_AccessorialsUoM),
      switchMap((payload) =>
        this.shipperAccessorialService.getAllAccessorialUnitOfMeasure().pipe(
          map((data) => new ShipperAccessorialLoadAccessorialUoMSuccessAction(data)),
          catchError((err) => of(new ShipperAccessorialLoadAccessorialUoMFailureAction(err)))
        )
      )
    ) }
  );

  $addMessage = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType<ShipperAccessorialAddSuccessAction>(ShipperAccessorialActionTypes.Add_Success),
        tap((_) => this.messageService.add({ id: 0, detail: 'Accessorial Rule Added', severity: 'success' }))
      ) },
    { dispatch: false }
  );

  $bulkOperationMessage = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType<ShipperAccessorialBulkOperationSuccessAction>(ShipperAccessorialActionTypes.BulkOperation_Success),
        tap((_) => this.messageService.add({ id: 0, detail: 'Accessorials updated', severity: 'success' }))
      ) },
    { dispatch: false }
  );

  $bulkOperation = createEffect(() =>
    { return this.actions$.pipe(
      ofType<ShipperAccessorialBulkOperationAction>(ShipperAccessorialActionTypes.BulkOperation),
      switchMap((action) =>
        this.shipperAccessorialService.bulkOperation(action.payload).pipe(
          mergeMap((data) => [new ShipperAccessorialLoadAction(action.customerId), new ShipperAccessorialBulkOperationSuccessAction()]),
          catchError((err) => {
            if (err && err.error && err.error.title && err.error.detail) {
              this.messageService.add({ summary: err.error.title, detail: err.error.detail, severity: 'error' });
            } else {
              this.messageService.add({
                summary: 'Error updating Accessorials',
                detail: 'One or more errors occurred when updating the accessorials.  See form for error details',
                severity: 'error',
              });
            }
            return of(new ShipperAccessorialBulkOperationFailureAction(err));
          })
        )
      )
    ) }
  );

  $add = createEffect(() =>
    { return this.actions$.pipe(
      ofType<ShipperAccessorialAddAction>(ShipperAccessorialActionTypes.Add),
      mapToPayload<ShipperAccessorialData>(),
      switchMap((accessorial: ShipperAccessorialData) =>
        this.shipperAccessorialService.add(accessorial).pipe(
          mergeMap((data) => {
            if (accessorial.customerId) {
              return [new NavigationGoAction({ path: ['/maint/accessorials/'] }), new ShipperAccessorialAddSuccessAction(data)];
            } else {
              return [new NavigationGoAction({ path: ['/maint/configurations/'] }), new ShipperAccessorialAddSuccessAction(data)];
            }
          }),
          catchError((err) => {
            if (err && err.error && err.error.title && err.error.detail) {
              this.messageService.add({ summary: err.error.title, detail: err.error.detail, severity: 'error' });
            }
            return of(new ShipperAccessorialAddFailureAction(err));
          })
        )
      )
    ) }
  );

  constructor(
    private actions$: Actions,
    private shipperAccessorialService: ShipperAdminAccessorialService,
    private messageService: MessageService
  ) {}
}
