import { concatLatestFrom } from '@ngrx/operators';import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { mapToPayload } from 'src/app/shared/utilities/map-to-payload';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ShippingService } from '../../services';
import {
  ShippingPostedActionTypes,
  ShippingPostedLoadAction,
  ShippingPostedLoadFailureAction,
  ShippingPostedLoadSuccessAction,
} from '../actions';
import { LoadDetailDeleteLoadAction, LoadDetailDeleteLoadActionTypes } from 'src/app/shared/store';
import { ShippingLoadSearchTypes } from 'src/app/shared/models/shipping-load-search-types';
import { PageableQueryHelper } from 'src/app/shared/utilities';
import { ShippingState } from '../reducers';
import { getShippingPostedQueryHelper } from '../selectors/shipping-posted.selectors';

@Injectable()
export class ShippingPostedEffects {
  $loadPosted: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType<ShippingPostedLoadAction>(ShippingPostedActionTypes.Load_Shipping_Posted),
      mapToPayload<{ searchType: string; queryHelper: PageableQueryHelper }>(),
      switchMap((payload: { searchType: string; queryHelper: PageableQueryHelper }) =>
        this.shippingService.getPageableLoadsBySearchType(payload.searchType, payload.queryHelper).pipe(
          map((data) => new ShippingPostedLoadSuccessAction(data)),
          catchError((err) => of(new ShippingPostedLoadFailureAction(err)))
        )
      )
    ) }
  );

  $loadDeleted: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LoadDetailDeleteLoadAction>(LoadDetailDeleteLoadActionTypes.DeleteLoad_Success),
      concatLatestFrom(() => this.store$.pipe(select(getShippingPostedQueryHelper))),
      switchMap(([action, currentQuery]) =>
        this.shippingService.getPageableLoadsBySearchType(ShippingLoadSearchTypes.Posted, currentQuery).pipe(
          map((data) => new ShippingPostedLoadSuccessAction(data)),
          catchError((err) => of(new ShippingPostedLoadFailureAction(err)))
        )
      )
    ) }
  );

  constructor(private actions$: Actions, private shippingService: ShippingService, private store$: Store<ShippingState>) {}
}
