// angular libs
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AdminModule } from './admin/admin.module';
// main component
import { AppComponent } from './app.component';
// routing
import { AppRoutingModule } from './app.routing.module';
import { ChatModule } from './chat/chat.module';
import { CONVERSATION_WEBSOCKET_PATH } from './chat/services';
// swagger generated modules
import { BASE_PATH as CONVERSATION_BASE_PATH, ApiModule as ConversationApiModule } from './conversation-api';
// app  modules
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterStateSerializer } from '@ngrx/router-store';
//TODO:Temp fix
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { CoreModule } from './core/core.module';
import { Environment } from './core/models/environment';
import {
  ForbiddenMessageService,
  LoadshopForbiddenMessageService,
  LoadshopSignedInMessageService,
  LoadshopUnauthorizedMessageService,
  SignedInMessageService,
  UnauthorizedMessageService,
} from './core/services';
import { LoadBoardModule } from './load-board/load-board.module';
import { RequestInterceptor } from './shared/interceptors/request-intercepter';
import { ResponseInterceptor } from './shared/interceptors/response-intercepter';
import { CustomSerializer } from './shared/interceptors/router-state.serializer';
import { SharedModule } from './shared/shared.module';
import { ShippingModule } from './shipping/shipping.module';
import { UserModule } from './user/user.module';

// services and guards

@NgModule({
  declarations: [AppComponent],
  imports: [
    // tms modules
    //TODO:Temp fix. Not sure how to pull config from
    AuthModule.forRoot({
      config: {
        configId: 'loadshop-identity-server',
        authority: environment.identityServerUrl,
        // stsServer: identityServerUrl,
        redirectUrl: `${environment.siteUrl}/signin-oidc`,
        triggerAuthorizationResultEvent: true,
        postLogoutRedirectUri: `${environment.siteUrl}/signout-callback-oidc`,
        clientId: environment.clientId || 'topsapis',
        scope: `openid profile email roles ${environment.scopes || 'vcusts visibilityApi'}`,
        responseType: 'id_token token',
        silentRenew: environment.silentRenew,
        silentRenewUrl: environment.silentRenew ? `${environment.siteUrl}/assets/silent-renew.html` : undefined,
        maxIdTokenIatOffsetAllowedInSeconds: 1800,
        logLevel: environment.debug ? LogLevel.Debug : !environment.production ? LogLevel.Warn : LogLevel.Error,
        forbiddenRoute: '/unauthorized', // HTTP 403
        unauthorizedRoute: '/unauthorized', // HTTP 401
        renewTimeBeforeTokenExpiresInSeconds: 10,
        postLoginRoute: '/dm',
        historyCleanupOff: true,
      },
      //TODO:Temp fix. Where to add issuer, jwksUri, authorizationEndpoint, tokenEndpoint, userinfoEndpoint, endSessionEndpoint, checkSessionIframe, revocationEndpoint, introspectionEndpoint
    }),
    // Core modules
    BrowserModule,
    BrowserAnimationsModule,

    // Reactive Forms
    ReactiveFormsModule,

    // App Modules
    CoreModule,
    SharedModule,
    ChatModule,
    UserModule,
    LoadBoardModule,
    AdminModule,
    ShippingModule,
    ConversationApiModule,

    // routing modules
    AppRoutingModule,
  ],
  providers: [
    { provide: ForbiddenMessageService, useClass: LoadshopForbiddenMessageService },
    { provide: UnauthorizedMessageService, useClass: LoadshopUnauthorizedMessageService },
    { provide: SignedInMessageService, useClass: LoadshopSignedInMessageService },
    {
      provide: CONVERSATION_BASE_PATH,
      useValue: environment.conversationApiUrl,
    },
    {
      provide: CONVERSATION_WEBSOCKET_PATH,
      useValue: environment.conversationWebSocketUrl,
    },
    // OidcConfigService,
    {
      provide: Environment,
      useValue: environment,
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: configureAuth,
    //   deps: [OidcConfigService, Environment],
    //   multi: true,
    // },
    // request intercepter provider for auth
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    // response intercepter provider to handle 401's
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
    // route state serializer for route state
    {
      provide: RouterStateSerializer,
      useClass: CustomSerializer,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// export function configureAuth(oidcConfigService: OidcConfigService, environmentSettings: Environment) {
//   const { identityServerUrl, siteUrl, production, debug, silentRenew, scopes, clientId } = environmentSettings;
//   return () =>
//     oidcConfigService.withConfig(
//       {
//         stsServer: identityServerUrl,
//         redirectUrl: `${siteUrl}/signin-oidc`,
//         triggerAuthorizationResultEvent: true,
//         postLogoutRedirectUri: `${siteUrl}/signout-callback-oidc`,
//         clientId: clientId || 'topsapis',
//         scope: `openid profile email roles ${scopes || 'vcusts visibilityApi'}`,
//         responseType: 'id_token token',
//         silentRenew: silentRenew,
//         silentRenewUrl: silentRenew ? `${siteUrl}/assets/silent-renew.html` : undefined,
//         maxIdTokenIatOffsetAllowedInSeconds: 1800,
//         logLevel: debug ? LogLevel.Debug : !production ? LogLevel.Warn : LogLevel.Error,
//         forbiddenRoute: '/unauthorized', // HTTP 403
//         unauthorizedRoute: '/unauthorized', // HTTP 401
//         renewTimeBeforeTokenExpiresInSeconds: 10,
//         postLoginRoute: '/dm',
//         historyCleanupOff: true,
//       },
//       {
//         issuer: identityServerUrl,
//         jwksUri: `${identityServerUrl}/.well-known/openid-configuration/jwks`,
//         authorizationEndpoint: `${identityServerUrl}/connect/authorize`,
//         tokenEndpoint: `${identityServerUrl}/connect/token`,
//         userinfoEndpoint: `${identityServerUrl}/connect/userinfo`,
//         endSessionEndpoint: `${identityServerUrl}/connect/endsession`,
//         checkSessionIframe: `${identityServerUrl}/connect/checksession`,
//         revocationEndpoint: `${identityServerUrl}/connect/revocation`,
//         introspectionEndpoint: `${identityServerUrl}/connect/introspect`,
//       }
//     );
// }
