import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CommonService } from '../../services/common.service';
import { FuelRateActionTypes, FuelRateEstimateAction, FuelRateEstimateSuccessAction, FuelRateEstimateFailureAction } from '../actions';

@Injectable()
export class FuelRateEffects {
  $getFuelRate = createEffect(() =>
    { return this.actions$.pipe(
      ofType(FuelRateActionTypes.GetFuelRateEstimate),
      mergeMap((action: FuelRateEstimateAction) =>
        this.commonService.getFuelRateEstimate(action.payload.loadId, action.payload.customerId).pipe(
          map((data) => new FuelRateEstimateSuccessAction({ loadId: action.payload.loadId, estimate: data })),
          catchError((err) => of(new FuelRateEstimateFailureAction({ loadId: action.payload.loadId, error: err })))
        )
      )
    ) }
  );

  constructor(private commonService: CommonService, private actions$: Actions) {}
}
