// tslint:disable: max-classes-per-file

import { Action } from '@ngrx/store';
import { AuthUser } from '../../models/auth-user';

export enum AuthLoginActionTypes {
  Login = '[Auth] LOGIN',
  Login_Success = '[Auth] LOGIN_SUCCESS',
  Login_Failure = '[Auth] LOGIN_FAILURE',
  Login_Forbidden = '[Auth] LOGIN_FORBIDDEN',
  Logout = '[Auth] LOGOUT',
}

export class AuthLoginAction implements Action {
  readonly type = AuthLoginActionTypes.Login;

  constructor() {}
}

export class AuthLoginSuccessAction implements Action {
  readonly type = AuthLoginActionTypes.Login_Success;

  constructor(public payload: AuthUser) {}
}

export class AuthLoginFailureAction implements Action {
  readonly type = AuthLoginActionTypes.Login_Failure;

  constructor(public payload: Error) {}
}

export class AuthLoginForbiddenAction implements Action {
  readonly type = AuthLoginActionTypes.Login_Forbidden;

  constructor(public payload: Error) {}
}

export class AuthLogoutAction implements Action {
  readonly type = AuthLoginActionTypes.Logout;

  constructor() {}
}

export type AuthActions = AuthLoginAction | AuthLoginSuccessAction | AuthLoginFailureAction | AuthLoginForbiddenAction | AuthLogoutAction;
