import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IEPolicyComponent } from './core/components/ie-policy';
import { NoCarrierContainerComponent } from './core/components/no-carrier';
import { AgreementsComponent, UserAgreementComponent } from './core/components/user-agreement';
import { AuthGuard } from './core/guards/auth.guard';
import { IncorrectRouteComponent } from './shared/components';
import {
  ForbiddenComponent,
  LoginComponent,
  NotFoundComponent,
  SignedInComponent,
  UnauthorizedComponent,
} from './shared/components/tms-core-components';
import { RootGuard } from './shared/guards/root.guard';
import { UserFocusEntityChangedComponent } from './user/components';

const routes: Routes = [
  {
    path: 'loads/dashboard',
    redirectTo: '/loads/search',
  },
  {
    path: '',
    pathMatch: 'full',
    canActivate: [RootGuard],
    children: [],
  },
  {
    path: 'signout-callback-oidc',
    redirectTo: '/loads/search',
    pathMatch: 'full',
  },
  {
    path: 'change-entity',
    pathMatch: 'full',
    component: UserFocusEntityChangedComponent,
  },
  {
    path: 'quick-quote',
    component: NoCarrierContainerComponent,
    outlet: 'modal',
  },
  {
    path: 'invalid',
    component: NoCarrierContainerComponent,
  },
  {
    path: 'incorrect-route',
    component: IncorrectRouteComponent,
  },
  {
    path: 'agreements',
    children: [
      { path: '', component: AgreementsComponent },
      { path: ':documentType', component: AgreementsComponent },
    ],
  },
  {
    path: 'user-agreement',
    component: UserAgreementComponent,
  },
  {
    path: 'ie-policy',
    component: IEPolicyComponent,
    canActivate: [AuthGuard],
  },
  // TMS routes
  // login component, calls .authorize()
  {
    path: 'autologin',
    component: LoginComponent,
  },
  // return url on auth
  {
    path: 'signin-oidc',
    component: SignedInComponent,
  },
  {
    path: 'signout-callback-oidc',
    redirectTo: '/',
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    onSameUrlNavigation: 'reload'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
