import { concatLatestFrom } from '@ngrx/operators';import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { mapToPayload } from 'src/app/shared/utilities/map-to-payload';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { LoadAudit } from 'src/app/shared/models/load-audit';
import { LoadBoardService } from '../../../load-board/services';
import { LoadBoardState } from '../../../load-board/store/reducers';
import { getLoadBoardShowAllLoadsContext } from '../../../load-board/store/selectors';
import { ShippingService } from '../../../shipping/services';
import { RemoveCarrierData, RemoveLoadData } from '../../models';
import {
  LoadBoardLoadAuditAction,
  LoadBoardLoadAuditFailureAction,
  LoadBoardLoadAuditSuccessAction,
  LoadBoardLoadDetailActionTypes,
  LoadBoardLoadDetailLoadAction,
  LoadBoardLoadDetailLoadFailureAction,
  LoadBoardLoadDetailLoadSuccessAction,
  LoadDetailCarrierRemovedAction,
  LoadDetailCarrierRemovedActionTypes,
  LoadDetailCarrierRemovedFailureAction,
  LoadDetailCarrierRemovedSuccessAction,
  LoadDetailDeleteLoadAction,
  LoadDetailDeleteLoadActionTypes,
  LoadDetailDeleteLoadFailureAction,
  LoadDetailDeleteLoadSuccessAction,
} from '../actions';

@Injectable()
export class LoadBoardLoadDetailEffects {
  $load = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LoadBoardLoadDetailLoadAction>(LoadBoardLoadDetailActionTypes.Load),
      concatLatestFrom(() => this.loadboardStore$.pipe(select(getLoadBoardShowAllLoadsContext))),
      switchMap(([payload, showAllContext]) =>
        this.loadBoardService.getLoadById(payload.id, payload.scac, showAllContext).pipe(
          map((data) => new LoadBoardLoadDetailLoadSuccessAction(data)),
          catchError((err) => of(new LoadBoardLoadDetailLoadFailureAction(err)))
        )
      )
    ) }
  );

  $view = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LoadBoardLoadAuditAction>(LoadBoardLoadDetailActionTypes.Load_Audit),
      mapToPayload<LoadAudit>(),
      switchMap((loadAudit) =>
        this.loadBoardService.auditLoad(loadAudit).pipe(
          map((data) => new LoadBoardLoadAuditSuccessAction(data)),
          catchError((err) => of(new LoadBoardLoadAuditFailureAction(err)))
        )
      )
    ) }
  );

  $carrierRemvoed = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LoadDetailCarrierRemovedAction>(LoadDetailCarrierRemovedActionTypes.CarrierRemoved),
      mapToPayload<RemoveCarrierData>(),
      switchMap((payload) =>
        this.shippingService.removeCarrier(payload).pipe(
          map((data) => new LoadDetailCarrierRemovedSuccessAction(data)),
          catchError((err) => of(new LoadDetailCarrierRemovedFailureAction(err)))
        )
      )
    ) }
  );

  $deleteLoad = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LoadDetailDeleteLoadAction>(LoadDetailDeleteLoadActionTypes.DeleteLoad),
      mapToPayload<RemoveLoadData>(),
      switchMap((load) =>
        this.shippingService.deleteDetailLoad(load).pipe(
          map((data) => new LoadDetailDeleteLoadSuccessAction(data)),
          catchError((err) => of(new LoadDetailDeleteLoadFailureAction(err)))
        )
      )
    ) }
  );

  constructor(
    private actions$: Actions,
    private loadboardStore$: Store<LoadBoardState>,
    private loadBoardService: LoadBoardService,
    private shippingService: ShippingService
  ) {}
}
