import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UnauthorizedMessageService } from '../../../core/services';

@Component({
  template: `
    <h1>Unauthorized</h1>
    <p *ngIf="(unauthorizedMessage$ | async) && !(unauthorizedMarkup$ | async)">
      {{ unauthorizedMessage$ | async }}
    </p>
    <p *ngIf="unauthorizedMarkup$ | async" [innerHtml]="unauthorizedMarkup$ | async"></p>
    <p>Please click <a routerLink="/autologin">here</a> or close your browser window and attempt to login again.</p>
  `,
})
export class UnauthorizedComponent implements OnInit {
  unauthorizedMessage$: Observable<string>;
  unauthorizedMarkup$: Observable<string>;

  constructor(private messages: UnauthorizedMessageService) {}

  ngOnInit() {
    this.unauthorizedMessage$ = this.messages.getUnauthorizedMessage();
    this.unauthorizedMarkup$ = this.messages.getUnauthorizedMarkup();
  }
}
