import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { AccessorialData, AccessorialUnitOfMeasureData, BulkOperationData, ShipperAccessorialData } from '../../../shared/models';

export enum ShipperAccessorialActionTypes {
  Load = '[ShipperAccessorial] LOAD',
  Load_Success = '[ShipperAccessorial] LOAD_SUCCESS',
  Load_Failure = '[ShipperAccessorial] LOAD_FAILURE',

  Load_Accessorials = '[ShipperAccessorial] LOAD_ACCESSORIALS',
  Load_Accessorials_Success = '[ShipperAccessorial] LOAD_ACCESSORIALS_SUCCESS',
  Load_Accessorials_Failure = '[ShipperAccessorial] LOAD_ACCESSORIALS_FAILURE',

  Load_AccessorialsUoM = '[ShipperAccessorial] LOAD_ACCESSORIALS_UOM',
  Load_AccessorialsUoM_Success = '[ShipperAccessorial] LOAD_ACCESSORIALS_UOM_SUCCESS',
  Load_AccessorialsUoM_Failure = '[ShipperAccessorial] LOAD_ACCESSORIALS_UOM_FAILURE',
  BulkOperation = '[ShipperAccessorial] BULK_OPERATION',
  BulkOperation_Success = '[ShipperAccessorial] BULK_OPERATION_SUCCESS',
  BulkOperation_Failure = '[ShipperAccessorial] BULK_OPERATION_FAILURE',
  Add = '[ShipperAccessorial] ADD',
  Add_Success = '[ShipperAccessorial] ADD_SUCCESS',
  Add_Failure = '[ShipperAccessorial] ADD_FAILURE',
}

export class ShipperAccessorialLoadAction implements Action {
  readonly type = ShipperAccessorialActionTypes.Load;
  // save to global property with store or entity property (used if both global and customer accessorials are needed)
  constructor(public customerId: string, public saveToGlobal = false) {}
}
export class ShipperAccessorialLoadSuccessAction implements Action {
  readonly type = ShipperAccessorialActionTypes.Load_Success;
  constructor(public payload: ShipperAccessorialData[], public saveToGlobal = false) {}
}
export class ShipperAccessorialLoadFailureAction implements Action {
  readonly type = ShipperAccessorialActionTypes.Load_Failure;
  constructor(public payload: Error) {}
}
export class ShipperAccessorialLoadAccessorialsAction implements Action {
  readonly type = ShipperAccessorialActionTypes.Load_Accessorials;
}
export class ShipperAccessorialLoadAccessorialsSuccessAction implements Action {
  readonly type = ShipperAccessorialActionTypes.Load_Accessorials_Success;
  constructor(public payload: AccessorialData[]) {}
}
export class ShipperAccessorialLoadAccessorialsFailureAction implements Action {
  readonly type = ShipperAccessorialActionTypes.Load_Accessorials_Failure;
  constructor(public payload: Error) {}
}

export class ShipperAccessorialLoadAccessorialUoMAction implements Action {
  readonly type = ShipperAccessorialActionTypes.Load_AccessorialsUoM;
}
export class ShipperAccessorialLoadAccessorialUoMSuccessAction implements Action {
  readonly type = ShipperAccessorialActionTypes.Load_AccessorialsUoM_Success;
  constructor(public payload: AccessorialUnitOfMeasureData[]) {}
}
export class ShipperAccessorialLoadAccessorialUoMFailureAction implements Action {
  readonly type = ShipperAccessorialActionTypes.Load_AccessorialsUoM_Failure;
  constructor(public payload: Error) {}
}
export class ShipperAccessorialBulkOperationAction implements Action {
  readonly type = ShipperAccessorialActionTypes.BulkOperation;

  constructor(public payload: BulkOperationData<ShipperAccessorialData>[], public customerId: string) {}
}

export class ShipperAccessorialBulkOperationSuccessAction implements Action {
  readonly type = ShipperAccessorialActionTypes.BulkOperation_Success;
}

export class ShipperAccessorialBulkOperationFailureAction implements Action {
  readonly type = ShipperAccessorialActionTypes.BulkOperation_Failure;

  constructor(public payload: HttpErrorResponse) {}
}
export class ShipperAccessorialAddAction implements Action {
  readonly type = ShipperAccessorialActionTypes.Add;

  constructor(public payload: ShipperAccessorialData) {}
}

export class ShipperAccessorialAddSuccessAction implements Action {
  readonly type = ShipperAccessorialActionTypes.Add_Success;

  constructor(public payload: ShipperAccessorialData) {}
}

export class ShipperAccessorialAddFailureAction implements Action {
  readonly type = ShipperAccessorialActionTypes.Add_Failure;

  constructor(public payload: HttpErrorResponse) {}
}

export type ShipperAccessorialActions =
  | ShipperAccessorialLoadAction
  | ShipperAccessorialLoadSuccessAction
  | ShipperAccessorialLoadFailureAction
  | ShipperAccessorialBulkOperationAction
  | ShipperAccessorialBulkOperationSuccessAction
  | ShipperAccessorialBulkOperationFailureAction
  | ShipperAccessorialLoadAccessorialsAction
  | ShipperAccessorialLoadAccessorialsSuccessAction
  | ShipperAccessorialLoadAccessorialsFailureAction
  | ShipperAccessorialLoadAccessorialUoMFailureAction
  | ShipperAccessorialLoadAccessorialUoMSuccessAction
  | ShipperAccessorialLoadAccessorialUoMAction
  | ShipperAccessorialAddAction
  | ShipperAccessorialAddSuccessAction
  | ShipperAccessorialAddFailureAction;
