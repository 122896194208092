import { Component, OnInit } from '@angular/core';
import { SignedInMessageService } from '../../../core/services';

@Component({
  template: `
    <div *ngIf="(SignedInMessage$ | async) && !(SignedInMarkup$ | async)">
      {{ SignedInMessage$ | async }}
    </div>
    <div *ngIf="SignedInMarkup$ | async" [innerHtml]="SignedInMarkup$ | async"></div>
  `,
})
export class SignedInComponent implements OnInit {
  SignedInMessage$;
  SignedInMarkup$;

  constructor(private messages: SignedInMessageService) {
    console.log('SignedInComponent');
  }

  ngOnInit() {
    this.SignedInMessage$ = this.messages.getSignedInMessage();
    this.SignedInMarkup$ = this.messages.getSignedInMarkup();
  }
}
