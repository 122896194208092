import { concatLatestFrom } from '@ngrx/operators';import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { mapToPayload } from 'src/app/shared/utilities/map-to-payload';
import { User, UserModel } from '../../../shared/models';
import {
  AuthLoginActionTypes,
  AuthLoginSuccessAction,
  LoadshopApplicationActionTypes,
  loadshopApplicationReady,
  LoadshopApplicationResetAction,
  LoadshopApplicationStartAction,
  NavigationGoAction,
  SharedState,
} from '../../../shared/store';
import { UserProfileService } from '../../services';
import {
  LoadStatusNotificationsLoadAction,
  LoadStatusNotificationsLoadFailureAction,
  LoadStatusNotificationsLoadSuccessAction,
  LoadStatusNotificationsUpdateAction,
  LoadStatusNotificationsUpdateFailureAction,
  LoadStatusNotificationsUpdateSuccessAction,
  UserProfileActionTypes,
  UserProfileLoadAction,
  UserProfileLoadFailureAction,
  UserProfileLoadSuccessAction,
  UserProfileUpdateAction,
  UserProfileUpdateFailureAction,
  UserProfileUpdateSuccessAction,
} from '../actions';
import { UserState } from '../reducers';
import { getUserProfileModel } from '../selectors';

@Injectable()
export class UserProfileEffects {
  /*
   ** Main startup action for the app.  We need to get the user profile in order to determine if the
   ** account is setup (i.e. agreed to terms)
   */
  $startup: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType<AuthLoginSuccessAction>(AuthLoginActionTypes.Login_Success),
      switchMap(() =>
        this.userProfileService.getCustomerProfile().pipe(
          map((data) => new UserProfileLoadSuccessAction(data)),
          catchError((err) => {
            if (!(err instanceof HttpErrorResponse)) {
              return of(new NavigationGoAction({ path: ['invalid'] }));
            }
            return of(new UserProfileLoadFailureAction(err));
          })
        )
      )
    ) }
  );
  /*
   ** Action to restart loadshop if user is impersonating
   */
  $restart: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LoadshopApplicationResetAction>(LoadshopApplicationActionTypes.LoadshopReset),
      switchMap(() =>
        this.userProfileService.getCustomerProfile().pipe(
          map((data) => new UserProfileLoadSuccessAction(data)),
          catchError((err) => {
            if (!(err instanceof HttpErrorResponse)) {
              return of(new NavigationGoAction({ path: ['invalid'] }));
            }
          })
        )
      )
    ) }
  );

  $load: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType<UserProfileLoadAction>(UserProfileActionTypes.Load),
      mapToPayload<string>(),
      switchMap(() =>
        this.userProfileService.getCustomerProfile().pipe(
          map((data) => new UserProfileLoadSuccessAction(data)),
          catchError((err) => of(new UserProfileLoadFailureAction(err)))
        )
      )
    ) }
  );

  $loadStatusNotifications: Observable<Action> = createEffect( () => { return this.actions$.pipe(
    ofType<LoadStatusNotificationsLoadAction>(UserProfileActionTypes.LoadStatusNotifications_Load),
    switchMap(() =>
      this.userProfileService.getUserLoadStatusNotifications().pipe(
        map((data) => new LoadStatusNotificationsLoadSuccessAction(data)),
        catchError((err) => of(new LoadStatusNotificationsLoadFailureAction(err)))
      )
    )
  ) });

  /*
   ** Checks if the user has agreed to the terms and will kick off the Loadshop start action, which will
   ** kick off background requests to load data for the app
   */
  $checkTermsAndPolicy: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType<UserProfileLoadSuccessAction>(UserProfileActionTypes.Load_Success),
      concatLatestFrom(() => this.sharedStore$.pipe(select(loadshopApplicationReady))),
      switchMap(([profile, appReady]) => {
        const user = profile.payload;
        if (user && !user.hasAgreedToTerms) {
          if (this.router.url === '/ie-policy' || this.router.url === '/agreements') {
            return EMPTY;
          } else {
            this.router.navigate(['/user-agreement']);
          }
        } else if (user && !appReady) {
          return of(new LoadshopApplicationStartAction());
        }
        return EMPTY;
      })
    ) }
  );

  $update: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType<UserProfileUpdateAction>(UserProfileActionTypes.Update),
      mapToPayload<User>(),
      switchMap((customer: User) =>
        this.userProfileService.update(customer).pipe(
          map((data) => new UserProfileUpdateSuccessAction(data)),
          catchError((err) => {
            if (err && err.error && err.error.title && err.error.detail) {
              this.messageService.add({ summary: err.error.title, detail: err.error.detail, severity: 'error' });
            }
            return of(new UserProfileUpdateFailureAction(err));
          })
        )
      )
    ) }
  );

  $profileMissingContactNumber = createEffect(() =>
    { return this.actions$.pipe(
      ofType(LoadshopApplicationActionTypes.LoadshopStart),
      concatLatestFrom(() => this.userStore$.pipe(select(getUserProfileModel))),
      tap((args) => {
        if (args && args[1]) {
          const profile = args[1] as UserModel;
          if (!profile) {
            return;
          }
          if (!this.userProfileService.validateUserNotifications(profile)) {
            this.router.navigate(['/user/profile']);
          }
        }
      })
    ) },
    { dispatch: false }
  );

  $profileUpdate: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType<UserProfileUpdateSuccessAction>(UserProfileActionTypes.Update_Success),
      tap((profile) => this.messageService.add({ id: 0, detail: 'Profile Updated', severity: 'success' }))
    ) },
    { dispatch: false }
  );

  $updateLoadStatusNotifications: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LoadStatusNotificationsUpdateAction>(UserProfileActionTypes.LoadStatusNotificationsUpdate),
      switchMap((action) =>
        this.userProfileService.updateLoadStatusNotifications(action.payload).pipe(
          map((data) => new LoadStatusNotificationsUpdateSuccessAction(data)),
          catchError((err) => {
            if (err && err.error && err.error.title && err.error.detail) {
              this.messageService.add({ summary: err.error.title, detail: err.error.detail, severity: 'error' });
            }
            return of(new LoadStatusNotificationsUpdateFailureAction(err));
          })
        )
      )
    ) }
  );

  $updateLoadStatusNotificationsMessage: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LoadStatusNotificationsUpdateSuccessAction>(UserProfileActionTypes.LoadStatusNotificationsUpdate_Success),
      tap((profile) => this.messageService.add({ id: 0, detail: 'Load status notifications updated', severity: 'success' }))
    ) },
    { dispatch: false }
  );
  constructor(
    private actions$: Actions,
    private userProfileService: UserProfileService,
    private messageService: MessageService,
    private router: Router,
    private sharedStore$: Store<SharedState>,
    private userStore$: Store<UserState>
  ) {}
}
