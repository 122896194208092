import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { mapToPayload } from 'src/app/shared/utilities/map-to-payload';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ShippingService } from '../../services';
import {
  ShippingLoadAuditLogActionTypes,
  ShippingLoadAuditLogsLoadAction,
  ShippingLoadAuditLogsLoadFailureAction,
  ShippingLoadAuditLogsLoadSuccessAction,
} from '../actions';

@Injectable()
export class ShippingLoadAuditLogEffects {
  $loadGroups = createEffect(() =>
    { return this.actions$.pipe(
      ofType<ShippingLoadAuditLogsLoadAction>(ShippingLoadAuditLogActionTypes.Load_Audit_Logs_Load),
      mapToPayload<{ loadId: string }>(),
      // use mergeMap to allow the logs for multiple loads to be loaded at once
      mergeMap((payload: { loadId: string }) =>
        this.shippingService.getLoadAuditLogs(payload.loadId).pipe(
          map((data) => new ShippingLoadAuditLogsLoadSuccessAction({ loadId: payload.loadId, logs: data })),
          catchError((err) => of(new ShippingLoadAuditLogsLoadFailureAction(err)))
        )
      )
    ) }
  );

  constructor(private actions$: Actions, private shippingService: ShippingService) {}
}
