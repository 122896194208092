export class Environment {
    production: boolean;
    debug: boolean;
    apiUrl: string;
    identityServerUrl: string;
    siteUrl: string;
    baseUrl: string;
    forbiddenRoute?: string;
    unauthorizedRoute?: string;
    silentRenew?: boolean;
    scopes?: string;
    clientId?: string;
    dataDogClientId?: string;
    dataDogUrl?: string;
    topsSilentRenew?: boolean;
    topsSilentRenewInterval?: number;
    topsInactivityLogoutInterval?: number;
  }
  