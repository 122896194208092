<div class="d-flex flex-column conversation-list">
  <div>
    <kbxl-select-conversation
      [user]="user"
      [conversations]="visibleConversations"
      (selectConversation)="selectConversation($event)"
    ></kbxl-select-conversation>
  </div>
  <div class="position-relative text-center mt-4" *ngIf="loading">
    <div class="progress-indicator">
      <div class="small-spinner-container">
        <p-progressSpinner></p-progressSpinner>
        <div>Loading conversations</div>
      </div>
    </div>
  </div>
  <div>
    <div class="list-group mt-2">
      <a
        class="list-group-item list-group-item-action"
        *ngFor="let conversation of visibleConversations"
        [ngClass]="{ active: conversation.selected, unread: hasUnreadOrNotification(conversation) }"
        (click)="selectConversation(conversation)"
      >
        <div class="d-flex flex-column flex-md-row w-100 justify-content-between align-items-start">
          <span class="mb-1 mr-2 conversation-recipient">{{ getUser(conversation) }}</span>
          <small class="message-timestamp text-nowrap">{{ conversation.lastChangeDate | date:'short' }}</small>
        </div>
        <div class="d-flex flex-column flex-md-row w-100 justify-content-between align-items-start">
          <span class="mb-1 mr-2 conversation-recipient">{{ getRecipient(conversation) }}</span>
        </div>
        <p class="recent-message text-truncate mb-0">{{ getMostRecentMessage(conversation) }}</p>
      </a>
    </div>
    <div class="text-muted text-center mt-4" *ngIf="loaded && (!conversations || conversations.length === 0)">
      <h6>No conversations</h6>
      <p>Conversations that have been inactive for 7 or more days are not shown here.</p>
    </div>
  </div>
</div>
