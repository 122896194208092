import { concatLatestFrom } from '@ngrx/operators';import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of, timer } from 'rxjs';
import { catchError, filter, map, mapTo, mergeMap, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { LoadBoardService } from 'src/app/load-board/services';
import { mapToPayload } from 'src/app/shared/utilities/map-to-payload';
import { getUserProfileModel, UserFocusEntitySelectorTypes, UserProfileActionTypes } from 'src/app/user/store';
import { User } from '../../../shared/models';
import { AuthLoginActionTypes, LoadshopApplicationActionTypes, NavigationGoAction } from '../../../shared/store';
import { CommonService } from '../../services';
import {
  MenuActionTypes,
  MenuLoadAction,
  MenuLoadFailureAction,
  MenuLoadSuccessAction,
  MenuUpdateFailureAction,
  MenuUpdateSuccessAction,
  MenuVisibilityBadgeLoadAction,
  MenuVisibilityBadgeLoadFailureAction,
  MenuVisibilityBadgeLoadSuccessAction,
} from '../actions';
import { CoreState } from '../reducers';
import { getBrowserIsInternetExplorer, getBrowserKeepUsingInternetExplorer } from '../selectors';

@Injectable()
export class MenuEffects {
  visibilityBadgePollingIntervalInSeconds: number = 2 * 60; // 2 mins

  $login = createEffect(() =>
    { return this.actions$.pipe(
      ofType(MenuActionTypes.Load, UserProfileActionTypes.Load_Success),
      withLatestFrom(
        this.store$.pipe(select(getUserProfileModel)),
        this.store$.pipe(select(getBrowserIsInternetExplorer)),
        this.store$.pipe(select(getBrowserKeepUsingInternetExplorer))
      ),
      switchMap(([_, user, isIE, keepUsingIE]) =>
        this.menuService.getMenuItems(user).pipe(
          map((data) => {
            if (isIE && !keepUsingIE) {
              this.router.navigate(['/ie-policy']);
            }
            return new MenuLoadSuccessAction(data);
          }),
          catchError((err) => of(new MenuLoadFailureAction(err)))
        )
      )
    ) }
  );

  $profileUpdate = createEffect(() =>
    { return this.actions$.pipe(
      ofType(UserFocusEntitySelectorTypes.UpdateFocusEntity),
      switchMap(() => [new NavigationGoAction({ path: ['/change-entity'] }), new MenuUpdateSuccessAction([])])
    ) }
  );

  $profileUpdateSuccess = createEffect(() =>
    { return this.actions$.pipe(
      ofType(UserProfileActionTypes.Update_Success),
      concatLatestFrom(() => this.store$.pipe(select(getUserProfileModel))),
      switchMap(([_, user]) =>
        this.menuService.getMenuItems(user).pipe(
          mergeMap((data) => [new MenuUpdateSuccessAction(data), new NavigationGoAction({ path: ['/'] })]),
          catchError((err) => of(new MenuUpdateFailureAction(err)))
        )
      )
    ) }
  );

  $startup = createEffect(() =>
    { return this.actions$.pipe(
      ofType(LoadshopApplicationActionTypes.LoadshopStart),
      switchMap(() => of(new MenuLoadAction()))
    ) }
  );

  /**
   * Load the Visibility Badge data from the API after the user profile is loaded
   */
  // TODO: We can probably eliminate this and replace with a start/cancel polling call when the app components tab menu
  // is inited/destroyed and once more structure is around the different pages that may need to be moved to whatever container
  // ends up holding the p-tabmenu
  $polling = createEffect(() =>
    { return this.actions$.pipe(
      ofType(UserProfileActionTypes.Load_Success),
      mapToPayload<User>(),
      filter((user) => user && user.isCarrier && user.carrierVisibilityTypes && user.carrierVisibilityTypes.length > 0),
      switchMap(() =>
        timer(0, this.visibilityBadgePollingIntervalInSeconds * 1000).pipe(
          takeUntil(this.actions$.pipe(ofType(AuthLoginActionTypes.Logout))),
          mapTo(new MenuVisibilityBadgeLoadAction())
        )
      )
    ) }
  );

  $loadVisibilityBadge = createEffect(() =>
    { return this.actions$.pipe(
      ofType<MenuVisibilityBadgeLoadAction>(MenuActionTypes.Visibility_Badge_Load),
      concatLatestFrom(() => this.store$.pipe(select(getUserProfileModel))),
      map(([, user]) => user),
      filter((user) => user && user.isCarrier && user.carrierVisibilityTypes && user.carrierVisibilityTypes.length > 0),
      switchMap(() => this.getVisibilityBadge())
    ) }
  );

  getVisibilityBadge() {
    return this.loadService.getNumLoadsRequiringVisibilityInfo().pipe(
      map((data) => new MenuVisibilityBadgeLoadSuccessAction(data)),
      catchError((err) => of(new MenuVisibilityBadgeLoadFailureAction(err)))
    );
  }

  constructor(
    private menuService: CommonService,
    private actions$: Actions,
    private loadService: LoadBoardService,
    private store$: Store<CoreState>,
    private router: Router
  ) {}
}
