<div class="loading-indicator" *ngIf="loading">
  <p-progressSpinner></p-progressSpinner>
</div>
<form [formGroup]="formGroup" style="max-height: 90vh; overflow-y: auto">
  <div *ngIf="errorSummary && errorCount <= 5">
    <div class="error-header">Error Summary</div>
    <div class="error-container">{{ errorSummary }}</div>
  </div>

  <div class="instruction">
    <span>Fill out the form to receive a forecasted <span [innerHtml]="smartSpotLabel"></span>.</span>
  </div>

  <div class="d-flex justify-content-between flex-wrap pt-2">
    <div class="flex-grow-1">
      <div class="kbxl-field-label">
        <label for="origin" class="control-label address" [ngClass]="{ error: formGroup.controls['origin'].invalid }"> Enter Origin </label>
      </div>
      <kbxl-address-autocomplete
        id="origin"
        formControlName="origin"
        [(place)]="origin"
        [includeAddress]="false"
        [takeFirstZip]="true"
        [placeholder]="'Search by City, State/Province; Postal Code'"
      ></kbxl-address-autocomplete>
    </div>
    <div class="flex-grow-1">
      <div class="kbxl-field-label">
        <label for="destination" class="control-label address" [ngClass]="{ error: formGroup.controls['destination'].invalid }">
          Enter Destination
        </label>
      </div>
      <kbxl-address-autocomplete
        id="destination"
        formControlName="destination"
        [(place)]="destination"
        [includeAddress]="false"
        [takeFirstZip]="true"
        [placeholder]="'Search by City, State/Province; Postal Code'"
      ></kbxl-address-autocomplete>
    </div>
  </div>
  <div class="d-flex">
    <div class="d-flex flex-column">
      <div class="kbxl-field-label p-0">
        <label
          for="pickupDate"
          class="control-label"
          [ngClass]="{ error: formGroup.controls['pickupDate'].invalid || formGroup.controls['pickupTime'].invalid }"
        >
          Pickup Date
        </label>
      </div>
      <div class="d-flex p-0">
        <p-calendar
          class="p-inputgroup"
          formControlName="pickupDate"
          name="pickupDate"
          [showIcon]="true"
          showButtonBar="true"
          [showTime]="false"
          [selectOtherMonths]="true"
          appendTo="body"
        ></p-calendar>
        <p-inputMask mask="99:99" class="ml-2" formControlName="pickupTime" name="pickupTime" placeholder="23:59"></p-inputMask>
      </div>
    </div>
    <div class="d-flex flex-column">
      <div class="kbxl-field-label p-0">
        <label
          for="deliveryDate"
          class="control-label"
          [ngClass]="{ error: formGroup.controls['deliveryDate'].invalid || formGroup.controls['deliveryTime'].invalid }"
        >
          Delivery Date
        </label>
      </div>
      <div class="d-flex p-0">
        <p-calendar
          class="p-inputgroup"
          formControlName="deliveryDate"
          name="deliveryDate"
          [showIcon]="true"
          showButtonBar="true"
          [showTime]="false"
          [selectOtherMonths]="true"
          appendTo="body"
        ></p-calendar>
        <p-inputMask mask="99:99" class="ml-2" formControlName="deliveryTime" name="deliveryTime" placeholder="23:59"></p-inputMask>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-between flex-wrap pt-2">
    <div class="flex-grow-1">
      <div class="kbxl-field-label">
        <label for="equipment" class="control-label" [ngClass]="{ error: formGroup.controls['equipmentId'].invalid }"> Equipment </label>
      </div>
      <kbxl-tree-drop-down
        [treeNodes]="groupedEquipment"
        [(selectedNode)]="selectedEquipment"
        [singleSelection]="true"
        placeHolder="Select Equipment"
        (selectedNodeChange)="equipmentSelectionMade($event)"
        isInModal="true"
      >
      </kbxl-tree-drop-down>
    </div>
    <div class="flex-grow-1">
      <div class="kbxl-field-label">
        <label for="weight" class="control-label" [ngClass]="{ error: formGroup.controls['weight'].invalid }"> Weight </label>
      </div>
      <input type="number" pInputText formControlName="weight" />
    </div>
  </div>
  <div class="d-flex justify-content-between flex-wrap pt-2">
    <div class="col">
      <div class="kbxl-field-label">
        <label for="commodities" class="control-label"> Commodities </label>
      </div>
      <p-dropdown
        [options]="commodities"
        placeholder="Select Commodity"
        formControlName="commodity"
        name="commodity"
        dataKey="commodityName"
        optionLabel="commodityName"
        [styleClass]="'p-dropdown-primary'"
        dropdownIcon="pi pi-caret-down"
        appendTo="body"
        kbxlCloseOnScroll
      >
        <ng-template let-item pTemplate="selectedItem">
          <span class="selected-item-label">{{ formGroup.get('commodity').value.commodityName }}</span>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="col">
      <div class="kbxl-field-label">
        <label for="serviceTypes" class="control-label"> Services </label>
      </div>
      <p-multiSelect
        [options]="serviceTypes"
        placeholder="Select All Services"
        formControlName="services"
        name="services"
        dataKey="serviceTypeId"
        optionLabel="name"
        [styleClass]="'p-dropdown-primary'"
        dropdownIcon="pi pi-caret-down"
        appendTo="body"
        maxSelectedLabels="10"
        kbxlCloseOnScroll
      >
      </p-multiSelect>
    </div>
    <div class="col" *ngIf="showSpotQuoteModal">
      <div class="kbxl-field-label">
        <label for="pickupAppointmentType" class="control-label"> Pickup Appointment Type </label>
      </div>
      <p-dropdown
        [options]="schedulerConfirmationTypes"
        placeholder="Select Schedule Confirmation"
        formControlName="pickupAppointmentType"
        name="pickupAppointmentType"
        dataKey="appointmentSchedulerConfirmationTypeId"
        optionLabel="description"
        [styleClass]="'p-dropdown-primary'"
        dropdownIcon="pi pi-caret-down"
        appendTo="body"
        kbxlCloseOnScroll
      >
        <ng-template let-item pTemplate="selectedItem">
          <span class="selected-item-label">{{ formGroup.get('pickupAppointmentType').value.description }}</span>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="col" *ngIf="showSpotQuoteModal">
      <div class="kbxl-field-label">
        <label for="dropoffAppointmentType" class="control-label"> Dropoff Appointment Type </label>
      </div>
      <p-dropdown
        [options]="schedulerConfirmationTypes"
        placeholder="Select Schedule Confirmation"
        formControlName="dropoffAppointmentType"
        name="dropoffAppointmentType"
        dataKey="appointmentSchedulerConfirmationTypeId"
        optionLabel="description"
        [styleClass]="'p-dropdown-primary'"
        dropdownIcon="pi pi-caret-down"
        appendTo="body"
        kbxlCloseOnScroll
      >
        <ng-template let-item pTemplate="selectedItem">
          <span class="selected-item-label">{{ formGroup.get('dropoffAppointmentType').value.description }}</span>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
  <div class="d-flex justify-content-between flex-wrap pt-2" *ngIf="showSpotQuoteModal">
    <div class="flex-grow-1">
      <div class="kbxl-field-label">
        <label for="totalPostedScacs" class="control-label"> Total Posted Scacs </label>
      </div>
      <input type="number" pInputText formControlName="totalPostedScacs" />
    </div>
    <div class="flex-grow-1">
      <div class="kbxl-field-label">
        <label for="totalPostedCarriers" class="control-label"> Total Posted Carriers </label>
      </div>
      <input type="number" pInputText formControlName="totalPostedCarriers" />
    </div>
  </div>

  <ng-container *ngIf="smartSpotQuote !== null">
    <hr />
    <div class="quote-disclaimer">
      Please Note: if limiting the number of carriers available to book the load when posting to the Marketplace, the
      <span [innerHtml]="smartSpotLabel"></span> shown at the time of posting may be different than this forecast.
    </div>

    <div class="d-flex justify-content-between align-content-center align-items-center flex-wrap pt-2">
      <label class="estimated-rate-label"><span [innerHtml]="smartSpotLabel"></span></label>
      <label class="estimated-rate-value">
        <div *ngIf="showSpotQuoteModal; else noSpotQuoteModal">
          <a (click)="openSpotPriceModal()">
            {{ smartSpotQuote.loadshopDatGuardRate | currency }}

            <fa-icon [icon]="['fas', 'search-plus']" class="ml-1 link" pTooltip="View Spot Quote breakdown"> </fa-icon>
          </a>
        </div>
        <ng-template #noSpotQuoteModal>
          <span> {{ smartSpotQuote.loadshopDatGuardRate | currency }}</span>
        </ng-template>
      </label>
    </div>
  </ng-container>

  <div class="d-flex justify-content-between align-content-center align-items-center flex-wrap pt-2">
    <div class="flex-grow-1">
      <button
        *ngIf="smartSpotQuote !== null && allowManualLoadCreation"
        pButton
        (click)="createOrderClick()"
        label="Create Order"
        type="button"
        class="p-button-teal"
      ></button>
    </div>
    <div>
      <button pButton (click)="clearClick()" label="Clear" type="button" class="p-button-outlined p-button-secondary"></button>
    </div>
    <div>
      <button pButton (click)="getQuoteClicked()" label="Go" type="button"></button>
    </div>
  </div>
</form>
