import { CounterOfferRequestStatusEnum } from '.';

export interface LoadCounterOfferActionData {
  loadCounterOfferActionId?: number;
  loadCounterOfferId: number;
  comment: string;
  userId: string;
  userName: string;
  createDtTm?: Date;
  status?: CounterOfferRequestStatusEnum;
  contractedCarrierOffer: boolean;
}
