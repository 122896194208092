import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CarrierProfile } from 'src/app/shared/models';
import { mapToPayload } from 'src/app/shared/utilities/map-to-payload';
import { CarrierProfileService } from '../../services';
import {
  CarrierProfileActionTypes,
  CarrierProfileLoadAction,
  CarrierProfileLoadAllAction,
  CarrierProfileLoadAllFailureAction,
  CarrierProfileLoadAllSuccessAction,
  CarrierProfileLoadFailureAction,
  CarrierProfileLoadSuccessAction,
  CarrierProfileUpdateAction,
  CarrierProfileUpdateFailureAction,
  CarrierProfileUpdateSuccessAction,
} from '../actions';

@Injectable()
export class CarrierProfileEffects {
  $loadCarrierProfile = createEffect(() =>
    { return this.actions$.pipe(
      ofType<CarrierProfileLoadAction>(CarrierProfileActionTypes.Load),
      mapToPayload<{ carrierId: string }>(),
      switchMap((payload: { carrierId: string }) =>
        this.carrierProfileService.getCarrier(payload.carrierId).pipe(
          map((data) => new CarrierProfileLoadSuccessAction(data)),
          catchError((err) => of(new CarrierProfileLoadFailureAction(err)))
        )
      )
    ) }
  );

  $updateCarrierProfile = createEffect(() =>
    { return this.actions$.pipe(
      ofType<CarrierProfileUpdateAction>(CarrierProfileActionTypes.Update),
      mapToPayload<CarrierProfile>(),
      switchMap((payload: CarrierProfile) =>
        this.carrierProfileService.updateCarrier(payload).pipe(
          map((data) => new CarrierProfileUpdateSuccessAction(data)),
          catchError((err) => of(new CarrierProfileUpdateFailureAction(err)))
        )
      ),
      tap((action: CarrierProfileUpdateSuccessAction) =>
        this.messageService.add({
          id: 0,
          detail: `${this.titleCasePipe.transform(action.payload.carrierName)} Updated`,
          severity: 'success',
        })
      )
    ) }
  );

  $loadAllCarriers = createEffect(() =>
    { return this.actions$.pipe(
      ofType<CarrierProfileLoadAllAction>(CarrierProfileActionTypes.Load_All),
      switchMap(() =>
        this.carrierProfileService.getAllCarriers().pipe(
          map((data) => new CarrierProfileLoadAllSuccessAction(data)),
          catchError((err) => of(new CarrierProfileLoadAllFailureAction(err)))
        )
      )
    ) }
  );

  constructor(
    private actions$: Actions,
    private carrierProfileService: CarrierProfileService,
    private messageService: MessageService,
    private titleCasePipe: TitleCasePipe
  ) {}
}
