import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { iif, Observable, of } from 'rxjs';
import { catchError, delay, map, mergeMap, repeat, tap } from 'rxjs/operators';
import { ENVIRONMENTSETTINGS } from '../../core/app-tokens/environment-setting';
import { Environment } from '../../core/models/environment';

@Injectable({
  providedIn: 'root',
})
export class TopsTokenRefreshService {
  apiUrl: string;
  enabled: boolean;
  checkInterval: number;
  logoutInterval: number;
  started: boolean;
  lastRequestDate: Date;
  refreshRequest$: Observable<boolean>;
  logout$: Observable<boolean>;

  constructor(handler: HttpBackend, @Inject(ENVIRONMENTSETTINGS) environmentSettings: Environment) {
    this.apiUrl = environmentSettings.apiUrl;
    this.enabled = environmentSettings.topsSilentRenew;
    this.checkInterval = environmentSettings.topsSilentRenewInterval || 10 * 60000; // default to 10 minutes
    this.logoutInterval = environmentSettings.topsInactivityLogoutInterval || 60 * 60000; // default to 1 hour

    this.refreshRequest$ = new HttpClient(handler).get(`/secur/api/token/refresh`).pipe(
      catchError(() => of(false)),
      map(() => true)
    );

    this.logout$ = of(true).pipe(
      tap(() => {
        if (top && top.window) {
          const origin = top.window.origin || `${top.window.location.protocol}//${top.window.location.hostname}`;
          top.window.location.href = `${origin}/secur/Account/LogOff`;
        }
      })
    );

    this.startRefresh();
  }

  setLastRequestDate(lastRequestDate: Date) {
    this.lastRequestDate = lastRequestDate;
  }

  startRefresh() {
    if (!this.started && this.enabled) {
      this.started = true;

      const refresh = of(1).pipe(
        delay(this.checkInterval),
        mergeMap(() =>
          iif(() => this.lastRequestDate > new Date(new Date().getTime() - this.logoutInterval), this.refreshRequest$, this.logout$)
        ),
        repeat()
      );

      refresh.subscribe();
    }
  }
}
