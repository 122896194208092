import { Component, Input } from '@angular/core';
import { NoteData } from 'src/app/shared/models/note-data';

@Component({
  selector: 'kbxl-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss'],
})
export class NotesListComponent {
  @Input() notes: NoteData[];
  constructor() {}
}
