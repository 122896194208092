import { Action } from '@ngrx/store';
import { LoadAccessorialRequestAvailabilityData } from '../../models/load-accessorial-request-availability-data';

export enum AccessorialRequestActionTypes {
  UnlockLoad = '[AccessorialRequest] UNLOCK_LOAD_ACCESSORIALS',
  UnlockLoad_Success = '[AccessorialRequest] UNLOCK_LOAD_ACCESSORIALS_SUCCESS',
  UnlockLoad_Failure = '[AccessorialRequest] UNLOCK_LOAD_ACCESSORIALS_FAILURE',
}

export class AccessorialRequestUnlockLoadAction implements Action {
  readonly type = AccessorialRequestActionTypes.UnlockLoad;
  constructor(public loadId: string, public unlock: boolean) {}
}

export class AccessorialRequestUnlockLoadSuccessAction implements Action {
  readonly type = AccessorialRequestActionTypes.UnlockLoad_Success;
  constructor(public payload: LoadAccessorialRequestAvailabilityData) {}
}
export class AccessorialRequestUnlockLoadFailureAction implements Action {
  readonly type = AccessorialRequestActionTypes.UnlockLoad_Failure;
  constructor(public payload: Error) {}
}
export type AccessorialRequestActions =
  | AccessorialRequestUnlockLoadAction
  | AccessorialRequestUnlockLoadSuccessAction
  | AccessorialRequestUnlockLoadFailureAction;
