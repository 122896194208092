import { Action } from '@ngrx/store';
import { ActionItemLoadView, ActionItemsFilterCriteriaData, PageableResult } from '../../models';
import { PageableQueryHelper } from '../../utilities';

export enum AccessorialActionItemActionTypes {
  LoadFilters = '[AccessorialActionItem] LOAD_FILTERS',
  LoadFilters_Success = '[AccessorialActionItem] LOAD_FILTERS_SUCCESS',
  LoadFilters_Failure = '[AccessorialActionItem] LOAD_FILTERS_FAILURE',

  Load_ActionItems = '[AccessorialActionItems] LOAD',
  Load_ActionItems_Success = '[AccessorialActionItems] LOAD_SUCCESS',
  Load_ActionItems_Failure = '[AccessorialActionItems] LOAD_FAILURE',
  Update_ActionItems_Query = '[AccessorialActionItems] UPDATE_QUERY',
}

export class AccessorialActionItemLoadFiltersAction implements Action {
  readonly type = AccessorialActionItemActionTypes.LoadFilters;
}

export class AccessorialActionItemLoadFiltersSuccessAction implements Action {
  readonly type = AccessorialActionItemActionTypes.LoadFilters_Success;

  constructor(public payload: ActionItemsFilterCriteriaData) {}
}
export class AccessorialActionItemLoadFiltersFailureAction implements Action {
  readonly type = AccessorialActionItemActionTypes.LoadFilters_Failure;
  constructor(public payload: Error) {}
}

export class AccessorialActionItemsLoadAction implements Action {
  readonly type = AccessorialActionItemActionTypes.Load_ActionItems;

  constructor(public payload: { searchType: string; queryHelper: PageableQueryHelper }) {}
}

export class AccessorialActionItemsLoadSuccessAction implements Action {
  readonly type = AccessorialActionItemActionTypes.Load_ActionItems_Success;

  constructor(public payload: PageableResult<ActionItemLoadView>) {}
}

export class AccessorialActionItemsLoadFailureAction implements Action {
  readonly type = AccessorialActionItemActionTypes.Load_ActionItems_Failure;

  constructor(public payload: Error) {}
}

export class AccessorialActionItemsUpdateQueryAction implements Action {
  readonly type = AccessorialActionItemActionTypes.Update_ActionItems_Query;

  constructor(public payload: PageableQueryHelper) {}
}
export type AccessorialActionItemActions =
  | AccessorialActionItemLoadFiltersAction
  | AccessorialActionItemLoadFiltersSuccessAction
  | AccessorialActionItemLoadFiltersFailureAction
  | AccessorialActionItemsLoadAction
  | AccessorialActionItemsLoadSuccessAction
  | AccessorialActionItemsLoadFailureAction
  | AccessorialActionItemsUpdateQueryAction;
