export interface LaneHistoryData {
  loadId: string;
  statusDesc: string;
  statusDtTm: Date;
  scac: string;
  carrierName: string;
  lineHaulRate: number;
  fuelRate: number;
  totalRate: number;
}
