import { Component } from '@angular/core';

@Component({
  selector: 'kbxl-notfound',
  template: `
    <div>
      <h1>Page Not Found</h1>
      <h3>Please enter a correct URL path.</h3>
    </div>
  `,
})
export class NotFoundComponent {}
