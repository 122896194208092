import { NavigationExtras } from '@angular/router';
import { Action } from '@ngrx/store';

export enum NavigationActionTypes {
  Go = '[Router] GO',
  Back = '[Router] BACK',
  Forward = '[Router] FORWARD',
}

export class NavigationGoAction implements Action {
  readonly type = NavigationActionTypes.Go;

  constructor(
    public payload: {
      // tslint:disable-next-line: no-any
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    }
  ) {}
}

export class NavigationBackAction implements Action {
  readonly type = NavigationActionTypes.Back;
}

export class NavigationForwardAction implements Action {
  readonly type = NavigationActionTypes.Forward;
}

export type Actions = NavigationGoAction | NavigationBackAction | NavigationForwardAction;
