import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ForbiddenMessageService {
  /**
   * Optional way for extending services to provide a plain text message.
   * Extending services can return of(null) here and/or return a value
   * from getForbiddenMarkup() in order to render HTML markup as the
   * Forbidden message instead of this plain text message.
   */
  getForbiddenMessage(): Observable<string> {
    return of(null);
  }

  /**
   * Optional way for extending services to provide an Forbidden message as
   * markup instead of plain text.  If markup is provide, it will override
   * any plain text message.  So if a plain text message is preferred,
   * extending services should have getForbiddenMarkup return of(null)
   */
  getForbiddenMarkup(): Observable<string> {
    return of(`You do not have access to this application. Please contact KBX Logistics
      at
      <a href="mailto:GBY.TopsAdmin@KBXLogistics.com"
        >GBY.TopsAdmin@KBXLogistics.com</a
      >
      and let them know you need access. Thanks!`);
  }
}
