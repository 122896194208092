import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { firstBy } from 'thenby';
import { CoreState, getBrowserIsMobile } from '../../../core/store';
import { BaseComponent } from '../../../shared/components';
import { UserModel } from '../../../shared/models';
import { UserFocusEntitySelectorModalComponent } from '../../../user/components/user-focus-entity-selector-modal';
import { getUserProfileModel, UserState } from '../../../user/store';
import { ChatNotification } from '../../models';
import { ExtendedConversation } from '../../models/extended-conversation';
import {
  ChatState,
  CloseSelectedConversationAction,
  getConversationHubReady,
  getConversations,
  getConversationsFirstLoad,
  getConversationsLoading,
  getUserContextNotifications,
  SelectConversationAction,
} from '../../store';

@Component({
  selector: 'kbxl-chat-view-container',
  templateUrl: './chat-view-container.component.html',
  styleUrls: ['./chat-view-container.component.scss'],
  providers: [DialogService],
})
export class ChatViewContainerComponent extends BaseComponent implements OnInit, OnDestroy {
  conversations$: Observable<ExtendedConversation[]>;
  otherUserContextNotifications: ChatNotification[];
  currentUserContextNotifications: number;
  loading$: Observable<boolean>;
  conversationFirstLoaded$: Observable<boolean>;
  hubReady$: Observable<boolean>;
  user: UserModel;
  isMobile = false;
  displaySidebar = false;

  isChatEnabled = false;
  urlConversationId: number;

  mobileSelectedTabIndex = 0;
  constructor(
    private store: Store<ChatState>,
    private userStore: Store<UserState>,
    private route: ActivatedRoute,
    private router: Router,
    private coreStore: Store<CoreState>,
    private dialogService: DialogService
  ) {
    super();
  }

  ngOnInit() {
    this.conversations$ = this.store.pipe(
      select(getConversations),
      map((x) => {
        x.sort(firstBy((y) => y.recentlyJoined, 'desc'));
        return x;
      })
    );
    this.loading$ = this.store.pipe(select(getConversationsLoading));
    this.conversationFirstLoaded$ = this.store.pipe(select(getConversationsFirstLoad));
    this.hubReady$ = this.store.pipe(select(getConversationHubReady));

    this.coreStore.pipe(select(getBrowserIsMobile), takeUntil(this.destroyed$)).subscribe((x) => {
      this.isMobile = x;
      if (this.isMobile) {
        // check if a conversation is selected, if not, open the conversations list
        if (!this.route.snapshot.params.id) {
          this.displaySidebar = true;
        }
      }
    });

    this.userStore.pipe(select(getUserProfileModel), takeUntil(this.destroyed$)).subscribe((user) => {
      this.user = user;

      // Shippers are allowed to opt-out of chat
      if (user && user.isShipper) {
        const shipper = user.authorizedShippers.find((x) => x.customerId === user.primaryCustomerId);
        this.isChatEnabled = shipper && shipper.isChatEnabled;
      } else {
        this.isChatEnabled = true;
      }
    });

    // setup deep linking conversations
    this.hubReady$
      .pipe(
        filter((x) => x === true),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        // subscribe to url changes
        this.route.params.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
          if (params && params.conversationId) {
            const id = Number.parseInt(params.conversationId, 10);
            if (id && Number.isInteger(id)) {
              this.urlConversationId = id;
              // dispatch event to show selected conversation
              this.store.dispatch(new SelectConversationAction(id, this.route.snapshot.queryParams.q));
              return;
            }
          }
          // bad route or id, redirect back to /chat
          this.router.navigate(['/chat']);
        });
        // get user notifications
        this.store.pipe(select(getUserContextNotifications)).subscribe((notifications) => {
          if (!this.user) {
            return;
          }

          this.otherUserContextNotifications = notifications.filter(
            (y) => !this.isNotificationForCurrentContext(y) && y.notificationCount > 0
          );
          this.currentUserContextNotifications = null;
          const currentEntity = notifications.filter((y) => this.isNotificationForCurrentContext(y) && y.notificationCount > 0);
          if (currentEntity && currentEntity.length > 0) {
            this.currentUserContextNotifications = currentEntity.map((x) => x.notificationCount).reduce((prev, value) => prev + value);
          }
        });
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    // if a conversation is selected, close it on navigate away
    this.store.dispatch(new CloseSelectedConversationAction());
  }

  onVisibleChange(visible: boolean) {
    if (!visible) {
      this.displaySidebar = false;
    }
  }
  isNotificationForCurrentContext(notification: ChatNotification): boolean {
    let userEntityId = this.user.primaryCustomerId ? this.user.primaryCustomerId.toLocaleUpperCase() : '';
    if (this.user.isCarrier) {
      userEntityId = this.user.carrierScac ? this.user.carrierScac.toLocaleUpperCase() : '';
    }

    const contextEntityId = notification.contextEntityId.toLocaleUpperCase();
    return contextEntityId === userEntityId;
  }

  dismissAllNotifications(): void {
    // temp hide the notifications
    this.otherUserContextNotifications = [];
    this.currentUserContextNotifications = null;
  }

  conversationSelected(conversation: ExtendedConversation): void {
    this.displaySidebar = false;
    this.urlConversationId = conversation.id;
    // navigate to new chat and clear query params (if any)
    this.router.navigate(['chat', conversation.id], {
      queryParams: {
        q: null, // query
        a: null, // attach to message
        s: null, // select load if the filter brings the list result to 1
      },
    });
  }

  openSwitchEntityModal(): void {
    this.dialogService.open(UserFocusEntitySelectorModalComponent, {
      header: 'Change Entity',
      baseZIndex: 5000,
    });
  }
}
