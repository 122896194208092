import { concatLatestFrom } from '@ngrx/operators';import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { getUserProfileModel, UserProfileActionTypes } from 'src/app/user/store';
import { LoadshopApplicationActionTypes } from '../../../shared/store';
import { CommonService } from '../../services';
import { AdminMenuActionTypes, AdminMenuLoadAction, AdminMenuLoadFailureAction, AdminMenuLoadSuccessAction } from '../actions';
import { CoreState } from '../reducers';
import { getLoadshopSettings } from '../selectors';

@Injectable()
export class AdminMenuEffects {
  $login = createEffect(() =>
    { return this.actions$.pipe(
      ofType(AdminMenuActionTypes.Load, UserProfileActionTypes.Load_Success, UserProfileActionTypes.Update_Success),
      concatLatestFrom(() => this.store$.pipe(select(getUserProfileModel))),
      concatLatestFrom(() => this.store$.pipe(select(getLoadshopSettings))),
      switchMap(([[_, user], lsSettings]) =>
        this.menuService.getAdminMenuItems(user, lsSettings).pipe(
          map((data) => new AdminMenuLoadSuccessAction(data)),
          catchError((err) => of(new AdminMenuLoadFailureAction(err)))
        )
      )
    ) }
  );

  $startup = createEffect(() =>
    { return this.actions$.pipe(
      ofType(LoadshopApplicationActionTypes.LoadshopStart),
      switchMap(() => of(new AdminMenuLoadAction()))
    ) }
  );

  constructor(private menuService: CommonService, private actions$: Actions, private store$: Store<CoreState>) {}
}
