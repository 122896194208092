import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CounterOfferRequestStatusEnum, LoadCounterOfferData, LoadCounterOfferGroupData, UserModel } from '../../../models';
import { BaseComponent } from '../../base-component';

@Component({
  selector: 'kbxl-counter-offer-group-accordion-tab-header',
  templateUrl: './counter-offer-group-accordion-tab-header.component.html',
  styleUrls: ['./counter-offer-group-accordion-tab-header.component.scss'],
})
export class CounterOfferGroupAccordionTabHeaderComponent extends BaseComponent implements OnChanges {
  @Input() counterOffer: LoadCounterOfferGroupData;
  @Input() user: UserModel;
  // latest offer
  activeOffer: LoadCounterOfferData;
  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.counterOffer) {
      this.activeOffer = this.getActiveCounterOffer();
    }
  }
  getActiveCounterOffer(): LoadCounterOfferData {
    const carrierOffer = this.counterOffer.counterOffers.find((x) => x.loadCounterOfferId === this.counterOffer.currentCarrierOfferId);
    const shipperOffer = this.counterOffer.counterOffers.find((x) => x.loadCounterOfferId === this.counterOffer.currentShipperOfferId);

    // if the shipper initiated the counter offer, use that. Ex shipper sent offer to contracted carrier
    if (!carrierOffer && shipperOffer) {
      return shipperOffer;
    } else if (carrierOffer && carrierOffer.status === CounterOfferRequestStatusEnum.Approved) {
      return carrierOffer;
    } else if (shipperOffer && shipperOffer.status === CounterOfferRequestStatusEnum.Approved) {
      return shipperOffer;
    }
    return shipperOffer && new Date(shipperOffer?.created) > new Date(carrierOffer?.created) ? shipperOffer : carrierOffer;
  }

  getBadgeColor() {
    if (!this.counterOffer) {
      return;
    }
    switch (this.counterOffer.currentStatus) {
      case CounterOfferRequestStatusEnum.PendingShipper:
        return {
          'badge-primary': true,
        };
      case CounterOfferRequestStatusEnum.PendingCarrier:
        return {
          'badge-info': true,
        };
      case CounterOfferRequestStatusEnum.Expired:
        return {
          'badge-warning': true,
        };
      case CounterOfferRequestStatusEnum.Denied:
        return {
          'badge-danger': true,
        };
      case CounterOfferRequestStatusEnum.Approved:
        return {
          'badge-success': true,
        };
    }
    return;
  }
}
