import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SharedState } from '../store';
import { AuthLoginFailureAction } from '../store/actions';
import { AuthLoginForbiddenAction } from '../store/actions/auth.actions';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(private securityService: OidcSecurityService, private store: Store<SharedState>) {}

  // tslint:disable: no-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            // TODO: there is no known equivalent in v11, make sure we dont need anymore?
            // this.securityService.resetAuthorizationData(false);
            // this.router.navigate(['/unauthorized'], { skipLocationChange: true });
            this.store.dispatch(new AuthLoginFailureAction(err));
          }
          if (err.status === 403) {
            // this.router.navigate(['/forbidden'], { skipLocationChange: true });
            this.store.dispatch(new AuthLoginForbiddenAction(err));
          }
        }
        return throwError(err);
      })
    );
  }
  // tslint:enable: no-any
}
