import { Component, Input } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { AgreementDocumentViewOnlyComponent } from '../../../../shared/components';
import { CarrierAgreementData } from '../../../../shared/models';

@Component({
  selector: 'kbxl-carrier-agreements',
  templateUrl: './carrier-agreements.component.html',
  styleUrls: ['./carrier-agreements.component.scss'],
  providers: [DialogService],
})
export class CarrierAgreementsComponent {
  @Input() loadingCarrierUsers: boolean;
  @Input() carrierAgreements: CarrierAgreementData[];
  constructor(private dialogService: DialogService) {}

  showAgreement(carrierAgreement: CarrierAgreementData): void {
    this.dialogService.open(AgreementDocumentViewOnlyComponent, {
      data: carrierAgreement.agreement,
      header: `Agreement - ${carrierAgreement.agreement.agreementTitle}`,
    });
  }
}
