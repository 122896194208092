export interface SearchLaneFilterRequest {
  customerId: string;
  origAddr1: string;
  origCity: string;
  origState: string;
  origZip: string;
  origCountry: string;
  origLat?: number;
  origLng?: number;
  destAddr1: string;
  destCity: string;
  destState: string;
  destZip: string;
  destCountry: string;
  destLat?: number;
  destLng?: number;
  equipmentId: string;
}
