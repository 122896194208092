import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TreeNode } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { takeUntil } from 'rxjs/operators';
import { SmartSpotBrandMarkup } from 'src/app/core/utilities/constants';
import {
  AppointmentSchedulerConfirmationType,
  Commodity,
  Equipment,
  Place,
  ServiceType,
  SmartSpotQuoteCreateRequest,
  SpotPriceQuickQuoteRequest,
  SpotPriceQuoteLocation,
  SpotPriceQuoteResponse,
  State,
  ValidationProblemDetails,
} from 'src/app/shared/models';
import { UserState } from '../../../user/store';
import { BaseComponent } from '../base-component';
import { SpotPriceModalComponent } from '../spot-price-modal';

@Component({
  selector: 'kbxl-quick-quote',
  templateUrl: './quick-quote.component.html',
  styleUrls: ['./quick-quote.component.scss'],
})
export class QuickQuoteComponent extends BaseComponent implements OnChanges, OnDestroy {
  @Input() states: State[];
  @Input() groupedEquipment: TreeNode[];
  @Input() loading: boolean;
  @Input() smartSpotQuote: SpotPriceQuoteResponse;
  @Input() set smartSpotErrors(value: ValidationProblemDetails) {
    this.setErrors(value ? value.errors || {} : {});
  }
  @Input() allowManualLoadCreation: boolean;
  @Input() commodities: Commodity[];
  @Input() serviceTypes: ServiceType[];
  @Input() schedulerConfirmationTypes: AppointmentSchedulerConfirmationType[];

  @Output() createOrder = new EventEmitter<SmartSpotQuoteCreateRequest>();
  @Output() getQuote = new EventEmitter<SpotPriceQuickQuoteRequest>();
  @Output() clear = new EventEmitter<object>();
  @Input() showSpotQuoteModal = false;

  public formGroup: UntypedFormGroup;
  public suggestions: Place[];
  public selectedEquipment: Equipment;
  public errorSummary: string;
  public errorCount: number;
  public origin: Place;
  public destination: Place;
  private equipment: Equipment;

  private errorMap = [
    { urn: '', formControlName: '' },
    { urn: 'EquipmentId', formControlName: 'equipmentId' },
    { urn: 'Weight', formControlName: 'weight' },
    { urn: 'PickupDate', formControlName: 'pickupDate' },
    { urn: 'DeliveryDate', formControlName: 'deliveryDate' },
    { urn: 'PickupTime', formControlName: 'pickupTime' },
    { urn: 'DeliveryTime', formControlName: 'deliveryTime' },
    { urn: 'OriginPostalCode', formControlName: 'origin' },
    { urn: 'OriginState', formControlName: 'origin' },
    { urn: 'DestinationPostalCode', formControlName: 'destination' },
    { urn: 'DestinationState', formControlName: 'destination' },
  ];

  smartSpotLabel = SmartSpotBrandMarkup;

  constructor(private fb: UntypedFormBuilder, private _dialogService: DialogService, private userState: Store<UserState>) {
    super();

    let defaultAppoinment = null;

    if (this.schedulerConfirmationTypes && this.schedulerConfirmationTypes.length > 0) {
      defaultAppoinment = this.schedulerConfirmationTypes[0];
    }

    let defaultCommodity = null;
    if (this.commodities && this.commodities.length > 0) {
      defaultCommodity = this.commodities.find((x) => x.commodityName.toLocaleLowerCase() === 'paper');
    }

    this.formGroup = this.fb.group({
      origin: null,
      destination: null,
      equipmentId: null,
      weight: null,
      pickupDate: null,
      pickupTime: null,
      deliveryDate: null,
      deliveryTime: null,
      // spotprice
      commodity: defaultCommodity,
      services: null,
      pickupAppointmentType: defaultAppoinment,
      dropoffAppointmentType: defaultAppoinment,
      totalPostedScacs: 2600,
      totalPostedCarriers: 2500,
    });

    this.formGroup.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((_) => this.clear.emit(null));
  }

  ngOnChanges(changes: SimpleChanges): void {
    let defaultAppoinment = null;
    let defaultCommodity = null;

    if (this.schedulerConfirmationTypes && this.schedulerConfirmationTypes.length > 0) {
      defaultAppoinment = this.schedulerConfirmationTypes[0];

      if (!this.formGroup.value.pickupAppointmentType) {
        this.formGroup.patchValue({
          pickupAppointmentType: defaultAppoinment,
        });
      }
      if (!this.formGroup.value.dropoffAppointmentType) {
        this.formGroup.patchValue({
          dropoffAppointmentType: defaultAppoinment,
        });
      }
    }
    if (this.commodities && this.commodities.length > 0) {
      defaultCommodity = this.commodities.find((x) => x.commodityName.toLocaleLowerCase() === 'paper');
      if (!this.formGroup.value.commodity) {
        this.formGroup.patchValue({
          commodity: defaultCommodity,
        });
      }
    }
  }

  equipmentSelectionMade(node: TreeNode) {
    this.equipment = node && node.data ? (node.data as Equipment) : null;
    this.formGroup.patchValue({
      equipmentId: this.equipment ? this.equipment.equipmentId : null,
    });
  }

  createOrderClick() {
    const values = this.formGroup.value;
    this.createOrder.emit({
      smartSpotQuoteId: null,
      loadId: null,
      origin: values ? this.origin : null,
      destination: values ? this.destination : null,
      equipment: this.equipment,
      weight: values ? values.weight : null,
      pickupDate: values ? values.pickupDate : null,
      deliveryDate: values ? values.deliveryDate : null,
      pickupTime: values ? values.pickupTime : null,
      deliveryTime: values ? values.deliveryTime : null,
    });
  }

  clearClick() {
    this.formGroup.reset();
    this.selectedEquipment = null;
    this.origin = null;
    this.destination = null;
    this.clear.emit(null);
  }

  /*
   ** Opens modal to view SpotPrice details
   */
  openSpotPriceModal(): void {
    this._dialogService.open(SpotPriceModalComponent, {
      header: 'Spot Price Quote Details',
      baseZIndex: 5000,
      data: {
        spotPriceQuote: this.smartSpotQuote,
      },
    });
  }

  private getRequest(): SpotPriceQuickQuoteRequest {
    const values = this.formGroup.value;
    const origin = values ? (this.origin as Place) : null;
    const destination = values ? (this.destination as Place) : null;
    const equipmentCategory = values && this.equipment ? this.equipment.categoryId : null;
    const shipmentServices =
      values && values.services
        ? (values.services as ServiceType[]).map((value) => {
            return value.name;
          })
        : null;

    const spotPriceOrigin = {
      city: origin.city,
      countryCode: origin.country,
      postalCode: origin.postalCode,
      state: origin.state,
      coordinates: {
        latitude: origin.latitude,
        longitude: origin.longitude,
      },
    } as SpotPriceQuoteLocation;

    const spotPriceDest = {
      city: destination.city,
      countryCode: destination.country,
      postalCode: destination.postalCode,
      state: destination.state,
      coordinates: {
        latitude: destination.latitude,
        longitude: destination.longitude,
      },
    } as SpotPriceQuoteLocation;

    if (this.showSpotQuoteModal) {
      return {
        origin: spotPriceOrigin,
        destination: spotPriceDest,
        equipmentId: values ? values.equipmentId : null,
        weight: values ? values.weight : null,
        pickupDate: values ? values.pickupDate : null,
        pickupTime: values ? values.pickupTime : null,
        deliveryDate: values ? values.deliveryDate : null,
        deliveryTime: values ? values.deliveryTime : null,
        commodity: values ? values.commodity?.commodityName : null,
        shipmentServices: values ? shipmentServices : null,
        equipmentCategory: equipmentCategory ? equipmentCategory : null,
        pickupAppointmentTypeCode: values && values.pickupAppointmentType ? values.pickupAppointmentType.appointmentSchedulingCode : null,
        dropoffAppointmentTypeCode: values && values.pickupAppointmentType ? values.dropoffAppointmentType.appointmentSchedulingCode : null,
        totalPostedScacs: values ? values.totalPostedScacs : null,
        totalPostedCarriers: values ? values.totalPostedCarriers : null,
        stops: 2,
      };
    } else {
      return {
        origin: spotPriceOrigin,
        destination: spotPriceDest,
        equipmentId: values ? values.equipmentId : null,
        weight: values ? values.weight : null,
        pickupDate: values ? values.pickupDate : null,
        pickupTime: values ? values.pickupTime : null,
        deliveryDate: values ? values.deliveryDate : null,
        deliveryTime: values ? values.deliveryTime : null,
        commodity: values ? values.commodity?.commodityName : null,
        shipmentServices: values ? shipmentServices : null,
        equipmentCategory: equipmentCategory ? equipmentCategory : null,
        pickupAppointmentTypeCode: 'CAR', // Carrier to Schedule Appointment. Confirmation Required
        dropoffAppointmentTypeCode: 'CAR', // Carrier to Schedule Appointment. Confirmation Required
        totalPostedScacs: 2500,
        totalPostedCarriers: 2500,
        stops: 2,
      };
    }
  }

  getQuoteClicked() {
    const request = this.getRequest();
    this.errorSummary = '';
    if (!request.origin.postalCode) {
      this.errorSummary = 'Please select an origin with a postal code. ';
    }
    if (!request.destination.postalCode) {
      this.errorSummary += 'Please select a destination with a postal code.';
    }
    if (!request.pickupDate) {
      this.errorSummary += 'Please select a pickup date. ';
    }
    if (!request.pickupTime) {
      this.errorSummary += 'Please select a pickup time. ';
    }
    if (!request.deliveryDate) {
      this.errorSummary += 'Please select a delivery date. ';
    }
    if (!request.deliveryTime) {
      this.errorSummary += 'Please select a delivery time. ';
    }
    if (this.errorSummary === '') {
      this.getQuote.emit(request);
    }
  }

  private setErrors(errors: object) {
    const urnRoot = 'urn:root';

    const messages = this.setFormGroupErrors(this.formGroup, urnRoot, this.errorMap, errors);
    this.errorSummary = messages ? messages.join('\n') : '';
    this.errorCount = messages ? messages.length : 0;
  }

  private setFormGroupErrors(
    formObject: UntypedFormGroup,
    urnPrefix: string,
    errorMap: { urn: string; formControlName: string }[],
    errors
  ): string[] {
    const errorList: string[] = [];
    formObject.setErrors(null);
    Object.keys(formObject.controls).forEach((key) => {
      formObject.controls[key].setErrors(null);
    });

    for (const entry of errorMap) {
      const currentUrn = urnPrefix + (entry.urn && entry.urn.length ? ':' + entry.urn : '');
      const name = entry.formControlName;
      const controlErrors = errors ? errors[currentUrn] : null;

      // Track the full list of errors
      if (controlErrors) {
        for (const error of controlErrors) {
          if (error) {
            errorList.push(error.trim());
          }
        }

        if (name != null) {
          if (name.length === 0) {
            formObject.setErrors(controlErrors);
          } else {
            formObject.get(name).setErrors(controlErrors);
          }
        }
      }
    }
    return errorList;
  }
}
