import { CustomerLoadType, Equipment, Place, UserSelectItem } from '.';

export interface IShippingLoadFilter {
  quickFilter: string;
  origin: Place;
  dest: Place;
  equipment: Equipment[];
  userFavoriteId: number;
  favoriteName: string;
  customerLoadTypes: CustomerLoadType[];
  shipperUsers: UserSelectItem[];
  defaultFavorite: boolean;
}

export class ShippingLoadFilter implements IShippingLoadFilter {
  constructor(init?: Partial<ShippingLoadFilter>) {
    Object.assign(this, init);
  }

  quickFilter: string;
  origin: Place;
  dest: Place;
  equipment: Equipment[];
  userFavoriteId: number;
  favoriteName: string;
  customerLoadTypes: CustomerLoadType[];
  shipperUsers: UserSelectItem[];
  defaultFavorite: boolean;
}
