import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, mapTo, switchMap, takeUntil } from 'rxjs/operators';
import { UpdateFocusEntityAction, UserFocusEntitySelectorTypes } from 'src/app/user/store';
import { LoadBoardLoadBookActionTypes, LoadBoardLoadBookSuccessAction } from '../../../shared/store';
import { LoadboardAdminSearchOptions } from '../../components/models';
import { LoadBoardService } from '../../services';
import {
  LoadBoardDashboardActionTypes,
  LoadBoardDashboardLoadAction,
  LoadBoardDashboardLoadFailureAction,
  LoadBoardDashboardLoadSuccessAction,
  LoadBoardDashboardStartLoadPollingAction,
} from '../actions';

@Injectable()
export class LoadBoardDashboardEffects {
  timerSeconds = 300;
  adminSearchOptions: LoadboardAdminSearchOptions = null;

  $load = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LoadBoardDashboardLoadAction>(LoadBoardDashboardActionTypes.Load),
      switchMap((action) => {
        this.adminSearchOptions = action.searchOptions;
        return this.loadBoardService.getDashboardLoadsByUser(this.adminSearchOptions).pipe(
          map((data) => new LoadBoardDashboardLoadSuccessAction(data)),
          catchError((err) => of(new LoadBoardDashboardLoadFailureAction(err)))
        );
      })
    ) }
  );

  $loadBooked = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LoadBoardLoadBookSuccessAction>(LoadBoardLoadBookActionTypes.Book_Success),
      map(() => new LoadBoardDashboardLoadAction(this.adminSearchOptions))
    ) }
  );

  $startPolling = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LoadBoardDashboardStartLoadPollingAction>(LoadBoardDashboardActionTypes.Start_Polling),
      switchMap((action) =>
        timer(0, this.timerSeconds * 1000).pipe(
          takeUntil(this.actions$.pipe(ofType(LoadBoardDashboardActionTypes.Cancel_Polling))),
          mapTo(new LoadBoardDashboardLoadAction(action.searchOptions))
        )
      )
    ) }
  );

  $clearAll = createEffect(() =>
    { return this.actions$.pipe(
      ofType<UpdateFocusEntityAction>(UserFocusEntitySelectorTypes.UpdateFocusEntity),
      switchMap(() => of(new LoadBoardDashboardLoadSuccessAction([])))
    ) }
  );

  constructor(private actions$: Actions, private loadBoardService: LoadBoardService) {}
}
