export interface CustomerLocation {
  locationId: string;
  locationName: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  latitude: string;
  longitude: string;
  appointmentSchedulerConfirmationTypeId: string;
}
