import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { Action } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { delay, withLatestFrom } from 'rxjs/operators';
import { Search } from '../../models';
import { PageableUiData } from './pagable-ui-data';
import { PageableQueryData, PageableQueryHelper } from './pageable-query-helper';

export interface PagingHandler {
  reloadData();
}

@Injectable({
  providedIn: null, //prevent angular from injecting this service
})
export class PageableUiHelper<TQueryUpdateAction extends Action, TLoadAction extends Action, TStoreType>
  implements OnDestroy, PagingHandler {
  filterSub: Subscription;
  pagingSub: Subscription;
  filter$: Observable<Search>;
  pagingData$: Observable<PageableQueryData>;
  init = false;
  latestQuery: PageableQueryHelper;

  constructor(@Optional() @Inject(null) public pageableUiData: PageableUiData<TQueryUpdateAction, TLoadAction, TStoreType> | null) {
    this.filter$ = pageableUiData.filterBehavior.asObservable();
    this.pagingData$ = pageableUiData.pagingBehavior.asObservable();

    this.pagingSub = this.pagingData$.pipe(withLatestFrom(pageableUiData.pageableQueryHelper$), delay(0)).subscribe((args) => {
      const pagingData = args[0];
      const currentQuery = args[1];
      this.latestQuery = currentQuery;
      if (pagingData) {
        currentQuery.pageNumber = pagingData.pageNumber;
        currentQuery.pageSize = pagingData.pageSize;
        currentQuery.sortField = pagingData.sortField;
        currentQuery.descending = pagingData.descending;

        this.dispatchActions(currentQuery);
      }
      this.init = true;
    });

    this.filterSub = this.filter$.pipe(withLatestFrom(pageableUiData.pageableQueryHelper$)).subscribe((args) => {
      if (this.init) {
        const newFilter = args[0];
        const currentQuery = args[1];
        this.latestQuery = currentQuery;
        currentQuery.filter = newFilter;
        // When search changes rest page to 1 or you will still skip records
        currentQuery.pageNumber = 1;
        if (pageableUiData.pageableComponent) {
          // if the component isn't destroyed or in an ngIf, then reset the grid to isFirst
          pageableUiData.pageableComponent.setFirst(0);
        }

        this.dispatchActions(currentQuery);
      }
    });
  }

  private dispatchActions(currentQuery: PageableQueryHelper) {
    const getQueryUpdateAction = this.pageableUiData.getQueryUpdateAction(currentQuery) as Action;
    this.pageableUiData.store.dispatch(getQueryUpdateAction);

    const getLoadAction = this.pageableUiData.getLoadAction(currentQuery) as Action;
    this.pageableUiData.store.dispatch(getLoadAction);
  }

  reloadData() {
    if (this.latestQuery) {
      this.dispatchActions(this.latestQuery);
    }
  }
  ngOnDestroy(): void {
    const getQueryUpdateAction = this.pageableUiData.getQueryUpdateAction(PageableQueryHelper.default()) as Action;
    this.pageableUiData.store.dispatch(getQueryUpdateAction);
    this.filterSub.unsubscribe();
    this.pagingSub.unsubscribe();
  }
}
