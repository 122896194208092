/* tslint:disable:no-bitwise */
export enum Caches {
  None = 0,
  User = 1 << 0,
  Customer = 1 << 1,
  State = 1 << 2,
  DropDown = 1 << 3,
  LoadshopSetting = 1 << 4,
  ServiceType = 1 << 5,
  Carrier = 1 << 6,
  Commodity = 1 << 7,
  LoadStop = 1 << 8,
  CustomerLoadType = 1 << 9,
  UnitOfMeasure = 1 << 10,
  TransportationMode = 1 << 11,
  Equipment = 1 << 12,
  DocumentServiceType = 1 << 13,
  ALL = ~(~0 << 14),
}
