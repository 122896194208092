import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'hoursToDays' })
export class HoursToDaysPipe implements PipeTransform {
  transform(value: number, limit: number) {
    if (value > limit) {
      const daysObject = this.splitTime(value);
      if (daysObject != null) {
        const daysText = daysObject.Days + ' ' + 'Days' + ' ' + daysObject.Hours + ' ' + 'Hours';
        return daysText;
      }
    }
    if (value !== 0 && value !== null) {
      return value + ' ' + 'Hours';
    }
    return value;
  }

  private splitTime(numberOfHours) {
    const Days = Math.floor(numberOfHours / 24);
    const Remainder = numberOfHours % 24;
    const Hours = Math.floor(Remainder);
    const Minutes = Math.floor(60 * (Remainder - Hours));
    return { Days: Days, Hours: Hours, Minutes: Minutes };
  }
}
