<div class="admin-header">
  <div class="title d-flex justify-content-center">
    <h4>Action Items</h4>
  </div>
</div>
<div class="d-flex flex-column">
  <div class="d-flex flex-column flex-lg-row admin-container">
    <div class="admin-nav-panel">
      <p-tabMenu orientation="left" [model]="menuItems" styleClass="admin-side-menu">
        <ng-template pTemplate="item" let-item let-i="index">
          <a [routerLink]="item.routerLink" class="p-menuitem-link" routerLinkActive="p-menuitem-link-active">
            <div class="menuItem">
              <span class="p-menuitem-text ng-star-inserted" [innerHtml]="item.label"></span>
              <span *ngIf="item.badge" class="ml-2 p-menuitem-text {{ item.badgeStyleClass }}">{{ item.badge }}</span>
            </div>
          </a>
        </ng-template>
      </p-tabMenu>
    </div>
    <div class="admin-content-panel flex-md-grow-1">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
