/**
 * HubMessage.Api
 * Simplified wrapper API for connecting with the hub service.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ConversationMember } from './conversationMember';

export interface UpdateConversationMembersRequest { 
    conversationId?: number;
    members?: Array<ConversationMember>;
}