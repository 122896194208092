export interface LoadCounterOfferRequestData {
  // Populated once the offer is received
  loadCounterOfferId?: number;
  // Populated once the offer is submitted
  rootCounterOfferId?: number;
  parentLoadCounterOfferId?: number;
  loadId: string;
  counterOfferLineHaulRate: number;
  expirationHours: number;
  expirationMinutes: number;
  // shipper counter offer fields
  loadshopFee?: number;
  counterOfferTotalRate?: number;
  percentIncrease?: number;
  // shipper originated offer fields
  scac?: string;
  carrierUserId?: string;
  // did the shipper create the initial request? else false
  shipperOriginatedRequest: boolean;
}
