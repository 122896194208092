<div class="page-container">
  <div class="d-flex flex-column">
    <div class="py-lg-2 px-md-1 px-xl-4 position-relative">
      <kbxl-top-search-criteria
        [criteria]="criteria$ | async"
        [equipment]="equipment$ | async | toTreeNodes"
        [savedSearches]="savedSearches$ | async"
        [lanes]="lanes$ | async"
        [states]="states$ | async"
        [isMobile]="isMobile$ | async"
        [isMarketplace]="true"
        [serviceTypes]="serviceTypes$ | async"
        [user]="user$ | async"
        [shippers]="shippers$ | async"
        (search)="search($event)"
        (add)="add($event)"
        (update)="update($event)"
        (clear)="clear()"
        (refreshLoads)="refreshLoads($event)"
      >
      </kbxl-top-search-criteria>
    </div>
    <kbxl-color-bar></kbxl-color-bar>
    <div class="grid-container px-xl-4 px-1">
      <kbxl-load-grid
        *ngIf="user?.isBookingEligible !== false"
        [loading]="loading$ | async"
        [loads]="filteredLoads$ | async"
        [serviceTypes]="serviceTypes$ | async"
        (selected)="selected($event)"
        kbxlActionCheck
        action="loadshop.ui.marketplace.loads.viewdetail"
        enforcementType="disable"
        [isMarketplace]="true"
        (isDisabledChange)="setDisabled($event)"
        [totalRecords]="totalRecords$ | async"
      ></kbxl-load-grid>
      <div *ngIf="user?.isBookingEligible === false">
        <fa-icon [icon]="['fas', 'exclamation-triangle']" size="lg"></fa-icon>
        <strong>You currently are not eligible to book loads on Loadshop.</strong>
        <br />
        <span>Please contact <a href="Loadshop@kbxlogistics.com">Loadshop&#64;kbxlogistics.com</a> with any questions.</span>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
