import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { mapToPayload } from 'src/app/shared/utilities/map-to-payload';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { LoadDocumentMetadata, LoadDocumentUpload } from '../../models';
import { LoadDocumentService } from '../../services';
import {
  LoadBoardLoadDetailLoadAction,
  LoadDocumentActionTypes,
  LoadDocumentAddDocumentAction,
  LoadDocumentAddDocumentFailureAction,
  LoadDocumentAddDocumentSuccessAction,
  LoadDocumentDeleteDocumentAction,
  LoadDocumentDeleteDocumentFailureAction,
  LoadDocumentDeleteDocumentSuccessAction,
  LoadDocumentDownloadDocumentAction,
  LoadDocumentDownloadDocumentFailureAction,
  LoadDocumentDownloadDocumentSuccessAction,
} from '../actions';

@Injectable()
export class LoadDocumentEffects {
  $uploadDocument = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LoadDocumentAddDocumentAction>(LoadDocumentActionTypes.AddDocument),
      mapToPayload<LoadDocumentUpload>(),
      switchMap((documentUpload) =>
        this.loadDocumentService.addDocument(documentUpload).pipe(
          switchMap((data) => [
            new LoadDocumentAddDocumentSuccessAction(data),
            new LoadBoardLoadDetailLoadAction(documentUpload.loadId), // reload the detail for load
          ]),
          catchError((err) => of(new LoadDocumentAddDocumentFailureAction(err)))
        )
      )
    ) }
  );

  $removeDocument = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LoadDocumentDeleteDocumentAction>(LoadDocumentActionTypes.DeleteDocument),
      mapToPayload<LoadDocumentMetadata>(),
      switchMap((documentUpload) =>
        this.loadDocumentService.removeDocument(documentUpload).pipe(
          switchMap((data) => [
            new LoadDocumentDeleteDocumentSuccessAction(documentUpload),
            new LoadBoardLoadDetailLoadAction(documentUpload.loadId), // reload the detail for load
          ]),
          catchError((err) => of(new LoadDocumentDeleteDocumentFailureAction(err)))
        )
      )
    ) }
  );

  $downloadDocument = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LoadDocumentDownloadDocumentAction>(LoadDocumentActionTypes.DownloadDocument),
      mapToPayload<LoadDocumentMetadata>(),
      switchMap((documentUpload) =>
        this.loadDocumentService.downloadDocument(documentUpload).pipe(
          map((data) => new LoadDocumentDownloadDocumentSuccessAction(data)),
          catchError((err) => of(new LoadDocumentDownloadDocumentFailureAction(err)))
        )
      )
    ) }
  );

  $documentUploaded = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType<LoadDocumentAddDocumentSuccessAction>(LoadDocumentActionTypes.AddDocument_Success),
        mapToPayload<LoadDocumentMetadata>(),
        tap((_) => this.messageService.add({ id: 0, detail: `Successfully uploaded ${_.fileName}`, severity: 'success' }))
      ) },
    { dispatch: false }
  );

  $documentRemoved = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType<LoadDocumentDeleteDocumentSuccessAction>(LoadDocumentActionTypes.DeleteDocument_Success),
        mapToPayload<LoadDocumentMetadata>(),
        tap((_) => this.messageService.add({ id: 0, detail: 'Successfully removed document', severity: 'success' }))
      ) },
    { dispatch: false }
  );

  constructor(private actions$: Actions, private loadDocumentService: LoadDocumentService, private messageService: MessageService) {}
}
