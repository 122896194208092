<form #form="ngForm">
  <div class="detail-container" *ngIf="details">
    <div class="d-flex justify-content-between detail-header px-1 px-xl-4">
      <div class="d-flex align-items-center">
        <label class="load-id-label">{{ details.referenceLoadDisplay }}</label>
        <a class="detail-link mx-3" (click)="closeClicked(form)">
          <fa-icon [icon]="['fas', 'times-circle']"></fa-icon>
          <span>Close</span>
        </a>
        <div class="on-loadshop" title="On Marketplace" *ngIf="details.onLoadshop">
          <span class="on-marketplace">M</span>
        </div>
      </div>
    </div>
    <div *ngIf="showErrorSummary()" class="d-flex flex-wrap error-container px-1 px-xl-4 m-2">
      <div>
        <div *ngIf="detailErrors.root">
          <span class="load-detail-error" *ngFor="let error of detailErrors.root">{{ error }}</span>
        </div>
        <div *ngIf="detailErrors.load">
          <span class="load-detail-error" *ngFor="let error of detailErrors.load">{{ error }}</span>
        </div>
        <div *ngIf="detailErrors.fuelRateByProgram">
          <span class="load-detail-error" *ngFor="let error of detailErrors.fuelRateByProgram">{{ error }}</span>
        </div>
        <div *ngIf="detailErrors.totalRate">
          <span class="load-detail-error" *ngFor="let error of detailErrors.totalRate">{{ error }}</span>
        </div>
        <div *ngIf="detailErrors.commodity">
          <span class="load-detail-error" *ngFor="let error of detailErrors.commodity">{{ error }}</span>
        </div>
        <div *ngIf="detailErrors.rateSeeker">
          <span class="load-detail-error" *ngFor="let error of detailErrors.rateSeeker">{{ error }}</span>
        </div>
      </div>
    </div>
    <div>
      <div class="warning-container m-2" *ngIf="!isInValidStateForPosting()">
        <div *ngIf="!isInValidStateForPosting()">
          This load is being processed for the Marketplace. It cannot be re-posted until it has finished processing.
        </div>
      </div>
      <div class="warning-container m-2" *ngIf="fuelError$ | async">
        <div *ngIf="fuelError$ | async">There was an error retrieving fuel. This load cannot be posted until a valid fuel rate is set.</div>
      </div>
    </div>
    <div class="d-flex flex-column flex-lg-row px-1 px-xl-4">
      <div class="d-flex flex-column">
        <div class="trip-display">
          <kbxl-trip-display [loadStops]="details.loadStops" class="small-line"></kbxl-trip-display>
        </div>
        <div class="carrier-clicks-section mt-2">
          <div class="d-flex justify-content-center">
            <div class="link-wrapper">
              <div *ngIf="loadingAuditLogs$ | async" class="small-spinner-container">
                <p-progressSpinner></p-progressSpinner>
              </div>
              <span *ngIf="!(loadingAuditLogs$ | async)">{{ (loadAuditLogs$ | async)?.length || 0 }}</span>
              <fa-icon
                [icon]="['fas', 'eye']"
                size="lg"
                class="ml-1 icon-detail"
                pTooltip="Carrier Views"
                (click)="viewCarrierClicksClicked()"
              >
              </fa-icon>
            </div>
            <div class="link-wrapper ml-3 pl-3 border-left">
              <div *ngIf="loadingLaneHistory$ | async" class="small-spinner-container">
                <p-progressSpinner></p-progressSpinner>
              </div>
              <span *ngIf="!(loadingLaneHistory$ | async)">{{ laneHistory?.length || 0 }}</span>
              <fa-icon
                [icon]="['fas', 'history']"
                size="lg"
                class="ml-1 icon-detail"
                pTooltip="Lane History"
                (click)="viewLaneHistoryClicked()"
              >
              </fa-icon>
            </div>
            <div class="ml-3 border-left" kbxlActionCheck="loadshop.ui.notes.view">
              <kbxl-notes-count [loadId]="details?.loadId" [user]="user" iconSize="lg"></kbxl-notes-count>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-grow-1 d-flex flex-column-reverse flex-xl-row flex-wrap-reverse mt-2 mt-lg-0 ml-xl-2">
        <div class="line-haul-features">
          <table>
            <tr>
              <th *ngIf="details.isCounterOfferEnabled" title="Counter Offer Enabled">
                <fa-icon [icon]="['fas', 'handshake']" size="md"></fa-icon>
              </th>
              <th title="Rate Seeker Enabled" class="invisible">
                <fa-icon [icon]="['fas', 'binoculars']" size="md"></fa-icon>
              </th>
            </tr>
            <tbody>
              <tr>
                <td *ngIf="details.isCounterOfferEnabled">
                  <div class="d-flex flex-column">
                    <p-inputSwitch
                      class="mx-2"
                      name="isCounterOfferEnabled"
                      [(ngModel)]="details.allowCounterOffer"
                      (onChange)="updateSummaryCard()"
                    >
                    </p-inputSwitch>
                    <button
                      class="btn btn-link dynamic-pricing-link"
                      [class.invisible]="!allowShipperCounter()"
                      (click)="showShipperCounterOfferModal()"
                    >
                      Counter Offer
                    </button>
                  </div>
                </td>
                <td>
                  <div class="d-flex flex-column invisible">
                    <p-inputSwitch
                      class="mx-2"
                      name="isDynamicPricingEnabled"
                      [(ngModel)]="details.isDynamicPricingEnabled"
                      (onChange)="updateSummaryCard()"
                    >
                    </p-inputSwitch>
                    <button
                      [class.invisible]="!details.isDynamicPricingEnabled"
                      class="btn btn-link dynamic-pricing-link"
                      (click)="showLoadDynamicPricing($event)"
                      [innerHtml]="rateSeekerLabel"
                    ></button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div *ngIf="allowShipperCounter()">
                    <a (click)="showShipperCounterOfferModal()" class="dynamic-pricing-link btn-link">
                      <fa-icon [icon]="['far', 'arrow-alt-circle-up']" size="2x"></fa-icon>
                    </a>
                  </div>
                </td>
                <td>
                  <div *ngIf="details.isDynamicPricingEnabled" class="my-1" class="invisible">
                    <button
                      class="btn btn-link dynamic-pricing-link mr-1"
                      (click)="showLoadDynamicPricing($event)"
                      pTooltip="Current Rate Seeker Line Haul Rate"
                    >
                      <span *ngIf="rateSeekerFloor && rateSeekerFloor > 0; else invalidRateSeeker">{{ rateSeekerFloor | currency }}</span>
                      <ng-template #invalidRateSeeker>-</ng-template>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="flex-grow-1 pricing-table-container ml-lg-2">
          <table class="pricing-table">
            <thead>
              <tr>
                <th [ngClass]="{ 'field-error': detailErrors && detailErrors.totalRate }">
                  <div
                    kbxlActionCheck
                    action="loadshop.ui.shopit.load.viewcaprates"
                    *ngIf="details.xCapRate && details.xCapRate > 0"
                    class="d-none d-xl-block"
                    style="position: absolute; top: 0"
                  >
                    <div class="smart-spot-rate">
                      <label>X-Cap LH</label>
                      <div>{{ details.xCapRate | currency }}</div>
                    </div>
                  </div>
                  <span class="ml-1">Line Haul</span> {{ detailErrors && detailErrors.totalRate ? '*' : '' }}
                </th>
                <th class="price-operator">
                  <fa-icon [icon]="['fas', 'plus']" size="md"></fa-icon>
                </th>
                <th class="pl-1" [ngClass]="{ 'field-error': detailErrors && detailErrors.fuelRateByProgram }">
                  <div
                    kbxlActionCheck
                    action="loadshop.ui.shopit.load.viewcaprates"
                    *ngIf="details.hCapRate && details.hCapRate > 0"
                    class="d-none d-xl-block"
                    style="position: absolute; top: 0"
                  >
                    <div class="smart-spot-rate">
                      <label>H-Cap</label>
                      <div>{{ details.hCapRate | currency }}</div>
                    </div>
                  </div>
                  <span *ngIf="!details.isEstimatedFSC || (details.isEstimatedFSC && selectedFuelProgram.value !== 1)" class="p-2"
                    >FSC</span
                  >
                  <span *ngIf="details.isEstimatedFSC && selectedFuelProgram.value === 1"><i>* Est FSC</i></span>
                  {{ detailErrors && detailErrors.fuelRateByProgram ? ' *' : '' }}
                </th>
                <th class="price-operator">
                  <fa-icon [icon]="['fas', 'equals']" size="md"></fa-icon>
                </th>
                <th>
                  <div class="d-none d-xl-block" style="position: absolute; top: 0">
                    <div class="smart-spot-rate">
                      <label [innerHtml]="smartSpotLabel"></label>
                      <div *ngIf="!(loadingSmartSpot$ | async) && !(loadingFuelRate$ | async) && details.smartSpotRate">
                        <div *ngIf="showSpotQuoteModal; else noSpotQuoteModal">
                          <a (click)="openSpotPriceModal()"
                            >{{ details.smartSpotRate | currency }}

                            <fa-icon [icon]="['fas', 'search-plus']" class="ml-1 link" pTooltip="View Spot Quote breakdown"> </fa-icon>
                          </a>
                        </div>
                        <ng-template #noSpotQuoteModal>
                          <span>{{ details.smartSpotRate | currency }}</span>
                        </ng-template>
                      </div>
                      <div *ngIf="loadingFuelRate$ | async" class="smart-spot-price-spinner-container font-weight-bold">
                        <p-progressSpinner></p-progressSpinner>
                        <div class="ml-2">Calculating fuel...</div>
                      </div>
                      <div *ngIf="(loadingSmartSpot$ | async) && !(loadingFuelRate$ | async)" class="smart-spot-price-spinner-container">
                        <p-progressSpinner></p-progressSpinner>
                        <div class="ml-2">Loading <span [innerHtml]="smartSpotLabel"></span>...</div>
                      </div>
                    </div>
                  </div>
                  Total Rate
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    pInputText
                    type="text"
                    class="pricing-detail"
                    name="lineHaulRate"
                    [(ngModel)]="details.lineHaulRate"
                    kbxlCurrencyInput
                    (blur)="onLineHaulAmountChange()"
                  />
                </td>
                <td class="price-operator">
                  <fa-icon [icon]="['fas', 'plus']" size="md"></fa-icon>
                </td>
                <td class="pricing-detail d-flex pl-1">
                  <div class="d-flex flex-column">
                    <div *ngIf="!details.allowEditingFuel && !details.usesAllInRates" class="p-2">
                      {{ details.shippersFSC | currency }}
                    </div>
                    <div *ngIf="details.allowEditingFuel && !details.usesAllInRates">
                      <input
                        pInputText
                        type="text"
                        class="pricing-detail"
                        name="shippersFSC"
                        [(ngModel)]="details.shippersFSC"
                        kbxlCurrencyInput
                        (blur)="updateFuelProgram()"
                      />
                    </div>
                    <div *ngIf="details.customer.fuelProgramType === 3" class="mt-1">
                      <p-selectButton
                        name="selectedFuelProgram"
                        [options]="fuelPrograms"
                        [(ngModel)]="selectedFuelProgram"
                        optionLabel="label"
                        [disabled]="!fuelProgramSelectorEnabled"
                        (onChange)="fuelProgramChanged($event)"
                      ></p-selectButton>
                    </div>
                  </div>

                  <div *ngIf="details.usesAllInRates" class="pricing-detail pl-1">&nbsp;</div>
                </td>
                <td class="price-operator">
                  <fa-icon [icon]="['fas', 'equals']" size="md"></fa-icon>
                </td>
                <td class="pl-1">
                  <input
                    pInputText
                    type="text"
                    class="total-pricing-detail"
                    name="totalRate"
                    id="totalRate"
                    [(ngModel)]="details.totalRate"
                    kbxlCurrencyInput
                    (blur)="backfillPricingComponents()"
                  />
                </td>
              </tr>
              <tr>
                <td colspan="4"></td>
                <td></td>
              </tr>
              <tr class="d-xl-none">
                <td colspan="2">
                  <div kbxlActionCheck action="loadshop.ui.shopit.load.viewcaprates" *ngIf="details.xCapRate && details.xCapRate > 0">
                    <div class="smart-spot-rate">
                      <label>X-Cap Line</label>
                      <div>{{ details.xCapRate | currency }}</div>
                    </div>
                  </div>
                </td>
                <td colspan="2">
                  <div kbxlActionCheck action="loadshop.ui.shopit.load.viewcaprates" *ngIf="details.hCapRate && details.hCapRate > 0">
                    <div class="smart-spot-rate">
                      <label>H-Cap</label>
                      <div>{{ details.hCapRate | currency }}</div>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div class="smart-spot-rate">
                      <label [innerHtml]="smartSpotLabel"></label>
                      <div *ngIf="!(loadingSmartSpot$ | async) && !(loadingFuelRate$ | async)">{{ details.smartSpotRate | currency }}</div>
                      <div *ngIf="loadingFuelRate$ | async" class="smart-spot-price-spinner-container font-weight-bold">
                        <p-progressSpinner></p-progressSpinner>
                        <div class="ml-2">Calculating fuel...</div>
                      </div>
                      <div *ngIf="(loadingSmartSpot$ | async) && !(loadingFuelRate$ | async)" class="smart-spot-price-spinner-container">
                        <p-progressSpinner></p-progressSpinner>
                        <div class="ml-2">Loading <span [innerHtml]="smartSpotLabel"></span>...</div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="seperator"></div>
    <div>
      <p-panel [toggleable]="true" styleClass="no-padding">
        <p-header class="load-details-panel-header">Load Details</p-header>
        <div class="d-flex flex-column flex-xl-row justify-content-between px-1 px-xl-4">
          <div class="detail-column flex-fill mr-xl-4">
            <div class="kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label" [ngClass]="{ 'field-error': detailErrors && detailErrors.commodity }">
                  Commodity {{ detailErrors && detailErrors.commodity ? '*' : '' }}
                </label>
              </div>
              <div>
                <p-dropdown
                  [options]="commodities"
                  [placeholder]="Select"
                  [(ngModel)]="selectedCommodity"
                  name="commodity"
                  dataKey="commodityName"
                  optionLabel="commodityName"
                  [styleClass]="'p-dropdown-primary'"
                  dropdownIcon="pi pi-caret-down"
                  appendTo="body"
                  kbxlCloseOnScroll
                  onchange="triggerSmartSpotPriceUpdate()"
                >
                  <ng-template let-item pTemplate="selectedItem">
                    <span class="selected-item-label">{{ selectedCommodity?.commodityName }}</span>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
            <div class="detail-column-seperator"></div>
            <div class="d-flex justify-content-between">
              <span class="detail-label">Equipment</span>
              <span class="detail-value">{{ details.equipmentCategoryId || 'Other' }} - {{ details.equipmentTypeDisplay }}</span>
            </div>
            <div class="detail-column-seperator"></div>
            <div class="d-flex justify-content-between">
              <span class="detail-label">Mileage</span>
              <span class="detail-value">{{ details.mileage | number }}</span>
            </div>
            <div class="detail-column-seperator"></div>
            <div class="d-flex justify-content-between">
              <span class="detail-label">Weight</span>
              <span class="detail-value">{{ details.weight | number }} lbs</span>
            </div>
            <div class="detail-column-seperator"></div>
            <div class="kbxl-inputgroup-container load-contact">
              <p-panel [toggleable]="true" collapsed="true" styleClass="load-contact-panel" class="load-contact-panel">
                <p-header class="panel-header">Load Contact</p-header>
                <div class="kbxl-inputgroup-container">
                  <div class="kbxl-field-label">
                    <label class="control-label">Email</label>
                  </div>
                  <div>
                    <input
                      pInputText
                      type="text"
                      name="loadContactEmail"
                      [(ngModel)]="details.loadContact.email"
                      (ngModelChange)="onLoadContactEmailChanged()"
                      [ngModelOptions]="{ updateOn: 'blur' }"
                    />
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="detail-column-seperator"></div>
            <div class="mt-2">
              <div class="kbxl-field-label">
                <label for="services" class="control-label">Required Services</label>
              </div>
              <p-multiSelect
                [options]="details.manuallyCreated && !details.isBrokeredLoad ? manualLoadServiceTypes : serviceTypes"
                placeholder="Select All Services"
                [(ngModel)]="details.serviceTypes"
                name="services"
                dataKey="serviceTypeId"
                optionLabel="name"
                [styleClass]="'p-dropdown-primary'"
                dropdownIcon="pi pi-caret-down"
                appendTo="body"
                maxSelectedLabels="10"
                kbxlCloseOnScroll
                [overlayOptions]="defaultOverlayOptions"
                (onChange)="onServicesChanged()"
              >
              </p-multiSelect>
            </div>
            <div
              class="mt-2"
              kbxlFeature="AltXCapRate"
              kbxlActionCheck
              action="loadshop.ui.shopit.load.viewcaprates"
              *ngIf="details.isPowerPlus"
            >
              <div class="kbxl-field-label">
                <label for="services" class="control-label">Next Best Alternate</label>
              </div>
              <div>
                <input
                  pInputText
                  type="text"
                  class="pricing-detail"
                  name="altXCapRate"
                  [(ngModel)]="details.altXCapRate"
                  kbxlCurrencyInput
                />
              </div>
            </div>
          </div>
          <div class="detail-column flex-fill mr-xl-4">
            <div *ngIf="loadingLoadCarriers$ | async" class="large-spinner-container">
              <p-progressSpinner></p-progressSpinner>
              <div class="ml-2">Loading Carriers</div>
            </div>
            <ng-container *ngIf="!(loadingLoadCarriers$ | async)">
              <div *ngIf="details.hasScacRestrictions" class="kbxl-inputgroup-container danger">Lane Restriction Applied</div>
              <div *ngIf="displayGroups?.length > 0" class="kbxl-inputgroup-container">
                <div class="kbxl-field-label">
                  <label class="control-label">Carrier Group(s)</label>
                </div>
                <div>
                  <p-multiSelect
                    placeholder="Select"
                    [options]="displayGroups"
                    [(ngModel)]="selectedGroups"
                    name="groups"
                    dataKey="customerLaneGroupId"
                    optionLabel="shippingDetailDisplay"
                    maxSelectedLabels="1"
                    selectedItemsLabel="{0} Carrier Groups Selected"
                    dropdownIcon="pi pi-caret-down"
                    filterBy="name"
                    filterPlaceHolder="Filter Carrier Groups"
                    resetFilterOnHide="true"
                    appendTo="body"
                    kbxlCloseOnScroll
                    [overlayOptions]="defaultOverlayOptions"
                    (onChange)="onScacChange()"
                  >
                  </p-multiSelect>
                </div>
              </div>
              <div *ngIf="displayGroups?.length > 0" class="kbxl-inputgroup-container">
                <div class="kbxl-field-label">
                  <label class="control-label">Carrier Included By Selected Group(s)</label>
                </div>
                <div class="d-flex flex-wrap">
                  <div *ngFor="let serviceType of carrierServiceTypeFilters" class="mb-1">
                    <div class="badge badge-primary d-flex align-items-center">
                      {{ serviceType.name }}
                      <button
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-times"
                        class="p-button-rounded p-button-secondary p-button-text badge-button ml-2"
                        (click)="removeServiceType(serviceType)"
                      ></button>
                    </div>
                  </div>
                </div>
                <div>
                  <p-multiSelect
                    placeholder="Select"
                    [options]="groupCarriers"
                    [(ngModel)]="selectedGroupCarriers"
                    name="selectedGroupCarriers"
                    dataKey="carrierId"
                    optionLabel="carrierName"
                    [showToggleAll]="true"
                    [selectAll]="selectedGroupCarriersSelectAll"
                    (onSelectAllChange)="onSelectedGroupCarriersSelectAllChange($event)" 
                    maxSelectedLabels="1"
                    [selectedItemsLabel]="'{0} of ' + groupCarriers?.length + ' Carrier(s) Selected'"
                    dropdownIcon="pi pi-caret-down"
                    [virtualScroll]="true"
                    virtualScrollItemSize="30"
                    filterBy="carrierName"
                    filterPlaceHolder="Filter Group Carriers"
                    resetFilterOnHide="true"
                    appendTo="body"
                    kbxlCloseOnScroll        
                    (onChange)="onScacChange()"
                    #selectedGroupCarriersMultiSelect
                  >
                    <ng-template let-item pTemplate="item">
                      <span>{{ item?.carrierName | titlecase }}</span>
                    </ng-template>
                  </p-multiSelect>
                </div>
              </div>
              <div class="kbxl-inputgroup-container">
                <div class="kbxl-field-label">
                  <label class="control-label"> {{ getCarrierSelectionLabel() }}</label>
                </div>
                <div class="d-flex flex-wrap">
                  <div *ngFor="let serviceType of carrierServiceTypeFilters" class="mb-1">
                    <div class="badge badge-primary d-flex align-items-center">
                      {{ serviceType.name }}
                      <button
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-times"
                        class="p-button-rounded p-button-secondary p-button-text badge-button ml-2"
                        (click)="removeServiceType(serviceType)"
                      ></button>
                    </div>
                  </div>
                </div>
                <div>
                  <p-multiSelect
                    [options]="availableCarriers"
                    [(ngModel)]="selectedCarriers"
                    name="selectedCarriers"
                    dataKey="carrierId"
                    optionLabel="carrierName"
                    [showToggleAll]="true"
                    [selectAll]="selectedCarriersSelectAll"
                    (onSelectAllChange)="onSelectedCarriersSelectAllChange($event)" 
                    maxSelectedLabels="1"
                    [selectedItemsLabel]="'{0} of ' + availableCarriers?.length + ' Carrier(s) Selected'"
                    dropdownIcon="pi pi-caret-down"
                    [virtualScroll]="true"
                    virtualScrollItemSize="30"
                    filterBy="carrierName"
                    filterPlaceHolder="Filter Carriers"
                    resetFilterOnHide="true"
                    placeholder="Select Carriers"
                    appendTo="body"
                    kbxlCloseOnScroll                  
                    (onChange)="onScacChange()"
                    #selectedCarriersMultiSelect
                  >
                    <ng-template let-item pTemplate="item">
                      <span>{{ item?.carrierName | titlecase }}</span>
                    </ng-template>
                  </p-multiSelect>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="detail-column flex-fill">
            <div *ngIf="loadingScacs$ | async" class="large-spinner-container">
              <p-progressSpinner></p-progressSpinner>
              <div class="ml-2">Loading Contract Rates</div>
            </div>
            <ng-container *ngIf="!(loadingScacs$ | async)">
              <div class="kbxl-inputgroup-container">
                <div class="kbxl-field-label">
                  <label class="control-label">Contracted Rate (Total Rate by SCAC)</label>
                </div>
                <div style="margin-bottom: 9px">
                  <p-dropdown
                    [options]="visibleScacs"
                    [placeholder]="
                      details.scacsSentWithLoad || details.onLoadshop || details.hasPrefetchedRates
                        ? 'Visible (' + (visibleScacs || []).length + ')'
                        : 'Unavailable'
                    "
                    optionLabel="scac"
                    [filter]="true"
                    filterBy="scac"
                    [disabled]="!details.scacsSentWithLoad && !details.onLoadshop && !details.hasPrefetchedRates"
                    [virtualScroll]="true"
                    virtualScrollItemSize="30"
                    [styleClass]="'p-dropdown-primary'"
                    dropdownIcon="pi pi-caret-down"
                    appendTo="body"
                    kbxlCloseOnScroll
                  >
                    <ng-template let-item pTemplate="selectedItem">
                      <span class="selected-item-label">Visible ({{ (visibleScacs || []).length }})</span>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                      <div class="container">
                        <div class="row">
                          <div class="col-2 pl-0">{{ item.scac }}</div>
                          <div class="text-muted" [ngClass]="item.contractRate ? 'col-3 pl-1' : 'col-10 p-0 text-right'">
                            {{ item.contractRate ? (item.contractRate | currency) : 'N/A' }}
                          </div>
                          <div *ngIf="item.contractRate > 0" class="col-7 p-0">
                            <div class="row">
                              <div class="col-2 pr-0 text-muted">
                                <fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
                              </div>
                              <div class="col-4 text-muted p-0">{{ item.fuelRate | currency }}</div>
                              <div class="col-1 p-0 text-muted">
                                <fa-icon [icon]="['fas', 'equals']" size="sm"></fa-icon>
                              </div>
                              <div class="font-weight-bold text-right pl-0 col-5">
                                {{ item.totalRate | currency }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
                <div>
                  <p-dropdown
                    [options]="hiddenScacs"
                    [placeholder]="
                      details.scacsSentWithLoad || details.onLoadshop || details.hasPrefetchedRates
                        ? 'Hidden (' + (hiddenScacs || []).length + ')'
                        : 'Unavailable'
                    "
                    optionLabel="scac"
                    [filter]="true"
                    filterBy="scac"
                    [disabled]="!details.scacsSentWithLoad && !details.onLoadshop && !details.hasPrefetchedRates"
                    [virtualScroll]="true"
                    virtualScrollItemSize="30"
                    [styleClass]="'p-dropdown-primary'"
                    dropdownIcon="pi pi-caret-down"
                    appendTo="body"
                    kbxlCloseOnScroll
                  >
                    <ng-template let-item pTemplate="selectedItem">
                      <span class="selected-item-label">Hidden ({{ (hiddenScacs || []).length }})</span>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                      <div class="container">
                        <div class="row">
                          <div class="col-2 pl-0">{{ item.scac }}</div>
                          <div class="text-muted" [ngClass]="item.contractRate ? 'col-3 pl-1' : 'col-10 p-0 text-right'">
                            {{ item.contractRate ? (item.contractRate | currency) : 'N/A' }}
                          </div>
                          <div *ngIf="item.contractRate > 0" class="col-7 p-0">
                            <div class="row">
                              <div class="col-2 pr-0 text-muted">
                                <fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
                              </div>
                              <div class="col-4 text-muted p-0">{{ item.fuelRate | currency }}</div>
                              <div class="col-1 p-0 text-muted">
                                <fa-icon [icon]="['fas', 'equals']" size="sm"></fa-icon>
                              </div>
                              <div class="font-weight-bold text-right pl-0 col-5">
                                {{ item.totalRate | currency }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </ng-container>
            <div class="detail-column-seperator mt-3"></div>
            <div class="kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">Special Instructions</label>
              </div>
              <p-editor name="comments" [(ngModel)]="details.comments" [style]="{ height: '100px' }" [readonly]="readonlyEditor">
                <p-header>
                  <span class="ql-formats">
                    <button class="ql-bold" aria-label="Bold"></button>
                    <button class="ql-italic" aria-label="Italic"></button>
                    <button class="ql-underline" aria-label="Underline"></button>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-list" value="ordered" aria-label="Ordered List"></button>
                    <button class="ql-list" value="bullet" aria-label="Bulleted List"></button>
                  </span>
                </p-header>
              </p-editor>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
  </div>
</form>

<p-dialog
  [(visible)]="displayDynamicPricingDialog"
  [style]="{ left: '50px', 'max-width': 'calc(100vw - 100px)' }"
  [modal]="true"
  styleClass="dynamic-pricing-modal"
  [closable]="false"
  [closeOnEscape]="false"
  [appendTo]="'body'"
>
  <p-header [innerHtml]="rateSeekerLabel"></p-header>
  <kbxl-load-dynamic-pricing-container
    #rateSeekerModal
    [load]="details"
    [visible]="displayDynamicPricingDialog"
    [defaultPricingRule]="defaultPricingRule$ | async"
    (updatePricingRule)="applyRateSeekerChanges($event)"
    (cancel)="displayDynamicPricingDialog = false"
  >
  </kbxl-load-dynamic-pricing-container>
</p-dialog>
