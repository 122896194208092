import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { CreateDirectMessageData } from '../../../../chat/models/create-direct-message-data';
import { GuidEmpty } from '../../../../core/utilities/constants';
import {
  Customer,
  LoadCounterOfferRequestData,
  LoadDetail,
  ResponseError,
  ShipperGeneratedCounterOffer,
  UserContactData,
  UserModel,
} from '../../../models';
import { BaseComponent } from '../../base-component';

@Component({
  selector: 'kbxl-add-counter-offer-request',
  templateUrl: './add-counter-offer-request.component.html',
  styleUrls: ['./add-counter-offer-request.component.scss'],
})
export class AddCounterOfferRequestComponent extends BaseComponent implements OnChanges {
  @Input() showCancel = true;
  @Input() user: UserModel;
  @Input() load: LoadDetail;
  @Input() shipper: Customer;
  @Input() shipperUserData: UserContactData;
  @Input() loadDetailErrors: ResponseError[];
  @Input() shipperOfferOptions: ShipperGeneratedCounterOffer;
  @Output() chatNow = new EventEmitter<CreateDirectMessageData>();
  @Output() pendingChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() submitOffer: EventEmitter<LoadCounterOfferRequestData> = new EventEmitter<LoadCounterOfferRequestData>();
  counterOfferForm: UntypedFormGroup;
  constructor(private formBuilder: UntypedFormBuilder, private confirmationService: ConfirmationService, private currencyPipe: CurrencyPipe) {
    super();
    this.counterOfferForm = this.formBuilder.group(
      {
        linehaul: new UntypedFormControl(0, [Validators.required, Validators.min(0.01)]),
        hours: new UntypedFormControl(1, [Validators.required, Validators.min(0), Validators.max(99)]),
        minutes: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(99)]),
      },
      {
        validators: [this.expirationValidator],
      }
    );

    this.counterOfferForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((x) => this.pendingChange.emit(this.counterOfferForm.dirty));
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.load) {
      let linehaul = this.load.lineHaulRate;
      let hours = 1;
      let minutes = 0;
      if (this.load.counterOffer && this.load.counterOffer.request) {
        // override with incoming
        linehaul = this.load.counterOffer.request.counterOfferLineHaulRate;
        hours = this.load.counterOffer.request.expirationHours;
        minutes = this.load.counterOffer.request.expirationMinutes;
      }

      this.counterOfferForm.patchValue({
        linehaul: linehaul,
        hours: hours,
        minutes: minutes,
      });
      this.counterOfferForm.controls.linehaul.setValidators(Validators.min(this.load.lineHaulRate + 0.01));
    }

    // if this is a shipper originated offer, use post tab values
    if (changes && changes.shipperOfferOptions && changes.shipperOfferOptions.currentValue) {
      this.counterOfferForm.patchValue({
        linehaul: this.shipperOfferOptions.postTabLineHaul,
        hours: 24, // default to 24 hours
        minutes: 0,
      });
      this.counterOfferForm.controls.linehaul.setValidators(Validators.min(0.01));
    }
  }

  expirationValidator(group: UntypedFormGroup): { [key: string]: any } | null {
    const hours = group.get('hours');
    const minutes = group.get('minutes');

    return hours && hours.value < 1 && minutes && minutes.value < 1 ? { expirationRequired: true } : null;
  }
  calcCounterOffer(): number {
    const input = Number.parseFloat(this.counterOfferForm.value.linehaul);
    return input + this.getFuelRate();
  }
  submitCounterOffer(): void {
    const form = this.counterOfferForm.value;

    const offer: LoadCounterOfferRequestData = {
      loadId: this.load.loadId,
      counterOfferLineHaulRate: form.linehaul as number,
      expirationHours: form.hours as number,
      expirationMinutes: form.minutes as number,
      shipperOriginatedRequest: false,
    };

    if (this.shipperOfferOptions) {
      offer.scac = this.shipperOfferOptions.scac;
      offer.carrierUserId = this.shipperOfferOptions.carrierUser.userId;
      offer.shipperOriginatedRequest = true;

      // for shipper offers, they need to confirm before we fire off this request
      this.confirmationService.confirm({
        header: 'Request Counter Confirmation',
        message: `Are you sure want to request counter offer for ${this.currencyPipe.transform(offer.counterOfferLineHaulRate)}?`,
        accept: () => this.submitOffer.emit(offer),
      });
    } else {
      // for carriers they are presented will booking agreements
      this.submitOffer.emit(offer);
    }
  }

  chatWithShipperUserClick(): void {
    const data: CreateDirectMessageData = {
      loadRefDisplay: this.load.referenceLoadDisplay,
      shipperId: this.load.customerId,
      shipperName: this.shipper.name,
      shipperUserFullName: this.shipperUserData.fullName,
      carrierName: this.user.scacName,
      carrierScac: this.user.carrierScac,
      carrierUserFullName: this.user.name,
      members: [
        {
          // Shipper User
          id: this.shipperUserData.identUserId,
          identityUserName: '', // Not used when mapping to ConversationMember
          displayName: this.shipperUserData.fullName,
        },
        {
          // Carrier User
          id: this.user.identUserId,
          identityUserName: '', // Not used when mapping to ConversationMember
          displayName: this.user.name,
        },
      ],
    };
    this.chatNow.emit(data);
  }
  chatWithCarrierUserClick(): void {
    if (!this.shipperUserData && this.user.isShipper) {
      // if this is a shipper initiated offer, populate the details with the current user
      this.shipperUserData = {
        userId: this.user.userId,
        identUserId: this.user.identUserId,
        userName: '',
        firstName: this.user.firstName,
        fullName: `${this.user.firstName} ${this.user.lastName}`,
        email: false,
        phoneNumbers: [],
        cellPhoneNumbers: [],
      };
    }

    const data: CreateDirectMessageData = {
      loadRefDisplay: this.load.referenceLoadDisplay,
      shipperId: this.load.customerId,
      shipperName: this.shipper.name,
      shipperUserFullName: this.shipperUserData.fullName,
      carrierName: this.shipperOfferOptions.scac, // TODO: maybe pass scacName
      carrierScac: this.shipperOfferOptions.scac,
      carrierUserFullName: this.shipperOfferOptions.carrierUser.fullName,
      members: [
        {
          // Shipper User
          id: this.shipperUserData.identUserId,
          identityUserName: '', // Not used when mapping to ConversationMember
          displayName: this.shipperUserData.fullName,
        },
        {
          // Carrier User
          id: this.shipperOfferOptions.carrierUser.identUserId,
          identityUserName: '', // Not used when mapping to ConversationMember
          displayName: this.shipperOfferOptions.carrierUser.fullName,
        },
      ],
    };
    this.chatNow.emit(data);
  }
  shouldShowShipperUserChatNow(): boolean {
    const hasShipperUserId =
      this.shipperUserData && this.shipperUserData.identUserId && this.shipperUserData.identUserId !== GuidEmpty ? true : false;

    // Do not allow chatting with a shipper who does not have chat enabled
    const isShipperChatEnabled = this.shipper && this.shipper.isChatEnabled ? true : false;
    return hasShipperUserId && isShipperChatEnabled;
  }

  shouldShowCarrierUserChatNow(): boolean {
    const hasShipperUserId =
      this.shipperOfferOptions &&
      this.shipperOfferOptions.carrierUser.identUserId &&
      this.shipperOfferOptions.carrierUser.identUserId !== GuidEmpty
        ? true
        : false;

    // Do not allow chatting with a shipper who does not have chat enabled
    const isShipperChatEnabled = this.shipper && this.shipper.isChatEnabled ? true : false;
    return hasShipperUserId && isShipperChatEnabled;
  }

  getLineHaul(): number {
    if (this.user && this.user.isShipper && this.shipperOfferOptions) {
      return this.shipperOfferOptions.contractedCarrierLineHaul;
    }

    return this.load.lineHaulRate;
  }

  getFuelRate(): number {
    if (this.user && this.user.isShipper && this.shipperOfferOptions) {
      return this.shipperOfferOptions.postTabFuel;
    }

    return this.load.fuelRate;
  }

  getTotalRate(): number {
    if (this.user && this.user.isShipper && this.shipperOfferOptions) {
      return this.shipperOfferOptions.postTabLineHaul + this.shipperOfferOptions.postTabFuel;
    }
    return this.load.lineHaulRate + this.load.fuelRate;
  }

  getRateLabel(): string {
    if (this.user && this.user.isShipper && this.shipperOfferOptions) {
      return "Carrier's Current Contract Rate";
    }
    return 'Current Rate';
  }
}
