import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnauthorizedMessageService {
  /**
   * Optional way for extending services to provide a plain text message.
   * Extending services can return of(null) here and/or return a value
   * from getUnauthorizedMarkup() in order to render HTML markup as the
   * unauthorized message instead of this plain text message.
   */
  getUnauthorizedMessage(): Observable<string> {
    return of('You either do not have access to this application or your session has expired.');
  }

  /**
   * Optional way for extending services to provide an unauthorized message as
   * markup instead of plain text.  If markup is provide, it will override
   * any plain text message.  So if a plain text message is preferred,
   * extending services should have getUnauthorizedMarkup return of(null).
   */
  getUnauthorizedMarkup(): Observable<string> {
    return of(null);
  }
}
