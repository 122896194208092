import { AccessorialActionItemEffects } from './accessorial-action-item.effects';
import { AccessorialRequestEffects } from './accessorial-request.effects';
import { ActionItemEffects } from './action-item.effects';
import { AuthEffects } from './auth.effects';
import { CounterOfferActionItemEffects } from './counter-offer-action-item.effects';
import { LoadBoardLoadBookEffects } from './load-board-load-book.effects';
import { LoadBoardLoadDetailEffects } from './load-board-load-detail.effects';
import { LoadDocumentEffects } from './load-document.effects';
import { RouterEffects } from './navigation.effects';
import { UserCommunicationDisplayEffects } from './user-communication-display.effects';

export const effects: any[] = [
  LoadBoardLoadBookEffects,
  LoadBoardLoadDetailEffects,
  UserCommunicationDisplayEffects,
  LoadDocumentEffects,
  ActionItemEffects,
  AccessorialActionItemEffects,
  CounterOfferActionItemEffects,
  AccessorialRequestEffects,
  RouterEffects,
  AuthEffects,
];

export * from './accessorial-request.effects';
export * from './load-board-load-book.effects';
export * from './load-board-load-detail.effects';
export * from './load-document.effects';
export * from './user-communication-display.effects';
