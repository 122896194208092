import { ChatMember } from './chat-member';

/**
 * Model for creating a new Direct Message conversation
 */
export interface CreateDirectMessageData {
  // load reference display
  loadRefDisplay?: string;
  shipperId: string;
  shipperName: string;
  shipperUserFullName: string;
  carrierScac: string;
  carrierName: string;
  carrierUserFullName: string;
  members: ChatMember[];
}
