import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { mapToPayload } from 'src/app/shared/utilities/map-to-payload';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AccessorialActionItemService } from '../../services';
import { PageableQueryHelper } from '../../utilities';
import {
  AccessorialActionItemActionTypes,
  AccessorialActionItemLoadFiltersAction,
  AccessorialActionItemLoadFiltersFailureAction,
  AccessorialActionItemLoadFiltersSuccessAction,
  AccessorialActionItemsLoadAction,
  AccessorialActionItemsLoadFailureAction,
  AccessorialActionItemsLoadSuccessAction,
} from '../actions';

@Injectable()
export class AccessorialActionItemEffects {
  $loadCriteria = createEffect(() =>
    { return this.actions$.pipe(
      ofType<AccessorialActionItemLoadFiltersAction>(AccessorialActionItemActionTypes.LoadFilters),
      switchMap(() =>
        this.accessorialActionItemService.getFilterCriteria().pipe(
          map((data) => new AccessorialActionItemLoadFiltersSuccessAction(data)),
          catchError((err) => of(new AccessorialActionItemLoadFiltersFailureAction(err)))
        )
      )
    ) }
  );

  $loadActionItems = createEffect(() =>
    { return this.actions$.pipe(
      ofType<AccessorialActionItemsLoadAction>(AccessorialActionItemActionTypes.Load_ActionItems),
      mapToPayload<{ searchType: string; queryHelper: PageableQueryHelper }>(),
      switchMap((payload) =>
        this.accessorialActionItemService.getPageableLoadsBySearchType('', payload.queryHelper).pipe(
          map((data) => new AccessorialActionItemsLoadSuccessAction(data)),
          catchError((err) => of(new AccessorialActionItemsLoadFailureAction(err)))
        )
      )
    ) }
  );

  constructor(private actions$: Actions, private accessorialActionItemService: AccessorialActionItemService) {}
}
