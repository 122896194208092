import { Component, EventEmitter, Input, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ConfirmationService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { LoadDocumentMetadata } from '../../../models';
import { LoadDocumentDownload } from '../../../models/load-document-download';
import {
  getLoadDocumentDownload,
  LoadDocumentDownloadDocumentAction,
  LoadDocumentDownloadDocumentClearAction,
  SharedState,
} from '../../../store';
import { BaseComponent } from '../../base-component';

@Component({
  selector: 'kbxl-document-list-item',
  templateUrl: './document-list-item.component.html',
  styleUrls: ['./document-list-item.component.scss'],
})
export class DocumentListItemComponent extends BaseComponent {
  @Input() document: LoadDocumentMetadata;
  @Input() isReadOnly: boolean;
  @Output() removeDocument: EventEmitter<LoadDocumentMetadata> = new EventEmitter<LoadDocumentMetadata>();
  loading = false;
  constructor(private store: Store<SharedState>, private confirmationService: ConfirmationService) {
    super();
    this.store.pipe(select(getLoadDocumentDownload), takeUntil(this.destroyed$)).subscribe((x) => this.handleFileDownload(x));
  }

  getDocumentDescription(): string {
    if (this.document.loadDocumentType) {
      if (this.document.loadDocumentType.description.toLowerCase() === 'other') {
        return `${this.document.loadDocumentType.description} - ${this.document.comment}`;
      } else if  (this.document.loadDocumentType.description.toLowerCase() === 'accessorial') {
        return `${this.document.loadDocumentType.description} - ${this.document.comment}`;
      }
      return this.document.loadDocumentType.description;
    }
  }

  downloadDocument(): void {
    this.loading = true;
    this.store.dispatch(new LoadDocumentDownloadDocumentAction(this.document));
  }
  deleteDocument(): void {
    this.removeDocument.emit(this.document);
  }

  private handleFileDownload(fileDownload: LoadDocumentDownload): void {
    // only download the document if the request matches this components id
    if (!this.document || !fileDownload || this.document.loadDocumentId !== fileDownload.metadata.loadDocumentId) {
      return;
    }
    this.loading = false;
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    
    //TODO:Temp fix
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(fileDownload.file, fileDownload.metadata.fileName);

      // clear the document from the store
      this.store.dispatch(new LoadDocumentDownloadDocumentClearAction());
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(fileDownload.file);

    const link = document.createElement('a');
    link.href = data;
    link.download = fileDownload.metadata.fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    this.store.dispatch(new LoadDocumentDownloadDocumentClearAction());

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }
}
