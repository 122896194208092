/* tslint:disable:no-bitwise */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CacheService } from 'src/app/admin/services/cache.service';
import { Caches } from 'src/app/shared/models';
import { BaseComponent } from '../../../../shared/components';

@Component({
  selector: 'kbxl-cache-container',
  templateUrl: './cache-container.component.html',
  styleUrls: ['./cache-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CacheContainerComponent extends BaseComponent implements OnInit {
  public isProcessing = false;
  public all = false;
  public selectedCaches: any[] = [];

  public caches: any[] = [];
  errorSummary: string;
  constructor(private cacheService: CacheService, private messageService: MessageService, private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    Object.values(Caches)
      .filter((x) => typeof x === 'number')
      .forEach((x) => {
        if (x !== Caches.ALL) {
          this.caches.push({ name: Caches[x], value: x });
        }
      });
  }

  clear() {
    this.errorSummary = '';
    let selected: Caches = Caches.None;
    if (this.all) {
      selected = Caches.ALL;
    } else {
      this.selectedCaches.forEach((x) => (selected |= x.value));
    }
    if (selected === 0) {
      this.errorSummary = 'Please select at least one cache source';
      return;
    }
    this.isProcessing = true;
    this.cacheService.clear(selected).subscribe((_) => {
      this.isProcessing = false;
      this.messageService.add({ id: 0, detail: 'Cache(s) cleared', severity: 'success' });
      this.cdr.detectChanges();
    });
  }

  allChanged() {
    if (this.all) {
      this.selectedCaches = [...this.caches];
    } else {
      this.selectedCaches = [];
    }
  }

  selectedCachesChanged() {
    if (this.selectedCaches.length === this.caches.length) {
      this.all = true;
    } else {
      this.all = false;
    }
  }
}
