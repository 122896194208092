<kbxl-admin-header></kbxl-admin-header>
<div class="d-flex flex-column flex-lg-row p-1 p-lg-4">
  <div class="user-selector left-tab" *ngIf="showCarrierSelector">
    <div class="mt-2 carrier-field position-relative" kbxlActionCheck="loadshop.ui.system.carrier.user.addedit">
      <div class="small-spinner-container" *ngIf="loadingCarriers$ | async">
        <p-progressSpinner></p-progressSpinner>
        <div>Loading Carriers</div>
      </div>
      <div class="kbxl-inputgroup-container">
        <div class="p-inputgroup">
          <p-dropdown
            *ngIf="!(loadingCarriers$ | async)"
            [options]="authorizedCarriers$ | async"
            [(ngModel)]="selectedCarrier"
            placeholder="Select Carrier"
            [styleClass]="'p-dropdown-primary'"
            dropdownIcon="pi pi-caret-down"
            (onChange)="carrierSelected()"
            [disabled]="loadingCarriers$ | async"
            [filter]="true"
            filterBy="label"
            [virtualScroll]="true"
            virtualScrollItemSize="30"
            class="w-100"
          >
            <ng-template let-item pTemplate="item">
              <span>{{ item?.label | titlecase }}</span>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div *ngIf="!selectedCarrier || (selectedCarrier && selectedCarrier.length === 0); else carrierUsersData">Please select a carrier</div>
    <ng-template #carrierUsersData>
      <div class="d-flex flex-column">
        <kbxl-carrier-users
          [loadingCarrierUsers]="loadingCarrierUsers$ | async"
          [carrierUsers]="carrierUsers$ | async"
        ></kbxl-carrier-users>
        <kbxl-carrier-agreements
          [loadingCarrierUsers]="loadingCarrierUsers$ | async"
          [carrierAgreements]="carrierAgreements$ | async"
        ></kbxl-carrier-agreements>
      </div>
    </ng-template>
  </div>
</div>
