import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { EMPTY, timer } from 'rxjs';
import { delayWhen, retryWhen, switchMap, tap } from 'rxjs/operators';
import {
  LoadBoardLoadBookActionTypes,
  LoadBoardLoadBookSuccessAction,
  LoadBoardLoadDetailActionTypes,
  LoadBoardLoadDetailLoadSuccessAction,
  LoadshopApplicationActionTypes,
} from '../../../shared/store';
import { AuthLoginSuccessAction } from '../../../shared/store/actions/auth.actions';
import { AnalyticsService } from '../../services/google-analytics.service';

declare let window;

@Injectable()
export class AnalyticsEffects {
  $login = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType(LoadshopApplicationActionTypes.LoadshopStart),
        tap((action: AuthLoginSuccessAction) => {
          // this.analyticsService.login(action.payload);
          this.analyticsService.addEvent('login', {
            method: 'IdentityServer',
          });
        })
      ) },
    { dispatch: false }
  );

  $route = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType(ROUTER_NAVIGATION),
        tap((action: RouterNavigationAction) => {
          const route = action.payload;
          this.analyticsService.addEvent('pageview', {
            event_label: route.routerState.url,
            event_category: 'routing',
          });
        })
      ) },
    { dispatch: false }
  );

  $detail = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType(LoadBoardLoadDetailActionTypes.Load_Success),
        tap((action: LoadBoardLoadDetailLoadSuccessAction) => {
          const load = action.payload;
          this.analyticsService.addEvent('detail', {
            event_label: load.referenceLoadDisplay,
            event_category: 'detail',
          });
        })
      ) },
    { dispatch: false }
  );

  $booked = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType<LoadBoardLoadBookSuccessAction>(LoadBoardLoadBookActionTypes.Book_Success),
        tap((action: LoadBoardLoadBookSuccessAction) => {
          const load = action.payload;
          this.analyticsService.addEvent('booked', {
            event_label: load.referenceLoadDisplay,
            event_category: 'detail',
          });
        })
      ) },
    { dispatch: false }
  );

  $startup = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType(LoadshopApplicationActionTypes.LoadshopStart),
        switchMap(() => {
          if (window.gtag) {
            this.analyticsService.initialize();
          } else {
            throw new Error('google.analytics not loaded yet');
          }

          return EMPTY;
        }),
        retryWhen((errors) => errors.pipe(delayWhen(() => timer(10))))
      ) },
    { dispatch: false }
  );

  constructor(private analyticsService: AnalyticsService, private actions$: Actions) {}
}
