import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { environment } from '../../../../../environments/environment';
import { GuidEmpty } from '../../../../core/utilities/constants';
import {
  AllMessageTypes,
  CarrierScac,
  ContactNumberMessageTypes,
  UserAdminData,
  UserNotification,
  UserNotificationModel,
} from '../../../../shared/models';
import { contactPhoneRequiredValidator } from '../../../../user/validators/user-profile-validators';

@Component({
  selector: 'kbxl-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAdminComponent implements OnChanges {
  @Output() userSearchFilterUpdated = new EventEmitter<string>();
  @Output() userSelected = new EventEmitter<UserAdminData>();
  @Output() carrierChanged = new EventEmitter();
  @Output() userSaved = new EventEmitter<{ user: UserAdminData; createMode: boolean }>();
  @Output() addUser = new EventEmitter<string>();
  @Output() deleteUser = new EventEmitter<UserAdminData>();

  @Input() public selectedUser: UserAdminData;
  @Input() public userResults: UserAdminData[];
  @Input() public updatingUser: UserAdminData;
  @Input() public allAuthorizedShippers: SelectItem[];
  @Input() public allAuthorizedSecurityRoles: SelectItem[];
  @Input() public allAuthorizedCarrierScacs: CarrierScac[];
  @Input() public processing: boolean;
  @Input() public createMode: boolean;

  filteredCarrierScacs: SelectItem[];

  registrationUrl: string;
  messageTypes = [];
  userProfileForm: UntypedFormGroup;
  submitted = false;
  public newUser: string;

  get contactNumbers() {
    const c = this.userProfileForm.get('contactNumbers') as UntypedFormArray;
    return c.controls;
  }

  get allNotificationsEnabled() {
    return this.userProfileForm.get('isNotificationsEnabled').value;
  }

  constructor(private fb: UntypedFormBuilder, private messageService: MessageService, private confirmationService: ConfirmationService) {
    this.registrationUrl = `${environment.identityServerUrl}/account/Register`;
    this.createForm();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.updatingUser && changes.updatingUser.currentValue) {
      this.submitted = false;
      this.updateForm();
    }
    this.updateDropdowns();
  }

  searchUsers(event) {
    this.userSearchFilterUpdated.emit(event.query);
  }

  select() {
    this.userSelected.emit(this.selectedUser);
    this.selectedUser = null;
  }

  carrierScacsChanged() {
    this.carrierChanged.emit();
  }

  saveUser() {
    this.submitted = true;
    this.userProfileForm.markAllAsTouched();

    if (!this.userProfileForm.valid) {
      this.messageService.add({
        summary: 'Error updating User Profile',
        detail: 'One or more errors occurred when updating the User Profile.  See form for error details',
        severity: 'error',
      });
      return;
    }

    const dedicatedPlanner = this.allAuthorizedSecurityRoles.find((x) => x.label === 'Dedicated Planner');
    const selectedRoles = this.userProfileForm.value.securityRoleIds as string[];
    if (dedicatedPlanner && selectedRoles.find((x) => dedicatedPlanner.value === x) && selectedRoles.length > 1) {
      this.messageService.add({
        summary: 'Error updating User Profile',
        detail: 'Dual roles for Dedicated Planner is not allowed.',
        severity: 'error',
      });
      return;
    }
    const formModel = this.userProfileForm.value;

    this.updatingUser.carrierScacs = formModel.carrierScacs as string[];
    this.updatingUser.securityRoleIds = formModel.securityRoleIds as string[];
    this.updatingUser.shipperIds = formModel.shipperIds as string[];
    this.updatingUser.isNotificationsEnabled = formModel.isNotificationsEnabled as boolean;
    this.updatingUser.isChatNotificationsEnabled = formModel.isChatNotificationsEnabled as boolean;
    this.updatingUser.allowCounterOffer = formModel.allowCounterOffer as boolean;
    this.updatingUser.email = formModel.email as string;

    const contactNumbers = formModel.contactNumbers as UserNotificationModel[];
    // format the extension to append to the notification value
    contactNumbers.forEach((x) => {
      if (x.messageTypeId === AllMessageTypes.Phone && x.additionalValue && x.additionalValue.length > 0) {
        x.notificationValue += `x${x.additionalValue}`;
        x.additionalValue = null;
      }
    });

    // replace contact numbers and email
    this.updatingUser.userNotifications = [];
    this.updatingUser.userNotifications.push(...contactNumbers);

    this.userSaved.emit({ user: this.updatingUser, createMode: this.createMode });
  }

  add(newUserpanel: OverlayPanel) {
    newUserpanel.hide();
    this.addUser.emit(this.newUser);
    this.newUser = '';
  }

  createForm(): void {
    // build message type dropdown
    ContactNumberMessageTypes.forEach((x) => {
      this.messageTypes.push({
        label: x.description,
        value: x.value,
      });
    });
    // create form
    this.userProfileForm = new UntypedFormGroup(
      {
        securityRoleIds: new UntypedFormControl([]),
        shipperIds: new UntypedFormControl([]),
        carrierScacs: new UntypedFormControl([]),
        isNotificationsEnabled: new UntypedFormControl(false),
        isChatNotificationsEnabled: new UntypedFormControl(false),
        allowCounterOffer: new UntypedFormControl(false),
        email: new UntypedFormControl([]),
        contactNumbers: this.fb.array([]),
      },
      { validators: contactPhoneRequiredValidator }
    );
  }

  updateForm(): void {
    // reset form to wipe values if selected user changed
    this.userProfileForm.reset();
    const items = this.userProfileForm.get('contactNumbers') as UntypedFormArray;
    items.reset();
    // clear the form array
    for (let index = items.length; index > -1; index--) {
      items.removeAt(index);
    }

    this.userProfileForm.patchValue({
      securityRoleIds: this.updatingUser.securityRoleIds,
      shipperIds: this.updatingUser.shipperIds,
      carrierScacs: this.updatingUser.carrierScacs,
      isNotificationsEnabled: this.updatingUser.isNotificationsEnabled,
      isChatNotificationsEnabled: this.updatingUser.isChatNotificationsEnabled,
      email: this.updatingUser.email,
      allowCounterOffer: this.updatingUser.allowCounterOffer,
    });

    // we need to clear the array without losing the validations
    const phoneNotifications = this.updatingUser.userNotifications.filter(
      (x) => x.messageTypeId !== AllMessageTypes.Email && x.userNotificationId !== GuidEmpty
    );

    for (let index = 0; index < phoneNotifications.length; index++) {
      const element = phoneNotifications[index];

      const exists = items.value.find((x) => x.userNotificationId === element.userNotificationId);
      if (!exists) {
        items.push(this.createContactItem(element));
      }
    }

    if (phoneNotifications.length === 0 && items.value.length === 0) {
      this.addContact();
    }
  }

  removeContact(index: number): void {
    const items = this.userProfileForm.get('contactNumbers') as UntypedFormArray;
    items.removeAt(index);
    if (items.value.length === 0) {
      this.addContact();
    }
  }

  addContact(): void {
    this.submitted = false;
    const items = this.userProfileForm.get('contactNumbers') as UntypedFormArray;
    items.push(this.createContactItem());
  }

  createContactItem(notification?: UserNotification): UntypedFormGroup {
    if (!notification) {
      notification = new UserNotificationModel({
        userNotificationId: GuidEmpty,
        messageTypeId: AllMessageTypes.Phone,
        notificationValue: '',
        additionalValue: '',
        isDefault: false,
        notificationEnabled: false,
      });
    } else {
      // check if we need to split the extension out
      if (notification.notificationValue.indexOf('x') > -1) {
        const split = notification.notificationValue.split('x');
        notification.notificationValue = split[0];
        notification.additionalValue = split[1];
      }
    }

    return this.fb.group({
      userNotificationId: notification.userNotificationId,
      messageTypeId: notification.messageTypeId,
      notificationValue: notification.notificationValue,
      additionalValue: notification.additionalValue,
      description: notification.description,
      notificationEnabled: notification.notificationEnabled,
    });
  }
  public displayCarrierScacs(): boolean {
    // Dont display carrier scacs for dedicated planners
    const dedicatedPlanner = this.allAuthorizedSecurityRoles.find((x) => x.label === 'Dedicated Planner');
    const outboundDedicated = this.allAuthorizedSecurityRoles.find((x) => x.label === 'KBX Outbound/Dedicated');
    const selectedRoles = this.userProfileForm.value.securityRoleIds as string[];
    if (
      (dedicatedPlanner && selectedRoles.find((x) => dedicatedPlanner.value === x)) ||
      (outboundDedicated && selectedRoles.find((x) => outboundDedicated.value === x))
    ) {
      return false;
    }
    return this.allAuthorizedCarrierScacs.length > 0;
  }
  public displayShippers(): boolean {
    // Dont display shippers for dedicated planners
    const dedicatedPlanner = this.allAuthorizedSecurityRoles.find((x) => x.label === 'Dedicated Planner');
    const selectedRoles = this.userProfileForm.value.securityRoleIds as string[];
    if (dedicatedPlanner && selectedRoles.find((x) => dedicatedPlanner.value === x)) {
      return false;
    }
    return this.allAuthorizedShippers.length > 0;
  }

  updateDropdowns(): void {
    let allowDedicated = true;

    const selectedRoleIds = this.userProfileForm.value.securityRoleIds as string[];

    const selectedRoles = this.allAuthorizedSecurityRoles.filter((x) => selectedRoleIds.indexOf(x.value) !== -1).map((x) => x.label);

    // if the user has one of these roles, do NOT show them dedicated SCACs
    if (
      selectedRoles.find((x) => x === 'Carrier Admin') ||
      selectedRoles.find((x) => x === 'Carrier User') ||
      selectedRoles.find((x) => x === 'Carrier User View Only')
    ) {
      allowDedicated = false;
    }

    let temp = this.allAuthorizedCarrierScacs.slice();

    if (!allowDedicated) {
      temp = temp.filter((x) => !x.isDedicated);
    }

    this.filteredCarrierScacs = temp.map((x) => ({
      label: `${x.carrierName} -${x.scac}`,
      value: x.scac,
    }));
  }

  _deleteUser() {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete the user?`,
      accept: () => this.deleteUser.emit(this.updatingUser),
    });
  }
}
