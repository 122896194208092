import {
  Carrier,
  CustomerData,
  FuelRateEstimateByProgramData,
  LoadFuelProgramData,
  LoadPricingRuleData,
  LoadStop,
  ServiceType,
  SpotPriceQuoteResponse,
} from '.';
import { LoadContact } from './load-contact';

export interface IShippingLoadDetail {
  loadId: string;
  referenceLoadId: string;
  referenceLoadDisplay: string;
  mileage: number;
  commodity: string;
  weight: number;
  equipmentId: string;
  equipmentCategoryId: string;
  equipmentCategoryDesc: string;
  equipmentTypeDisplay: string;
  comments: string;
  lineHaulRate: number;
  smartSpotRate: number;
  shippersFSC: number;
  loadStops: LoadStop[];
  onLoadshop: boolean;
  scacsSentWithLoad: boolean;
  hasPrefetchedRates: boolean;
  isEstimatedFSC: boolean;
  allowZeroFuel: boolean;
  allowEditingFuel: boolean;
  manuallyCreated: boolean;
  isExternalLoad: boolean;
  customerLoadTypeId: number;
  hCapRate: number;
  xCapRate: number;
  altXCapRate: number;
  datGuardRate: number;
  machineLearningRate: number;
  hasScacRestrictions: boolean;
  carrierGroupIds: number[];
  allCarriersPosted: boolean;
  serviceTypes: ServiceType[];
  isPowerPlus: boolean;
  isDynamicPricingEnabled: boolean;
  applicablePricingRuleId: number;
  latestLoadPricingRule: LoadPricingRuleData;
  usesAllInRates: boolean;
  isTopsCustomer: boolean;
  createBy: string;
  latestTransactionTypeId: string;
  isCounterOfferEnabled: boolean;
  allowCounterOffer: boolean;
  isBrokeredLoad: boolean;
  // used in UI
  selectedGroupCarriers: Carrier[];
  selectedCarriers: Carrier[];
  selected: boolean;
  hasChanges: boolean;
  hasRateSeekerError: boolean;
  overrideFuel: number;
  totalRate: number;
  customer?: CustomerData;
  loadFuelPrograms?: LoadFuelProgramData[];
  // Used when the load is posted
  fuelRateByProgram?: FuelRateEstimateByProgramData;
  postedTotalRate?: number;
}

export class ShippingLoadDetail implements IShippingLoadDetail {
  constructor(init?: Partial<ShippingLoadDetail>) {
    this.hasChanges = false;
    this.selected = false;
    this.selectedGroupCarriers = [];
    this.selectedCarriers = [];

    Object.assign(this, init);
  }

  loadId: string;
  referenceLoadId: string;
  referenceLoadDisplay: string;
  mileage: number;
  commodity: string;
  weight: number;
  equipmentId: string;
  equipmentCategoryId: string;
  equipmentCategoryDesc: string;
  equipmentTypeDisplay: string;
  comments: string;
  lineHaulRate: number;
  smartSpotRate: number;
  shippersFSC: number;
  loadStops: LoadStop[];
  onLoadshop: boolean;
  scacsSentWithLoad: boolean;
  hasPrefetchedRates: boolean;
  isEstimatedFSC: boolean;
  allowZeroFuel: boolean;
  allowEditingFuel: boolean;
  manuallyCreated: boolean;
  isExternalLoad: boolean;
  customerLoadTypeId: number;
  hCapRate: number;
  xCapRate: number;
  altXCapRate: number;
  datGuardRate: number;
  machineLearningRate: number;
  hasScacRestrictions: boolean;

  selectedGroupCarriers: Carrier[];
  selectedCarriers: Carrier[];
  hasChanges: boolean;
  carrierGroupIds: number[];
  allCarriersPosted: boolean;
  serviceTypes: ServiceType[];
  isPowerPlus: boolean;

  isDynamicPricingEnabled: boolean;
  applicablePricingRuleId: number;
  latestLoadPricingRule: LoadPricingRuleData;

  usesAllInRates: boolean;
  isTopsCustomer: boolean;
  createBy: string;
  latestTransactionTypeId: string;
  isCounterOfferEnabled: boolean;
  allowCounterOffer: boolean;
  selected: boolean;

  loadContact: LoadContact;
  isBrokeredLoad: boolean;
  hasRateSeekerError: boolean;
  overrideFuel: number;
  totalRate: number;
  customer?: CustomerData;
  fuelRateByProgram?: FuelRateEstimateByProgramData;
  loadFuelPrograms?: LoadFuelProgramData[];
  postedTotalRate?: number;
  spotPriceQuote?: SpotPriceQuoteResponse;
}
