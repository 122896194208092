import { Actions } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

export function mapToPayload<T>(): OperatorFunction<Action, T> {
  return (source: Actions<Action>) =>
    source.pipe(map((x: Action & { payload: T }) => x.payload));
}

// export declare function ofType<T extends Action>(...allowedTypes: string[]): OperatorFunction<Action, T>;
