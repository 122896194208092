import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AccessorialRequestService } from '../../services';
import {
  AccessorialRequestActionTypes,
  AccessorialRequestUnlockLoadAction,
  AccessorialRequestUnlockLoadFailureAction,
  AccessorialRequestUnlockLoadSuccessAction,
} from '../actions';

@Injectable()
export class AccessorialRequestEffects {
  $unlockLoad = createEffect(() =>
    { return this.actions$.pipe(
      ofType<AccessorialRequestUnlockLoadAction>(AccessorialRequestActionTypes.UnlockLoad),
      switchMap((payload) =>
        this.accessorialRequestService.unlockLoad(payload.loadId, payload.unlock).pipe(
          map((data) => new AccessorialRequestUnlockLoadSuccessAction(data)),
          catchError((err) => of(new AccessorialRequestUnlockLoadFailureAction(err)))
        )
      )
    ) }
  );

  constructor(private actions$: Actions, private accessorialRequestService: AccessorialRequestService) {}
}
