import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../core/guards/can-deactivate.guard';
import { AppActionGuard, AppActionOperatorType } from '../shared/guards';
import { SecurityAppActionType } from '../shared/models/security-app-action-type';
import {
  AccessorialDetailContainerComponent,
  AdminContainerComponent,
  CacheContainerComponent,
  CarrierContainerComponent,
  CarrierProfileContainerComponent,
  ConfigurationContainerComponent,
  ShipperProfileContainerComponent,
  UserCommunicationContainerComponent,
  UserCommunicationFormContainerComponent,
  UsersContainerComponent,
} from './components';
import { ShipperAccessorialContainerComponent } from './components/accessorials/shipper-accessorial-container';
import { LaneGroupContainerComponent, LaneGroupDetailContainerComponent } from './components/lane-management';
import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from '../core/guards/auth.guard';

const routes: Routes = [
  {
    // if root path changes be sure to update AdminGuard and CommonService.getMenuItems
    path: 'maint',
    component: AdminContainerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: AdminContainerComponent,
        canActivate: [AdminGuard],
      },
      {
        path: 'users',
        component: UsersContainerComponent,
        canActivate: [AppActionGuard],
        data: {
          roles: [SecurityAppActionType.CarrierUserAddEdit, SecurityAppActionType.ShipperUserAddEdit],
          operator: AppActionOperatorType.Any,
        },
      },
      {
        path: 'carrier',
        component: CarrierContainerComponent,
        canActivate: [AppActionGuard],
        data: { roles: [SecurityAppActionType.CarrierViewUsers] },
      },
      {
        path: 'shipper-profile',
        component: ShipperProfileContainerComponent,
        canActivate: [AppActionGuard],
        data: { roles: [SecurityAppActionType.ShipperAddEdit] },
      },
      {
        path: 'shipper-profile/detail',
        component: ShipperProfileContainerComponent,
        canActivate: [AppActionGuard],
        data: { roles: [SecurityAppActionType.ShipperAddEdit] },
      },
      {
        path: 'shipper-profile/detail/:id',
        component: ShipperProfileContainerComponent,
        canActivate: [AppActionGuard],
        data: { roles: [SecurityAppActionType.ShipperAddEdit] },
      },
      {
        path: 'lane-management',
        component: LaneGroupContainerComponent,
        canActivate: [AppActionGuard],
        data: { roles: [SecurityAppActionType.ShipperCustomerLaneGroupsAddEdit] },
      },
      {
        path: 'lane-management/detail/:id',
        component: LaneGroupDetailContainerComponent,
        canActivate: [AppActionGuard],
        data: { roles: [SecurityAppActionType.ShipperCustomerLaneGroupsAddEdit] },
      },
      {
        path: 'lane-management/detail',
        component: LaneGroupDetailContainerComponent,
        canActivate: [AppActionGuard],
        data: { roles: [SecurityAppActionType.ShipperCustomerLaneGroupsAddEdit] },
      },
      {
        path: 'carrier-profile',
        component: CarrierProfileContainerComponent,
        canActivate: [AppActionGuard],
        data: { roles: [SecurityAppActionType.CarrierAddEdit] },
      },
      {
        path: 'user-communications',
        component: UserCommunicationContainerComponent,
        canActivate: [AppActionGuard],
        data: { roles: [SecurityAppActionType.UserCommunicationAddEdit] },
      },
      {
        path: 'user-communications/detail/:id',
        component: UserCommunicationFormContainerComponent,
        canActivate: [AppActionGuard],
        data: { roles: [SecurityAppActionType.UserCommunicationAddEdit] },
      },
      {
        path: 'user-communications/detail',
        component: UserCommunicationFormContainerComponent,
        canActivate: [AppActionGuard],
        data: { roles: [SecurityAppActionType.UserCommunicationAddEdit] },
      },
      {
        path: 'configurations',
        component: ConfigurationContainerComponent,
        canActivate: [AppActionGuard],
        data: { roles: [SecurityAppActionType.ConfigurationsAddEdit] },
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: 'configurations/detail',
        component: AccessorialDetailContainerComponent,
        canActivate: [AppActionGuard],
        data: { roles: [SecurityAppActionType.ConfigurationsAddEdit], enableUoM: true },
      },
      {
        path: 'accessorials',
        component: ShipperAccessorialContainerComponent,
        canActivate: [AppActionGuard],
        data: { roles: [SecurityAppActionType.ShipperAccessorialsAddEdit] },
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: 'accessorials/detail',
        component: AccessorialDetailContainerComponent,
        canActivate: [AppActionGuard],
        data: { roles: [SecurityAppActionType.ShipperAccessorialsAddEdit], enableUoM: false },
      },
      {
        path: 'admin',
        component: CacheContainerComponent,
        canActivate: [AppActionGuard],
        data: {
          roles: [SecurityAppActionType.AdminClearCache],
          operator: AppActionOperatorType.Any,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [AdminGuard],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
