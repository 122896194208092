import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { getUserProfileModel, UserState } from 'src/app/user/store';
import { RouteType } from '../models/route-type';
import { SecurityAppActionType } from '../models/security-app-action-type';

export enum AppActionOperatorType {
  Any = 'ANY',
  All = 'ALL',
}

@Injectable({
  providedIn: 'root',
})
export class AppActionGuard  {
  constructor(private userStore: Store<UserState>, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const roles = route.data.roles as Array<SecurityAppActionType>;
    let operator = route.data.operator as AppActionOperatorType;
    const routeType = route.data.routeType as RouteType;

    if (!operator) {
      operator = AppActionOperatorType.All;
    }

    return this.userStore.pipe(
      select(getUserProfileModel),
      filter((x) => x != null),
      map((user) => {
        if (routeType) {
          switch (routeType) {
            case RouteType.Admin: {
              if (!user.isAdmin) {
                return this.router.createUrlTree(['unauthorized']);
              }
              break;
            }
            case RouteType.Carrier: {
              if (!user.isCarrier) {
                return this.router.createUrlTree(['incorrect-route']);
              }
              break;
            }
            case RouteType.Shipper: {
              if (!user.isShipper) {
                return this.router.createUrlTree(['incorrect-route']);
              }
              break;
            }
          }
        }

        for (let i = 0; i < roles.length; i++) {
          if (operator === AppActionOperatorType.Any && user.hasSecurityAction(roles[i])) {
            return true;
          }
          if (operator === AppActionOperatorType.All && !user.hasSecurityAction(roles[i])) {
            return this.router.createUrlTree(['unauthorized']);
          }
        }
        if (operator === AppActionOperatorType.Any) {
          return this.router.createUrlTree(['unauthorized']);
        }
        return true;
      })
    );
  }
}
