import { Component, Input } from '@angular/core';
import { autoApproveCounterOfferMessage } from '../../constants';

@Component({
  selector: 'kbxl-auto-approve-warning',
  templateUrl: './auto-approve-warning.component.html',
  styleUrls: ['./auto-approve-warning.component.scss'],
})
export class AutoApproveWarningComponent {
  autoApproveWarningMessage: string = autoApproveCounterOfferMessage;

  @Input() hoverWarning: boolean;
  constructor() {}
}
