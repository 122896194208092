<ng-container *ngIf="displayInSidebar">
  <p-sidebar
    [visible]="true"
    position="right"
    styleClass="detail-sidebar"
    [baseZIndex]="50"
    [modal]="modal"
    [dismissible]="true"
    [appendTo]="'body'"
    [blockScroll]="true"
    [showCloseIcon]="false"
    [closeOnEscape]="false"
    (onHide)="onHide()"
    (visibleChange)="onVisibleChange($event)"
  >
    <kbxl-sidebar-close (closed)="onHide()"></kbxl-sidebar-close>
    <kbxl-load-detail
      [user]="userProfile$ | async"
      [load]="loadDetail$ | async"
      [ratingQuestions]="ratingQuestions$ | async"
      (book)="book($event)"
      (removeCarrier)="removeCarrier($event)"
      (deleteLoad)="deleteLoad($event)"
      (deleteDetailLoad)="deleteDetailLoad($event)"
      [isShippingDetail]="isShippingDetail"
      [isChatDetail]="isChatDetail"
      [captureReasonOnDelete]="captureReasonOnDelete"
      [loadStatus]="loadStatus$ | async"
      [loadingStatus]="loadingStatus$ | async"
      [processing]="processing$ | async"
      [loadDocumentTypes]="loadDocumentTypes$ | async"
      [loadDetailErrors]="loadDetailErrors$ | async"
      [isReadOnly]="isReadOnly"
      (loadCurrentStatus)="loadCurrentStatus()"
      (deleteDocument)="deleteDocument($event)"
      (showMarginalAnalysis)="showMarginalAnalysis($event)"
      (viewCarrierClicks)="viewCarrierClicks()"
      [loadAuditLogs]="loadAuditLogs$ | async"
      (chatNow)="chatNow($event)"
      [shipper]="shipper$ | async"
      [showRateBreakDowns]="showRateBreakDowns$ | async"
    >
    </kbxl-load-detail>
  </p-sidebar>
</ng-container>
<div *ngIf="!displayInSidebar" class="page-container mobile-padding p-lg-4" style="max-width: 900px">
  <kbxl-load-detail
    [user]="userProfile$ | async"
    [load]="loadDetail$ | async"
    (book)="book($event)"
    [isShippingDetail]="isShippingDetail"
    [loadStatus]="loadStatus$ | async"
    [loadingStatus]="loadingStatus$ | async"
    [processing]="processing$ | async"
    (loadCurrentStatus)="loadCurrentStatus()"
    (deleteDocument)="deleteDocument($event)"
    [loadDetailErrors]="loadDetailErrors$ | async"
    (showMarginalAnalysis)="showMarginalAnalysis($event)"
    (chatNow)="chatNow($event)"
    [shipper]="shipper$ | async"
    [showRateBreakDowns]="showRateBreakDowns$ | async"
  >
  </kbxl-load-detail>
</div>

<p-dialog
  header="Marginal Analysis"
  [(visible)]="displayMarginalAnalysisDialog"
  [modal]="true"
  [closeOnEscape]="false"
  [baseZIndex]="2000"
  [closable]="false"
>
  <kbxl-marginal-analysis-form
    [userProfile]="userProfile$ | async"
    [load]="loadDetail$ | async"
    [loadDetailErrors]="loadDetailErrors$ | async"
    [processing]="processing$ | async"
    [(visible)]="displayMarginalAnalysisDialog"
    (book)="book($event)"
  >
  </kbxl-marginal-analysis-form>
</p-dialog>
