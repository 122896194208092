<div class="d-flex flex-column position-relative">
  <div class="progress-indicator" *ngIf="loading$ | async">
    <div class="progressspinner-container">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>

  <form [formGroup]="form" class="create-form-container">
    <div class="row">
      <div class="col-12">
        <p-panel header="Error Summary" *ngIf="errorSummary && errorCount <= 5" [toggler]="'header'" [toggleable]="true">
          <div class="error-container">{{ errorSummary }}</div>
        </p-panel>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div
          class="float-right"
          *ngIf="(favorites$ | async)?.length > 0 && this.orderEntry.isExternalLoad === false && !this.isBrokeredQuote"
        >
          <div class="kbxl-inputgroup-container mb-1 mt-2">
            <div id="saved-templates" class="p-inputgroup d-flex">
              <p-dropdown
                appendTo="body"
                placeholder="Saved Templates"
                [options]="favorites$ | async | toSelectItem"
                formControlName="customerFavoriteId"
                [autoDisplayFirst]="false"
                [filter]="true"
                [showClear]="true"
                [styleClass]="'p-dropdown-primary'"
                dropdownIcon="pi pi-caret-down"
                (onChange)="loadFavorite()"
                class="ml-auto"
              >
              </p-dropdown>
              <button
                *ngIf="this.form.controls['customerFavoriteId'].value"
                pButton
                type="button"
                icon="pi pi-trash"
                title="Delete Saved Template"
                class="p-button-danger"
                (click)="deleteFavorite()"
              ></button>
            </div>
          </div>
        </div>
        <p-panel [toggler]="'header'" [toggleable]="true" styleClass="">
          <p-header class="order-entry-panel-header"> Order Entry </p-header>
          <div class="row">
            <div class="col-12 col-lg-4 kbxl-inputgroup-container">
              <div class="kbxl-field-label" [ngClass]="{ error: form.controls['referenceLoadDisplay'].invalid }">
                <label for="referenceLoadDisplay" class="control-label">Order Number</label>
              </div>
              <input id="referenceLoadDisplay" formControlName="referenceLoadDisplay" type="text" pInputText maxlength="18" />
            </div>
            <div class="col-12 col-lg-4 kbxl-inputgroup-container">
              <div class="kbxl-field-label" [ngClass]="{ error: form.controls['commodity'].invalid }">
                <label for="commodity" class="control-label">Commodity</label>
                <div class="small-spinner-container ml-2">
                  <p-progressSpinner *ngIf="loadingCommodities$ | async"></p-progressSpinner>
                </div>
              </div>
              <p-dropdown
                [options]="commodities$ | async"
                placeholder="Select Commodity"
                formControlName="commodity"
                name="commodity"
                dataKey="commodityName"
                optionLabel="commodityName"
                [styleClass]="'p-dropdown-primary'"
                dropdownIcon="pi pi-caret-down"
                appendTo="body"
                kbxlCloseOnScroll
              >
                <ng-template let-item pTemplate="selectedItem">
                  <span class="selected-item-label">{{ form.get('commodity').value.commodityName }}</span>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="col-12 col-lg-4 kbxl-inputgroup-container">
              <div class="kbxl-field-label" [ngClass]="{ error: form.controls['equipment'].invalid }">
                <label for="equipment" class="control-label">Equipment</label>
                <div class="small-spinner-container ml-2">
                  <p-progressSpinner *ngIf="loadingEquipment$ | async"></p-progressSpinner>
                </div>
              </div>
              <kbxl-tree-drop-down
                [treeNodes]="equipment$ | async | toTreeNodes: true"
                [(selectedNode)]="selectedEquipment"
                [singleSelection]="true"
                placeHolder="Select Equipment"
                (selectedNodeChange)="equipmentSelectionMade($event)"
                [disable]="disableForLinieItemsOnly"
              ></kbxl-tree-drop-down>
            </div>
            <div class="col-12 col-lg-4 kbxl-inputgroup-container">
              <div class="kbxl-field-label d-flex align-items-center">
                <div>
                  <label [ngClass]="{ error: form.controls['services'].invalid }" for="services" class="control-label">Required Services</label>
                  <div class="small-spinner-container ml-2">
                    <p-progressSpinner *ngIf="loadingServiceTypes$ | async"></p-progressSpinner>
                  </div>
                </div>
                <fa-icon
                  *ngIf="requiredServicesAutoApplied && requiredServicesAutoApplied.length > 0"
                  [icon]="['fas', 'exclamation-triangle']"
                  class="ml-1 icon-style pointer"
                  pTooltip="{{ requiredServicesAutoApplied }}"
                ></fa-icon>
              </div>
              <p-multiSelect
                [options]="serviceTypes$ | async"
                placeholder="Select All Services"
                formControlName="services"
                name="services"
                dataKey="serviceTypeId"
                optionLabel="name"
                [styleClass]="'p-dropdown-primary'"
                dropdownIcon="pi pi-caret-down"
                appendTo="body"
                maxSelectedLabels="10"
                kbxlCloseOnScroll
                [overlayOptions]="defaultOverlayOptions"
              >
              </p-multiSelect>
            </div>

            <div class="col-12 col-lg-4 kbxl-inputgroup-container">
              <div class="kbxl-field-label" [ngClass]="{ error: form.controls['shipperPickupNumber'].invalid }">
                <label for="shipperPickupNumber" class="control-label">Shipper Pickup Number</label>
              </div>
              <input id="shipperPickupNumber" formControlName="shipperPickupNumber" type="text" pInputText maxlength="20" />
            </div>
            <div class="col-12 col-lg-4 kbxl-inputgroup-container">
              <div class="kbxl-field-label" [ngClass]="{ error: form.controls['transportationMode'].invalid }">
                <label for="transportationMode" class="control-label">Transportation Mode</label>
                <div class="small-spinner-container ml-2">
                  <p-progressSpinner *ngIf="loadingTransportationModes$ | async"></p-progressSpinner>
                </div>
              </div>
              <p-dropdown
                [options]="transportationModes$ | async"
                placeholder="Select Transportation Mode"
                formControlName="transportationMode"
                name="transportationMode"
                dataKey="name"
                optionLabel="name"
                [styleClass]="'p-dropdown-primary'"
                dropdownIcon="pi pi-caret-down"
                appendTo="body"
                kbxlCloseOnScroll
              >
                <ng-template let-item pTemplate="selectedItem">
                  <span class="selected-item-label">{{ form.get('transportationMode').value.name }}</span>
                </ng-template>
              </p-dropdown>
            </div>
          </div>

          <div class="d-flex justify-content-end panel-button">
            <button
              pButton
              type="button"
              class="p-button-teal"
              pButton
              icon="pi pi-plus"
              (click)="addOrderContact()"
              label="Add Contact"
              [disabled]="disableForLinieItemsOnly || enforceAddContactRestriction"
            ></button>
          </div>
          <p-panel [toggleable]="true" class="pb-2">
            <p-header class="order-entry-panel-header px-0 py-2">
              <span [ngClass]="{ error: orderContacts.invalid }">Contacts</span>
            </p-header>

            <div class="row" *ngFor="let contact of orderContacts.controls; let i = index">
              <div class="col p-0">
                <kbxl-contact-form [contact]="contact" [index]="i"></kbxl-contact-form>
              </div>
              <div class="col-1">
                <div class="kbxl-field-label">
                  <label for="" class="control-label">&nbsp;</label>
                </div>
                <button
                  *ngIf="i > 0"
                  class="p-button-danger"
                  pButton
                  icon="pi pi-trash"
                  (click)="deleteOrderContact(i)"
                  title="Delete Contact"
                  [disabled]="disableForLinieItemsOnly"
                ></button>
              </div>
            </div>
          </p-panel>
        </p-panel>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div *ngFor="let stop of loadStops.controls; let i = index">
          <kbxl-load-stop-form
            [form]="stop"
            [index]="i"
            [includeDelete]="loadStops.controls.length > 2 && i !== 0"
            [schedulerConfirmationTypes]="schedulerConfirmationTypes$ | async"
            [loadingSchedulerConfirmationTypes]="loadingSchedulerConfirmationTypes$ | async"
            [states]="states$ | async"
            [loadingStates]="loadingStates$ | async"
            [unitsOfMeasure]="unitsOfMeasure$ | async"
            [loadingUnitsOfMeasure]="loadingUnitsOfMeasure$ | async"
            [pickupStopNumbers]="pickupStopNumbers"
            (addLoadStop)="addStop(i)"
            (changeLoadStopType)="changeStopType(i)"
            (deleteLoadStop)="deleteStop(i)"
            [orderEntryEditStatus]="orderEntryEditStatus"
          >
          </kbxl-load-stop-form>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <p-panel [toggler]="'header'" [toggleable]="true" styleClass="">
          <p-header class="order-entry-panel-header">Special Instructions </p-header>
          <div class="py-2">
            <p-editor
              name="specialInstructions"
              [readonly]="disableForLinieItemsOnly"
              formControlName="specialInstructions"
              [style]="{ height: '100px' }"
            >
              <p-header class="order-entry-panel-header">
                <span class="ql-formats">
                  <button class="ql-bold" aria-label="Bold"></button>
                  <button class="ql-italic" aria-label="Italic"></button>
                  <button class="ql-underline" aria-label="Underline"></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-list" value="ordered" aria-label="Ordered List"></button>
                  <button class="ql-list" value="bullet" aria-label="Bulleted List"></button>
                </span>
              </p-header>
            </p-editor>
          </div>
        </p-panel>
      </div>
    </div>

    <p-dialog header="Save Template" [(visible)]="showSaveFavoriteDialog" [style]="{ width: '425px' }">
      <div class="row">
        <div class="col-12 kbxl-inputgroup-container">
          <div class="kbxl-field-label" [ngClass]="{ error: form.controls['customerFavoriteName'].invalid }">
            <label for="customerFavoriteName" class="control-label">Template Name</label>
          </div>
          <input id="customerFavoriteName" formControlName="customerFavoriteName" type="text" pInputText maxlength="50" />
        </div>
      </div>
      <p-footer>
        <div class="row p-1">
          <div class="col">
            <button
              pButton
              type="button"
              class="p-button-outlined p-button-secondary"
              label="Cancel"
              (click)="toggleFavoriteDialog(false)"
            ></button>
          </div>
          <div class="col">
            <button pButton type="button" label="Save" (click)="saveFavorite()"></button>
          </div>
        </div>
      </p-footer>
    </p-dialog>
  </form>

  <div class="row">
    <div class="col-12">
      <div class="create-footer-container d-flex justify-content-end py-3">
        <button pButton type="button" (click)="cancel()" class="p-button-outlined p-button-secondary cancel-button" label="Cancel"></button>
        <p-splitButton
          *ngIf="!editMode"
          (onClick)="save()"
          class="ml-3 mr-2"
          styleClass=""
          label="Create Load"
          [model]="saveItems"
        ></p-splitButton>
        <button pButton *ngIf="editMode" type="button" (click)="save()" class="ml-3 mr-2 p-button-primary" label="Save Changes"></button>
      </div>
    </div>
  </div>
</div>

<p-dialog
  [style]="{ width: '600px' }"
  [appendTo]="'body'"
  [baseZIndex]="51"
  header="Load Created"
  [(visible)]="showCreateLoadSuccessModal"
  [modal]="true"
  [closeOnEscape]="false"
>
  <kbxl-load-create-success-modal
    [loadId]="savedId"
    [loadRefId]="savedRefId"
    (goToPostTab)="goToPostTab($event)"
    (createAnotherLoad)="createAnotherLoad()"
    (copyLoad)="copyLoad($event)"
  >
  </kbxl-load-create-success-modal>
</p-dialog>
