import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { adminMenuReducer, AdminMenuState } from './admin-menu.reducer';
import { agreementDocumentReducer, AgreementDocumentState } from './agreement-document.reducer';
import { browserReducer, BrowserState } from './browser.reducer';
import { carrierReducer, CarrierState } from './carrier.reducer';
import { customerFavoriteReducer, CustomerFavoriteState } from './customer-favorite.reducer';
import { customerReducer, CustomerState } from './customer.reducer';
import { favoriteReducer, FavoriteState } from './favorite.reducer';
import { fuelRateReducer, FuelRateState } from './fuel-rate.reducer';
import { loadStatusReducer, LoadStatusState } from './load-status.reducer';
import { menuReducer, MenuState } from './menu.reducer';
import { shipperUserReducer, ShipperUserState } from './shipper-user.reducer';
import { smartSpotPriceReducer, SmartSpotPriceState } from './smart-spot-price.reducer';
import { startupReducer, StartupState } from './startup.reducer';

export interface CoreState {
  menu: MenuState;
  favorite: FavoriteState;
  customerFavorite: CustomerFavoriteState;
  browser: BrowserState;
  carriers: CarrierState;
  customer: CustomerState;
  adminMenu: AdminMenuState;
  smartSpotPrice: SmartSpotPriceState;
  loadStatus: LoadStatusState;
  agreementDocumentState: AgreementDocumentState;
  fuelRateState: FuelRateState;
  shipperUserState: ShipperUserState;
  startupState: StartupState;
}

export const reducers: ActionReducerMap<CoreState> = {
  menu: menuReducer,
  favorite: favoriteReducer,
  customerFavorite: customerFavoriteReducer,
  browser: browserReducer,
  carriers: carrierReducer,
  customer: customerReducer,
  adminMenu: adminMenuReducer,
  smartSpotPrice: smartSpotPriceReducer,
  loadStatus: loadStatusReducer,
  agreementDocumentState: agreementDocumentReducer,
  fuelRateState: fuelRateReducer,
  shipperUserState: shipperUserReducer,
  startupState: startupReducer,
};

export function logger(reducer: ActionReducer<CoreState>): ActionReducer<CoreState> {
  return function (state: CoreState, action: any): CoreState {
    // console.log('state', state);
    // console.log('action', action);
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<CoreState>[] = !environment.production ? [logger] : [];
