/**
 * HubMessage.Api
 * Simplified wrapper API for connecting with the hub service.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ConversationMember } from './conversationMember';
import { ConversationStatus } from './conversationStatus';
import { Message } from './message';

export interface Conversation { 
    id?: number;
    userId?: string;
    status?: ConversationStatus;
    applicationId?: number;
    applicationCode?: string;
    messages?: Array<Message>;
    attributes?: { [key: string]: Array<string>; };
    lastChangeDate?: Date;
    members?: Array<ConversationMember>;
}