<div class="wrapper">
  <div class="progress-indicator" *ngIf="loading">
    <div class="progressspinner-container">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
  <div class="d-flex flex-column">
    <div>
      <div class="kbxl-inputgroup-container">
        <div class="kbxl-field-label">
          <label for="loadCarrierScacs" class="control-label"> Carrier / SCAC </label>
        </div>
        <div>
          <p-dropdown
            [options]="scacs"
            [(ngModel)]="selectedScac"
            placeholder="Select a Carrier/ SCAC"
            [filter]="true"
            filterBy="scac"
            [group]="true"
            (onChange)="loadCarrierUsers()"
          >
            <ng-template let-group pTemplate="group">
              <div class="d-flex align-items-center">
                <span>{{ group.label }} ({{ group.items.length }})</span>
              </div>
            </ng-template>
            <ng-template let-scac pTemplate="item">
              <div class="container">
                <div class="row">
                  <span class="col-2">{{ scac.label }}</span>
                  <span class="col-3 text-center text-muted">{{ scac.value.contractRate | currency }}</span>
                  <span class="col-1 p-0 text-muted"> <fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon></span>
                  <span class="col-2 pl-1 text-muted">{{ scac.value.fuelRate | currency }}</span>
                  <span class="col-1 p-0 text-muted"> <fa-icon [icon]="['fas', 'equals']" size="sm"></fa-icon></span>
                  <span class="font-weight-bold text-center col-3 pl-1">
                    {{ scac.value.totalRate | currency }}
                  </span>
                </div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
      <div>
        <div class="d-flex flex-column mt-3" *ngIf="selectedScac && carrierUsers && carrierUsers.length > 0">
          <div class="kbxl-field-label">
            <label class="control-label"> Carrier User Contact </label>
          </div>
          <div>
            <p-dropdown
              [options]="carrierUsers"
              [(ngModel)]="selectedCarrierUser"
              optionLabel="fullName"
              placeholder="Select User"
              dropdownIcon="pi pi-caret-down"
              [styleClass]="'p-dropdown-primary'"
            >
              <ng-template let-user pTemplate="item">
                <div class="d-flex">
                  <span>{{ user.value.fullName | titlecase }}</span>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
          <div class="d-flex mt-3" *ngIf="selectedCarrierUser">
            <div class="d-flex flex-column">
              <div class="kbxl-field-label">
                <label class="control-label"> Name: </label>
              </div>
              <div>{{ selectedCarrierUser.fullName | titlecase }}</div>
            </div>
            <div class="d-flex flex-column mx-3">
              <div class="kbxl-field-label">
                <label class="control-label"> Email: </label>
              </div>
              <div>
                <a class="text-truncate" href="mailto: {{ selectedCarrierUser.email }}" [title]="selectedCarrierUser.email"
                  >{{ selectedCarrierUser.email | lowercase }}
                </a>
              </div>
            </div>
            <div class="d-flex flex-column">
              <div class="kbxl-field-label">
                <label class="control-label"> Phone: </label>
              </div>
              <div *ngFor="let phoneNumber of selectedCarrierUser.phoneNumbers">
                <a href="tel:{{ phoneNumber }}">{{ phoneNumber }}</a>
              </div>
              <div *ngFor="let cellPhoneNumber of selectedCarrierUser.cellPhoneNumbers">
                <span>Cell</span><a href="tel:{{ cellPhoneNumber }}">{{ cellPhoneNumber }}</a>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between mt-4 mb-2">
          <button pButton type="button" class="p-button-outlined p-button-secondary" label="Cancel" (click)="close()"></button>
          <button
            pButton
            type="button"
            class="ml-3"
            label="Counter Offer to Carrier"
            [disabled]="!selectedCarrierUser"
            (click)="selectCarrier()"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>
