import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LoginResponse, OidcSecurityService, UserDataResult } from 'angular-auth-oidc-client';
import { distinctUntilChanged, lastValueFrom, Subject, takeUntil } from 'rxjs';
import { ENVIRONMENTSETTINGS } from '../../../core/app-tokens/environment-setting';
import { Environment } from '../../../core/models/environment';
import { AuthUser } from '../../models/auth-user';
import { AuthLoginAction, AuthLoginSuccessAction } from '../../store/actions';
import { AuthState } from '../../store/reducers/auth.reducer';

@Component({
  selector: 'kbxl-autologin',
  template: '<div></div>',
})
export class AutoLoginComponent implements OnInit, OnDestroy {
  private _unsubscribe$ = new Subject<void>();
  private alreadyLoggedIn: boolean;

  constructor(
    private securityService: OidcSecurityService,
    private router: Router,
    private store: Store<AuthState>,
    @Inject(ENVIRONMENTSETTINGS) private environmentSettings: Environment
  ) {}

  ngOnInit(): void {
    this.setRedirectToCurrentPage();
    this.securityService
      .checkAuth()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((loginResponse: LoginResponse) => {
        if (loginResponse.isAuthenticated) {
          this.onAuthorizationResultComplete(loginResponse.isAuthenticated);
        } else {
          // https://angular-auth-oidc-client.com/docs/documentation/login-logout
          this.securityService.authorize();
          this.store.dispatch(new AuthLoginAction());
        }
      });

    this.securityService.userData$.pipe(distinctUntilChanged(), takeUntil(this._unsubscribe$)).subscribe((userResult: UserDataResult) => {
      const userData = userResult.userData;
      if (userData) {
        const authUser = {
          email: userData.email,
          name: userData.name,
          family_name: userData.family_name,
          given_name: userData.given_name,
          preferred_username: userData.preferred_username,
          sub: userData.sub,
          Company: userData.Company,
        } as AuthUser;
        this.store.dispatch(new AuthLoginSuccessAction({ ...authUser }));
      }
    });
  }

  private onAuthorizationResultComplete(isAuthenticated: boolean) {
    if (isAuthenticated) {
      if (!this.alreadyLoggedIn) {
        this.alreadyLoggedIn = true;
        this.navigateToStoredEndpoint();
      }
    } else {
      this.router.navigate(['/unauthorized'], { skipLocationChange: true });
    }
  }

  private navigateToStoredEndpoint() {
    const url = this.read('tms-redirect');
    this.delete('tms-redirect');

    if (url === null || url === undefined || this.router.url === url) {
      this.router.navigate(['/']);
      return;
    }

    if (url.toString().toLowerCase().includes('/unauthorized') || url.toString().toLowerCase().includes('/signout-callback-oidc')) {
      this.router.navigate(['/']);
    } else {
      this.router.navigateByUrl(url);
    }
  }

  private async setRedirectToCurrentPage() {
    const redirectedUrl = (await lastValueFrom(this.securityService.getConfiguration('loadshop-identity-server'))).redirectUrl; // The URL that we are sent to after authentication

    if (
      !window.location.pathname.toLowerCase().includes('autologin') &&
      !redirectedUrl.toLowerCase().includes(window.location.pathname.toLowerCase())
    ) {
      this.write('tms-redirect', window.location.pathname + window.location.search + window.location.hash);
    }
  }

  // tslint:disable-next-line: no-any
  private read(key: string): any {
    const data = sessionStorage.getItem(key);
    if (data != null) {
      return JSON.parse(data);
    }
    return;
  }

  // tslint:disable-next-line: no-any
  private write(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
  // tslint:disable-next-line: no-any
  private delete(key: string) {
    sessionStorage.removeItem(key);
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
