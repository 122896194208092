import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared/shared.module';
import {
  UserFocusEntityChangedComponent,
  UserFocusEntitySelectorComponent,
  UserFocusEntitySelectorContainerComponent,
  UserFocusEntitySelectorModalComponent,
  UserImpersonationIndicatorComponent,
  UserImpersonationModalComponent,
  UserLaneDetailComponent,
  UserLoadNotificationsComponent,
  UserProfileComponent,
  UserProfileContainerComponent,
  UserProfileMenuComponent,
  UserSavedLanesComponent,
  EntityInactiveIndicatorComponent,
} from './components';
import { UserLaneDetailContainerComponent } from './components/user-lane-detail-container';
import { UserComponent } from './components/user.component';
import { UserLanesService, UserProfileService } from './services';
import { StateService } from './services/state.service';
import { effects, reducers } from './store';
import { CustomerRoutingModule } from './user.routing.module';

@NgModule({
    imports: [CustomerRoutingModule, SharedModule, EffectsModule.forFeature(effects), StoreModule.forFeature('user', reducers)],
    declarations: [
        UserComponent,
        UserProfileComponent,
        UserProfileContainerComponent,
        UserSavedLanesComponent,
        UserLaneDetailComponent,
        UserLaneDetailContainerComponent,
        UserFocusEntitySelectorComponent,
        UserFocusEntitySelectorContainerComponent,
        UserFocusEntityChangedComponent,
        UserLoadNotificationsComponent,
        UserProfileMenuComponent,
        UserImpersonationModalComponent,
        UserImpersonationIndicatorComponent,
        UserFocusEntitySelectorModalComponent,
        EntityInactiveIndicatorComponent,
    ],
    exports: [
        UserFocusEntitySelectorContainerComponent,
        UserFocusEntityChangedComponent,
        UserProfileMenuComponent,
        UserImpersonationIndicatorComponent,
        EntityInactiveIndicatorComponent,
    ],
    providers: [UserProfileService, UserLanesService, StateService]
})
export class UserModule {}
