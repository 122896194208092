<div class="d-flex flex-column">
  <div class="loading-indicator" *ngIf="loading">
    <p-progressSpinner></p-progressSpinner>
  </div>
    <div #scrollContainer id="scrollContainer">     
        <kbxl-notes-list
        [notes]="notes"
    >
    </kbxl-notes-list>
    </div>   
    <div class="d-flex mt-2" kbxlActionCheck="loadshop.ui.notes.addedit">
        <textarea
          pInputTextarea
          placeholder="Type a new note"
          rows="1"
          autoResize="true"
          name="newnote"
          class="addnote-input"
          maxlength="4000"  
          [(ngModel)]="note"       
        ></textarea>
        <button
          pButton
          (click)="addNoteClick()"
          icon="pi pi-arrow-right"
          type="button"
          class="p-button-teal ml-2 add-notes-button"
        ></button>
      </div>
</div>

