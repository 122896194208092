import { Contact, LoadDocumentMetadata, UserContactData } from '.';
import { LoadStop } from './load-stop';

export interface LoadView {
  commodity: string;
  customerId: string;
  customerName: string;
  destLat: number;
  destLng: number;
  destCity: string;
  destState: string;
  destCountry: string;
  destEarlyDtTm: Date;
  destLateDtTm: Date;
  distanceFrom: number;
  distanceFromOrig: number;
  distanceFromDest: number;
  equipmentId: string;
  equipmentType: string;
  equipmentCategoryId: string;
  equipmentCategoryDesc: string;
  equipmentTypeDisplay: string;
  fuelRate: number;
  isHazMat: boolean;
  lineHaulRate: number;
  loadId: string;
  miles: number;
  originLat: number;
  originLng: number;
  originCity: string;
  originState: string;
  originCountry: string;
  originEarlyDtTm: Date;
  originLateDtTm: Date;
  referenceLoadId: string;
  referenceLoadDisplay: string;
  stops: number;
  transactionTypeId: string;
  userLaneId: string;
  weight: number;
  scac: string;
  bookedUser: UserContactData;
  originDisplay: string;
  destinationDisplay: string;
  totalRateDisplay: number;
  billingLoadId: string;
  billingLoadDisplay: string;
  visibilityPhoneNumber: string;
  visibilityTruckNumber: string;
  mobileExternallyEntered: boolean;
  visibilityChgDtTm: Date;
  showVisibilityWarning: boolean;
  isEstimatedFSC: boolean;
  customerLoadTypeId: number;
  platformPlusLoadId: string;
  isPlatformPlus: boolean;
  serviceTypeIds: number[];
  hasPod: boolean;
  requiresPod: boolean;
  podDocuments: LoadDocumentMetadata[];
  usesAllInRates: boolean;
  loadStops: LoadStop[];
  isBrokeredLoad: boolean;
  contacts: Contact[];

  // client side properties
  isFavorite: boolean;
}
