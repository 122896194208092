/**
 * HubMessage.Api
 * Simplified wrapper API for connecting with the hub service.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ConversationQueryOptions } from './conversationQueryOptions';
import { ConversationStatus } from './conversationStatus';

export interface ConversationQuery { 
    userId?: string;
    filterByUserId?: boolean;
    conversationId?: number;
    conversationIds?: Array<number>;
    statuses?: Array<ConversationStatus>;
    applicationId?: number;
    attributes?: { [key: string]: Array<string>; };
    attributesAsString?: string;
    startDate?: Date;
    endDate?: Date;
    options?: ConversationQueryOptions;
}