import { concatLatestFrom } from '@ngrx/operators';import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ShippingLoadSearchTypes } from 'src/app/shared/models/shipping-load-search-types';
import { ShippingService } from '../../services';
import {
  ShippingBookedActionTypes,
  ShippingBookedLoadAction,
  ShippingBookedLoadFailureAction,
  ShippingBookedLoadSuccessAction,
} from '../actions';
import {
  LoadDetailCarrierRemovedActionTypes,
  LoadDetailCarrierRemovedSuccessAction,
  LoadDetailDeleteLoadAction,
  LoadDetailDeleteLoadActionTypes,
} from 'src/app/shared/store';
import { PageableQueryHelper } from 'src/app/shared/utilities';
import { mapToPayload } from 'src/app/shared/utilities/map-to-payload';
import { ShippingState } from '../reducers';
import { getShippingBookedQueryHelper } from '../selectors';

@Injectable()
export class ShippingBookedEffects {
  $loadBooked = createEffect(() =>
    { return this.actions$.pipe(
      ofType<ShippingBookedLoadAction>(ShippingBookedActionTypes.Load_Shipping_Booked),
      mapToPayload<{ searchType: string; queryHelper: PageableQueryHelper }>(),
      switchMap((payload) =>
        this.shippingService.getPageableLoadsBySearchType(ShippingLoadSearchTypes.Booked, payload.queryHelper).pipe(
          map((data) => new ShippingBookedLoadSuccessAction(data)),
          catchError((err) => of(new ShippingBookedLoadFailureAction(err)))
        )
      )
    ) }
  );

  $carrierRemoved = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LoadDetailCarrierRemovedSuccessAction>(LoadDetailCarrierRemovedActionTypes.CarrierRemoved_Success),
      concatLatestFrom(() => this.store$.pipe(select(getShippingBookedQueryHelper))),
      switchMap(([action, currentQuery]) =>
        this.shippingService.getPageableLoadsBySearchType(ShippingLoadSearchTypes.Booked, currentQuery).pipe(
          map((data) => new ShippingBookedLoadSuccessAction(data)),
          catchError((err) => of(new ShippingBookedLoadFailureAction(err)))
        )
      )
    ) }
  );

  $loadDeleted = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LoadDetailDeleteLoadAction>(LoadDetailDeleteLoadActionTypes.DeleteLoad_Success),
      concatLatestFrom(() => this.store$.pipe(select(getShippingBookedQueryHelper))),
      switchMap(([action, currentQuery]) =>
        this.shippingService.getPageableLoadsBySearchType(ShippingLoadSearchTypes.Booked, currentQuery).pipe(
          map((data) => new ShippingBookedLoadSuccessAction(data)),
          catchError((err) => of(new ShippingBookedLoadFailureAction(err)))
        )
      )
    ) }
  );

  constructor(private actions$: Actions, private shippingService: ShippingService, private store$: Store<ShippingState>) {}
}
