import { Injectable } from '@angular/core';

import { AuthenticatedResult, OidcSecurityService } from 'angular-auth-oidc-client';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(private securityService: OidcSecurityService, private cookieService: CookieService) {}

  canActivate(): Observable<boolean> | boolean {
    return this.securityService.isAuthenticated$.pipe(
      map((authenticatedResult: AuthenticatedResult) => {
        return authenticatedResult.isAuthenticated;
      })
    );
  }

  canActivateChild(): Observable<boolean> | boolean {
    return this.canActivate();
  }
}
