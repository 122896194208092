import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CommonService } from '../../services/common.service';
import { ShipperUserActionTypes, ShipperUserLoadSuccessAction, ShipperUserLoadAction } from '../actions';

@Injectable()
export class ShipperUserEffects {
  $load = createEffect(() =>
    { return this.actions$.pipe(
      ofType(ShipperUserActionTypes.Load),
      switchMap((action: ShipperUserLoadAction) =>
        this.commonService.getShipperUsers(action.payload).pipe(
          map((data) => new ShipperUserLoadSuccessAction(data)),
          catchError((err) => of(new ShipperUserLoadSuccessAction(err)))
        )
      )
    ) }
  );

  constructor(private commonService: CommonService, private actions$: Actions) {}
}
