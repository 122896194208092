import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthLoginAction } from '../../store';
import { AuthState } from '../../store/reducers/auth.reducer';

@Component({
  template: '<div>Redirecting to login...</div>',
})
export class LoginComponent implements OnInit, OnDestroy {
  private _unsubscribe$ = new Subject<void>();

  constructor(private securityService: OidcSecurityService, private store: Store<AuthState>) {}

  ngOnInit() {
    this.securityService
      .checkAuth()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((loginResponse: LoginResponse) => {
        if (!loginResponse.isAuthenticated) {
          this.securityService.authorize();
          this.store.dispatch(new AuthLoginAction());
        }
      });
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
