import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { MenuItem, MessageService, OverlayOptions, SelectItem, TreeNode } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { takeUntil } from 'rxjs/operators';
import { SecurityAppActionType } from 'src/app/shared/models/security-app-action-type';
import { CoreState, FavoriteUpdateAction } from '../../../core/store';
import { BaseComponent } from '../../../shared/components';
import {
  AllUserFavoriteTypes,
  Customer,
  MarketplaceSavedSearchFavorite,
  Place,
  Search,
  SearchTypeData,
  ServiceType,
  State,
  UserFavorite,
  UserLane,
  UserModel,
  defaultLane,
  defaultSearch,
} from '../../../shared/models';
import { formatDate, groupBy, validateUserlane } from '../../../shared/utilities';
import { SearchCriteriaService } from '../../services';
import { AddUserSearchModalComponent } from '../user-search';
import { ManageUserSearchModalComponent } from '../user-search/manage-user-search-modal';

@Component({
  selector: 'kbxl-top-search-criteria',
  templateUrl: './top-search-criteria.component.html',
  styleUrls: ['./top-search-criteria.component.scss'],
  providers: [DialogService],
})
export class TopSearchCriteriaComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() equipment: TreeNode[];
  @Input() criteria: Search;
  @Input() lanes: UserLane[];
  @Input() savedSearches: UserFavorite[];
  @Input() states: State[];
  @Input() isMobile: boolean;
  @Input() isMarketplace: boolean;
  @Input() serviceTypes: ServiceType[];
  @Input() user: UserModel;
  @Input() shippers: Customer[];
  @Input() showExport = false;
  @Output() search: EventEmitter<Search> = new EventEmitter<Search>();
  @Output() add: EventEmitter<UserLane> = new EventEmitter<UserLane>();
  @Output() update: EventEmitter<UserLane> = new EventEmitter<UserLane>();
  @Output() clear: EventEmitter<string> = new EventEmitter<string>();
  @Output() refreshLoads: EventEmitter<Search> = new EventEmitter<Search>();
  @Output() export: EventEmitter<boolean> = new EventEmitter<boolean>();

  defaultOverlayOptions: OverlayOptions = {
    hideTransitionOptions: '0ms',
    showTransitionOptions: '0ms',
  };
  saveActionItems: MenuItem[];

  crit: Search = { ...defaultSearch };
  origDate: Date[];
  destDate: Date[];
  selectedLane: UserLane;
  selectedLaneId: string;
  selectLanes: SelectItem[];
  savedSearchesSelectItems: SelectItem[];
  origin: Place;
  dest: Place;
  userLaneId: string;
  selectedUserSearchId: string;
  collapsed = true;
  selectedEquipment: TreeNode[];
  selectedShippers: Customer[];
  selectedServiceTypes: ServiceType[];
  hasShipperViewAppAction = false;
  showManageSearch = false;
  selectedUserFavorite: UserFavorite;
  saveSearchEnabled = false;

  defaultSearchRan = false;

  constructor(
    private coreStore: Store<CoreState>,
    private messageService: MessageService,
    private searchCriteriaService: SearchCriteriaService,
    private dialogService: DialogService
  ) {
    super();
  }
  ngOnInit(): void {
    this.showManageSearch = this.user.hasSecurityAction(SecurityAppActionType.SearchManage);

    if (this.showManageSearch) {
      this.buildManageSearchMenu();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lanes && this.lanes) {
      this.selectLanes = this.lanes.map((x) => ({ label: this.createLaneLabel(x), value: x.userLaneId }));
    }
    if (this.savedSearches && this.savedSearches.length > 0) {
      this.savedSearchesSelectItems = this.savedSearches.map((x) => ({ label: x.data.name, value: x }));

      if (this.selectedUserFavorite) {
        // update the favorite
        this.selectedUserFavorite = this.savedSearches.find((x) => x.userFavoriteId === this.selectedUserFavorite.userFavoriteId);
      }

      const currentDefaultSearch = this.savedSearches.find((x) => x.data.defaultFavorite);

      if (!currentDefaultSearch) {
        // in case the user saves a new default search, don't rerun the search
        this.defaultSearchRan = true;
      }

      const canExecuteSearch = this.equipment && this.serviceTypes && this.states && this.shippers;

      if (currentDefaultSearch && canExecuteSearch && !this.defaultSearchRan) {
        // only run the default search once
        this.defaultSearchRan = true;
        this.selectedUserFavorite = currentDefaultSearch;
        this.populateSearchFields(currentDefaultSearch.data);
      }
    }

    if (changes.criteria) {
      const crit = this.criteria || { ...defaultSearch };
      this.crit = { ...crit };

      this.updateSelectedEquipment();
      this.setDates(this.crit);
    }

    if (changes.user && this.user) {
      this.hasShipperViewAppAction = this.user.hasSecurityAction(SecurityAppActionType.CarrierCanViewShipper);
    }
  }
  selectSavedSearch(savedSearch: UserFavorite): void {
    this.selectedUserFavorite = savedSearch;

    // deselect any favorite lanes
    this.userLaneId = null;
    this.selectedLane = null;

    this.populateSearchFields(savedSearch.data);
  }
  setDates(model: Search) {
    if (!model) {
      this.origDate = null;
      this.destDate = null;
      return;
    }
    if (model.origDateStart) {
      if (model.origDateEnd) {
        this.origDate = [moment(model.origDateStart).toDate(), moment(model.origDateEnd).toDate()];
      } else {
        this.origDate = [moment(model.origDateStart).toDate()];
      }
    } else {
      this.origDate = null;
    }

    if (model.destDateStart) {
      if (model.destDateEnd) {
        this.destDate = [moment(model.destDateStart).toDate(), moment(model.destDateEnd).toDate()];
      } else {
        this.destDate = [moment(model.destDateStart).toDate()];
      }
    } else {
      this.destDate = null;
    }
  }

  setOriginDestination(model: UserLane | Search) {
    const originDest = this.searchCriteriaService.setOriginDestination(model, this.states);
    this.origin = originDest[0];
    this.dest = originDest[1];
  }

  runSearch(applying = false) {
    // skip the search if we are in mobile mode and we are not applying from a button
    if ((this.isMobile && !applying) || !this.isMarketplace) {
      return;
    }

    this.setSearchData().then((search) => {
      this.search.emit(search);
      this.collapsed = true;
    });
  }

  runRefreshLoads(force: boolean = false) {
    if (!force && (this.isMobile || !this.isMarketplace)) {
      return;
    }

    this.setSearchData().then((search) => {
      this.refreshLoads.emit(search);
      this.collapsed = true;
    });
  }

  searchClick() {
    // check if search can be saved

    this.setSearchData().then((search) => {
      this.search.emit(search);
      this.collapsed = true;
    });
  }

  setSearchData(): Promise<Search> {
    // trim the quick search in case the user pastes or adds space
    if (this.crit && this.crit.quickSearch) {
      this.crit.quickSearch = this.crit.quickSearch.trim();
    }

    const origDate = this.origDate || [];
    const destDate = this.destDate || [];
    const searchObj: Search = {
      origCity: null,
      origCountry: null,
      origState: null,
      origLat: null,
      origLng: null,
      destCity: null,
      destCountry: null,
      destState: null,
      destLat: null,
      destLng: null,
      origDateStart: formatDate(origDate[0]),
      origDateEnd: formatDate(origDate[1]),
      destDateStart: formatDate(destDate[0]),
      destDateEnd: formatDate(destDate[1]),
      equipmentType: this.searchCriteriaService.buildSelectedEquipmentAsString(this.selectedEquipment),
      quickSearch: this.crit.quickSearch,
      showAllLoads: this.crit.showAllLoads,
      shipperIds: this.selectedShippers ? this.selectedShippers.map((_) => _.customerId) : [],
    };

    if (this.selectedServiceTypes) {
      searchObj.serviceTypes = this.selectedServiceTypes.map((x) => x.serviceTypeId);
    }
    return this.searchCriteriaService
      .setOriginDestinationOnSearch(searchObj, this.origin, this.dest, this.crit.origDH, this.crit.destDH, this.states)
      .then((search) => {
        // check if anything was selected
        if (
          search.destDH === 50 &&
          !search.destDateEnd &&
          !search.destDateStart &&
          !search.equipmentType &&
          search.origDH === 50 &&
          !search.origDateStart &&
          !search.origDateEnd &&
          !search.quickSearch &&
          search.shipperIds.length === 0 &&
          !search.showAllLoads
        ) {
          this.saveSearchEnabled = false;
        }
        this.saveSearchEnabled = true;

        return search;
      });
  }

  loadFavorite(laneId: string) {
    // deselect any saved filter
    this.selectedUserFavorite = null;

    this.crit = { ...defaultSearch };
    this.userLaneId = laneId;
    this.selectedLane = this.lanes.find((x) => x.userLaneId === laneId);
    if (this.selectedLane) {
      this.origin = null;
      this.dest = null;
      this.setOriginDestination(this.selectedLane);
      this.crit.origDH = this.selectedLane.origDH;
      this.crit.destDH = this.selectedLane.destDH;
      this.crit.equipmentType = JSON.stringify(this.selectedLane.equipmentIds);
      this.setDates(null);
      // Set Selected Equipment Tree Nodes
      this.updateSelectedEquipment();
    }
    this.runSearch(true);
  }

  populateSearchFields(search: Search): void {
    this.crit = this.deepClone(search);
    this.origin = null;
    this.dest = null;
    this.setOriginDestination(search);
    this.crit.origDH = search.origDH;
    this.crit.destDH = search.destDH;
    this.crit.equipmentType = search.equipmentType;

    if (search.serviceTypes) {
      const selectedServiceTypes = this.serviceTypes.filter((x) => search.serviceTypes.indexOf(x.serviceTypeId) > -1);
      this.selectedServiceTypes = selectedServiceTypes;
    }

    if (search.shipperIds) {
      const selectedShippers = this.shippers.filter((x) => search.shipperIds.indexOf(x.customerId) > -1);
      this.selectedShippers = selectedShippers;
    }

    this.setDates(search);

    // Set Selected Equipment Tree Nodes
    this.updateSelectedEquipment();

    this.runSearch(true);
    this.buildManageSearchMenu();
  }

  clearClick($event) {
    let forceResearchOnLoads = false;
    if (this.crit.showAllLoads) {
      forceResearchOnLoads = true;
    }

    this.selectedUserFavorite = null;
    this.userLaneId = null;
    this.selectedLaneId = null;

    this.destDate = null;
    this.origDate = null;
    this.selectedServiceTypes = null;
    this.selectedShippers = null;
    this.crit.quickSearch = null;
    this.crit.showAllLoads = false;
    this.origin = null;
    this.dest = null;
    this.collapsed = true;
    $event.stopPropagation();
    this.buildManageSearchMenu();
    if (forceResearchOnLoads) {
      // force to get a new dataset from the server
      this.runRefreshLoads(true);
    } else {
      // search can use data we have locally
      this.clear.emit();
    }
  }

  clearFilter(prop: any) {
    this[prop] = null;
    if (prop === 'quickSearch') {
      this.crit.quickSearch = '';
      this.searchClick();
    } else {
      this.runSearch();
    }
  }

  saveFavoriteClick() {
    this.setSearchData().then((search) => {
      const lane: UserLane = Object.assign({}, defaultLane, {
        equipmentIds: this.searchCriteriaService.buildSelectedEquipment(this.selectedEquipment),
        origLat: search.origLat,
        origLng: search.origLng,
        origDH: search.origDH,
        origState: search.origState,
        destLat: search.destLat,
        destLng: search.destLng,
        destDH: search.destDH,
        destState: search.destState,
        searchType: SearchTypeData.OriginDest,
      });
      if (this.origin) {
        lane.origCity = this.origin.city;
        lane.origCountry = this.origin.country;
      }
      if (this.dest) {
        lane.destCity = this.dest.city;
        lane.destCountry = this.dest.country;
      }
      const errors = validateUserlane(lane);
      if (errors.length) {
        this.messageService.addAll(errors);
      } else {
        if (this.userLaneId) {
          lane.userLaneId = this.userLaneId;
          this.update.emit(lane);
        } else {
          this.add.emit(lane);
        }
      }
    });
  }

  applyClick($event: Event) {
    this.runSearch(true);
    $event.stopPropagation();
  }

  exportData() {
    this.export.emit();
  }

  saveSearchClick(saveNewSearch = false): void {
    if (!this.saveSearchEnabled) {
      this.messageService.add({ id: 0, detail: 'No search criteria selected', severity: 'error' });
      return;
    }

    this.setSearchData().then((search) => {
      const favorite = this.buildFavorite(search, saveNewSearch);
      // check if favorite is unique, otherwise throw
      if (!this.hasUniqueSearchCriteria(favorite)) {
        this.messageService.add({ id: 0, detail: 'Search criteria is similar to other saved searches', severity: 'error' });
        return;
      }
      if (this.selectedUserFavorite && !saveNewSearch) {
        // update
        this.coreStore.dispatch(new FavoriteUpdateAction(favorite));
      } else {
        // save new
        const ref = this.dialogService.open(AddUserSearchModalComponent, {
          header: `Save Search`,
          data: {
            favorite: favorite,
          },
        });

        ref.onClose.pipe(takeUntil(this.destroyed$)).subscribe((closeParams) => {
          if (closeParams && closeParams.continueSearch) {
            if (closeParams.userFavoriteId) {
              this.selectedUserFavorite = this.savedSearches.find((x) => x.userFavoriteId === closeParams.userFavoriteId);
              // we haven't gotten the update yet so stub the obj and let the ngChanges take care of it
              if (!this.selectedUserFavorite) {
                this.selectedUserFavorite = {
                  ...this.selectedUserFavorite,
                  userFavoriteId: closeParams.userFavoriteId,
                };
              }
            }
            this.searchClick();
          }
        });
      }
    });
  }
  manageSavedSearches(): void {
    this.dialogService.open(ManageUserSearchModalComponent, {
      header: `Manage Saved Searches`,
    });
  }

  private hasUniqueSearchCriteria(favorite: UserFavorite): boolean {
    let isDifferent = true;
    // check if non blank
    if (this.savedSearches && this.savedSearches.length > 0) {
      this.savedSearches.forEach((x) => {
        const s = this.deepClone(x.data) as MarketplaceSavedSearchFavorite;
        const n = favorite.data as MarketplaceSavedSearchFavorite;
        if (
          s.quickSearch === n.quickSearch &&
          s.origCity === n.origCity &&
          s.origCountry === n.origCountry &&
          s.origState === n.origState &&
          s.origDH === n.origDH &&
          s.origLat === n.origLat &&
          s.origLng === n.origLng &&
          s.destCity === n.destCity &&
          s.destCountry === n.destCountry &&
          s.destState === n.destState &&
          s.destDH === n.destDH &&
          s.destLat === n.destLat &&
          s.destLng === n.destLng &&
          s.showAllLoads === n.showAllLoads &&
          ((!s.serviceTypes && !n.serviceTypes) ||
            (s.serviceTypes && n.serviceTypes && s.serviceTypes.sort().join(',') === n.serviceTypes.sort().join(','))) &&
          ((!s.equipmentType && !n.equipmentType) ||
            (s.equipmentType && n.equipmentType && s.equipmentType.sort().join(',') === n.equipmentType.sort().join(','))) &&
          ((!s.shipperIds && !n.shipperIds) ||
            (s.shipperIds && n.shipperIds && s.shipperIds?.sort().join(',') === n.shipperIds?.sort().join(',')))
        ) {
          isDifferent = false;
          return;
        }
      });
    }
    return isDifferent;
  }

  private updateSelectedEquipment() {
    if (this.crit && this.crit.equipmentType && this.equipment) {
      const flattenTreeNodes = this.equipment.map((_) => _.children).reduce((acc, value) => acc.concat(value));
      const equipmentTypes = JSON.parse(this.crit.equipmentType) as string[];
      const equipmentTreeNodes = equipmentTypes.map((currentEquipmentId) => {
        const equipment = flattenTreeNodes.find((_) => _.data.equipmentId === currentEquipmentId);

        if (equipment && equipment.parent) {
          equipment.parent.partialSelected = true;
        }

        return equipment;
      });

      const groupedSelections = groupBy((x) => x.data.categoryId, equipmentTreeNodes);

      groupedSelections.forEach((group) => {
        const treeViewGroup = this.equipment.find((x) => x.key === group.key);

        if (group.items.length === treeViewGroup.children.length) {
          treeViewGroup.partialSelected = false;
          equipmentTreeNodes.push(treeViewGroup);
        }
      });

      this.selectedEquipment = equipmentTreeNodes;
    } else {
      this.selectedEquipment = [];
    }
  }

  private createLaneLabel(lane: UserLane): string {
    let orig = '<div>';
    if (lane.origCity) {
      orig += `<span>${lane.origCity}, ${lane.origState}`;
      if (lane.origDH) {
        orig += ` (${lane.origDH} mi)`;
      }
      orig += '</span>';
    }
    if (!lane.origCity && lane.origState) {
      orig = `<span>${lane.origState}</span>`;
    }
    if (!lane.origCity && !lane.origState) {
      orig += '<span>Anywhere</span>';
    }
    orig += '</div>';

    let dest = '<div>';
    if (lane.destCity) {
      dest += `<span>${lane.destCity}, ${lane.destState}`;
      if (lane.origDH) {
        dest += ` (${lane.destDH} mi)`;
      }
      dest += '</span>';
    }
    if (!lane.destCity && lane.destState) {
      dest += `<span>${lane.destState}</span>`;
    }
    if (!lane.destCity && !lane.destState) {
      dest += '<span>Anywhere</span>';
    }
    dest += '</div>';
    return orig + '<div>&darr;</div>' + dest;
  }

  private buildManageSearchMenu(): void {
    this.saveActionItems = [];
    if (this.selectedUserFavorite && this.selectedUserFavorite.userFavoriteId > 0) {
      this.saveActionItems.push({ label: 'Save New Filter', icon: 'fas fa-search-plus', command: () => this.saveSearchClick(true) });
      this.saveActionItems.push({ separator: true });
    }
    this.saveActionItems.push({ label: 'Manage Saved Searches', icon: 'pi pi-cog', command: () => this.manageSavedSearches() });
  }

  private buildFavorite(search: Search, newSearch: boolean): UserFavorite {
    // remove pickup and deliver date
    search.origDateStart = null;
    search.origDateEnd = null;
    search.destDateStart = null;
    search.destDateEnd = null;

    const favorite: UserFavorite = {
      userFavoriteId: 0,
      userId: this.user.userId,
      type: AllUserFavoriteTypes.MarketplaceSavedSearch,
      version: '1',
      data: {
        ...search,
        type: AllUserFavoriteTypes.MarketplaceSavedSearch,
      },
    };

    if (this.selectedUserFavorite && !newSearch) {
      favorite.userFavoriteId = this.selectedUserFavorite.userFavoriteId;
      // make sure the name is copied over
      favorite.data.name = this.selectedUserFavorite.data.name;
      favorite.data.defaultFavorite = this.selectedUserFavorite.data.defaultFavorite;
    }

    return this.deepClone(favorite);
  }
}
