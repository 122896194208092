import { concatLatestFrom } from '@ngrx/operators';import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { mapToPayload } from 'src/app/shared/utilities/map-to-payload';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AllCustomerFavoriteTypes, CustomerFavorite } from 'src/app/shared/models/customer-favorite';
import { LoadshopApplicationActionTypes } from 'src/app/shared/store';
import { getUserProfileModel } from 'src/app/user/store';
import { CoreState } from '..';
import { CommonService } from '../../services/common.service';
import {
  CustomerFavoriteActionTypes,
  CustomerFavoriteAddFailureAction,
  CustomerFavoriteAddSuccessAction,
  CustomerFavoriteDeleteFailureAction,
  CustomerFavoriteDeleteSuccessAction,
  CustomerFavoriteLoadAction,
  CustomerFavoriteLoadFailureAction,
  CustomerFavoriteLoadSuccessAction,
  CustomerFavoriteUpdateFailureAction,
  CustomerFavoriteUpdateSuccessAction,
} from '../actions';

@Injectable()
export class CustomerFavoriteEffects {
  $load = createEffect(() =>
    { return this.actions$.pipe(
      ofType(CustomerFavoriteActionTypes.Load),
      switchMap(() =>
        this.commonService.getCustomerFavorites().pipe(
          map((data) => new CustomerFavoriteLoadSuccessAction(data)),
          catchError((err) => of(new CustomerFavoriteLoadFailureAction(err)))
        )
      )
    ) }
  );

  $postFavorite = createEffect(() =>
    { return this.actions$.pipe(
      ofType(CustomerFavoriteActionTypes.Add_Favorite),
      mapToPayload<CustomerFavorite>(),
      switchMap((payload) =>
        this.commonService.addCustomerFavorite(payload).pipe(
          map((data) => new CustomerFavoriteAddSuccessAction(data)),
          catchError((err) => of(new CustomerFavoriteAddFailureAction(err)))
        )
      ),
      tap((action: CustomerFavoriteAddSuccessAction) => {
        if (action.type === CustomerFavoriteActionTypes.Add_Favorite_Success) {
          const message = action.payload.type === AllCustomerFavoriteTypes.OrderEntryTemplate ? 'Template Added' : 'Favorite Added';
          this.messageService.add({ id: 0, detail: message, severity: 'success' });
        }
      })
    ) }
  );

  $updateFavorite = createEffect(() =>
    { return this.actions$.pipe(
      ofType(CustomerFavoriteActionTypes.Update_Favorite),
      mapToPayload<CustomerFavorite>(),
      switchMap((payload) =>
        this.commonService.updateCustomerFavorite(payload).pipe(
          map((data) => new CustomerFavoriteUpdateSuccessAction(data)),
          catchError((err) => of(new CustomerFavoriteUpdateFailureAction(err)))
        )
      ),
      tap((action: CustomerFavoriteUpdateSuccessAction) => {
        if (action.type === CustomerFavoriteActionTypes.Update_Favorite_Success) {
          const message = action.payload.type === AllCustomerFavoriteTypes.OrderEntryTemplate ? 'Template Updated' : 'Favorite Updated';
          this.messageService.add({ id: 0, detail: message, severity: 'success' });
        }
      })
    ) }
  );

  $deleteFavorite = createEffect(() =>
    { return this.actions$.pipe(
      ofType(CustomerFavoriteActionTypes.Delete_Favorite),
      mapToPayload<CustomerFavorite>(),
      switchMap((payload) =>
        this.commonService.deleteCustomerFavorite(payload).pipe(
          map(() => new CustomerFavoriteDeleteSuccessAction(payload)),
          catchError((err) => of(new CustomerFavoriteDeleteFailureAction(err)))
        )
      ),
      tap((action: CustomerFavoriteDeleteSuccessAction) => {
        if (action.type === CustomerFavoriteActionTypes.Delete_Favorite_Success) {
          const message = action.payload.type === AllCustomerFavoriteTypes.OrderEntryTemplate ? 'Template Deleted' : 'Favorite Deleted';
          this.messageService.add({ id: 0, detail: message, severity: 'success' });
        }
      })
    ) }
  );

  $startup = createEffect(() =>
    { return this.actions$.pipe(
      ofType(LoadshopApplicationActionTypes.LoadshopStart),
      concatLatestFrom(() => this.store$.pipe(select(getUserProfileModel))),
      map(([, user]) => user),
      filter((user) => user && user.isShipper),
      map(() => new CustomerFavoriteLoadAction())
    ) }
  );

  constructor(
    private commonService: CommonService,
    private actions$: Actions,
    private messageService: MessageService,
    private store$: Store<CoreState>
  ) {}
}
