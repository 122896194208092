import { Component } from '@angular/core';
import { SelectItemGroup } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonService } from '../../../core/services';
import { BaseComponent } from '../../../shared/components';
import { LoadCarrierScacData, UserContactData } from '../../../shared/models';

@Component({
  selector: 'kbxl-shipper-counter-offer-carrier-selection',
  templateUrl: './shipper-counter-offer-carrier-selection.component.html',
  styleUrls: ['./shipper-counter-offer-carrier-selection.component.scss'],
})
export class ShipperCounterOfferCarrierSelectionComponent extends BaseComponent {
  loading = false;
  visibleScacs: LoadCarrierScacData[];
  hiddenScacs: LoadCarrierScacData[];
  scacs: SelectItemGroup[];
  selectedScac: LoadCarrierScacData;
  carrierUsers: UserContactData[];
  selectedCarrierUser: UserContactData;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private commonService: CommonService) {
    super();

    this.visibleScacs = config.data.visibleScacs;
    this.hiddenScacs = config.data.hiddenScacs;

    this.scacs = [
      {
        label: 'Visible',
        value: 'visible',
        items: this.visibleScacs
          .filter((x) => x.contractRate > 0)
          .map((x) => {
            return { label: x.scac, value: x };
          }),
      },
      {
        label: 'Hidden',
        value: 'hidden',
        items: this.hiddenScacs
          .filter((x) => x.contractRate > 0)
          .map((x) => {
            return { label: x.scac, value: x };
          }),
      },
    ];
  }

  selectCarrier(): void {
    this.ref.close({
      scacFuelProgramType: this.selectedScac.fuelProgramType,
      scac: this.selectedScac.scac,
      carrierUser: this.selectedCarrierUser,
      contractRate: this.selectedScac.contractRate,
    });
  }
  close(): void {
    this.ref.close();
  }
  loadCarrierUsers(): void {
    this.loading = true;
    this.carrierUsers = [];
    this.selectedCarrierUser = null;

    this.commonService.getCarrierCompanyUsers(this.selectedScac.scac, true).subscribe((x) => {
      this.carrierUsers = x;
      this.loading = false;
    });
  }
}
