import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SignedInMessageService {
  /**
   * Optional way for extending services to provide a plain text message.
   * Extending services can return of(null) here and/or return a value
   * from getSignedInMarkup() in order to render HTML markup as the
   * SignedIn message instead of this plain text message.
   */
  getSignedInMessage(): Observable<string> {
    return of('You are now signed in, redirecting...');
  }

  /**
   * Optional way for extending services to provide an SignedIn message as
   * markup instead of plain text.  If markup is provide, it will override
   * any plain text message.  So if a plain text message is preferred,
   * extending services should have getSignedInMarkup return of(null).
   */
  getSignedInMarkup(): Observable<string> {
    return of(null);
  }
}
