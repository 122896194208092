import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { RouterStateUrl } from '../../models';
import { accessorialActionItemReducer, AccessorialActionItemState } from './accessorial-action-item.reducer';
import { AccessorialRequestReducer, AccessorialRequestState } from './accessorial-request.reducer';
import { actionItemReducer, ActionItemState } from './action-item.reducer';
import { authReducer, AuthState } from './auth.reducer';
import { counterOfferActionItemReducer, CounterOfferActionItemState } from './counter-offer-action-item.reducer';
import { LoadBoardBookedReducer, LoadBoardBookedState } from './load-board-booked-reducer';
import { LoadBoardLoadDetailReducer, LoadBoardLoadDetailState } from './load-board-load-detail.reducer';
import { LoadDocumentReducer, LoadDocumentState } from './load-document.reducer';
import { LoadshopApplicationReducer, LoadshopApplicationState } from './loadshop-application.reducer';
import { UserCommuncationDisplayState, UserCommunicationDisplayReducer } from './user-communication-display.reducer';

export interface SharedState {
  auth: AuthState;
  loadDetail: LoadBoardLoadDetailState;
  userCommunicationDisplay: UserCommuncationDisplayState;
  loadshopApplication: LoadshopApplicationState;
  loadDocumentTypes: LoadDocumentState;
  loadBookedState: LoadBoardBookedState;
  actionItemState: ActionItemState;
  accessorialActionItemState: AccessorialActionItemState;
  counterOfferActionItemState: CounterOfferActionItemState;
  accessorialRequestState: AccessorialRequestState;
  router: RouterReducerState<RouterStateUrl>;
}

export const reducers: ActionReducerMap<SharedState> = {
  auth: authReducer,
  loadDetail: LoadBoardLoadDetailReducer,
  userCommunicationDisplay: UserCommunicationDisplayReducer,
  loadshopApplication: LoadshopApplicationReducer,
  loadDocumentTypes: LoadDocumentReducer,
  loadBookedState: LoadBoardBookedReducer,
  actionItemState: actionItemReducer,
  accessorialActionItemState: accessorialActionItemReducer,
  counterOfferActionItemState: counterOfferActionItemReducer,
  accessorialRequestState: AccessorialRequestReducer,
  router: routerReducer,
};

export const getSharedFeatureState = createFeatureSelector<SharedState>('sharedstate');
