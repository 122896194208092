export interface AgreementDocumentData {
  agreementDocumentId: string;
  agreementType: string;
  agreementTitle: string;
  agreementContent: string;
  agreementActiveDtTm: Date;
  isApplicableForCounterOffer: boolean;
  isRequiredAgreement: boolean;
  version: number;
}
