import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { UserCommunicationDetail } from 'src/app/shared/models';
import { mapToPayload } from 'src/app/shared/utilities/map-to-payload';
import { NavigationGoAction } from '../../../shared/store';
import { UserCommunicationService } from '../../services';
import {
  UserCommunicationActionTypes,
  UserCommunicationCreateAction,
  UserCommunicationCreateFailureAction,
  UserCommunicationCreateSuccessAction,
  UserCommunicationLoadAction,
  UserCommunicationLoadAllAction,
  UserCommunicationLoadAllFailureAction,
  UserCommunicationLoadAllSuccessAction,
  UserCommunicationLoadFailureAction,
  UserCommunicationLoadSuccessAction,
  UserCommunicationUpdateAction,
  UserCommunicationUpdateFailureAction,
  UserCommunicationUpdateSuccessAction,
} from '../actions';

@Injectable()
export class UserCommunicationEffects {
  $loadUserCommunications = createEffect(() =>
    { return this.actions$.pipe(
      ofType<UserCommunicationLoadAllAction>(UserCommunicationActionTypes.Load_All),
      switchMap(() =>
        this.userCommunicationService.getUserCommunications().pipe(
          map((data) => new UserCommunicationLoadAllSuccessAction(data)),
          catchError((err) => of(new UserCommunicationLoadAllFailureAction(err)))
        )
      )
    ) }
  );

  $loadUserCommunication = createEffect(() =>
    { return this.actions$.pipe(
      ofType<UserCommunicationLoadAction>(UserCommunicationActionTypes.Load),
      mapToPayload<{ userCommunicationId: string }>(),
      switchMap((payload: { userCommunicationId: string }) =>
        this.userCommunicationService.getUserCommunication(payload.userCommunicationId).pipe(
          map((data) => new UserCommunicationLoadSuccessAction(data)),
          catchError((err) => of(new UserCommunicationLoadFailureAction(err)))
        )
      )
    ) }
  );

  $updateUserCommunication = createEffect(() =>
    { return this.actions$.pipe(
      ofType<UserCommunicationUpdateAction>(UserCommunicationActionTypes.Update),
      mapToPayload<UserCommunicationDetail>(),
      switchMap((payload: UserCommunicationDetail) =>
        this.userCommunicationService.updateUserCommunication(payload).pipe(
          map((data) => new UserCommunicationUpdateSuccessAction(data)),
          catchError((err) => of(new UserCommunicationUpdateFailureAction(err)))
        )
      ),
      tap((action: UserCommunicationUpdateSuccessAction) =>
        this.messageService.add({ id: 0, detail: `User Communication "${action.payload.title}" Updated`, severity: 'success' })
      ),
      switchMap((result) => [new NavigationGoAction({ path: ['/maint/user-communications/'] }), result])
    ) }
  );

  $createUserCommunication = createEffect(() =>
    { return this.actions$.pipe(
      ofType<UserCommunicationCreateAction>(UserCommunicationActionTypes.Create),
      mapToPayload<UserCommunicationDetail>(),
      switchMap((payload: UserCommunicationDetail) =>
        this.userCommunicationService.createUserCommunication(payload).pipe(
          map((data) => new UserCommunicationCreateSuccessAction(data)),
          catchError((err) => of(new UserCommunicationCreateFailureAction(err)))
        )
      ),
      tap((action: UserCommunicationCreateSuccessAction) =>
        this.messageService.add({ id: 0, detail: `User Communication "${action.payload.title}" Created`, severity: 'success' })
      ),
      switchMap((result) => [new NavigationGoAction({ path: ['/maint/user-communications/'] }), result])
    ) }
  );

  constructor(
    private actions$: Actions,
    private userCommunicationService: UserCommunicationService,
    private messageService: MessageService
  ) {}
}
