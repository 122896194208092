import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { NavigationActionTypes, NavigationGoAction } from '../actions';

@Injectable()
export class RouterEffects {
  navigate$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType<NavigationGoAction>(NavigationActionTypes.Go),
        map((action) => action.payload),
        tap(({ path, query: queryParams, extras }) => this.router.navigate(path, { queryParams, ...extras }))
      ) },
    { dispatch: false }
  );

  navigateBack$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType(NavigationActionTypes.Back),
        tap(() => this.location.back())
      ) },
    { dispatch: false }
  );

  navigateForward$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType(NavigationActionTypes.Forward),
        tap(() => this.location.forward())
      ) },
    { dispatch: false }
  );

  constructor(private actions$: Actions, private router: Router, private location: Location) {}
}
