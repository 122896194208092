import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { mapToPayload } from 'src/app/shared/utilities/map-to-payload';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CoreState } from 'src/app/core/store';
import { MenuVisibilityBadgeLoadAction, MenuVisibilityBadgeLoadSuccessAction } from 'src/app/core/store/actions';
import { PageableQueryHelper } from 'src/app/shared/utilities';
import { LoadView, UpdateVisibilityRequest } from '../../../shared/models';
import { LoadBoardService } from '../../services';
import {
  LoadBoardBookedActionTypes,
  LoadBoardBookedLoadAction,
  LoadBoardBookedLoadFailureAction,
  LoadBoardBookedLoadSuccessAction,
  LoadBoardBookedUpdateVisibilityFailureAction,
  LoadBoardBookedUpdateVisibility,
  LoadBoardBookedUpdateVisibilitySuccessAction,
} from '../actions';

@Injectable()
export class LoadBoardBookedEffects {
  $load = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LoadBoardBookedLoadAction>(LoadBoardBookedActionTypes.Load),
      mapToPayload<{ queryHelper: PageableQueryHelper }>(),
      switchMap((payload) =>
        this.loadBoardService.getBookedLoadsByUser(payload.queryHelper).pipe(
          map((data) => {
            this.menuStore.dispatch(new MenuVisibilityBadgeLoadAction());
            return new LoadBoardBookedLoadSuccessAction(data);
          }),
          catchError((err) => of(new LoadBoardBookedLoadFailureAction(err)))
        )
      )
    ) }
  );

  $save = createEffect(() =>
    { return this.actions$.pipe(
      ofType<LoadBoardBookedUpdateVisibility>(LoadBoardBookedActionTypes.Update_Visibility),
      mapToPayload<UpdateVisibilityRequest>(),
      switchMap((load) =>
        this.loadBoardService.saveVisibilityData(load).pipe(
          switchMap((data) => [
            new MenuVisibilityBadgeLoadSuccessAction(data.visibilityBadge),
            new LoadBoardBookedUpdateVisibilitySuccessAction(data.loadClaim),
          ]),
          catchError((err) => of(new LoadBoardBookedUpdateVisibilityFailureAction(err)))
        )
      )
    ) }
  );

  $visibilitySaved = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType<LoadBoardBookedLoadAction>(LoadBoardBookedActionTypes.Update_Visibility_Success),
        tap((action) => this.messageService.add({ id: 0, detail: 'Visibility Data Saved', severity: 'success' }))
      ) },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private loadBoardService: LoadBoardService,
    private menuStore: Store<CoreState>,
    private messageService: MessageService
  ) {}
}
