export interface MessageNotification {
  /// <summary>
  /// The value of well-known group attribute stored on a message/conversation.
  /// This can be anything useful for the application, but in the case of Loadshop,
  /// this would be the Shipper ID or SCAC, or something like that.
  /// </summary>
  groupValue: string;
  nbrNewMessages: number;
  conversationId?: number;
  conversationAttributes?: { [key: string]: Array<string>; };
}
