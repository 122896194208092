import { Action } from '@ngrx/store';
import {
  Application,
  ConversationQuery,
  MostRecentMessagesRequest,
  OldMessagesRequest,
  SearchNotificationsRequest,
} from '../../../conversation-api';
import {
  ChatNotification,
  CreateDirectMessageData,
  ExtendedConversation,
  ExtendedMessage,
  MessageNotification,
  ReadReceipt,
  UserConversationEntity,
} from '../../models';

export enum ConversationActionTypes {
  Reset = '[Conversation] RESET',
  Load = '[Conversation] LOAD',
  Load_Success = '[Conversation] LOAD_SUCCESS',
  Load_Failure = '[Conversation] LOAD_FAILURE',
  JoinNewDM = '[Conversation] JOIN_NEW_DM',
  JoinNewDM_Success = '[Conversation] JOIN_NEW_SUCCESS',
  JoinNewDM_Failure = '[Conversation] JOIN_NEW_FAILURE',
  Search = '[Conversation] SEARCH',
  Search_Success = '[Conversation] SEARCH_SUCCESS',
  Search_Failure = '[Conversation] SEARCH_FAILURE',
  Select_Conversation = '[Conversation] SELECT_CONVERSATION',
  Select_Conversation_Success = '[Conversation] SELECT_CONVERSATION_SUCCESS',
  Close_Selected_Conversation = '[Conversation] CLOSE_SELECTED_CONVERSATION',
  Close_Selected_Conversation_Success = '[Conversation] CLOSE_SELECTED_CONVERSATION_SUCCESS',
  AddMessage = '[Conversation] ADD_MESSAGE',
  AddMessage_Success = '[Conversation] ADD_MESSAGE_SUCCESS',
  AddMessage_Failure = '[Conversation] ADD_MESSAGE_FAILURE',
  AddIncomingMessages = '[Conversation] ADD_INCOMING_MESSAGES',
  AddIncomingMessages_Success = '[Conversation] ADD_INCOMING_MESSAGES_SUCCESS',
  AddIncomingMessages_Failure = '[Conversation] ADD_INCOMING_MESSAGES_FAILURE',
  LoadNotifications = '[Conversation] LOAD_NOTIFICATIONS',
  LoadNotifications_Success = '[Conversation] LOAD_NOTIFICATIONS_SUCCESS',
  LoadNotifications_Failure = '[Conversation] LOAD_NOTIFICATIONS_FAILURE',
  UpdateNotificationCount = '[Conversation] UPDATE_NOTIFICATION_COUNT',
  UpdateNotificationCount_Success = '[Conversation] UPDATE_NOTIFICATION_COUNT_SUCCESS',
  DismissNotifications = '[Conversation] DISMISS_NOTIFICATIONS',
  DismissNotifications_Success = '[Conversation] DISMISS_NOTIFICATIONS_SUCCESS',
  DismissNotifications_Failure = '[Conversation] DISMISS_NOTIFICATIONS_FAILURE',
  LoadServiceHubApplication = '[Conversation] LOAD_SERVICE_HUB_APPLICATION',
  LoadServiceHubApplication_Success = '[Conversation] LOAD_SERVICE_HUB_APPLICATION_SUCCESS',
  LoadServiceHubApplication_Failure = '[Conversation] LOAD_SERVICE_HUB_APPLICATION_FAILURE',
  UpdateUserConversationEntities = '[Conversation] UPDATE_USER_CONVERSATION_ENTITIES',
  GetMessages = '[Conversation] GET_MESSAGES',
  GetMessages_Success = '[Conversation] GET_MESSAGES_SUCCESS',
  GetMessages_Failure = '[Conversation] GET_MESSAGES_FAILURE',
  GetOlderMessages = '[Conversation] GET_OLDER_MESSAGES',
  GetOlderMessages_Success = '[Conversation] GET_OLDER_MESSAGES_SUCCESS',
  GetOlderMessages_Failure = '[Conversation] GET_OLDER_MESSAGES_FAILURE',
  ReadReceipt = '[Conversation] READ_RECEIPT',
  ReadReceipt_Success = '[Conversation] READ_RECEIPT_SUCCESS',
  ReadReceipt_Failure = '[Conversation] READ_RECEIPT_FAILURE',
  DisableNotifications = '[Conversation] DISABLE_NOTIFICATIONS',
  GetUserPresence = '[Conversation] GET_USER_PRESENCE',
  GetUserPresence_Failure = '[Conversation] GET_USER_PRESENCE_FAILURE',
  GetUserPresence_Success = '[Conversation] GET_USER_PRESENCE_SUCCESS',
}

export class ResetConversationsAction implements Action {
  readonly type = ConversationActionTypes.Reset;
}
export class ConversationsLoadAction implements Action {
  readonly type = ConversationActionTypes.Load;
  constructor(public payload: ConversationQuery[]) {}
}
export class ConversationsLoadSuccessAction implements Action {
  readonly type = ConversationActionTypes.Load_Success;
  constructor(public payload: ExtendedConversation[]) {}
}
export class ConversationsLoadFailureAction implements Action {
  readonly type = ConversationActionTypes.Load_Failure;
  constructor(public payload: Error) {}
}

export class ConversationsSearchAction implements Action {
  readonly type = ConversationActionTypes.Search;
  constructor(public payload: ConversationQuery, public addRecentlyJoined: boolean = false) {}
}
export class ConversationsSearchSuccessAction implements Action {
  readonly type = ConversationActionTypes.Search_Success;
  constructor(public payload: ExtendedConversation[]) {}
}
export class ConversationsSearchFailureAction implements Action {
  readonly type = ConversationActionTypes.Search_Failure;
  constructor(public payload: Error) {}
}
export class JoinDirectMessageAction implements Action {
  readonly type = ConversationActionTypes.JoinNewDM;
  constructor(public payload: CreateDirectMessageData) {}
}
export class JoinDirectMessageSuccessAction implements Action {
  readonly type = ConversationActionTypes.JoinNewDM_Success;
  constructor(public payload: ExtendedConversation, public referenceLoadId: string = '', public attachToMessage = true) {}
}
export class JoinDirectMessageFailureAction implements Action {
  readonly type = ConversationActionTypes.JoinNewDM_Failure;
  constructor(public payload: Error) {}
}
export class SelectConversationAction implements Action {
  readonly type = ConversationActionTypes.Select_Conversation;
  constructor(public conversationId: number, public filterQuery: string = '', public attachToMessage = false) {}
}

export class SelectConversationSuccessAction implements Action {
  readonly type = ConversationActionTypes.Select_Conversation_Success;
  constructor(public conversationId: number) {}
}
export class CloseSelectedConversationAction implements Action {
  readonly type = ConversationActionTypes.Close_Selected_Conversation;
  constructor(public navigateToChat = false) {}
}
export class CloseSelectedConversationSuccessAction implements Action {
  readonly type = ConversationActionTypes.Close_Selected_Conversation_Success;
}
export class AddMessageToConversationAction implements Action {
  readonly type = ConversationActionTypes.AddMessage;
  constructor(public message: ExtendedMessage) {}
}

export class AddMessageToConversationSuccessAction implements Action {
  readonly type = ConversationActionTypes.AddMessage_Success;
  constructor(public message: ExtendedMessage) {}
}
export class AddMessageToConversationFailAction implements Action {
  readonly type = ConversationActionTypes.AddMessage_Failure;
  constructor(public payload: Error) {}
}

export class AddIncomingMessagesOnConversationAction implements Action {
  readonly type = ConversationActionTypes.AddIncomingMessages;
  constructor(public conversationId: number, public messages: ExtendedMessage[]) {}
}

export class AddIncomingMessagesOnConversationSuccessAction implements Action {
  readonly type = ConversationActionTypes.AddIncomingMessages_Success;
}
export class AddIncomingMessagesOnConversationFailAction implements Action {
  readonly type = ConversationActionTypes.AddIncomingMessages_Failure;
  constructor(public payload: Error) {}
}

export class LoadConversationNotificationsAction implements Action {
  readonly type = ConversationActionTypes.LoadNotifications;
  constructor(public payload: SearchNotificationsRequest[]) {}
}
export class LoadConversationNotificationsSuccessAction implements Action {
  readonly type = ConversationActionTypes.LoadNotifications_Success;
  constructor(public notifications: MessageNotification[]) {}
}
export class LoadConversationNotificationsFailureAction implements Action {
  readonly type = ConversationActionTypes.LoadNotifications_Failure;
  constructor(public payload: Error) {}
}

export class UpdateNotificationCountAction implements Action {
  readonly type = ConversationActionTypes.UpdateNotificationCount;
  constructor(public notifications: MessageNotification[]) {}
}

export class UpdateNotificationCountSuccessAction implements Action {
  readonly type = ConversationActionTypes.UpdateNotificationCount_Success;
  constructor(public notificationCount: number, public userContextNotifications: ChatNotification[]) {}
}

export class DismissNotificationsAction implements Action {
  readonly type = ConversationActionTypes.DismissNotifications;
  // Dismiss all or only the current context notifications
  constructor(public conversationId: number) {}
}
export class DismissNotificationsSuccessAction implements Action {
  readonly type = ConversationActionTypes.DismissNotifications_Success;
  // Dismiss all or only the current context notifications
  constructor(public notificationCount: number, public userContextNotifications: ChatNotification[]) {}
}

export class DismissNotificationsFailureAction implements Action {
  readonly type = ConversationActionTypes.DismissNotifications_Failure;
  // Dismiss all or only the current context notifications
  constructor(public payload: Error) {}
}

export class LoadServiceHubApplicationAction implements Action {
  readonly type = ConversationActionTypes.LoadServiceHubApplication;
  constructor(public applicationCode: string) {}
}

export class LoadServiceHubApplicationSuccessAction implements Action {
  readonly type = ConversationActionTypes.LoadServiceHubApplication_Success;
  constructor(public payload: Application) {}
}
export class LoadServiceHubApplicationFailAction implements Action {
  readonly type = ConversationActionTypes.LoadServiceHubApplication_Failure;
  constructor(public payload: Error) {}
}

export class UpdateUserConversationEntitiesAction implements Action {
  readonly type = ConversationActionTypes.UpdateUserConversationEntities;
  constructor(public payload: UserConversationEntity[]) {}
}

export class GetMessagesForConversationAction implements Action {
  readonly type = ConversationActionTypes.GetMessages;
  constructor(public payload: MostRecentMessagesRequest) {}
}

export class GetMessagesForConversationSuccessAction implements Action {
  readonly type = ConversationActionTypes.GetMessages_Success;
  constructor(public conversationId: number, public messages: ExtendedMessage[], public hasOlderMessages?: boolean) {}
}
export class GetMessagesForConversationFailAction implements Action {
  readonly type = ConversationActionTypes.GetMessages_Failure;
  constructor(public payload: Error) {}
}

export class GetOlderMessagesForConversationAction implements Action {
  readonly type = ConversationActionTypes.GetOlderMessages;
  constructor(public payload: OldMessagesRequest) {}
}

export class GetOlderMessagesForConversationSuccessAction implements Action {
  readonly type = ConversationActionTypes.GetOlderMessages_Success;
  constructor(public conversationId: number, public messages: ExtendedMessage[], public hasOlderMessages?: boolean) {}
}
export class GetOlderMessagesForConversationFailAction implements Action {
  readonly type = ConversationActionTypes.GetOlderMessages_Failure;
  constructor(public payload: Error) {}
}

export class ReadReceiptMessageOnConversationAction implements Action {
  readonly type = ConversationActionTypes.ReadReceipt;
  constructor(public payload: ReadReceipt) {}
}

export class ReadReceiptMessageOnConversationSuccessAction implements Action {
  readonly type = ConversationActionTypes.ReadReceipt_Success;
  constructor(public payload: ReadReceipt) {}
}
export class ReadReceiptMessageOnConversationFailAction implements Action {
  readonly type = ConversationActionTypes.ReadReceipt_Failure;
  constructor(public payload: Error) {}
}
export class DisableNotificationsAction implements Action {
  readonly type = ConversationActionTypes.DisableNotifications;
  constructor(public disableNotifications: boolean) {}
}
export class GetUserPresenceAction implements Action {
  readonly type = ConversationActionTypes.GetUserPresence;
  constructor(public userId: string) {}
}
export class GetUserPresenceFailureAction implements Action {
  readonly type = ConversationActionTypes.GetUserPresence_Failure;
  constructor(public payload: Error) {}
}
export class GetUserPresenceSuccessAction implements Action {
  readonly type = ConversationActionTypes.GetUserPresence_Success;
  constructor(public active: boolean) {}
}
export type ConversationActions =
  | ResetConversationsAction
  | ConversationsLoadAction
  | ConversationsLoadSuccessAction
  | ConversationsLoadFailureAction
  | ConversationsSearchAction
  | ConversationsSearchSuccessAction
  | ConversationsSearchFailureAction
  | JoinDirectMessageAction
  | JoinDirectMessageSuccessAction
  | JoinDirectMessageFailureAction
  | SelectConversationAction
  | SelectConversationSuccessAction
  | CloseSelectedConversationAction
  | CloseSelectedConversationSuccessAction
  | AddMessageToConversationAction
  | AddMessageToConversationSuccessAction
  | AddMessageToConversationFailAction
  | AddIncomingMessagesOnConversationAction
  | AddIncomingMessagesOnConversationSuccessAction
  | AddIncomingMessagesOnConversationFailAction
  | UpdateNotificationCountAction
  | UpdateNotificationCountSuccessAction
  | DismissNotificationsAction
  | DismissNotificationsSuccessAction
  | DismissNotificationsFailureAction
  | LoadServiceHubApplicationAction
  | LoadServiceHubApplicationSuccessAction
  | LoadServiceHubApplicationFailAction
  | UpdateUserConversationEntitiesAction
  | GetMessagesForConversationAction
  | GetMessagesForConversationSuccessAction
  | GetMessagesForConversationFailAction
  | GetOlderMessagesForConversationAction
  | GetOlderMessagesForConversationSuccessAction
  | GetOlderMessagesForConversationFailAction
  | ReadReceiptMessageOnConversationAction
  | ReadReceiptMessageOnConversationSuccessAction
  | ReadReceiptMessageOnConversationFailAction
  | LoadConversationNotificationsAction
  | LoadConversationNotificationsSuccessAction
  | LoadConversationNotificationsFailureAction
  | DisableNotificationsAction
  | GetUserPresenceAction
  | GetUserPresenceFailureAction
  | GetUserPresenceSuccessAction;
