import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { ConfirmationService, MenuItem, MessageService, OverlayOptions, TreeNode } from 'primeng/api';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { debounceTime, map, take, takeUntil } from 'rxjs/operators';
import { LaneManagementService } from 'src/app/admin/services/lane-management-service';
import {
  CoreState,
  CustomerFavoriteAddAction,
  CustomerFavoriteDeleteAction,
  CustomerFavoriteUpdateAction,
  SmartSpotClearCreateOrderFromQuote,
  getAppointmentSchedulerConfirmationTypes,
  getCommodities,
  getCustomerFavorites,
  getEquipment,
  getLoadingAppointmentSchedulerConfirmationTypes,
  getLoadingCommodities,
  getLoadingCustomerFavorites,
  getLoadingEquipment,
  getLoadingServiceTypes,
  getLoadingStates,
  getLoadingTransporationModes,
  getLoadingUnitsOfMeasure,
  getLoadshopSettings,
  getManualLoadServiceTypes,
  getQuickQuoteCreateOrderDetails,
  getServiceTypes,
  getStates,
  getTransporationModes,
  getUnitsOfMeasure,
} from 'src/app/core/store';
import { GuidEmpty } from 'src/app/core/utilities/constants';
import { BaseComponent } from 'src/app/shared/components';
import {
  AllCustomerFavoriteTypes,
  AppointmentSchedulerConfirmationType,
  Commodity,
  CustomerData,
  CustomerFavorite,
  DefaultOrderEntryTemplateLoadData,
  Equipment,
  LoadLineItem,
  LoadStop,
  LoadshopSetting,
  OrderEntryTemplateLoadData,
  SearchLaneFilterRequest,
  ServiceType,
  State,
  StopTypes,
  TransportationMode,
  UnitOfMeasure,
  User,
  defaultLoadLineItem,
  defaultTransportationMode,
} from 'src/app/shared/models';
import { LoadContact } from 'src/app/shared/models/load-contact';
import { LoadStopContact } from 'src/app/shared/models/load-stop-contact';
import { OrderEntryForm, OrderEntryFormDisplay, OrderEntryFormEditStatus } from 'src/app/shared/models/order-entry-form';
import { OrderEntryFormBookedEditEnum } from 'src/app/shared/models/order-entry-form-booked-edit-enum';
import {
  OrderEntryLoadStop,
  defaultOrderEntryDeliveryStop,
  defaultOrderEntryPickupStop,
  fromLoadStops,
} from 'src/app/shared/models/order-entry-load-stop';
import { ValidationProblemDetails } from 'src/app/shared/models/validation-problem-details';
import {
  getOrderEntryFormDisplay,
  getOrderEntryLoadingDetails,
  getOrderEntryProblemDetails,
  getOrderEntrySavedId,
  getOrderEntrySavedIsExternalLoad,
  getOrderEntrySavedRefId,
} from 'src/app/shipping/store/selectors/order-entry.selectors';
import { getUserProfileEntity } from 'src/app/user/store';
import { CanDeactivateGuard } from '../../../../core/guards/can-deactivate.guard';
import { CommonService } from '../../../../core/services';
import {
  OrderEntryClearErrorsAction,
  OrderEntryCopyLoadAction,
  OrderEntryCreateLoadAction,
  OrderEntryGetLoadAction,
  OrderEntryResetSavedAction,
  OrderEntryUpdateLoadAction,
  ShippingLoadDetailLoadAllAction,
  ShippingState,
} from '../../../store';

@Component({
  selector: 'kbxl-shipping-load-create-container',
  templateUrl: './shipping-load-create-container.component.html',
  styleUrls: ['./shipping-load-create-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingLoadCreateContainerComponent extends BaseComponent implements OnInit, OnDestroy, CanDeactivateGuard {
  serviceTypes: ServiceType[];
  filter$: Observable<any>;
  loading$: Observable<boolean>;
  editMode: boolean;
  problemDetails: ValidationProblemDetails;
  defaultLoadStopContactErrorLabels = {
    email: false,
    phoneNumber: false,
    display: false,
    firstName: false,
    lastName: false,
  };
  defaultLoadLineItemErrorLabels = {
    loadLineItemNumber: false,
    quantity: false,
    unitOfMeasure: false,
    weight: false,
    customerPurchaseOrder: false,
    pickupStopNumber: false,
    deliveryStopNumber: false,
  };
  defaultLoadStopErrorLabels = {
    city: false,
    state: false,
    address1: false,
    locationName: false,
    postalCode: false,
    schedulerConfirmationType: false,
    pickupTo: false,
    contactLabels: [{ ...this.defaultLoadStopContactErrorLabels }],
    lineItemLabels: [{ ...this.defaultLoadLineItemErrorLabels }],
  };
  /**
   * Every contact has to be unique flower, so we have display on some
   * and first name and last name on others.  Eventually these should be
   * consolidated, since the UI always has first name and last name
   */
  defaultLoadContactErrorLabels = {
    email: false,
    phoneNumber: false,
    display: false,
    firstName: false,
    lastName: false,
  };
  defaultErrorLabels = {
    referenceLoadDisplay: false,
    commodity: false,
    equipment: false,
    shipperPickupNumber: false,
    transportationMode: false,
    services: false,
    contacts: false,

    // Single order contact on the form by default
    contactLabels: [{ ...this.defaultLoadContactErrorLabels }],

    // Two load stops on the form by default; require error label objects for each
    loadStopLabels: [{ ...this.defaultLoadStopErrorLabels }, { ...this.defaultLoadStopErrorLabels }],
  };
  errorLabels = this.defaultErrorLabels;
  errorSummary = null;
  errorCount = 0;

  form: UntypedFormGroup;
  orderEntry: OrderEntryFormDisplay;
  isExistingLoad = false;
  pickupStopNumbers: { stopNbr: number }[] = [];

  favorites$: Observable<CustomerFavorite[]>;
  commodities$: Observable<Commodity[]>;
  loadingCommodities$: Observable<boolean>;
  equipment$: Observable<Equipment[]>;
  loadingEquipment$: Observable<boolean>;
  unitsOfMeasure$: Observable<UnitOfMeasure[]>;
  loadingUnitsOfMeasure$: Observable<boolean>;
  transportationModes$: Observable<TransportationMode[]>;
  loadingTransportationModes$: Observable<boolean>;
  serviceTypes$: Observable<ServiceType[]>;
  loadingServiceTypes$: Observable<boolean>;
  schedulerConfirmationTypes$: Observable<AppointmentSchedulerConfirmationType[]>;
  loadingSchedulerConfirmationTypes$: Observable<boolean>;
  states$: Observable<State[]>;
  loadingStates$: Observable<boolean>;
  userProfile$: Observable<User>;

  selectedEquipment: TreeNode;
  apiRequest: string = null;
  saveItems: MenuItem[];
  showSaveFavoriteDialog = false;
  saveLoadAfterSavingFavorite = false;
  isBrokeredQuote = false;
  isBrokeredShipper = false;
  requiredServicesAutoApplied: string;
  private user: User;
  private favorites: CustomerFavorite[];
  private customerLaneRequest: {
    equipmentId: string;
    originPlaceId: string;
    destPlaceId: string;
  };
  private refreshCustomerLane = false;
  showCreateLoadSuccessModal = false;
  savedId = GuidEmpty;
  savedRefId = null;
  defaultOverlayOptions: OverlayOptions = {
    hideTransitionOptions: '0ms',
    showTransitionOptions: '0ms',
  };

  private loadMap = [
    { urn: '', formControlName: '' },
    { urn: 'ReferenceLoadDisplay', formControlName: 'referenceLoadDisplay' },
    { urn: 'Commodity', formControlName: 'commodity' },
    { urn: 'EquipmentType', formControlName: 'equipment' },
    { urn: 'Contacts', formControlName: 'contacts' },
    { urn: 'ShipperPickupNumber', formControlName: 'shipperPickupNumber' },
    { urn: 'LoadStops', formControlName: 'loadStops' },
    { urn: 'ServiceTypes', formControlName: 'services' },
    { urn: 'LineItems', formControlName: '' }, // for now just display the error, we may change it to be required per delivery stop
  ];
  private contactMap = [
    { urn: '', formControlName: '' },
    { urn: 'Email', formControlName: 'email' },
    { urn: 'Phone', formControlName: 'phoneNumber' },
    { urn: 'EmailOrPhoneNumber', formControlName: 'phoneNumber' },
    { urn: 'Display', formControlName: 'firstName' },
    { urn: 'FirstName', formControlName: 'firstName' },
    { urn: 'LastName', formControlName: 'lastName' },
  ];
  private loadStopMap = [
    { urn: '', formControlName: '' },
    { urn: 'StopNbr', formControlName: 'stopNbr' },
    { urn: 'StopType', formControlName: 'stopType' },
    { urn: 'City', formControlName: 'city' },
    { urn: 'State', formControlName: 'state' },
    { urn: 'Country', formControlName: 'country' },
    { urn: 'Address1', formControlName: 'address1' },
    { urn: 'LocationName', formControlName: 'locationName' },
    { urn: 'PostalCode', formControlName: 'postalCode' },
    { urn: 'StopType', formControlName: 'stopType' },
    { urn: 'AppointmentSchedulingCode', formControlName: 'schedulerConfirmationType' },
    { urn: 'AppointmentConfirmationCode', formControlName: 'schedulerConfirmationType' },
    { urn: 'EarlyDtTm', formControlName: 'earlyDtTm' },
    { urn: 'EarlyTime', formControlName: 'earlyTime' },
    { urn: 'LateDtTm', formControlName: 'lateDtTm' },
    { urn: 'LateTime', formControlName: 'lateTime' },
    { urn: 'Contacts', formControlName: 'contacts' },
    { urn: 'LineItems', formControlName: 'lineItems' },
  ];
  private lineItemMap = [
    { urn: '', formControlName: '' },
    { urn: 'Quantity', formControlName: 'quantity' },
    { urn: 'Weight', formControlName: 'weight' },
    { urn: 'Volume', formControlName: 'volume' },
    { urn: 'UnitOfMeasure', formControlName: 'unitOfMeasure' },
    { urn: 'PickupStopNumber', formControlName: 'pickupStopNumber' },
    { urn: 'DeliveryStopNumber', formControlName: 'deliveryStopNumber' },
  ];

  orderEntryEditStatus: OrderEntryFormEditStatus;
  disableForLinieItemsOnly: boolean;
  loadshopSettingSub: Subscription;
  loadshopSettings: LoadshopSetting[];
  enforceAddContactRestriction: boolean;
  constructor(
    private shippingStore: Store<ShippingState>,
    private coreStore: Store<CoreState>,
    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private commonService: CommonService,
    private laneManagementService: LaneManagementService
  ) {
    super();
  }

  /**
   * https://medium.com/@stodge/ngrx-common-gotchas-8f59f541e47c
   * Required to prevent memory leaks when subscribing to ngrx store for purposes
   * other than dumping to an HTML template with an async pipe
   */
  ngOnDestroy() {
    this.coreStore.dispatch(new SmartSpotClearCreateOrderFromQuote());
    this.loadshopSettingSub.unsubscribe();
  }

  ngOnInit() {
    this.loadshopSettingSub = this.coreStore.pipe(select(getLoadshopSettings)).subscribe((settings) => {
      this.loadshopSettings = settings;
    });
    this.favorites$ = this.coreStore.pipe(select(getCustomerFavorites, AllCustomerFavoriteTypes.OrderEntryTemplate));
    this.userProfile$ = this.coreStore.pipe(select(getUserProfileEntity));
    this.loading$ = combineLatest([
      this.coreStore.pipe(select(getLoadingCustomerFavorites)),
      this.shippingStore.pipe(select(getOrderEntryLoadingDetails)),
    ]).pipe(map((args) => args[0] || args[1]));

    combineLatest([
      this.route.params.pipe(map((params) => params)),
      this.route.url.pipe(map((segments) => segments.join(''))),
      this.route.queryParams.pipe(map((queryParams) => queryParams)),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([params, url, queryParams]) => {
        const payload = { loadId: '', smartSpotQuoteId: '' };
        if (params.id) {
          payload.loadId = params.id;
        }

        // This component is used by the 'copy' route as well, and in that
        // case that page is being visited, we want to copy the give load id param
        // instead of just loading it up as-is, like we do for the 'edit' route.
        if (url.indexOf('copy') >= 0) {
          this.shippingStore.dispatch(new OrderEntryCopyLoadAction(payload));
        } else if (url.indexOf('edit') >= 0) {
          this.shippingStore.dispatch(new OrderEntryGetLoadAction(payload));
          this.editMode = true;
        } else {
          this.shippingStore.dispatch(new OrderEntryGetLoadAction(payload));
        }
      });

    this.commodities$ = this.coreStore.pipe(select(getCommodities));
    this.loadingCommodities$ = this.coreStore.pipe(select(getLoadingCommodities));
    this.equipment$ = this.coreStore.pipe(select(getEquipment));
    this.loadingEquipment$ = this.coreStore.pipe(select(getLoadingEquipment));
    this.unitsOfMeasure$ = this.coreStore.pipe(select(getUnitsOfMeasure));
    this.loadingUnitsOfMeasure$ = this.coreStore.pipe(select(getLoadingUnitsOfMeasure));
    this.transportationModes$ = this.coreStore.pipe(select(getTransporationModes));
    this.loadingTransportationModes$ = this.coreStore.pipe(select(getLoadingTransporationModes));
    this.coreStore.pipe(select(getServiceTypes), takeUntil(this.destroyed$)).subscribe((form) => (this.serviceTypes = form));

    this.serviceTypes$ = this.coreStore.pipe(select(getManualLoadServiceTypes));
    this.loadingServiceTypes$ = this.coreStore.pipe(select(getLoadingServiceTypes));

    this.schedulerConfirmationTypes$ = this.coreStore.pipe(select(getAppointmentSchedulerConfirmationTypes));
    this.loadingSchedulerConfirmationTypes$ = this.coreStore.pipe(select(getLoadingAppointmentSchedulerConfirmationTypes));
    this.states$ = this.coreStore.pipe(select(getStates));
    this.loadingStates$ = this.coreStore.pipe(select(getLoadingStates));

    this.favorites$.pipe(takeUntil(this.destroyed$)).subscribe((form) => {
      this.favorites = form;
    });
    this.userProfile$.pipe(takeUntil(this.destroyed$)).subscribe((form) => {
      this.user = form;
      if (this.user) {
        const shipper = this.user.authorizedShippers.find((y) => y.customerId === this.user.primaryCustomerId) as CustomerData;
        this.isBrokeredShipper = shipper && shipper.brokeredLoads;
      }
    });

    combineLatest([
      this.shippingStore.select(getOrderEntryFormDisplay),
      this.coreStore.select(getAppointmentSchedulerConfirmationTypes),
      this.coreStore.select(getUnitsOfMeasure),
      this.coreStore.pipe(select(getQuickQuoteCreateOrderDetails)),
    ])
      .pipe(
        takeUntil(this.destroyed$),
        map((_) => this.deepClone(_))
      )
      .subscribe(([orderEntry, schedConfTypes, unitsOfMeasure, qqDetails]) => {
        this.orderEntry = orderEntry;
        if (this.orderEntry.loadId && this.orderEntry.loadId.length > 0) {
          this.isExistingLoad = true;
        }

        const allDataLoaded = !!orderEntry && !!schedConfTypes && !!unitsOfMeasure;
        if (!this.isExistingLoad && allDataLoaded && qqDetails) {
          this.orderEntry.equipment = qqDetails.equipment ? qqDetails.equipment.equipmentId : null;
          this.orderEntry.equipmentDesc = qqDetails.equipment ? qqDetails.equipment.equipmentDesc : null;
          this.orderEntry.categoryEquipmentDesc = qqDetails.equipment ? qqDetails.equipment.categoryEquipmentDesc : null;
          this.orderEntry.loadStops[0].city = qqDetails.origin ? qqDetails.origin.city : null;
          this.orderEntry.loadStops[0].state = qqDetails.origin ? qqDetails.origin.stateAbbrev : null;
          this.orderEntry.loadStops[0].stateName = qqDetails.origin ? qqDetails.origin.state : null;
          this.orderEntry.loadStops[0].postalCode = qqDetails.origin ? qqDetails.origin.postalCode : null;
          this.orderEntry.loadStops[0].country = qqDetails.origin ? qqDetails.origin.country : null;
          this.orderEntry.loadStops[0].lateDate = qqDetails.pickupDate;
          this.orderEntry.loadStops[0].lateTime = qqDetails.pickupTime;
          this.orderEntry.loadStops[0].earlyDate = qqDetails.pickupDate;
          this.orderEntry.loadStops[0].earlyTime = qqDetails.pickupTime;
          this.orderEntry.lineItems[0].weight = qqDetails.weight;
          this.orderEntry.loadStops[1].city = qqDetails.destination ? qqDetails.destination.city : null;
          this.orderEntry.loadStops[1].state = qqDetails.destination ? qqDetails.destination.stateAbbrev : null;
          this.orderEntry.loadStops[1].stateName = qqDetails.destination ? qqDetails.destination.state : null;
          this.orderEntry.loadStops[1].postalCode = qqDetails.destination ? qqDetails.destination.postalCode : null;
          this.orderEntry.loadStops[1].country = qqDetails.destination ? qqDetails.destination.country : null;
          this.orderEntry.loadStops[1].lateDate = qqDetails.deliveryDate;
          this.orderEntry.loadStops[1].lateTime = qqDetails.deliveryTime;
          this.orderEntry.loadStops[1].earlyDate = qqDetails.deliveryDate;
          this.orderEntry.loadStops[1].earlyTime = qqDetails.deliveryTime;
        }

        this.buildForm(orderEntry, schedConfTypes, unitsOfMeasure);
        this.shippingStore.dispatch(new OrderEntryClearErrorsAction());
        this.shippingStore.dispatch(new OrderEntryResetSavedAction());

        // Don't try to set errors until the form is completely built
        this.shippingStore
          .select(getOrderEntryProblemDetails)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((problems) => {
            this.problemDetails = problems;
            this.setErrors();
          });
      });

    this.saveItems = [
      {
        label: 'Create Load',
        command: () => {
          this.saveLoadAfterSavingFavorite = false;
          this.save();
        },
      },
    ];

    if (!this.isBrokeredQuote) {
      this.saveItems.push(
        ...[
          {
            label: 'Create Load and Add/Update Template',
            command: () => {
              this.saveLoadAfterSavingFavorite = true;
              this.toggleFavoriteDialog(true);
            },
          },
          {
            label: 'Add/Update Template',
            command: () => {
              this.saveLoadAfterSavingFavorite = false;
              this.toggleFavoriteDialog(true);
            },
          },
        ]
      );
    }

    // We don't actually save anything about the form in state, so
    // subscribing to changes in the order entry form doesn't do us any
    // good.  As such, we check this saved state and rebuild our form
    // from the same default initial state of the order entry form
    combineLatest([
      this.shippingStore.select(getOrderEntrySavedId),
      this.shippingStore.select(getOrderEntrySavedRefId),
      this.shippingStore.select(getOrderEntrySavedIsExternalLoad),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([savedId, savedRefId, isExternalLoad]) => {
        if (savedId && savedId !== GuidEmpty && savedRefId) {
          this.savedId = savedId;
          this.savedRefId = savedRefId;
          if (this.orderEntry.isBooked) {
            this.form.markAsPristine();
            this.router.navigate(['/shipping/booked/detail/', this.orderEntry.loadId]);
          } else if (isExternalLoad === true) {
            this.goToPostTab(this.savedRefId);
          } else {
            this.showCreateLoadSuccessModal = true;
          }
        }
      });
  }

  goToPostTab(loadRefId: string) {
    this.clearCreateLoadSuccessState();
    // Reload all Shipping Post tab loads, so that the new one is available to select
    this.shippingStore.dispatch(new ShippingLoadDetailLoadAllAction());
    this.router.navigate(['/shipping/home'], { queryParams: { loadids: loadRefId, sep: ';' } });
    this.coreStore.dispatch(new SmartSpotClearCreateOrderFromQuote());
  }

  createAnotherLoad() {
    this.clearCreateLoadSuccessState();
    this.router.navigate(['shipping/home/create']);
    // in case this isn't a copy load, fire off the template api call
    this.shippingStore.dispatch(new OrderEntryGetLoadAction({ loadId: '', smartSpotQuoteId: '' }));
  }

  copyLoad(loadId: string) {
    this.clearCreateLoadSuccessState();
    this.router.navigate(['shipping/home/copy', loadId]);
  }

  clearCreateLoadSuccessState() {
    this.showCreateLoadSuccessModal = false;
    this.savedId = GuidEmpty;
    this.shippingStore.dispatch(new OrderEntryResetSavedAction());
  }

  get orderContacts() {
    return this.form.controls['contacts'] as UntypedFormArray;
  }

  get loadStops() {
    return this.form.controls['loadStops'] as UntypedFormArray;
  }

  addOrderContact() {
    this.orderContacts.push(
      this.fb.group({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
      })
    );
    if (this.orderEntry.isBooked && this.orderEntry.loadEditStatus === OrderEntryFormBookedEditEnum.AllowChanges) {
      this.enforceAddContactRestriction = true;
    }
  }

  deleteOrderContact(index: number) {
    this.orderContacts.removeAt(index);
  }

  setErrors() {
    this.errorSummary = '';
    this.errorCount = 0;
    this.setLoadErrors(this.problemDetails ? this.problemDetails.errors || {} : {});
  }

  setFormGroupErrors(
    formObject: UntypedFormGroup | UntypedFormArray,
    urnPrefix: string,
    errorMap: { urn: string; formControlName: string }[],
    errors
  ) {
    formObject.setErrors(null);
    Object.keys(formObject.controls).forEach((key) => {
      formObject.controls[key].setErrors(null);
    });

    for (const entry of errorMap) {
      const currentUrn = urnPrefix + (entry.urn && entry.urn.length ? ':' + entry.urn : '');
      const name = entry.formControlName;
      const controlErrors = errors ? errors[currentUrn] : null;

      // Track the full list of errors
      if (controlErrors) {
        for (const error of controlErrors) {
          if (error) {
            this.errorSummary += `${error.trim()}\n`;
            this.errorCount++;
          }
        }

        if (name != null) {
          if (name.length === 0) {
            formObject.setErrors(controlErrors);
          } else {
            formObject.get(name).setErrors(controlErrors);
          }
        }
      }
    }
  }

  setLoadErrors(errors) {
    const urnRoot = 'urn:root';
    this.setFormGroupErrors(this.form, urnRoot, this.loadMap, errors);

    this.setContactErrors(urnRoot, errors, this.form.get('contacts') as UntypedFormArray);
    this.setLoadStopErrors(urnRoot, errors);
  }

  setContactErrors(urnPrefix: string, errors, contacts: UntypedFormArray) {
    for (let i = 0; i < contacts.length; i++) {
      const contactUrnPrefix = `${urnPrefix}:Contacts:${i}`;
      this.setFormGroupErrors(contacts.controls[i] as UntypedFormGroup, contactUrnPrefix, this.contactMap, errors);
    }
  }

  setLoadStopErrors(urnPrefix: string, errors) {
    const loadStops = this.loadStops;
    let stopIndex = 0;
    let lineItemIndex = 0;

    for (let i = 0; i < loadStops.length; i++) {
      const stopUrnPrefix = `${urnPrefix}:LoadStops:${stopIndex}`;
      const stopGroup = loadStops.controls[i] as UntypedFormGroup;
      this.setFormGroupErrors(stopGroup, stopUrnPrefix, this.loadStopMap, errors);
      stopIndex++;

      const lineItems = stopGroup.controls['lineItems'] as UntypedFormArray;
      for (let j = 0; j < lineItems.length; j++) {
        const lineItemUrnPrefix = `${urnPrefix}:LineItems:${lineItemIndex}`;
        this.setFormGroupErrors(lineItems.controls[j] as UntypedFormGroup, lineItemUrnPrefix, this.lineItemMap, errors);
        lineItemIndex++;
      }

      const contacts = stopGroup.controls['contacts'] as UntypedFormArray;
      for (let j = 0; j < contacts.length; j++) {
        const contactUrnPrefix = `${stopUrnPrefix}:Contacts:${j}`;
        this.setFormGroupErrors(contacts.controls[j] as UntypedFormGroup, contactUrnPrefix, this.contactMap, errors);
      }
    }
  }

  buildForm(
    orderEntry: OrderEntryFormDisplay,
    schedConfTypes: AppointmentSchedulerConfirmationType[] = null,
    unitsOfMeasure: UnitOfMeasure[] = null
  ) {
    this.form = this.fb.group({
      loadId: [orderEntry.loadId],
      referenceLoadDisplay: [orderEntry.referenceLoadDisplay],
      referenceLoadId: [orderEntry.referenceLoadId],
      commodity: { commodityName: orderEntry.commodity },
      equipment: { equipmentId: orderEntry.equipment, equipmentDesc: orderEntry.equipmentDesc },
      contacts: this.buildLoadContacts(orderEntry.contacts),
      shipperPickupNumber: [orderEntry.shipperPickupNumber],
      transportationMode: [defaultTransportationMode],
      services: [orderEntry.services],
      loadStops: this.buildLoadStopsUI(orderEntry.loadStops, orderEntry.lineItems, schedConfTypes, unitsOfMeasure),
      specialInstructions: orderEntry.specialInstructions,
      lineHaulRate: [orderEntry.lineHaulRate],
      fuelRate: [orderEntry.fuelRate],
      weight: [orderEntry.weight],
      cube: [orderEntry.cube],
      customerFavoriteId: [null],
      customerFavoriteName: [null],
      isBooked: orderEntry.isBooked,
      loadEditStatus: orderEntry.loadEditStatus,
      isExternalLoad: orderEntry.isExternalLoad,
      smartSpotQuoteId: orderEntry.smartSpotQuoteId,
    });

    this.selectedEquipment = null;
    if (orderEntry.equipment) {
      this.selectedEquipment = {
        label: orderEntry.categoryEquipmentDesc,
        selectable: true,
        key: orderEntry.equipment,
      };
    }

    this.pickupStopNumbers = [];
    orderEntry.loadStops.forEach((_) => {
      if (_.stopType === StopTypes[StopTypes.Pickup]) {
        this.pickupStopNumbers.push({ stopNbr: _.stopNbr });
      }
    });

    setTimeout(() => {
      window.scroll(0, 0);
      if (this.editMode && orderEntry.isBooked) {
        this.setFormRestrictions(orderEntry.isBooked, orderEntry.loadEditStatus);
      } else {
        this.enableForm();
      }
    }, 100);

    // some of the settings, if we write in the above setFormRestrictions is not triggering the change detection to trigger
    // seems there is a mempry leak in this control- the state is not getting reset when we navigate out of the component
    if (this.editMode && orderEntry.isBooked) {
      const editStatus = {
        isBooked: orderEntry.isBooked,
        loadEditRestrictions: orderEntry.loadEditStatus,
      };
      this.orderEntryEditStatus = editStatus;

      if (orderEntry.loadEditStatus === OrderEntryFormBookedEditEnum.AllowChanges) {
        if (this.orderContacts.value.length > 0) {
          this.enforceAddContactRestriction = true;
        }
      }
    } else {
      this.enableForm();
    }
    const getPlaceId = (loadStop: any) => {
      if (loadStop && loadStop.latitude && loadStop.longitude) {
        return `${loadStop.latitude}::${loadStop.longitude}`;
      }
    };
    const getCustomerLaneRequest = (origin: any, destination: any, form: any) => {
      if (origin && destination) {
        return {
          originPlaceId: getPlaceId(origin),
          destinationPlaceId: getPlaceId(destination),
          equipment_Id: form?.equipment?.equipmentId,
        };
      }
    };

    // do not capture changes on form, the service Types shouldn't change and the
    // origin/destination will be validated on post when from BrokeredQuote.
    //
    // capture the form changes to validate if an API call to the customer lane api is needed
    if (this.isBrokeredQuote === false) {
      this.form.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(350)).subscribe((form) => {
        const origin = { ...form.loadStops[0] };
        const destination = { ...form.loadStops[form.loadStops.length - 1] };
        const { originPlaceId, destinationPlaceId, equipment_Id } = getCustomerLaneRequest(origin, destination, form);
        // preventing copied or injected data from triggering the customer lane changed behavior until form has been touched.
        if (this.form.untouched) {
          this.customerLaneRequest = {
            equipmentId: equipment_Id,
            originPlaceId: originPlaceId,
            destPlaceId: destinationPlaceId,
          };
          return;
        }

        if (
          !originPlaceId ||
          !this.customerLaneRequest ||
          originPlaceId !== this.customerLaneRequest.originPlaceId ||
          !destinationPlaceId ||
          destinationPlaceId !== this.customerLaneRequest.destPlaceId ||
          !equipment_Id ||
          equipment_Id !== this.customerLaneRequest.equipmentId
        ) {
          // lane has changed, fetch
          this.refreshCustomerLane = true;
        }
        if (originPlaceId && destinationPlaceId && equipment_Id && this.refreshCustomerLane) {
          // check if lane is populated to fetch customer lane
          this.refreshCustomerLane = false;

          // save request to compare if user changes
          this.customerLaneRequest = {
            equipmentId: equipment_Id,
            originPlaceId: originPlaceId,
            destPlaceId: destinationPlaceId,
          };
          // reset customer lane
          this.form.patchValue({
            services: null,
          });
          this.requiredServicesAutoApplied = null;

          const filter: SearchLaneFilterRequest = {
            equipmentId: equipment_Id,
            customerId: this.user.primaryCustomerId,
            origAddr1: origin.addressLine1,
            origCity: origin.city,
            origState: origin.state.abbreviation,
            origZip: origin.postalCode,
            origCountry: origin.country.name,
            origLat: origin.latitude,
            origLng: origin.longitude,
            destAddr1: destination.addressLine1,
            destCity: destination.city,
            destState: destination.state.abbreviation,
            destZip: destination.postalCode,
            destCountry: destination.country.name,
            destLat: destination.latitude,
            destLng: destination.longitude,
          };
          // fetch customer lane
          this.laneManagementService.getServiceTypeDetails(filter, 'RequiredServiceType').subscribe((laneMatch) => {
            // on call back, enable the control and populate
            // this.form.controls['services'].enable();
            if (!laneMatch) {
              return;
            }
            // merge the customer and the lane required services
            const laneRequiredServices = [];
            if (laneMatch.customer && laneMatch.customer.serviceTypeIds && laneMatch.customer.serviceTypeIds.length > 0) {
              laneRequiredServices.push(...laneMatch.customer.serviceTypeIds);
            }

            if (
              laneMatch.customerLaneGroupDetail &&
              laneMatch.customerLaneGroupDetail.serviceTypeIds &&
              laneMatch.customerLaneGroupDetail.serviceTypeIds.length > 0
            ) {
              laneRequiredServices.push(...laneMatch.customerLaneGroupDetail.serviceTypeIds);
            }

            const temp = this.serviceTypes.filter((y) => laneRequiredServices.find((z) => z === y.serviceTypeId));

            if (temp && temp.length > 0) {
              this.form.patchValue({
                services: temp,
              });

              this.requiredServicesAutoApplied = `Auto applied based on customer and lane: ${temp.map((y) => y.name).join(', ')}`;
            }
          });
        }
      });
    }
  }
  setFormRestrictions(isBooked: boolean, loadEditStatus: OrderEntryFormBookedEditEnum) {
    if (isBooked) {
      if (loadEditStatus === OrderEntryFormBookedEditEnum.AllowChanges) {
        this.form.controls['referenceLoadDisplay'].disable();
        const servicesToRemove = this.loadshopSettings
          .find((settingKey) => settingKey.key === 'ManualLoad_EditingBookedLoads_RestrictedServiceTypes')
          .value.split(',');
        let newServiceTypes;
        this.serviceTypes$.subscribe((data: ServiceType[]) => {
          newServiceTypes = data.filter(function (item) {
            return servicesToRemove.indexOf(item.name) === -1;
          });
          this.serviceTypes$ = new Observable((ob) => ob.next(newServiceTypes));
        });
      } else if (loadEditStatus === OrderEntryFormBookedEditEnum.LineItemsOnly) {
        this.form.controls['commodity'].disable();
        this.form.controls['referenceLoadDisplay'].disable();
        this.form.controls['contacts'].disable();
        this.form.controls['customerFavoriteId'].disable();
        this.form.controls['shipperPickupNumber'].disable();
        this.form.controls['transportationMode'].disable();
        this.form.controls['services'].disable();
        this.disableForLinieItemsOnly = true;
      }
    }
  }
  enableForm() {
    this.form.enable();
    this.disableForLinieItemsOnly = false;
    this.enforceAddContactRestriction = false;
    this.orderEntryEditStatus = null;

    if (this.isBrokeredQuote && !this.isExistingLoad) {
      this.form.controls['services'].disable();
    }
  }

  buildLoadContacts(contacts: LoadContact[]): UntypedFormArray {
    if (!contacts || contacts.length <= 0) {
      contacts = [];
    }

    const controls = contacts.map((c) =>
      this.fb.group({
        firstName: c.firstName,
        lastName: c.lastName,
        phoneNumber: c.phoneNumber,
        email: c.email,
      })
    );
    return this.fb.array(controls);
  }

  buildLoadStopContacts(contacts: LoadStopContact[]): UntypedFormArray {
    if (!contacts || contacts.length <= 0) {
      contacts = [];
    }

    const controls = contacts.map((c) =>
      this.fb.group({
        firstName: c.firstName,
        lastName: c.lastName,
        phoneNumber: c.phoneNumber,
        email: c.email,
      })
    );
    return this.fb.array(controls);
  }

  buildLoadLineItems(lineItems: LoadLineItem[], unitsOfMeasure: UnitOfMeasure[] = null): UntypedFormArray {
    if (!lineItems || lineItems.length <= 0) {
      lineItems = [];
    }

    const controls = lineItems.map((l) => {
      let unitOfMeasureName = null;
      if (unitsOfMeasure && unitsOfMeasure.length) {
        const uom = unitsOfMeasure.find((form) => form.unitOfMeasureId === l.unitOfMeasureId || form.name === l.unitOfMeasure);
        unitOfMeasureName = uom ? uom.name : null;
      }
      return this.fb.group({
        loadLineItemNumber: l.loadLineItemNumber,
        quantity: l.quantity,
        unitOfMeasure: unitOfMeasureName,
        weight: l.weight,
        customerPurchaseOrder: l.customerPurchaseOrder,
        pickupStopNumber: l.pickupStopNumber,
        deliveryStopNumber: l.deliveryStopNumber,
      });
    });
    return this.fb.array(controls);
  }

  /**
   * Build a list of load pairs to match the UI layout, where for each
   * row of the UI there is 0 or 1 pickups at index 0, and 0 or 1 deliveries
   * at index 2.  This way the UI can be built by looping through the list of rows.
   * @param loadStops List of all LoadStops, pickups first, followed by deliveries
   */
  buildLoadStopsUI(
    loadStops: OrderEntryLoadStop[],
    lineItems: LoadLineItem[],
    schedConfTypes: AppointmentSchedulerConfirmationType[] = null,
    unitsOfMeasure: UnitOfMeasure[] = null
  ): UntypedFormArray {
    const allStops = [];
    for (let form = 0; form < loadStops.length; form++) {
      const stopFormGroup = this.buildLoadStopFormGroup(loadStops[form], lineItems, schedConfTypes, unitsOfMeasure);
      allStops.push(stopFormGroup);
    }
    return this.fb.array(allStops);
  }

  buildLoadStopFormGroup(
    s: OrderEntryLoadStop,
    lineItems: LoadLineItem[],
    schedConfTypes: AppointmentSchedulerConfirmationType[] = null,
    unitsOfMeasure: UnitOfMeasure[] = null
  ): UntypedFormGroup {
    const stopLineItems =
      lineItems && s.stopType === StopTypes[StopTypes.Delivery] ? lineItems.filter((_) => _.deliveryStopNumber === s.stopNbr) : null;
    let schedulerConfirmationType = null;
    if (schedConfTypes && schedConfTypes.length > 0) {
      const confCode = s.appointmentConfirmationCode;
      const schedCode = s.appointmentSchedulingCode;
      if (confCode && schedCode) {
        schedulerConfirmationType = schedConfTypes.find(
          (item) => item.appointmentConfirmationCode === confCode && item.appointmentSchedulingCode === schedCode
        );
      }
    }
    return this.fb.group({
      loadStopId: s.loadStopId,
      loadId: s.loadId,
      stopNbr: s.stopNbr,
      city: s.city,
      state: { abbreviation: s.state, name: s.stateName },
      country: { id: s.country, name: this.getCountry(s.country) },
      latitude: s.latitude,
      longitude: s.longitude,
      earlyDtTm: s.earlyDtTm,
      earlyDate: s.earlyDate,
      earlyTime: s.earlyTime,
      lateDtTm: s.lateDtTm,
      lateDate: s.lateDate,
      lateTime: s.lateTime,
      apptType: s.apptType,
      instructions: s.instructions,
      schedulerConfirmationType: schedulerConfirmationType,
      locationName: s.locationName,
      addressAutoComplete: { address: s.address1 },
      address1: s.address1,
      address2: s.address2,
      address3: s.address3,
      postalCode: s.postalCode,
      isLive: s.isLive ? 'true' : 'false',
      stopType: s.stopType,
      contacts: this.buildLoadStopContacts(s.contacts),
      lineItems: this.buildLoadLineItems(stopLineItems, unitsOfMeasure),
    });
  }

  get countries() {
    return this.commonService.getCountries();
  }

  getCountry(countryAbbrev: string): string {
    if (!countryAbbrev) {
      return '';
    }
    if (!this.countries) {
      return countryAbbrev;
    }
    const country = this.countries.find((form) => form.id.toLowerCase() === countryAbbrev.toLowerCase());
    return country ? country.name : '';
  }

  addChildForm(name: string, group: UntypedFormGroup) {
    this.form.addControl(name, group);
  }

  isStopContactEmpty(c: LoadStopContact): boolean {
    return !c.firstName && !c.lastName && !c.phoneNumber && !c.email;
  }

  isApiLoadContactEmpty(c: any): boolean {
    return !c.display && !c.phoneNumber && !c.email;
  }

  mapFormToApi(): OrderEntryForm {
    const orderNumber = this.form.controls['referenceLoadDisplay'].value;
    const loadStopAndLineItems = this.mapLoadStopsToApi(orderNumber);
    const loadDetailData: OrderEntryForm = {
      loadId: this.form.controls['loadId'].value ? this.form.controls['loadId'].value : '',
      referenceLoadDisplay: orderNumber,
      referenceLoadId: this.form.controls['referenceLoadId'].value ? this.form.controls['referenceLoadId'].value : '',
      commodity: this.form.controls['commodity'].value ? this.form.controls['commodity'].value.commodityName : '',
      equipment: this.selectedEquipment ? this.selectedEquipment.key : '',
      lineItems: loadStopAndLineItems.lineItems,
      contacts: this.mapLoadContactsToApi(this.form.controls['contacts'] as UntypedFormArray),
      // If no value, Shipper Pickup Number should default to order number
      shipperPickupNumber: this.form.controls['shipperPickupNumber'].value
        ? this.form.controls['shipperPickupNumber'].value
        : this.form.controls['referenceLoadDisplay'].value,
      transportationMode: this.form.controls['transportationMode'].value ? this.form.controls['transportationMode'].value.name : null,
      services: this.form.controls['services'].value ? this.form.controls['services'].value.filter((form) => form !== null) : null,
      loadStops: fromLoadStops(loadStopAndLineItems.loadStops),
      specialInstructions: this.form.controls['specialInstructions'].value,
      miles: 0,
      lineHaulRate: this.form.controls['lineHaulRate'].value ? this.form.controls['lineHaulRate'].value : 0,
      fuelRate: this.form.controls['fuelRate'].value ? this.form.controls['fuelRate'].value : 0,
      weight: 0,
      cube: 0,
      isBooked: this.form.controls['isBooked'].value,
      loadEditStatus: this.form.controls['loadEditStatus'].value,
      isExternalLoad: this.form.controls['isExternalLoad'].value,
      smartSpotQuoteId: this.form.controls['smartSpotQuoteId'].value,
    };

    return loadDetailData;
  }

  mapLoadContactsToApi(f: UntypedFormArray): LoadContact[] {
    const contacts = [];
    for (let i = 0; i < f.length; i++) {
      const group = f.controls[i] as UntypedFormGroup;
      const display = ((group.controls['firstName'].value || '') + ' ' + (group.controls['lastName'].value || '')).trim() || null;
      const contact = {
        display: display,
        phoneNumber: group.controls['phoneNumber'].value,
        email: group.controls['email'].value,
      };

      // The API does not want empty contacts
      if (!this.isApiLoadContactEmpty(contact)) {
        contacts.push(contact);
      }
    }
    return contacts;
  }

  mapLoadStopsToApi(orderNumber: string): { loadStops: LoadStop[]; lineItems: LoadLineItem[] } {
    const loadStops = [];
    let lineItems = [];
    const lineItemNumber = 1;

    for (let i = 0; i < this.loadStops.length; i++) {
      const form = this.loadStops.controls[i] as UntypedFormGroup;
      const stop = this.mapLoadStopToApi(form);
      if (stop) {
        loadStops.push(stop);

        if (stop.stopType === StopTypes[StopTypes.Delivery]) {
          const stopLineItems = this.mapLoadLineItemsToApi(form, lineItemNumber, orderNumber);
          if (stopLineItems && stopLineItems.length > 0) {
            lineItems = lineItems.concat(stopLineItems);
          }
        }
      }
    }

    return { loadStops: loadStops, lineItems: lineItems };
  }

  mapLoadStopToApi(f: UntypedFormGroup): LoadStop {
    if (f && f.controls) {
      let earlyTime = f.controls['earlyTime'].value ? f.controls['earlyTime'].value : '';
      if (earlyTime) {
        earlyTime = earlyTime.toString().replace(':', '');
      }

      let lateTime = f.controls['lateTime'].value ? f.controls['lateTime'].value : '';
      if (lateTime) {
        lateTime = lateTime.toString().replace(':', '');
      }

      const stop = {
        loadStopId: f.controls['loadStopId'].value,
        loadId: f.controls['loadId'].value,
        stopNbr: f.controls['stopNbr'].value,
        city: f.controls['city'].value,
        state: f.controls['state'].value ? f.controls['state'].value.abbreviation : '',
        stateName: f.controls['state'].value ? f.controls['state'].value.name : '',
        country: f.controls['country'].value ? f.controls['country'].value.id : '',
        latitude: f.controls['latitude'].value,
        longitude: f.controls['longitude'].value,
        earlyDtTm: '',
        earlyDate: f.controls['earlyDate'].value ? f.controls['earlyDate'].value : '',
        earlyTime: earlyTime,
        lateDtTm: '',
        lateDate: f.controls['lateDate'].value ? f.controls['lateDate'].value : '',
        lateTime: lateTime,
        apptType: f.controls['apptType'].value,
        instructions: f.controls['instructions'].value,
        appointmentConfirmationCode: f.controls['schedulerConfirmationType'].value
          ? f.controls['schedulerConfirmationType'].value.appointmentConfirmationCode
          : '',
        appointmentSchedulingCode: f.controls['schedulerConfirmationType'].value
          ? f.controls['schedulerConfirmationType'].value.appointmentSchedulingCode
          : '',
        locationName: f.controls['locationName'].value,
        address1: f.controls['address1'].value,
        address2: f.controls['address2'].value,
        address3: f.controls['address3'].value,
        postalCode: f.controls['postalCode'].value,
        isLive: f.controls['isLive'].value,
        stopType: f.controls['stopType'].value,
        contacts: this.mapLoadStopContactsToApi(f.controls['contacts'] as UntypedFormArray),
      };

      return stop;
    }
    return null;
  }

  mapLoadStopContactsToApi(f: UntypedFormArray): LoadStopContact[] {
    const contacts = [];
    for (let i = 0; i < f.length; i++) {
      const group = f.controls[i] as UntypedFormGroup;
      const contact = {
        firstName: group.controls['firstName'].value,
        lastName: group.controls['lastName'].value,
        phoneNumber: group.controls['phoneNumber'].value,
        email: group.controls['email'].value,
      };

      // The API does not want empty contacts
      if (!this.isStopContactEmpty(contact)) {
        contacts.push(contact);
      }
    }
    return contacts;
  }

  mapLoadLineItemsToApi(f: UntypedFormGroup, lineItemNumber: number, orderNumber: string): LoadLineItem[] {
    const lineItems = [];
    if (f && f.controls) {
      const formArray = f.controls['lineItems'] as UntypedFormArray;
      for (let i = 0; i < formArray.length; i++) {
        const group = formArray.controls[i] as UntypedFormGroup;
        const customerPurchaseOrder = group.controls['customerPurchaseOrder'].value;
        if (!customerPurchaseOrder || customerPurchaseOrder.length === 0) {
          group.patchValue({ customerPurchaseOrder: orderNumber });
        }

        const lineItem = {
          loadLineItemNumber: group.controls['loadLineItemNumber'].value || lineItemNumber,
          quantity: group.controls['quantity'].value || 0,
          unitOfMeasure: group.controls['unitOfMeasure'].value,
          weight: group.controls['weight'].value || 0,
          customerPurchaseOrder: group.controls['customerPurchaseOrder'].value,
          pickupStopNumber: group.controls['pickupStopNumber'].value || 1,
          deliveryStopNumber: group.controls['deliveryStopNumber'].value || 0,
        };
        lineItems.push(lineItem);
        lineItemNumber++;
      }
    }
    return lineItems;
  }

  addStop(eventStopIndex: number) {
    const insertIndex = eventStopIndex + 1; // inserting after the index provided
    const stopNumber = insertIndex + 1;
    const lineItem = { ...defaultLoadLineItem, pickupStopNumber: 1, deliveryStopNumber: stopNumber };

    const stop = this.loadStops.value[eventStopIndex];

    let stopTemplate: OrderEntryLoadStop;
    if (stop.stopType === StopTypes[StopTypes.Delivery]) {
      stopTemplate = { ...defaultOrderEntryDeliveryStop, stopNbr: stopNumber };
    } else {
      stopTemplate = { ...defaultOrderEntryPickupStop, stopNbr: stopNumber };
    }
    this.loadStops.insert(insertIndex, this.buildLoadStopFormGroup(stopTemplate, [lineItem]));

    for (let i = 0; i < this.loadStops.length; i++) {
      this.setStopNbr(this.loadStops.controls[i] as UntypedFormGroup, i + 1);
    }

    this.updatePickupStopNumbers();
    this.adjustLineItemPickupStopNumbersForInsertedStop(stopNumber);
  }

  setStopNbr(formGroup: UntypedFormGroup, stopNumber: number) {
    formGroup.patchValue({ stopNbr: stopNumber });
    const lineItems = formGroup.get('lineItems') as UntypedFormArray;
    for (let i = 0; i < lineItems.length; i++) {
      lineItems.controls[i].patchValue({ deliveryStopNumber: stopNumber });
    }
  }

  adjustLineItemPickupStopNumbersForInsertedStop(insertedStopNumber: number) {
    for (let i = 0; i < this.loadStops.length; i++) {
      const lineItems = this.loadStops.controls[i].get('lineItems') as UntypedFormArray;
      for (let j = 0; j < lineItems.length; j++) {
        if (lineItems.controls[j].value.pickupStopNumber >= insertedStopNumber) {
          // if we inserted a stop, increment any pickup stop numbers that are higher than or equal to the insert
          lineItems.controls[j].patchValue({ pickupStopNumber: lineItems.controls[j].value.pickupStopNumber + 1 });
        }
      }
    }
  }

  adjustLineItemPickupStopNumbersForDeletedStop(deletedStopNumber: number) {
    for (let i = 0; i < this.loadStops.length; i++) {
      const lineItems = this.loadStops.controls[i].get('lineItems') as UntypedFormArray;
      for (let j = 0; j < lineItems.length; j++) {
        // if we deleted the line items pickup stop, reset it to 1, else if we are after the delete stop decrement
        if (lineItems.controls[j].value.pickupStopNumber === deletedStopNumber) {
          lineItems.controls[j].patchValue({ pickupStopNumber: 1 });
        } else if (lineItems.controls[j].value.pickupStopNumber > deletedStopNumber) {
          lineItems.controls[j].patchValue({ pickupStopNumber: lineItems.controls[j].value.pickupStopNumber - 1 });
        }
      }
    }
  }

  adjustLineItemPickupStopNumbersForChangedStopType(changedStopNumber: number) {
    for (let i = 0; i < this.loadStops.length; i++) {
      const lineItems = this.loadStops.controls[i].get('lineItems') as UntypedFormArray;
      for (let j = 0; j < lineItems.length; j++) {
        if (lineItems.controls[j].value.pickupStopNumber === changedStopNumber) {
          lineItems.controls[j].patchValue({ pickupStopNumber: 1 });
        }
      }
    }
  }

  changeStopType(index: number) {
    const changedStopNumber = this.loadStops.value[index].stopNbr;

    this.updatePickupStopNumbers();
    this.adjustLineItemPickupStopNumbersForChangedStopType(changedStopNumber);
  }

  updatePickupStopNumbers() {
    this.pickupStopNumbers = [];
    for (let i = 0; i < this.loadStops.length; i++) {
      const loadStop = this.loadStops.value[i];
      if (loadStop && loadStop.stopType === StopTypes[StopTypes.Pickup]) {
        this.pickupStopNumbers.push({ stopNbr: loadStop.stopNbr });
      }
    }

    this.pickupStopNumbers.sort((a, b) => (a.stopNbr > b.stopNbr ? 1 : -1));
    this.pickupStopNumbers = this.pickupStopNumbers.slice();
  }

  deleteStop(index: number) {
    const removedStop = this.loadStops.controls[index] as UntypedFormGroup;
    const removedStopNumber = removedStop.get('stopNbr').value;
    this.loadStops.removeAt(index);

    for (let i = 0; i < this.loadStops.length; i++) {
      this.setStopNbr(this.loadStops.controls[i] as UntypedFormGroup, i + 1);
    }

    this.updatePickupStopNumbers();
    this.adjustLineItemPickupStopNumbersForDeletedStop(removedStopNumber);
  }

  cancel() {
    this.router.navigate(['/shipping/home']);
  }

  save(validateDates: boolean = true) {
    const apiModel = this.mapFormToApi();
    this.apiRequest = JSON.stringify(apiModel, null, 2);

    if (validateDates) {
      if (!this.dateValidation(apiModel)) {
        return;
      }
    }

    window.scroll(0, 0);
    if (apiModel) {
      if (apiModel.loadId && apiModel.loadId.length > 0) {
        this.shippingStore.dispatch(new OrderEntryUpdateLoadAction(apiModel, !this.orderEntry.isBooked));
      } else {
        this.shippingStore.dispatch(new OrderEntryCreateLoadAction(apiModel));
      }
    }
  }

  private dateValidation(apiModel: OrderEntryForm): boolean {
    const today = new Date().setHours(0, 0, 0, 0);
    let errorMsg = '';

    apiModel.loadStops.forEach((stop, idx) => {
      if ((stop.earlyDate && stop.earlyDate < today) || stop.lateDate < today) {
        if (stop.stopType === StopTypes[StopTypes.Pickup]) {
          errorMsg += `Stop: ${stop.stopNbr} has a Pickup date in the past.<br/>`;
        } else {
          errorMsg += `Stop: ${stop.stopNbr} has a Delivery date in the past.<br/>`;
        }
      }
    });

    if (errorMsg.length > 0) {
      this.confirmationService.confirm({
        message: `${errorMsg}<br/>Are you sure you want to proceed?`,
        accept: () => {
          this.save(false);
        },
      });

      return false;
    }

    return true;
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (!this.form.dirty) {
      return true;
    }

    return new Promise((resolve, reject) => {
      this.confirmationService.confirm({
        message: 'Changes will not be saved.  Are you sure you want to proceed?',
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        },
      });
    });
  }

  equipmentSelectionMade(node: TreeNode): void {
    if (!node) {
      return;
    }
    this.form.patchValue({
      equipment: { equipmentId: this.selectedEquipment.key, equipmentDesc: this.selectedEquipment.label },
    });
  }

  private buildFavorite(): CustomerFavorite {
    const orderEntryTemplateData: OrderEntryTemplateLoadData = {
      ...DefaultOrderEntryTemplateLoadData,
      name: this.form.controls['customerFavoriteName'].value,
    };

    const favorite: CustomerFavorite = {
      customerFavoriteId: this.form.controls['customerFavoriteId'].value ? this.form.controls['customerFavoriteId'].value : 0,
      customerId: this.user.primaryCustomerId,
      type: AllCustomerFavoriteTypes.OrderEntryTemplate,
      version: '1',
      data: orderEntryTemplateData,
    };

    const loadDetailData = this.mapFormToApi();
    if (loadDetailData) {
      favorite.data.commodity = loadDetailData.commodity;
      favorite.data.equipmentType = loadDetailData.equipment;
      favorite.data.equipmentDesc = this.selectedEquipment ? this.selectedEquipment.label : '';
      favorite.data.categoryEquipmentDesc = this.selectedEquipment ? this.selectedEquipment.label : '';
      favorite.data.transportationMode = loadDetailData.transportationMode;
      favorite.data.comments = loadDetailData.specialInstructions;

      favorite.data.serviceTypes = loadDetailData.services;
      favorite.data.loadStops = loadDetailData.loadStops;
      favorite.data.lineItems = loadDetailData.lineItems;
    }

    return favorite;
  }

  public toggleFavoriteDialog(show: boolean) {
    this.showSaveFavoriteDialog = show;
  }

  public saveFavorite() {
    const favorite = this.buildFavorite();
    if (favorite.data.name) {
      if (favorite.customerFavoriteId) {
        this.coreStore.dispatch(new CustomerFavoriteUpdateAction(favorite));
      } else {
        this.coreStore.dispatch(new CustomerFavoriteAddAction(favorite));
      }

      this.toggleFavoriteDialog(false);
      if (this.saveLoadAfterSavingFavorite) {
        this.saveLoadAfterSavingFavorite = false;
        this.save();
      }
    } else {
      this.form.controls['customerFavoriteName'].setErrors({ incorrect: true });
      this.messageService.add({ detail: 'Template Name is required.', severity: 'error' });
    }
  }

  public deleteFavorite() {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete ${this.form.controls['customerFavoriteName'].value}?`,
      accept: () => {
        const favorite = this.buildFavorite();
        this.coreStore.dispatch(new CustomerFavoriteDeleteAction(favorite));
        this.form.controls['customerFavoriteId'].patchValue('');
        this.form.controls['customerFavoriteName'].patchValue('');
      },
    });
  }

  loadFavorite() {
    const selectedFavorite = this.favorites.find((form) => form.customerFavoriteId === this.form.controls['customerFavoriteId'].value);
    if (selectedFavorite) {
      (this.orderEntry.commodity = selectedFavorite.data.commodity), (this.orderEntry.equipment = selectedFavorite.data.equipmentType);
      this.orderEntry.equipmentDesc = selectedFavorite.data.equipmentDesc;
      this.orderEntry.categoryEquipmentDesc = selectedFavorite.data.categoryEquipmentDesc;
      this.orderEntry.shipperPickupNumber = selectedFavorite.data.shipperPickupNumber;
      this.orderEntry.transportationMode = selectedFavorite.data.transportationMode;
      this.orderEntry.specialInstructions = selectedFavorite.data.comments;

      this.orderEntry.services = selectedFavorite.data.serviceTypes;
      this.orderEntry.loadStops = selectedFavorite.data.loadStops;
      this.orderEntry.lineItems = selectedFavorite.data.lineItems;

      combineLatest([this.schedulerConfirmationTypes$, this.unitsOfMeasure$])
        .pipe(takeUntil(this.destroyed$), take(1))
        .subscribe(([schedConfTypes, unitsOfMeasure]) => {
          this.buildForm(this.orderEntry, schedConfTypes, unitsOfMeasure);
          this.shippingStore.dispatch(new OrderEntryClearErrorsAction());

          this.form.controls['customerFavoriteId'].patchValue(selectedFavorite.customerFavoriteId);
          this.form.controls['customerFavoriteName'].patchValue(selectedFavorite.data.name);
        });
    }
  }
}
