import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { CommonService } from '../../services/common.service';
import {
  SmartSpotPriceActionTypes,
  SmartSpotPriceLoadAction,
  SmartSpotPriceLoadFailureAction,
  SmartSpotPriceLoadQuoteAction,
  SmartSpotPriceLoadQuoteFailureAction,
  SmartSpotPriceLoadQuoteSuccessAction,
  SmartSpotPriceLoadSpotQuoteAction,
  SmartSpotPriceLoadSpotQuoteFailureAction,
  SmartSpotPriceLoadSpotQuoteSuccessAction,
  SmartSpotPriceLoadSuccessAction,
} from '../actions';

@Injectable()
export class SmartSpotPriceEffects {
  load$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(SmartSpotPriceActionTypes.Load),
      mergeMap((action: SmartSpotPriceLoadAction) =>
        this.commonService.getSmartSpotPrice(action.payload).pipe(
          map((response) => {
            if (response && response.success) {
              const data = response.data;
              return new SmartSpotPriceLoadSuccessAction(data);
            } else if (response && response.errors && response.errors.length > 0) {
              const error = response.errors[0];
              return new SmartSpotPriceLoadFailureAction(error);
            }
          })
        )
      )
    ) }
  );

  loadQuote$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(SmartSpotPriceActionTypes.LoadQuote),
      switchMap((action: SmartSpotPriceLoadQuoteAction) =>
        this.commonService.getSmartSpotQuote(action.payload).pipe(
          map((data) => new SmartSpotPriceLoadQuoteSuccessAction(data)),
          catchError((err) => of(new SmartSpotPriceLoadQuoteFailureAction(err)))
        )
      )
    ) }
  );

  createOrderFromQuote$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(SmartSpotPriceActionTypes.CreateOrderFromQuote),
      switchMap(() => {
        this.router.navigate(['/shipping/home/create']);
        return EMPTY;
      })
    ) },
    { dispatch: false }
  );

  spotQuote$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(SmartSpotPriceActionTypes.LoadSpotQuote),
      mergeMap((action: SmartSpotPriceLoadSpotQuoteAction) =>
        this.commonService.getSpotQuote(action.payload).pipe(
          map((response) => {
            if (response && response.success) {
              const data = response.data;
              return new SmartSpotPriceLoadSpotQuoteSuccessAction(data, action.payload.loadId);
            } else if (response && response.errors && response.errors.length > 0) {
              const error = response.errors[0];
              return new SmartSpotPriceLoadSpotQuoteFailureAction(error);
            }
          })
        )
      )
    ) }
  );

  constructor(private commonService: CommonService, private actions$: Actions, private router: Router) {}
}
