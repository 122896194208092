export enum CounterOfferRequestStatusEnum {
  PendingShipper = 'Pending Shipper',
  PendingCarrier = 'Pending Carrier',
  Approved = 'Approved',
  Denied = 'Denied',
  Expired = 'Expired',

  // UI ONLY
  ShipperCounter = 'ShipperCounter',
  CarrierCounter = 'CarrierCounter',
}
