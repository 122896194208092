/*
 ** API model for entities that can be targeted for chat
 */
export interface CreateChatEntityData {
  isShipper: boolean;
  entityId: string;
  entityName: string;
  identUserId: string;
  userDisplayName: string;
}
