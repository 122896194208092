import { FuelProgramTypeEnum } from './FuelProgramType';

// copied model from the Loadshop.Internal.Sdk.Common.Data.CustomerData
export interface CustomerData {
  customerId: string;
  name: string;
  prefetchContractRates: boolean;
  applyCounterOfferToAllLoads: boolean;
  isActive: boolean;
  isChatEnabled: boolean;
  useLoadshopAccessorials: boolean;
  useDefaultContact: boolean;
  isTOPSCustomer: boolean;
  deleteLoadCreatedOnTops: boolean;
  requireMarginalAnalysis: boolean;
  customerLoadTypeExpirationDate: any;
  autoPostLoad: boolean;
  customerLoadTypeId: string;
  useFuelRerating: boolean;
  validateAddresses: boolean;
  allowZeroFuel: boolean;
  allowEditingFuel: boolean;
  defaultCommodity: string;
  customerOfferingLevelId: string;
  autoAcceptBookedLoads: boolean;
  useContractRates: boolean;
  fuelReratingNumberOfDays: number;
  brokeredLoads: boolean;
  contractCarriersBookAtPostedRate: boolean;
  fuelProgramType?: FuelProgramTypeEnum;
}
