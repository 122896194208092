import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { NavigationGoAction } from '../../../shared/store';
import { UserProfileLoadAction } from '../../../user/store';
import { AgreementDocumentService } from '../../services/agreement-document.service';
import {
  AcceptAgreementDocumentAction,
  AcceptAgreementDocumentFailureAction,
  AcceptAgreementDocumentSuccessAction,
  AgreementDocumentActionTypes,
} from '../actions';

@Injectable()
export class AgreementDocumentEffects {
  $acceptAgreement = createEffect(() =>
    { return this.actions$.pipe(
      ofType<AcceptAgreementDocumentAction>(AgreementDocumentActionTypes.AcceptAgreement),
      switchMap(() =>
        this.agreementDocumentService.acceptAgreement().pipe(
          switchMap(() => [
            new AcceptAgreementDocumentSuccessAction(),
            new UserProfileLoadAction(), // reload user profile
            new NavigationGoAction({ path: ['/'] }),
          ]),
          catchError((err) => of(new AcceptAgreementDocumentFailureAction(err)))
        )
      )
    ) }
  );

  constructor(private actions$: Actions, private agreementDocumentService: AgreementDocumentService) {}
}
