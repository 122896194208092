import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { takeUntil } from 'rxjs/operators';
import { CreateDirectMessageData } from 'src/app/chat';
import { ShippingLaneHistoryDisplayComponent } from 'src/app/shipping/components/home/shipping-lane-history-display/shipping-lane-history-display.component';
import { counterOfferRequestModalZIndex, postLoadEditConfirmationMessage, tenderToCarrierModalZIndex } from '../../constants';
import {
  BookLoadRequest,
  BookLoadType,
  Customer,
  FeedbackQuestionEnum,
  Load,
  LoadAuditLogData,
  LoadDetail,
  LoadDocumentMetadata,
  LoadDocumentType,
  LoadStatusDetail,
  LoadStop,
  RatingQuestion,
  RatingQuestionAnswer,
  RemoveCarrierData,
  RemoveLoadData,
  ResponseError,
  TenderToOptions,
  TransactionType,
  UserModel,
  defaultLoadDetail,
} from '../../models';
import { FuelProgramTypeEnum } from '../../models/FuelProgramType';
import { Directions } from '../../models/directions';
import { LoadStopOptions } from '../../models/load-stop-options';
import { LoadStopStatus } from '../../models/load-stop-status';
import { SecurityAppActionType } from '../../models/security-app-action-type';
import { TransactionTypeService } from '../../services';
import { BaseComponent } from '../base-component';
import { CounterOfferRequestsModalComponent } from '../counter-offer';
import { TenderToCarrierModalComponent } from '../tender-to-carrier-modal';

@Component({
  selector: 'kbxl-load-detail',
  templateUrl: './load-detail.component.html',
  styleUrls: ['./load-detail.component.scss'],
  providers: [DialogService],
})
export class LoadDetailComponent extends BaseComponent implements OnChanges {
  @Input() user: UserModel;
  @Input() load: LoadDetail;
  @Input() isShippingDetail: boolean; // Do not show the view only warning if we're displaying load detail from a shipping tab
  @Input() isChatDetail: boolean;
  @Input() loadStatus: LoadStatusDetail;
  @Input() loadingStatus: boolean;
  @Input() captureReasonOnDelete = false;
  @Input() ratingQuestions: RatingQuestion[];
  @Input() loadDocumentTypes: LoadDocumentType[];
  @Input() loadDetailErrors: ResponseError[];
  @Input() processing: boolean;
  @Input() isReadOnly = false;
  @Input() loadAuditLogs: LoadAuditLogData[];
  @Input() shipper: Customer;
  @Input() showRateBreakDowns: boolean;
  @Output() book: EventEmitter<BookLoadRequest> = new EventEmitter<BookLoadRequest>();
  @Output() showMarginalAnalysis: EventEmitter<Load> = new EventEmitter<Load>();
  @Output() deleteLoad: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleteDetailLoad: EventEmitter<RemoveLoadData> = new EventEmitter<RemoveLoadData>();
  @Output() removeCarrier: EventEmitter<RemoveCarrierData> = new EventEmitter<RemoveCarrierData>();
  @Output() deleteDocument: EventEmitter<LoadDocumentMetadata> = new EventEmitter<LoadDocumentMetadata>();
  @Output() loadCurrentStatus = new EventEmitter<object>();
  @Output() viewCarrierClicks = new EventEmitter<object>();
  @Output() chatNow = new EventEmitter<CreateDirectMessageData>();

  loading = false;
  feedbackQuestionEnum = FeedbackQuestionEnum;
  menuActive = false;
  loadActionMenuItems: MenuItem[];
  loadStopOptions: LoadStopOptions;
  get accepted() {
    return this.load && this.load.loadTransaction && this.load.loadTransaction.transactionType === TransactionType.Accepted;
  }

  get delivered() {
    return this.load && this.load.loadTransaction && this.load.loadTransaction.transactionType === TransactionType.Delivered;
  }

  get pendingPod() {
    return this.load && this.load.loadTransaction && this.load.loadTransaction.transactionType === TransactionType.PendingPod;
  }

  get isCarrierDetail() {
    return !this.isShippingDetail;
  }

  get showLoadStops() {
    if (this.load && this.loadStops.length > 0 && !this.load.restrictiveViewOnly) {
      if (
        this.load.loadTransaction &&
        this.transactionTypeService.getTransactionTypesForMarketplace().some((i) => i === this.load.loadTransaction.transactionType) &&
        this.loadStops.length <= 2
      ) {
        return false;
      }

      return true;
    }
    return false;
  }

  loadStops: LoadStop[];
  origin: LoadStop;
  dest: LoadStop;
  allStatuses: LoadStopStatus[];
  directions: Directions;
  contacts: string[] = [];
  hasComments = false;
  showRemoveLoadPopup = false;
  showBookedLoadModal = false;
  removeComment = '';
  removeRatingQuestionAnswer = '';
  removeText = '';
  removeMode = -1; // 1 for remove carrier, 2 for remove load
  viewingCurrentStatus = true;

  latestOrigApptLabel = null;
  latestOrigEtaLabel = null;
  latestDestApptLabel = null;
  latestDestEtaLabel = null;
  fuelProgramTypeEnum = FuelProgramTypeEnum;
  constructor(
    private confirmationService: ConfirmationService,
    private router: Router,
    private dialogService: DialogService,
    private transactionTypeService: TransactionTypeService,
    private messageService: MessageService
  ) {
    super();
  }

  viewCarrierClicksClicked() {
    this.viewCarrierClicks.emit(null);
  }
  ngOnChanges(changes: SimpleChanges) {
    this.hasComments = false;

    this.loading = !this.load || this.load === defaultLoadDetail;

    if (this.load) {
      this.origin = this.load.loadStops[0];
      this.dest = this.load.loadStops[this.load.loadStops.length - 1];
      this.loadStops = this.load.loadStops;

      let statuses: LoadStopStatus[] = [];
      this.loadStops.forEach((stop) => {
        statuses = statuses.concat(stop.loadStopStatuses);
      });
      this.allStatuses = statuses.sort((a, b) => {
        const dateA = moment(a.statusDateUtc);
        const dateB = moment(b.statusDateUtc);
        return dateA.diff(dateB);
      });

      if (this.load.contacts && this.load.contacts.length) {
        this.contacts = this.load.contacts[0].email.split(';');
      }
      if (
        this.load.comments &&
        this.load.comments.length &&
        this.load.comments.trim() !== '<br>' &&
        this.load.comments.trim() !== '<div><br></div>'
      ) {
        this.hasComments = true;
      }
      this.buildMenu();
      this.loadStopOptions = this.getLoadStopOptions(this.load);
    } else {
      this.contacts = [];
      this.origin = null;
      this.dest = null;
      this.loadStops = [];
      this.loadActionMenuItems = [];
      this.loadStopOptions = null;
    }
    if (changes.loadDetailErrors) {
      this.showBookedLoadModal = false;
      if (this.load && this.load.counterOffer) {
        // TODO: figure out if we need to show modal
        this.viewCounterOffer();
      }
    }

    this.buildAppointmentAndEstimateLabels();
  }

  private buildMenu() {
    const tmpItems: MenuItem[] = [];

    if (this.user.hasSecurityAction(SecurityAppActionType.LoadCarrierViews)) {
      tmpItems.push({
        label: (this.loadAuditLogs && this.loadAuditLogs.length ? `${this.loadAuditLogs.length} ` : '') + 'Carrier Views',
        command: () => {
          this.viewCarrierClicksClicked();
        },
        icon: 'pi pi-search',
      });
    }

    if (!this.isReadOnly) {
      if (this.canCopyLoad()) {
        tmpItems.push({
          label: 'Copy Load',
          command: () => {
            this.copyLoadClick();
          },
          icon: 'pi pi-copy',
        });
      }

      if (this.canDeleteLoad()) {
        tmpItems.push({
          label: 'Delete Load',
          command: () => {
            this.deleteLoadClick();
          },
          icon: 'pi pi-times',
        });
      }

      if (this.canRemoveCarrier()) {
        tmpItems.push({
          label: 'Remove Carrier',
          command: () => {
            this.removeCarrierClick();
          },
          icon: 'pi pi-minus',
        });
      }

      if (this.canEditLoad()) {
        tmpItems.push({
          label: 'Edit Load',
          command: () => {
            this.editLoadClick();
          },
          icon: 'pi pi-times',
        });
      }

      if (this.canTenderToCarrier()) {
        tmpItems.push({
          label: 'Tender To',
          command: () => {
            this.showTenderToModal();
          },
          icon: 'pi pi-tag',
        });
      }

      if (this.canReviewLaneHistory()) {
        tmpItems.push({
          label: 'Lane History',
          command: () => {
            this.viewLaneHistoryClicked();
          },
          icon: 'pi pi-search',
        });
      }
    }
    this.loadActionMenuItems = tmpItems;
  }

  viewLaneHistoryClicked() {
    const currentZindex = Number(
      window.document.defaultView.getComputedStyle(document.getElementsByClassName('detail-sidebar')[0]).getPropertyValue('z-index')
    );
    this.dialogService.open(ShippingLaneHistoryDisplayComponent, {
      data: {
        loadId: this.load.loadId,
        laneHistory: [],
      },
      width: '100%',
      style: { 'max-width': 'calc(100vw - 200px)', overflow: 'auto', 'max-height': '70%' },
      header: 'Lane History',
      closable: true,
      closeOnEscape: true,
      baseZIndex: currentZindex + 1,
    });
  }

  buildAppointmentAndEstimateLabels() {
    if (this.loadStops && this.loadStops.length > 0) {
      if (this.origin && this.origin.loadStopStatuses) {
        const latestOrigAppt = this.origin.loadStopStatuses
          .filter((x) => x.statusType.indexOf('APPOINTMENT') >= 0)
          .sort((a, b) => (a.statusDateUtc >= b.statusDateUtc ? 1 : -1))[0];
        if (latestOrigAppt) {
          this.latestOrigApptLabel = `(APPT ${this.formatUtcDate(latestOrigAppt.statusDateUtc, latestOrigAppt.statusDateTimeZone)})`;
        } else {
          this.latestOrigApptLabel = null;
        }

        const latestOrigEta = this.origin.loadStopStatuses
          .filter((x) => x.statusTypeDesc.indexOf('ESTIMATED') >= 0)
          .sort((a, b) => (a.statusDateUtc >= b.statusDateUtc ? 1 : -1))[0];
        if (latestOrigEta) {
          this.latestOrigEtaLabel = `(ETA ${this.formatUtcDate(latestOrigEta.statusDateUtc, latestOrigEta.statusDateTimeZone)})`;
        } else {
          this.latestOrigEtaLabel = null;
        }
      }

      if (this.dest && this.dest.loadStopStatuses) {
        const latestDestAppt = this.dest.loadStopStatuses
          .filter((x) => x.statusType.indexOf('APPOINTMENT') >= 0)
          .sort((a, b) => (a.statusDateUtc >= b.statusDateUtc ? 1 : -1))[0];
        if (latestDestAppt) {
          this.latestDestApptLabel = `(APPT ${this.formatUtcDate(latestDestAppt.statusDateUtc, latestDestAppt.statusDateTimeZone)})`;
        } else {
          this.latestDestApptLabel = null;
        }

        const latestDestEta = this.dest.loadStopStatuses
          .filter((x) => x.statusTypeDesc.indexOf('ESTIMATED') >= 0)
          .sort((a, b) => (a.statusDateUtc >= b.statusDateUtc ? 1 : -1))[0];
        if (latestDestEta) {
          this.latestDestEtaLabel = `(ETA ${this.formatUtcDate(latestDestEta.statusDateUtc, latestDestEta.statusDateTimeZone)})`;
        } else {
          this.latestDestEtaLabel = null;
        }
      }
    }
  }

  private formatUtcDate(date: Date, timeZone: string) {
    return moment.utc(date).tz(timeZone).format('M/D/YY h:mm A z');
  }

  tryBookLoad(event: any) {
    if (!this.load || this.load.viewOnly) {
      return;
    }

    if (this.load.marginalAnalysisRequired) {
      // show marginal analysis modal in order to book the load
      this.showMarginalAnalysis.emit(this.load);
    } else {
      this.showBookedLoadModal = true;
    }
  }

  bookLoad(load: LoadDetail) {
    this.showBookedLoadModal = false;
    const request: BookLoadRequest = {
      bookingType: BookLoadType.CarrierBooked,
      loadData: load,
    };

    this.book.emit(request);
  }

  deleteLoadClick() {
    if (!this.captureReasonOnDelete) {
      // delete is clicked from marketplace, email, or delivered tab, do not show reason modal
      this.confirmationService.confirm({
        message: 'Are you sure you want to completely delete this load from Loadshop?',
        accept: () => this.deleteLoad.emit(this.load.loadId),
      });
    } else {
      // delete is clicked from booked
      this.removeMode = 2;
      this.showRemoveLoadPopup = true;
      this.removeText = 'Are you sure you want to delete this load from Loadshop?';
    }
  }

  copyLoadClick() {
    if (this.load && this.load.manuallyCreated && this.load.loadId) {
      this.router.navigate(['shipping/home/copy', this.load.loadId]);
    }
  }

  removeCarrierClick() {
    if (this.load.isPlatformLoad) {
      if (this.load.platformLoadUrl && this.load.platformLoadUrl.length > 0) {
        // create a hidden anchor tag and click on that to redirect user to platform
        const anchor = document.createElement('a');
        anchor.href = this.load.platformLoadUrl;
        anchor.target = '_blank';
        anchor.style.display = 'none';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      } else {
        console.warn(
          'Platform link not found for load, please login to the TM and find this shipment to remove carrier.  Please check load metadata for platformLoadUrl'
        );

        this.messageService.add({
          detail: 'Platform link not found for load, please login to the TM and find this shipment to the remove carrier.',
          severity: 'error',
        });
      }
    } else {
      this.removeMode = 1;
      this.showRemoveLoadPopup = true;
      this.removeText = 'Are you sure you want to remove the load from the marketplace?';
    }
  }
  editLoadClick() {
    if (
      this.user.isShipper &&
      (this.load.loadTransaction.transactionType === TransactionType.New ||
        this.load.loadTransaction.transactionType === TransactionType.Updated)
    ) {
      this.confirmationService.confirm({
        message: postLoadEditConfirmationMessage,
        accept: () => {
          this.router.navigate(['shipping/home/edit/', this.load.loadId]);
        },
      });
    } else {
      this.router.navigate(['shipping/home/edit/', this.load.loadId]);
    }
  }
  setDirections(directions: Directions) {
    this.directions = directions;
  }

  cancelRemovePopup(): void {
    this.showRemoveLoadPopup = false;
    this.removeComment = '';
    this.removeRatingQuestionAnswer = '';
    this.removeText = '';
    this.removeMode = -1;
  }
  removeConfirmed(): void {
    if (this.removeMode < 0) {
      return;
    }
    const ratingQuestionAnswer: RatingQuestionAnswer = {
      ratingQuestionId: this.removeRatingQuestionAnswer,
      answerYN: true,
      additionalComment: this.removeComment,
    };

    if (this.removeMode === 1) {
      const payload: RemoveCarrierData = {
        load: this.load,
        ratingQuestionAnswer: ratingQuestionAnswer,
      };
      this.removeCarrier.emit(payload);
    } else if (this.removeMode === 2) {
      const payload: RemoveLoadData = {
        load: this.load,
        ratingQuestionAnswer: ratingQuestionAnswer,
      };
      this.deleteDetailLoad.emit(payload);
    }
  }

  toggleLoadStatusView(event: Event) {
    event.stopPropagation();
    event.preventDefault();

    if (!this.viewingCurrentStatus) {
      this.loadCurrentStatus.emit(null);
    }
    this.viewingCurrentStatus = !this.viewingCurrentStatus;
  }

  removeDocument(doc: LoadDocumentMetadata): void {
    this.confirmationService.confirm({
      message: `Are you sure you want to remove ${doc.fileName}?`,
      accept: () => this.deleteDocument.emit(doc),
    });
  }

  shouldDisplayDocuments(): boolean {
    if (
      this.load &&
      (this.load.loadTransaction.transactionType === 'PreTender' ||
        this.load.loadTransaction.transactionType === 'Pending' ||
        this.load.loadTransaction.transactionType === 'SentToShipperTender' ||
        this.load.loadTransaction.transactionType === 'Accepted' ||
        this.load.loadTransaction.transactionType === 'Delivered')
    ) {
      return true;
    }
    return false;
  }
  getServiceTypes(): string {
    if (this.load.serviceTypes) {
      return this.load.serviceTypes.map((x) => x.name).join(',');
    }
    return '';
  }

  canBook(): boolean {
    if (this.load.viewOnly || this.isShippingDetail) {
      return false;
    }

    return this.load.loadTransaction.transactionType === 'New' || this.load.loadTransaction.transactionType === 'Updated';
  }

  getPostalCodeDisplay(stop: LoadStop): string {
    if (stop.postalCode) {
      return ', ' + stop.postalCode;
    }
  }

  showMenu(menuEnabled: boolean) {
    if (menuEnabled == null) {
      this.menuActive = !this.menuActive;
    } else {
      this.menuActive = menuEnabled;
    }
  }

  viewCounterOffer(): void {
    const ref = this.dialogService.open(CounterOfferRequestsModalComponent, {
      data: {
        loadId: this.load.loadId,
        user: this.user,
        fetchLoad: false,
        shipper: this.shipper,
      },
      header: 'View Counter Offers',
      baseZIndex: counterOfferRequestModalZIndex,
      closable: false,
    });

    ref.onClose.pipe(takeUntil(this.destroyed$)).subscribe((x: { refreshGrid: boolean }) => {});
  }

  shouldShowCarrierIneligibleWarning(): boolean {
    return (
      this.user &&
      this.user.isCarrier &&
      this.load.viewOnly &&
      !this.load.isAccepted &&
      this.load.loadTransaction.transactionType !== 'Removed'
    );
  }

  shouldShowShipperContactChatNow(): boolean {
    // Do this to avoid truthy to boolean type bitching
    const hasUserId = this.load.contacts[0].identUserId ? true : false;
    return !this.isChatDetail && this.isCarrierDetail && this.load.isShipperChatEnabled && hasUserId;
  }

  shouldShowBookingUserChatNow(): boolean {
    return !this.isChatDetail && this.isShippingDetail;
  }

  chatWithShipperContactClick(): void {
    const data: CreateDirectMessageData = {
      loadRefDisplay: this.load.referenceLoadDisplay,
      shipperId: this.load.customerId,
      shipperName: this.shipper.name,
      shipperUserFullName: this.load.contacts[0].display,
      carrierName: this.user.scacName,
      carrierScac: this.user.carrierScac,
      carrierUserFullName: this.user.name,
      members: [
        {
          // Shipper User
          id: this.load.contacts[0].identUserId,
          identityUserName: '', // Not used when mapping to ConversationMember
          displayName: this.load.contacts[0].display,
        },
        {
          // Carrier User
          id: this.user.identUserId,
          identityUserName: '', // Not used when mapping to ConversationMember
          displayName: this.user.name,
        },
      ],
    };
    this.chatNow.emit(data);
  }

  chatWithBookingUserClick(): void {
    const data: CreateDirectMessageData = {
      loadRefDisplay: this.load.referenceLoadDisplay,
      shipperId: this.shipper.customerId,
      shipperName: this.shipper.name,
      shipperUserFullName: this.user.name,
      carrierScac: this.load.scac,
      carrierName: this.load.bookedUserScacName,
      carrierUserFullName: this.load.bookedUser.fullName,
      members: [
        {
          // Shipper User
          id: this.user.identUserId,
          identityUserName: '', // Not used when mapping to ConversationMember
          displayName: this.user.name,
        },
        {
          // Carrier User
          id: this.load.bookedUser.identUserId,
          identityUserName: '', // Not used when mapping to ConversationMember
          displayName: this.load.bookedUser.fullName,
        },
      ],
    };
    this.chatNow.emit(data);
  }

  showTenderToModal(): void {
    const ref = this.dialogService.open(TenderToCarrierModalComponent, {
      data: {
        load: this.load,
        user: this.user,
      },
      header: 'Tender to Carrier',
      baseZIndex: tenderToCarrierModalZIndex,
      closable: true,
    });

    ref.onClose.pipe(takeUntil(this.destroyed$)).subscribe((x: { bookLoad: boolean; tenderToOptions: TenderToOptions }) => {
      if (x && x.bookLoad && x.tenderToOptions) {
        const request: BookLoadRequest = {
          bookingType: BookLoadType.TenderedTo,
          loadData: this.load,
          tenderingToOptions: x.tenderToOptions,
        };
        this.book.emit(request);
      }
    });
  }
  private canCopyLoad(): boolean {
    if (!this.user.hasSecurityAction(SecurityAppActionType.CopyManualLoad)) {
      return false;
    }

    return this.isShippingDetail && this.load.manuallyCreated;
  }

  private canRemoveCarrier(): boolean {
    if (!this.user.hasSecurityAction(SecurityAppActionType.LoadshopUIShoptLoadEdit)) {
      return false;
    }

    return (
      this.isShippingDetail &&
      (this.load.loadTransaction.transactionType === 'Accepted' ||
        this.load.loadTransaction.transactionType === 'Pending' ||
        this.load.loadTransaction.transactionType === 'PreTender' ||
        this.load.loadTransaction.transactionType === 'SentToShipperTender')
    );
  }

  private canDeleteLoad(): boolean {
    if (!this.user.hasSecurityAction(SecurityAppActionType.LoadshopUIShoptLoadEdit)) {
      return false;
    }

    return (
      this.isShippingDetail &&
      !this.load.isPlatformLoad &&
      this.load.loadTransaction.transactionType !== TransactionType.Delivered &&
      this.load.loadTransaction.transactionType !== TransactionType.PendingPod
    );
  }
  private canEditLoad(): boolean {
    if (
      this.user.isShipper &&
      (this.load.manuallyCreated || this.load.isExternalLoad) &&
      this.load.loadTransaction.transactionType !== TransactionType.Delivered &&
      this.load.loadTransaction.transactionType !== TransactionType.PendingPod &&
      this.load.allowEditingBookedManualLoads &&
      this.user.hasSecurityAction(SecurityAppActionType.EditManualLoad)
    ) {
      return true;
    }
    return false;
  }
  private canTenderToCarrier(): boolean {
    if (
      this.user.isCarrier &&
      this.user.hasSecurityAction(SecurityAppActionType.TenderToCarrier) &&
      (this.load.loadTransaction.transactionType === TransactionType.Updated ||
        this.load.loadTransaction.transactionType === TransactionType.New)
    ) {
      return true;
    }
    return false;
  }
  private canReviewLaneHistory(): boolean {
    if (this.user.isAdmin) {
      return true;
    }
    return false;
  }
  private getLoadStopOptions(load: LoadDetail): LoadStopOptions {
    let options: LoadStopOptions = null;
    if (this.transactionTypeService.getTransactionTypesForBooked().some((i) => i === load.loadTransaction.transactionType)) {
      options = new LoadStopOptions();
      options.showContacts = true;
      options.showCompleteAddress = true;
      options.showReferenceTypes = true;
    } else if (this.transactionTypeService.getTransactionTypesForDelivered().some((i) => i === load.loadTransaction.transactionType)) {
      options = new LoadStopOptions();
      options.showContacts = true;
      options.showCompleteAddress = true;
      options.showReferenceTypes = true;
    }
    return options;
  }
}
