import { Action } from '@ngrx/store';
import { ActionItemStatusData, CounterOfferActionItemLoadView, PageableResult } from '../../models';
import { PageableQueryHelper } from '../../utilities';

export enum CounterOfferActionItemActionTypes {
  LoadFilters = '[CounterOfferActionItem] LOAD_FILTERS',
  LoadFilters_Success = '[CounterOfferActionItem] LOAD_FILTERS_SUCCESS',
  LoadFilters_Failure = '[CounterOfferActionItem] LOAD_FILTERS_FAILURE',

  Load_ActionItems = '[CounterOfferActionItems] LOAD',
  Load_ActionItems_Success = '[CounterOfferActionItems] LOAD_SUCCESS',
  Load_ActionItems_Failure = '[CounterOfferActionItems] LOAD_FAILURE',
  Update_ActionItems_Query = '[CounterOfferActionItems] UPDATE_QUERY',
}

export class CounterOfferActionItemLoadFiltersAction implements Action {
  readonly type = CounterOfferActionItemActionTypes.LoadFilters;
}

export class CounterOfferActionItemLoadFiltersSuccessAction implements Action {
  readonly type = CounterOfferActionItemActionTypes.LoadFilters_Success;

  constructor(public payload: ActionItemStatusData) {}
}
export class CounterOfferActionItemLoadFiltersFailureAction implements Action {
  readonly type = CounterOfferActionItemActionTypes.LoadFilters_Failure;
  constructor(public payload: Error) {}
}

export class CounterOfferActionItemsLoadAction implements Action {
  readonly type = CounterOfferActionItemActionTypes.Load_ActionItems;

  constructor(public payload: { searchType: string; queryHelper: PageableQueryHelper }) {}
}

export class CounterOfferActionItemsLoadSuccessAction implements Action {
  readonly type = CounterOfferActionItemActionTypes.Load_ActionItems_Success;

  constructor(public payload: PageableResult<CounterOfferActionItemLoadView>) {}
}

export class CounterOfferActionItemsLoadFailureAction implements Action {
  readonly type = CounterOfferActionItemActionTypes.Load_ActionItems_Failure;

  constructor(public payload: Error) {}
}

export class CounterOfferActionItemsUpdateQueryAction implements Action {
  readonly type = CounterOfferActionItemActionTypes.Update_ActionItems_Query;

  constructor(public payload: PageableQueryHelper) {}
}
export type CounterOfferActionItemActions =
  | CounterOfferActionItemLoadFiltersAction
  | CounterOfferActionItemLoadFiltersSuccessAction
  | CounterOfferActionItemLoadFiltersFailureAction
  | CounterOfferActionItemsLoadAction
  | CounterOfferActionItemsLoadSuccessAction
  | CounterOfferActionItemsLoadFailureAction
  | CounterOfferActionItemsUpdateQueryAction;
