<p-sidebar
  [visible]="true"
  position="right"
  styleClass="detail-sidebar"
  [modal]="true"
  [blockScroll]="true"
  [dismissible]="true"
  [showCloseIcon]="false"
  (onHide)="onHide()"
  (visibleChange)="onVisibleChange($event)"
>
  <kbxl-sidebar-close (closed)="onHide()"></kbxl-sidebar-close>
  <kbxl-user-lane-detail
    [lane]="laneDetail$ | async"
    [states]="states$ | async"
    [equipment]="equipment$ | async | toTreeNodes"
    (updated)="updated($event)"
    (deleted)="deleted($event)"
    (closed)="closed($event)"
  >
  </kbxl-user-lane-detail>
</p-sidebar>
