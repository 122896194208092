<ng-container *ngIf="!isMobile">
  <p-dialog
    *ngIf="userCommunications"
    [visible]="userCommunications && userCommunications.length > 0"
    [style]="{ width: '60%' }"
    [appendTo]="'body'"
    [baseZIndex]="51"
    [modal]="true"
    [showHeader]="false"
    styleClass="ui-user-communication-dialog"
  >
    <ng-container [ngTemplateOutlet]="communication" [ngTemplateOutletContext]="{ userCommunications: userCommunications }"></ng-container>
  </p-dialog>
</ng-container>
<ng-container *ngIf="isMobile">
  <p-sidebar
    [visible]="userCommunications && userCommunications.length > 0"
    [fullScreen]="true"
    [dismissible]="false"
    [showCloseIcon]="false"
    [closeOnEscape]="false"
  >
    <ng-container [ngTemplateOutlet]="communication" [ngTemplateOutletContext]="{ userCommunications: userCommunications }"></ng-container>
  </p-sidebar>
</ng-container>
<ng-template #communication let-userCommunications="userCommunications">
  <p-carousel [value]="userCommunications" [numVisible]="1" [circular]="false" *ngIf="userCommunications && userCommunications.length > 1">
    <p-header>
      <h4>Loadshop Communications</h4>
    </p-header>
    <ng-template let-userCommunication pTemplate="item">
      <ng-container
        [ngTemplateOutlet]="communicationHtml"
        [ngTemplateOutletContext]="{ userCommunication: userCommunication }"
      ></ng-container>
    </ng-template>
  </p-carousel>
  <ng-container
    [ngTemplateOutlet]="communicationHtml"
    [ngTemplateOutletContext]="{ userCommunication: userCommunications[0] }"
    *ngIf="userCommunications && userCommunications.length === 1"
  ></ng-container>
</ng-template>
<ng-template #communicationHtml let-userCommunication="userCommunication">
  <div class="d-flex flex-column mb-4 mb-md-0 p-md-2" style="position: relative">
    <div class="progress-indicator" *ngIf="processing$ | async">
      <div class="progressspinner-container">
        <p-progressSpinner></p-progressSpinner>
      </div>
    </div>
    <p-panel>
      <p-header>
        {{ userCommunication.title }}
      </p-header>
      <div class="communication" [innerHtml]="userCommunication.message"></div>
      <div class="d-flex flex-row-reverse mt-2">
        <button
          pButton
          type="button"
          class="p-button-info flex-fill"
          label="Acknowledge"
          (click)="onAcknowledge(userCommunication)"
        ></button>
      </div>
    </p-panel>
  </div>
</ng-template>
