export interface SpotPriceQuoteRequest {
  loadId: string;
  commodity: string;
  shipmentServices: string[];
  equipmentCategory: string;
  equipmentId: string;
  origin: SpotPriceQuoteLocation;
  destination: SpotPriceQuoteLocation;
  pickupAppointmentType: string;
  dropoffAppointmentType: string;
  stops: number;
  miles: number;
  weight: number;
  intermediateStopsCoordinates?: SpotPriceQuoteRequestLatLongPoint[];
  totalPostedScacs: number;
  totalPostedCarriers: number;
  originLateArrivalDateTime: Date;
  destinationLateArrivalDateTime: Date;
}

export interface SpotPriceQuoteLocation {
  city: string;
  state: string;
  countryCode: string;
  postalCode: string;
  coordinates: SpotPriceQuoteRequestLatLongPoint;
}

export interface SpotPriceQuoteRequestLatLongPoint {
  latitude: number;
  longitude: number;
}

export interface SpotPriceQuickQuoteRequest {
  commodity: string;
  shipmentServices: string[];
  equipmentCategory: string;
  equipmentId: string;
  origin: SpotPriceQuoteLocation;
  destination: SpotPriceQuoteLocation;
  intermediateStopsCoordinates?: SpotPriceQuoteRequestLatLongPoint[];
  pickupAppointmentTypeCode: string;
  dropoffAppointmentTypeCode: string;
  stops: number;
  weight: number;
  totalPostedScacs: number;
  totalPostedCarriers: number;
  pickupDate: Date;
  pickupTime: string;
  deliveryDate: Date;
  deliveryTime: string;
}
