import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, timer, EMPTY } from 'rxjs';
import { delayWhen, retryWhen, switchMap } from 'rxjs/operators';
import { GoogleMapService } from '../../services/google-map.service';
import { LoadshopApplicationActionTypes } from 'src/app/shared/store';

declare let google: any;

@Injectable()
export class GoogleMapEffects {
  $startup = createEffect(() =>
    { return this.actions$.pipe(
      ofType(LoadshopApplicationActionTypes.LoadshopStart),
      switchMap(() => {
        if (google.maps) {
          this.googleMapService.initialize();
        } else {
          throw new Error('google.maps not loaded yet');
        }

        return EMPTY;
      }),
      retryWhen((errors) => errors.pipe(delayWhen(() => timer(10))))
    ) },
    { dispatch: false }
  );

  constructor(private googleMapService: GoogleMapService, private actions$: Actions) {}
}
