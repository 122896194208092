import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { CookieService } from 'ngx-cookie-service';
import { lastValueFrom, Observable, of, switchMap } from 'rxjs';
import { TopsTokenRefreshService } from '../services/tops-token-refresh.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private securityService: OidcSecurityService;
  private cookieService: CookieService;
  private topsTokenRefreshService: TopsTokenRefreshService;

  constructor(private injector: Injector) {}

  // tslint:disable: no-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestToForward = req;

    if (this.securityService === undefined) {
      this.securityService = this.injector.get(OidcSecurityService);
    }
    if (this.cookieService === undefined) {
      this.cookieService = this.injector.get(CookieService);
    }
    if (this.topsTokenRefreshService === undefined) {
      this.topsTokenRefreshService = this.injector.get(TopsTokenRefreshService);
    }

    this.topsTokenRefreshService.setLastRequestDate(new Date());

    return this.getToken()
    .pipe(
      switchMap((token) => {
        if (token !== '') {
          const tokenValue = `Bearer ${token}`;
          requestToForward = req.clone({
            setHeaders: { Authorization: tokenValue },
          });
        }
        return next.handle(requestToForward);
      })
    );
    
  }
  // tslint:enable: no-any

  private getToken(): Observable<string> {
    if (this.cookieService !== undefined) {
      const accessToken = this.cookieService.get('TopsAT');
      if (accessToken) {
        return of(accessToken);
      }
    }
    if (this.securityService !== undefined) {
      return this.securityService.getAccessToken();
    }
    return of('');
  }
}
