import { AgreementDocumentData, CounterOfferData, MarginalAnalysisWorksheet } from '.';
import { Load, defaultLoad } from './load';
import { LoadAccessorialData } from './load-accessorial-data';
import { LoadAccessorialRequestAvailabilityData } from './load-accessorial-request-availability-data';
import { LoadCurrentStatusData } from './load-current-status-data';
import { LoadShareLink } from './load-share-link';
import { RateBreakDownData } from './rate-break-down-data';

export interface LoadDetail extends Load {
  viewOnly: boolean;
  isEstimatedFSC: boolean;
  billingLoadId: string;
  billingLoadDisplay: string;
  hasDynamicPrice: boolean;
  marginalAnalysisRequired: boolean;
  marginalAnalysisWorksheet: MarginalAnalysisWorksheet;
  bookingAgreements: AgreementDocumentData[];
  smartSpot: number;
  loadAccessorials: LoadAccessorialData[];
  carb: boolean;
  carbAdj: boolean;
  shareLinks: LoadShareLink[];
  loadAccessorialRequests: LoadAccessorialRequestAvailabilityData;
  loadErrorMessage: string;
  restrictiveViewOnly: boolean;
  counterOffer?: CounterOfferData;
  customerId: string;
  isShipperChatEnabled: boolean;
  allowEditingBookedManualLoads: boolean;
  isBrokeredLoad: boolean;
  isExternalLoad: boolean;
  rateBreakDownsData: RateBreakDownData[];
  loadCurrentStatus: LoadCurrentStatusData;
}

export const defaultLoadDetail: LoadDetail = {
  ...defaultLoad,
  viewOnly: false,
  isEstimatedFSC: null,
  billingLoadId: null,
  billingLoadDisplay: null,
  hasDynamicPrice: null,
  marginalAnalysisRequired: false,
  marginalAnalysisWorksheet: null,
  smartSpot: 0,
  bookingAgreements: [],
  loadAccessorials: [],
  carb: null,
  carbAdj: null,
  shareLinks: [],
  loadAccessorialRequests: null,
  loadErrorMessage: null,
  restrictiveViewOnly: false,
  customerId: null,
  isShipperChatEnabled: false,
  allowEditingBookedManualLoads: false,
  isBrokeredLoad: false,
  isExternalLoad: false,
  rateBreakDownsData: null,
  loadCurrentStatus: null,
};
