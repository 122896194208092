<div class="d-flex flex-column flex-xl-row">
  <div class="d-flex flex-column">
    <div class="error-container" *ngIf="validate && errorMsg && errorMsg.length > 0">
      <p class="m-0">{{ errorMsg }}</p>
    </div>
    <form [formGroup]="pricingForm">
      <div class="kbxl-inputgroup-container">
        <div class="d-flex">
          <div>
            <div class="kbxl-field-label">
              <label class="control-label">Rate (Floor) Calculator</label>
              <kbxl-error-list [errors]="errors" [urnPath]="getUrnPath('floor')" errorType="field"></kbxl-error-list>
            </div>
            <p-selectButton
              class="calculation-buttons mb-2"
              [options]="calculationTypes"
              formControlName="calculation"
              (onChange)="resetFloor($event)"
            ></p-selectButton>
          </div>
          <div class="floor-input ml-3">
            <div *ngIf="pricingForm.value.calculation === 'flat'">
              <div class="kbxl-field-label">
                <label class="control-label">&nbsp;</label>
              </div>
              <input [readonly]="doe_changes_temporary_readonly" pInputText type="text" class="pricing-detail" formControlName="floorAmount" kbxlCurrencyInput />
            </div>
            <div *ngIf="pricingForm.value.calculation === 'percent'">
              <div class="kbxl-field-label">
                <label class="control-label">&nbsp;</label>
              </div>
              <div class="percentage-dropdown">
                <p-dropdown
                  [readonly]="doe_changes_temporary_readonly"
                  appendTo="body"
                  [options]="percentOptions | toSelectItem"
                  placeholder="Select Percent"
                  [styleClass]="'p-dropdown-primary'"
                  dropdownIcon="pi pi-caret-down"
                  formControlName="floorPercent"
                  showClear="true"
                >
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="brokeredLoad">
        <div class="d-flex justify-content-between">
          <div>
            <div class="kbxl-field-label">
              <label class="control-label">Quoted Rate</label>
            </div>
            <div>
              {{ brokeredTotalPrice | currency }}
            </div>
          </div>
          <div></div>
          <div *ngIf="useReservedMargin">
            <div class="kbxl-field-label">
              <label class="control-label">Reserved Margin</label>
            </div>
            <div>
              <input pInputText type="text" class="reserved-margin" formControlName="reservedMarginControl" kbxlCurrencyInput />
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div *ngIf="!!marketplaceView" class="mt-3">
        <div class="d-flex justify-content-between">
          <div>
            <div class="kbxl-field-label">
              <label class="control-label">Finishing Line Haul</label>
            </div>
            <div>
              {{ demolineHaul | currency }}
            </div>
          </div>
          <div>
            <div class="kbxl-field-label">
              <label class="control-label">&nbsp;</label>
            </div>
            <span>-</span>
          </div>
          <div>
            <div class="kbxl-field-label">
              <label class="control-label">Adjustment Amount</label>
            </div>
            <div [ngClass]="{ visible: pricingSchedule && graphData, invisible: !pricingSchedule && !graphData }">
              {{ pricingSchedule?.totalAdjustmentAmount | currency }}
            </div>
          </div>
          <div>
            <div class="kbxl-field-label">
              <label class="control-label">&nbsp;</label>
            </div>
            <span>=</span>
          </div>
          <div>
            <div class="kbxl-field-label">
              <label class="control-label">Starting Line Haul</label>
            </div>
            <div [ngClass]="{ visible: pricingSchedule && graphData, invisible: !pricingSchedule && !graphData }">
              {{ getStartingLineHaul() | currency }}
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column mt-4" *ngIf="brokeredLoad && pricingRule">
        <div class="kbxl-inputgroup-container">
          <div class="kbxl-field-label">
            <label class="control-label">Start Before Pickup Date</label>
          </div>
          <div>
            <kbxl-hours-to-days-slider
              [(hours)]="pricingRule.startBeforePickUpDateHours"
              [control]="pricingForm.controls.startBeforePickUpControl"
              inputName="startBeforePickUpDate"
            ></kbxl-hours-to-days-slider>
          </div>
        </div>
        <div class="kbxl-inputgroup-container">
          <div class="kbxl-field-label">
            <label class="control-label">Stop Before Pickup Date</label>
          </div>
          <div>
            <kbxl-hours-to-days-slider
              [(hours)]="pricingRule.stopBeforePickUpDateHours"
              [control]="pricingForm.controls.stopBeforePickUpControl"
              inputName="stopBeforePickUpDate"
            ></kbxl-hours-to-days-slider>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="kbxl-field-label">
          <label class="control-label">Frequency of Line Haul Adjustment</label>
        </div>
        <div class="d-flex">
          <div class="kbxl-inputgroup-container">
            <div class="kbxl-field-label">
              <label class="control-label">Intervals</label>
              <kbxl-error-list [errors]="errors" [urnPath]="getUrnPath('NbrAdjustments')" errorType="field"></kbxl-error-list>
            </div>
            <div class="interval-dropdown">
              <p-dropdown
                [readonly]="doe_changes_temporary_readonly"
                appendTo="body"
                [options]="intervalOptions | toSelectItem"
                placeholder="Select Interval"
                [styleClass]="'p-dropdown-primary'"
                dropdownIcon="pi pi-caret-down"
                formControlName="interval"
                showClear="true"
              >
              </p-dropdown>
            </div>
          </div>
          <div class="kbxl-inputgroup-container ml-3">
            <div class="kbxl-field-label">
              <label class="control-label">Timing</label>
              <kbxl-error-list [errors]="errors" [urnPath]="getUrnPath('AdjustmentFrequency')" errorType="field"></kbxl-error-list>
            </div>
            <div *ngIf="!brokeredLoad" class="timing-dropdown">
              <p-dropdown
                [readonly]="doe_changes_temporary_readonly"
                appendTo="body"
                [options]="frequencyOptions | toSelectItem"
                placeholder="Select Frequency"
                [styleClass]="'p-dropdown-primary'"
                dropdownIcon="pi pi-caret-down"
                formControlName="frequency"
                showClear="true"
              >
              </p-dropdown>
            </div>
            <div *ngIf="brokeredLoad">
              {{ pricingRule?.adjustmentFrequency | timeSpanToTime }}
            </div>
          </div>
          <div class="kbxl-inputgroup-container ml-3">
            <div class="kbxl-field-label">
              <label class="control-label">Line Haul Adjustment Amount</label>
            </div>
            <div>
              <div *ngIf="loadingDynamicPricing && !showSchedule" class="dynamic-price-spinner-container font-weight-bold d-flex">
                <p-progressSpinner></p-progressSpinner>
                <div class="ml-2">Loading...</div>
              </div>
              <div *ngIf="pricingSchedule">
                <span>{{ pricingSchedule?.priceIncreaseAmount | currency }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="showSchedule" class="mx-xl-4 flex-fill">
    <kbxl-auto-approve-warning
      *ngIf="showAutoApproveWarning && pricingSchedule && graphData"
      [hoverWarning]="false"
    ></kbxl-auto-approve-warning>
    <div *ngIf="loadingDynamicPricing" class="dynamic-price-spinner-container font-weight-bold d-flex">
      <p-progressSpinner></p-progressSpinner>
      <div class="ml-2">Loading...</div>
    </div>
    <div
      class="d-flex flex-column dynamic-price-chart"
      [ngClass]="{ visible: pricingSchedule && graphData, invisible: !pricingSchedule || !graphData }"
    >
      <div class="kbxl-field-label text-center">
        <label *ngIf="!!marketplaceView" class="control-label"
          >Current Marketplace pricing schedule with {{ demolineHaul | currency }} line haul rate</label
        >
        <label *ngIf="!marketplaceView" class="control-label"
          >Visual of <span [innerHtml]="rateSeekerLabel"></span> with {{ demolineHaul | currency }} Line Haul Rate</label
        >
      </div>
      <p-chart class="flex-grow-1 mb-2" type="line" [data]="graphData" [options]="graphPlugIns"></p-chart>
      <div class="mx-2 schedule-table">
        <p-table
          [rowHover]="true"
          [value]="pricingSchedule?.schedule"
          styleClass="p-datatable-no-border"
          [scrollable]="true"
          scrollHeight="200px"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Date Time</th>
              <th class="text-right">Amount</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-schedule>
            <tr [pSelectableRow]="pricingRule" [ngClass]="{ 'active-price': schedule.activePrice }">
              <td>
                <span class="mr-3"> {{ schedule.adjustmentStartTime | date: 'M/d/yy h:mm a' }}</span>
              </td>
              <td class="text-right">
                <span>{{ schedule.adjustmentPrice | currency }} </span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
