import { AuthUser } from '../../models/auth-user';
import { AuthActions, AuthLoginActionTypes } from '../actions/auth.actions';

export interface AuthState {
  loggedin: boolean;
  loggingin: boolean;
  isAuthorized: boolean;
  entity: AuthUser;
}

const initialState: AuthState = {
  loggedin: false,
  loggingin: false,
  isAuthorized: false,
  entity: null,
};

export function authReducer(state: AuthState = initialState, action: AuthActions): AuthState {
  switch (action.type) {
    case AuthLoginActionTypes.Login: {
      return Object.assign({}, state, {
        loggingin: true,
      });
    }
    case AuthLoginActionTypes.Login_Success: {
      return Object.assign({}, state, {
        loggedin: true,
        loggingin: false,
        isAuthorized: true,
        entity: action.payload,
      });
    }
    case AuthLoginActionTypes.Logout:
    case AuthLoginActionTypes.Login_Failure: {
      return Object.assign({}, state, {
        loggingin: false,
        loggedin: false,
        isAuthorized: false,
        entity: null,
      });
    }
    case AuthLoginActionTypes.Login_Forbidden: {
      return Object.assign({}, state, {
        loggingin: false,
        loggedin: true,
        isAuthorized: false,
      });
    }
    default:
      return state;
  }
}

export const getLoggedIn = (state: AuthState) => state.loggedin;
export const getLoggingIn = (state: AuthState) => state.loggingin;
export const getEntity = (state: AuthState) => state.entity;
