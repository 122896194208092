import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { mapToPayload } from 'src/app/shared/utilities/map-to-payload';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { defaultUserAdminData, UserAdminData } from 'src/app/shared/models';
import { UserAdminService } from '../../services';
import {
  UserAdminActionTypes,
  UserAdminCreateUserAction,
  UserAdminCreateUserFailureAction,
  UserAdminCreateUserSuccessAction,
  UserAdminDeleteUserAction,
  UserAdminDeleteUserFailureAction,
  UserAdminDeleteUserSuccessAction,
  UserAdminLoadAdminUsersAction,
  UserAdminLoadAdminUsersFailureAction,
  UserAdminLoadAdminUsersSuccessAction,
  UserAdminLoadAuthorizedEntitiesAction,
  UserAdminLoadAuthorizedEntitiesFailureAction,
  UserAdminLoadAuthorizedEntitiesSuccessAction,
  UserAdminLoadCarrierUsersDataAction,
  UserAdminLoadCarrierUsersDataFailureAction,
  UserAdminLoadCarrierUsersDataSuccessAction,
  UserAdminLoadIdentityUserAction,
  UserAdminLoadIdentityUserFailureAction,
  UserAdminLoadIdentityUserNotFoundAction,
  UserAdminLoadIdentityUserSuccessAction,
  UserAdminLoadUserAction,
  UserAdminLoadUserFailureAction,
  UserAdminLoadUsersAction,
  UserAdminLoadUsersFailureAction,
  UserAdminLoadUsersSuccessAction,
  UserAdminLoadUserSuccessAction,
  UserAdminUpdateUserAction,
  UserAdminUpdateUserFailureAction,
  UserAdminUpdateUserSuccessAction,
} from '../actions';

@Injectable()
export class UserAdminEffects {
  $loadUsers = createEffect(() =>
    { return this.actions$.pipe(
      ofType<UserAdminLoadUsersAction>(UserAdminActionTypes.Load_Users),
      mapToPayload<{ query: string }>(),
      switchMap((payload: { query: string }) =>
        this.userAdminService.getUsers(payload.query).pipe(
          map((data) => new UserAdminLoadUsersSuccessAction(data)),
          catchError((err) => of(new UserAdminLoadUsersFailureAction(err)))
        )
      )
    ) }
  );

  $loadAdminUsers = createEffect(() =>
    { return this.actions$.pipe(
      ofType<UserAdminLoadAdminUsersAction>(UserAdminActionTypes.Load_AdminUsers),
      mapToPayload<{ query: string }>(),
      switchMap((payload: { query: string }) =>
        this.userAdminService.getAdminUsers().pipe(
          map((data) => new UserAdminLoadAdminUsersSuccessAction(data)),
          catchError((err) => of(new UserAdminLoadAdminUsersFailureAction(err)))
        )
      )
    ) }
  );

  $loadUser = createEffect(() =>
    { return this.actions$.pipe(
      ofType<UserAdminLoadUserAction>(UserAdminActionTypes.Load_User),
      mapToPayload<{ userId: string }>(),
      switchMap((payload: { userId: string }) =>
        this.userAdminService.getUser(payload.userId).pipe(
          map((data) => new UserAdminLoadUserSuccessAction(data)),
          catchError((err) => of(new UserAdminLoadUserFailureAction(err)))
        )
      )
    ) }
  );

  $loadIdentityUser = createEffect(() =>
    { return this.actions$.pipe(
      ofType<UserAdminLoadIdentityUserAction>(UserAdminActionTypes.Load_Identity_User),
      mapToPayload<{ username: string }>(),
      switchMap((payload: { username: string }) =>
        this.userAdminService.getIdentityUser(payload.username).pipe(
          map((data) => {
            if (data) {
              return new UserAdminLoadIdentityUserSuccessAction({
                ...defaultUserAdminData,
                identUserId: data.id,
                username: data.userName,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                companyName: data.company,
                allowCounterOffer: true, // allow the default user to have counter offer
              });
            }
            this.messageService.add({ id: 0, detail: 'User not found. Please check the user name and try again', severity: 'warn' });
            return new UserAdminLoadIdentityUserNotFoundAction();
          }),
          catchError((err) => of(new UserAdminLoadIdentityUserFailureAction(err)))
        )
      )
    ) }
  );

  $updateUser = createEffect(() =>
    { return this.actions$.pipe(
      ofType<UserAdminUpdateUserAction>(UserAdminActionTypes.Update_User),
      mapToPayload<{ user: UserAdminData }>(),
      switchMap((payload: { user: UserAdminData }) =>
        this.userAdminService.updateUser(payload.user).pipe(
          map((data) => new UserAdminUpdateUserSuccessAction(data)),
          catchError((err) => of(new UserAdminUpdateUserFailureAction(err)))
        )
      )
    ) }
  );

  $userUpdated = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType<UserAdminUpdateUserSuccessAction>(UserAdminActionTypes.Update_User_Success),
        tap((action: UserAdminUpdateUserSuccessAction) =>
          this.messageService.add({
            id: 0,
            detail: `${this.titleCasePipe.transform(action.payload.firstName)} ${this.titleCasePipe.transform(
              action.payload.lastName
            )} Updated`,
            severity: 'success',
          })
        )
      ) },
    { dispatch: false }
  );

  $createUser = createEffect(() =>
    { return this.actions$.pipe(
      ofType<UserAdminCreateUserAction>(UserAdminActionTypes.Create_User),
      mapToPayload<{ user: UserAdminData }>(),
      switchMap((payload: { user: UserAdminData }) =>
        this.userAdminService.createUser(payload.user).pipe(
          map((data) => new UserAdminCreateUserSuccessAction(data)),
          catchError((err) => of(new UserAdminCreateUserFailureAction(err)))
        )
      )
    ) }
  );

  $userCreated = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType<UserAdminCreateUserSuccessAction>(UserAdminActionTypes.Create_User_Success),
        tap((action: UserAdminCreateUserSuccessAction) =>
          this.messageService.add({
            id: 0,
            detail: `${this.titleCasePipe.transform(action.payload.firstName)} ${this.titleCasePipe.transform(
              action.payload.lastName
            )} Created`,
            severity: 'success',
          })
        )
      ) },
    { dispatch: false }
  );

  $loadMyAuthorizedEntities = createEffect(() =>
    { return this.actions$.pipe(
      ofType<UserAdminLoadAuthorizedEntitiesAction>(UserAdminActionTypes.Load_Authorized_Entities),
      switchMap(() =>
        this.userAdminService.getMyAuthorizedEntities().pipe(
          map((data) => new UserAdminLoadAuthorizedEntitiesSuccessAction(data)),
          catchError((err) => of(new UserAdminLoadAuthorizedEntitiesFailureAction(err)))
        )
      )
    ) }
  );

  $loadCarrierUsers = createEffect(() =>
    { return this.actions$.pipe(
      ofType<UserAdminLoadCarrierUsersDataAction>(UserAdminActionTypes.Load_Carrier_Users_Data),
      map((action) => action.payload),
      switchMap((carrierId) =>
        this.userAdminService.getCarrierUsersData(carrierId).pipe(
          map((data) => new UserAdminLoadCarrierUsersDataSuccessAction(data)),
          catchError((err) => of(new UserAdminLoadCarrierUsersDataFailureAction(err)))
        )
      )
    ) }
  );

  $deleteUser = createEffect(() =>
    { return this.actions$.pipe(
      ofType<UserAdminDeleteUserAction>(UserAdminActionTypes.Delete_User),
      map((action) => action.payload),
      switchMap((user) =>
        this.userAdminService.deleteUser(user).pipe(
          map((data) => new UserAdminDeleteUserSuccessAction(data)),
          catchError((err) => of(new UserAdminDeleteUserFailureAction(err)))
        )
      )
    ) }
  );

  $userDeleted = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType<UserAdminDeleteUserSuccessAction>(UserAdminActionTypes.Delete_User_Success),
        tap((action: UserAdminDeleteUserSuccessAction) =>
          this.messageService.add({
            id: 0,
            detail: 'User Deleted',
            severity: 'success',
          })
        )
      ) },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private userAdminService: UserAdminService,
    private messageService: MessageService,
    private titleCasePipe: TitleCasePipe
  ) {}
}
