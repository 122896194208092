<kbxl-user-impersonation-indicator></kbxl-user-impersonation-indicator>
<kbxl-entity-inactive-indicator></kbxl-entity-inactive-indicator>
<kbxl-ie-detection></kbxl-ie-detection>
<div class="page-header d-flex justify-content-center align-items-center">
  <div class="flex-grow-1">
    <a class="header-link px-1 pr-md-3 pl-md-4" [routerLink]="['/']">
      <img src="../assets/Loadshop-poweredbykbx-logo-white-RGB.svg" alt="logo" />
    </a>
  </div>
  <kbxl-user-profile-menu></kbxl-user-profile-menu>
</div>
<div class="page-body d-flex flex-column">
  <div class="flex-grow-1" style="flex-basis: auto">
    <p-tabMenu *ngIf="user$ | async" [model]="menu$ | async" [(activeItem)]="activeItem" styleClass="topmenu">
      <ng-template pTemplate="item" let-item let-i="index">
        <a [routerLink]="item.routerLink" class="p-menuitem-link">
          <span class="p-menuitem-text">{{ item.label }}</span>
          <span *ngIf="item.badge" class="{{ item.badgeStyleClass }}">
            <i style="padding-top: 10%" class="pi pi-exclamation-triangle"></i>
          </span>
        </a>
      </ng-template>
    </p-tabMenu>
    <main role="main" class="container-fluid background p-0">
      <router-outlet></router-outlet>
    </main>
  </div>
  <div class="sticky-bottom p-1 p-md-4">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="terms-of-use p-1">
          <a [routerLink]="['/agreements/terms']">Terms of Use</a>
        </div>
        <div class="privacy-policy p-1">
          <a [routerLink]="['/agreements/privacy']">Privacy Policy</a>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="support-message">
          <div class="p-1">For a load specific question, please use the contact information shown when clicking into the load.</div>
          <div class="p-1 pb-md-4 mb-lg-3 mb-md-0">
            For general questions or system support, please contact
            <a *ngIf="user && user.isCarrier" href="mailto:Loadshop@kbxlogistics.com"> Loadshop&#64;kbxlogistics.com </a>
            <a *ngIf="user && user.isShipper" href="mailto:shipper@loadshop.com"> shipper&#64;loadshop.com </a>
            <a *ngIf="!user || (user && !user.isShipper && !user.isCarrier)" href="mailto:loadshop@kbxlogistics.com">
              loadshop&#64;kbxlogistics.com
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<kbxl-autologin></kbxl-autologin>
<p-toast [baseZIndex]="11000" position="top-right">
  <ng-template let-message pTemplate="message">
    <p innerHtml="{{ message.detail }}"></p>
  </ng-template>
</p-toast>
<p-confirmDialog
  #confirmationDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '425px' }"
  [baseZIndex]="10000"
>
  <p-footer>
    <div class="row p-1">
      <div class="col d-none" [ngClass]="{ 'd-inline-block': confirmationDialog?.rejectVisible }">
        <button
          pButton
          type="button"
          class="p-button-outlined p-button-secondary"
          [label]="!confirmationDialog.rejectLabel ? 'NO' : confirmationDialog.rejectLabel"
          (click)="reject(confirmationDialog)"
        ></button>
      </div>
      <div class="col d-none" [ngClass]="{ 'd-inline-block': confirmationDialog?.acceptVisible }">
        <button
          pButton
          type="button"
          [label]="!confirmationDialog.acceptLabel ? 'YES' : confirmationDialog.acceptLabel"
          (click)="accept(confirmationDialog)"
        ></button>
      </div>
    </div>
  </p-footer>
</p-confirmDialog>
<kbxl-mobile-browser-detection></kbxl-mobile-browser-detection>
<kbxl-user-communication-display-container></kbxl-user-communication-display-container>
<div id="printable-placeholder"></div>
