import { Action } from '@ngrx/store';
import { ActionItemTotalCountData } from '../../models';

export enum ActionItemActionTypes {
  TogglePolling = '[ActionItem] TOGGLE_POLLING',
  LoadCount = '[ActionItem] LOAD_COUNT',
  LoadCount_Success = '[ActionItem] LOAD_COUNT_SUCCESS',
  LoadCount_Failure = '[ActionItem] LOAD_COUNT_FAILURE',
}

export class ActionItemTogglePollingAction implements Action {
  readonly type = ActionItemActionTypes.TogglePolling;
  constructor(public enablePolling: boolean) {}
}
export class ActionItemLoadCountAction implements Action {
  readonly type = ActionItemActionTypes.LoadCount;
}

export class ActionItemLoadCountSuccessAction implements Action {
  readonly type = ActionItemActionTypes.LoadCount_Success;

  constructor(public payload: ActionItemTotalCountData) {}
}
export class ActionItemLoadCountFailureAction implements Action {
  readonly type = ActionItemActionTypes.LoadCount_Failure;
  constructor(public payload: Error) {}
}

export type ActionItemActions =
  | ActionItemTogglePollingAction
  | ActionItemLoadCountAction
  | ActionItemLoadCountSuccessAction
  | ActionItemLoadCountFailureAction;
