import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { VirtualScroller } from 'primeng/virtualscroller';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { LoadBoardService } from '../../../load-board/services';
import { BaseComponent } from '../../../shared/components';
import { LoadView, Search, UserModel } from '../../../shared/models';
import { TransactionTypeService } from '../../../shared/services';
import { PageableQueryHelper } from '../../../shared/utilities';
import { getUserProfileModel, UserState } from '../../../user/store';
import { ExtendedConversation } from '../../models';
import { ChatActiveLoadsSelectAction, ConversationState, getSelectedConversation, getSelectedLoadId } from '../../store';

@Component({
  selector: 'kbxl-active-loads-list',
  templateUrl: './active-loads-list.component.html',
  styleUrls: ['./active-loads-list.component.scss'],
})
export class ActiveLoadsListComponent extends BaseComponent implements OnInit {
  // This is used on mobile when the view doesn't need to be rendered immediately
  // If this is set to true and not viewable on the screen, the virtual scroller will not render anything
  @Input() active = true;

  @ViewChild('virtualScroller') virtualScroller: VirtualScroller;
  conversation: ExtendedConversation;
  user: UserModel;
  virtualLoads: LoadView[];
  loading: boolean;
  selectedLoadId: string;
  pageInfo: PageableQueryHelper;

  private searchFilter: Search;
  quickSearch: string;

  pageSize = 30;

  quickFilter: Subject<string> = new Subject<string>();

  pageReady$: BehaviorSubject<boolean>;

  firstLoad = false;
  constructor(
    private store: Store<ConversationState>,
    private userStore: Store<UserState>,
    private router: Router,
    private route: ActivatedRoute,
    private loadBoardService: LoadBoardService,
    private transactionTypeService: TransactionTypeService
  ) {
    super();

    this.pageReady$ = new BehaviorSubject<boolean>(false);
    this.searchFilter = new Search();

    this.userStore.pipe(select(getUserProfileModel), takeUntil(this.destroyed$)).subscribe((x) => (this.user = x));
  }

  ngOnInit(): void {
    this.virtualLoads = Array.from({ length: this.pageSize });
    this.store.pipe(select(getSelectedLoadId), takeUntil(this.destroyed$)).subscribe((x) => (this.selectedLoadId = x));

    this.store.pipe(select(getSelectedConversation), takeUntil(this.destroyed$)).subscribe((selectedConversation) => {
      if (
        !selectedConversation ||
        !this.conversation ||
        (selectedConversation && this.conversation && selectedConversation.id !== this.conversation.id)
      ) {
        // clear search when a new conversation is selected
        this.quickSearch = '';
        this.searchFilter.quickSearch = '';

        this.conversation = selectedConversation;
        if (this.conversation) {
          const newScac = this.conversation.getCarrierId();
          const newShipperId = this.conversation.getShipperId();
          const existingScac =
            this.searchFilter && this.searchFilter.scacs && this.searchFilter.scacs.length > 0 ? this.searchFilter.scacs[0] : '';
          const existingShipperId =
            this.searchFilter && this.searchFilter.shipperIds && this.searchFilter.shipperIds.length > 0
              ? this.searchFilter.shipperIds[0]
              : '';

          let shouldRunSearch = false;
          if (
            newScac.toLocaleLowerCase() !== existingScac.toLocaleLowerCase() ||
            newShipperId.toLocaleLowerCase() !== existingShipperId.toLocaleLowerCase()
          ) {
            // If the previous conversation has a different shipper or carrier than the current
            // conversation, then we should rerun the active loads search to load the correct
            // loads for that conversation.  Do not bother reloading if the new converation is
            // just with a different user at the same entity
            shouldRunSearch = true;
          }
          this.searchFilter.scacs = [this.conversation.getCarrierId()];
          this.searchFilter.shipperIds = [this.conversation.getShipperId()];

          if (!this.pageInfo) {
            // Run search if this is the first conversation selection and no active
            // loads have been loaded yet.
            this.pageInfo = new PageableQueryHelper(this.pageSize, 0, this.searchFilter);
            this.pageReady$.next(true);
          } else if (shouldRunSearch) {
            this.runSearch(this.pageInfo);
          }
        }
      }
    });

    this.pageReady$
      .pipe(
        filter((x) => !!x),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        // only check the URL once everything is ready
        this.route.queryParams
          .pipe(
            filter((queryParams) => queryParams.q),
            map((queryParams) => queryParams.q),
            takeUntil(this.destroyed$)
          )
          .subscribe((query) => {
            if (query && query.length > 0) {
              this.searchFilter.quickSearch = query;
              if (this.firstLoad) {
                // reset loads
                this.virtualLoads = [];
                const pageInfo = new PageableQueryHelper(this.pageSize, 1, this.searchFilter);
                this.runSearch(pageInfo);
              }

              // value came from url, populate the filter text
              this.quickSearch = query;
            }
          });
      });

    this.quickFilter
      .asObservable()
      .pipe(takeUntil(this.destroyed$), debounceTime(350), distinctUntilChanged())
      .subscribe((x) => {
        // put in url incase user reloads page
        this.router.navigate([], {
          queryParams: {
            q: x, // query
            a: null, // attach to message
            s: null, // select load if the filter brings the list result to 1
          },
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });

        if (!x) {
          this.clearSearch();
        }
      });
  }

  selectLoad(load: LoadView): void {
    this.store.dispatch(new ChatActiveLoadsSelectAction(load.loadId));
    this.router.navigate(['load-details', load.loadId], { relativeTo: this.route });
  }

  loadActiveLoadsLazy() {
    if (!this.conversation || !this.pageInfo) {
      return;
    }
    if (!this.firstLoad) {
      this.firstLoad = true;
    }
    this.pageInfo.pageNumber++;
    // fetch the current info and query for the next page
    const pageInfo = new PageableQueryHelper(this.pageSize, this.pageInfo.pageNumber, this.searchFilter);

    this.runSearch(pageInfo);
  }

  updateSearch(): void {
    this.quickFilter.next(this.quickSearch);
  }

  clearSearch(): void {
    // reset loads
    this.quickSearch = null;
    this.quickFilter.next(this.quickSearch);
    // run search
    this.virtualLoads = [];
    const pageInfo = new PageableQueryHelper(this.pageSize, 1, this.searchFilter);
    this.searchFilter.quickSearch = null;
    this.runSearch(pageInfo);
  }

  runSearch(pageInfo: PageableQueryHelper): void {
    this.loading = true;
    this.loadBoardService.getActiveLoads(pageInfo).subscribe((page) => {
      // reset the list if a search query happened
      if (pageInfo.pageNumber === 1) {
        // if (this.virtualScroller) {
        //   // console.error('PRIMENG- Call removed - this.virtualScroller.clearCache();');
        //   //this.virtualScroller.clearCache();
        // }

        this.virtualLoads = Array.from({ length: page.totalRecords });
      }

      // populate page of virtual loads
      const offset = (pageInfo.pageNumber - 1) * pageInfo.pageSize;
      Array.prototype.splice.apply(this.virtualLoads, [...[offset, this.pageSize], ...page.data]);

      // trigger change detection
      this.virtualLoads = [...this.virtualLoads];
      this.loading = false;
      // assign so we know what page we are on
      this.pageInfo = pageInfo;

      // check if we should auto select / open the load
      if (this.virtualLoads && this.virtualLoads.length === 1 && this.route.snapshot.queryParams.s === 'true') {
        this.selectLoad(this.virtualLoads[0]);
      }
    });
  }
  getContactDisplay(load: LoadView): string {
    if (!this.user) {
      return '';
    }
    if (this.transactionTypeService.getTransactionTypesForMarketplace().find((x) => x === load.transactionTypeId)) {
      return '';
    }

    if (this.user.isShipper) {
      return load.bookedUser?.fullName;
    } else {
      if (!load.contacts || load.contacts.length === 0) {
        return '';
      }
      return load.contacts[0].display;
    }
  }
  getBadgeDisplay(load: LoadView): string {
    if (!load) {
      return '';
    }
    if (this.transactionTypeService.getTransactionTypesForMarketplace().find((x) => x === load.transactionTypeId)) {
      return 'Marketplace';
    } else if (this.transactionTypeService.getTransactionTypesForBooked().find((x) => x === load.transactionTypeId)) {
      return 'Booked';
    } else if (this.transactionTypeService.getTransactionTypesForDelivered().find((x) => x === load.transactionTypeId)) {
      return 'Delivered';
    }
  }
  getBadgeStyleClass(load: LoadView): string {
    if (!load) {
      return '';
    }
    if (this.transactionTypeService.getTransactionTypesForMarketplace().find((x) => x === load.transactionTypeId)) {
      return 'badge-primary';
    } else if (this.transactionTypeService.getTransactionTypesForBooked().find((x) => x === load.transactionTypeId)) {
      return 'badge-success';
    } else if (this.transactionTypeService.getTransactionTypesForDelivered().find((x) => x === load.transactionTypeId)) {
      return 'badge-secondary';
    }
  }

  getPickUpDate(load: LoadView): Date {
    if (!load) {
      return null;
    }
    if (load.originEarlyDtTm) {
      return load.originEarlyDtTm;
    }
    if (load.originLateDtTm) {
      return load.originLateDtTm;
    }
    return null;
  }
  getDeliveryDate(load: LoadView): Date {
    if (!load) {
      return null;
    }
    if (load.destEarlyDtTm) {
      return load.destEarlyDtTm;
    }
    if (load.destLateDtTm) {
      return load.destLateDtTm;
    }
    return null;
  }
}
