export interface SmartSpotQuoteRequest {
  originCity: string;
  originState: string;
  originPostalCode: string;
  originCountry: string;
  destinationCity: string;
  destinationState: string;
  destinationPostalCode: string;
  destinationCountry: string;
  equipmentId: string;
  weight: number;
  pickupDate: Date;
  pickupTime: string;
  deliveryDate: Date;
  deliveryTime: string;
  // for new spotprice
  commodity: string;
  shipmentServices: string[];
  equipmentCategory: string;
  pickupAppointmentTypeCode: string;
  dropoffAppointmentTypeCode: string;
  totalPostedScacs: number;
  totalPostedCarriers: number;
}
