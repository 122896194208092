import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base-component';

@Component({
  selector: 'kbxl-hours-to-days-slider',
  templateUrl: './hours-to-days-slider.component.html',
  styleUrls: ['./hours-to-days-slider.component.scss'],
})
export class HoursToDaysSliderComponent extends BaseComponent implements OnInit {
  @Input() hours: number;
  @Input() inputName: string;
  @Input() control: UntypedFormControl;
  @Output() hoursChange: EventEmitter<number> = new EventEmitter<number>();
  private valueChangeSubject = new Subject<number>();

  constructor() {
    super();
  }

  ngOnInit(): void {
    // debouncetime is added to keep the values firing from the change and slid end events  to be in control
    this.valueChangeSubject.pipe(takeUntil(this.destroyed$), debounceTime(1000), distinctUntilChanged()).subscribe((x) => {
      this.control.markAsTouched();
      this.hoursChange.emit(x);
    });
  }

  handleChange(e) {
    this.valueChangeSubject.next(e.value);
  }

  handleSlideEnd(e) {
    this.valueChangeSubject.next(e.value);
  }
}
