import { CarrierDisplayData } from './carrier-display-data';
import { LoadCounterOfferData } from './load-counter-offer-data';

export interface LoadCounterOfferGroupData {
  rootLoadCounterOfferId: number;
  fuelRate: number;
  lineHaulRate: number;
  loadshopFee?: number;
  totalRate: number;
  customerId: string;
  carrierData: CarrierDisplayData;
  counterOffers: LoadCounterOfferData[];
  currentCarrierOfferId: number;
  currentShipperOfferId: number;
  currentStatus: string;
  contractCarrierCounterOffer: boolean;
}
